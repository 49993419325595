.type-indicator {
  border-radius: 50%;
  display: inline-block;
  &.type-regularized {
    background-color: var(--bg-regularized);
  }
  &.type-deduction {
    background-color: var(--bg-deduction);
  }
  &.type-override {
    background-color: var(--bg-override);
  }
  &.type-punch {
    background-color: var(--bg-punch);
  }
  &.type-on-duty {
    background-color: var(--bg-onduty);
  }
  &.type-late {
    background-color: var(--bg-late);
  }
  &.type-weekoff {
    background-color: var(--bg-weekoff);
  }
  &.type-leave {
    background-color: var(--bg-leave);
  }
  &.type-present {
    background-color: var(--bg-present);
  }
  &.type-absent {
    background-color: var(--bg-absent);
  }
  &.type-holiday {
    background-color: var(--bg-holiday);
  }
  &.type-national-holiday {
    background-color: var(--bg-national);
  }
  &.type-floating-holiday {
    background-color: var(--bg-floating);
  }
  &.type-regular-holiday {
    background-color: var(--bg-regular);
  }
  &.type-WFH {
    background-color: var(--bg--WFH);
  }
  &.type-early-checkout {
    background-color: var(--bg--early-checkout);
  }  &.type-overdue {
    background-color: var(--bg--overdue);
  }
  &.type-on-behalf-request {
    background-color: var(--bg--on-behalf-request);
  }
}
