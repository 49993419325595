$fontweight: (
  300,
  400,
  500,
  600,
  700
);
@each $weight in $fontweight {
  .fw-#{$weight} {
    font-weight: #{$weight} !important;
  }
}
$shadows: (
  "0": none,
  "sm": 0rem 0.5rem 1rem 0rem #121b211a,
  "md": 0rem 0.5rem 1rem 0rem #121b211a,
  "lg": 0rem 1.5rem 8rem 0rem #121b2166,
);
@each $shadow, $value in $shadows {
  .shadow-#{$shadow} {
    box-shadow: #{$value} !important;
  }
}
$radius: (
  "0": 0,
  "2": 0.125,
  "4": 0.25,
  "6": 0.375,
  "8": 0.5,
  "16": 1,
  "24": 1.5,
  "40": 2.5,
);
// @each $r, $value in $radius {
//   .radius-#{$r},
//   .radius-l-#{$r},
//   .radius-t-#{$r},
//   .radius-tl-#{$r} {
//     border-top-left-radius: #{$value}rem !important;
//   }
//   .radius-#{$r},
//   .radius-l-#{$r},
//   .radius-b-#{$r},
//   .radius-bl-#{$r} {
//     border-bottom-left-radius: #{$value}rem !important;
//   }
//   .radius-#{$r},
//   .radius-r-#{$r},
//   .radius-t-#{$r},
//   .radius-tr-#{$r} {
//     border-top-right-radius: #{$value}rem !important;
//   }
//   .radius-#{$r},
//   .radius-r-#{$r},
//   .radius-b-#{$r},
//   .radius-tr-#{$r} {
//     border-bottom-right-radius: #{$value}rem !important;
//   }
// }
$radius-positions: (
  "l": (border-top-left-radius, border-bottom-left-radius),
  "r": (border-top-right-radius, border-bottom-right-radius),
  "t": (border-top-left-radius, border-top-right-radius),
  "b": (border-bottom-left-radius, border-bottom-right-radius),
  "tl": (border-top-left-radius),
  "tr": (border-top-right-radius),
  "bl": (border-bottom-left-radius),
  "br": (border-bottom-right-radius)
);
@each $r, $value in $radius {
  .radius-#{$r} {
    border-radius: #{$value}rem !important;
  }
  @each $key, $properties in $radius-positions {
    .radius-#{$key}-#{$r} {
      @each $property in $properties {
        #{$property}: #{$value}rem !important;
      }
    }
  }
}
.border {
  border: 0.0625rem solid #e4e8ea !important;
}
@media only screen and (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}
.lh-28 {
  line-height: 1.75rem;
}
.pointer {
  cursor: pointer;
}
.scrollbar-10 {
  @include scrollbars(0.625rem, rgba(50, 69, 82, 0.15), #f8fafb);
}
.w-35 {
  width: 35% !important;
}
$rem: (
  "1": 0.063,
  "2": 0.125,
  "3": 0.188,
  "4": 0.25,
  "5": 0.313,
  "6": 0.375,
  "7": 0.438,
  "8": 0.5,
  "9": 0.563,
  "10": 0.625,
  "11": 0.688,
  "12": 0.75,
  "13": 0.813,
  "14": 0.875,
  "15": 0.938,
  "16": 1,
  "17": 1.063,
  "18": 1.125,
  "20": 1.25,
  "22": 1.375,
  "23": 1.438,
  "24": 1.5,
  "25": 1.563,
  "26": 1.625,
  "28": 1.75,
  "30": 1.875,
  "31": 1.938,
  "32": 2,
  "35": 2.188,
  "36": 2.25,
  "40": 2.5,
  "42": 2.625,
  "44": 2.75,
  "45": 2.813,
  "46": 2.875,
  "48": 3,
  "50": 3.125,
  "56": 3.5,
  "57": 3.563,
  "64": 4,
  "68": 4.25,
  "72": 4.5,
  "75": 4.688,
  "80": 5,
  "88": 5.5,
  "90": 5.625,
  "96": 6,
  "100": 6.25,
);
@each $px, $value in $rem {
  .fs-#{$px} {
    font-size: #{$value}rem !important;
  }
  .gap-#{$px} {
    gap: #{$value}rem !important;
  }
  .row-#{$px} {
    --bs-gutter-x: #{$value}rem;
    --bs-gutter-y: #{$value}rem;
  }
  .li-#{$px} {
    li+li {
      padding-top: #{$value}rem;
    }
  }
  .sq-#{$px} {
    width: #{$value}rem !important;
    height: #{$value}rem !important;
    min-height: unset !important;
    aspect-ratio: 1;
    flex-shrink: 0;
    object-fit: contain !important;
  }
}
// @each $px, $value in $rem {
//   .m-#{$px},
//   .my-#{$px},
//   .mt-#{$px} {
//     margin-top: #{$value}rem !important;
//   }
//   .m-#{$px},
//   .my-#{$px},
//   .mb-#{$px} {
//     margin-bottom: #{$value}rem !important;
//   }
//   .m-#{$px},
//   .mx-#{$px},
//   .ms-#{$px} {
//     margin-left: #{$value}rem !important;
//   }
//   .m-#{$px},
//   .mx-#{$px},
//   .me-#{$px} {
//     margin-right: #{$value}rem !important;
//   }
//   .p-#{$px},
//   .py-#{$px},
//   .pt-#{$px} {
//     padding-top: #{$value}rem !important;
//   }
//   .p-#{$px},
//   .py-#{$px},
//   .pb-#{$px} {
//     padding-bottom: #{$value}rem !important;
//   }
//   .p-#{$px},
//   .px-#{$px},
//   .ps-#{$px} {
//     padding-left: #{$value}rem !important;
//   }
//   .p-#{$px},
//   .px-#{$px},
//   .pe-#{$px} {
//     padding-right: #{$value}rem !important;
//   }
// }
$properties: (
  (margin-top, mt, my, m),
  (margin-bottom, mb, my, m),
  (margin-left, ms, mx, m),
  (margin-right, me, mx, m),
  (padding-top, pt, py, p),
  (padding-bottom, pb, py, p),
  (padding-left, ps, px, p),
  (padding-right, pe, px, p)
);
@each $px, $value in $rem {
  @if $px !="1" and $px !="2" and $px !="3" and $px !="4" and $px !="5" {
    @each $property, $short, $combined, $base in $properties {
      .#{$base}-#{$px},
      .#{$combined}-#{$px},
      .#{$short}-#{$px} {
        #{$property}: #{$value}rem !important;
      }
    }
  }
}
$table-cell: (
  "4": 0.25,
  "6": 0.375,
  "8": 0.5,
  "10": 0.625,
  "12": 0.75,
  "14": 0.875,
  "16": 1,
  "18": 1.125,
  "20": 1.25,
  "22": 1.375,
  "24": 1.5,
  "28": 1.75,
  "32": 2,
);
@each $px, $value in $table-cell {
  .td-fs-#{$px} td {
    font-size: #{$value}rem !important;
  }
  .th-fs-#{$px} th {
    font-size: #{$value}rem !important;
  }
  @each $property, $short, $combined, $base in $properties {
    .td-#{$base}-#{$px} td,
    .td-#{$combined}-#{$px} td,
    .td-#{$short}-#{$px} td {
      #{$property}: #{$value}rem !important;
    }
    .th-#{$base}-#{$px} th,
    .th-#{$combined}-#{$px} th,
    .th-#{$short}-#{$px} th {
      #{$property}: #{$value}rem !important;
    }
  }
}