// @import '3-helpers/mixin';
.btn {
  @include flex($x: center, $y: center);
  border-radius: 0.25rem;
  padding: 0 1rem;
  min-height: 2.5rem;
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
  }
  &-primary {
    color: #fff;
    background-color: $accent-01-active !important;
    border-color: $accent-01-active !important;
    &:disabled {
      background-color: $accent-01-inactive !important;
      border-color: $accent-01-inactive !important;
    }
    &:hover {
      background-color: $accent-01-hover !important;
      border-color: $accent-01-hover !important;
    }
    &:active,
    &:focus {
      background-color: $accent-01-selected !important;
      border-color: $accent-01-selected !important;
    }
  }
  &-accent2 {
    color: #fff;
    background-color: $accent-02-active !important;
    border-color: $accent-02-active !important;
    &:disabled {
      background-color: $accent-02-inactive !important;
      border-color: $accent-02-inactive !important;
    }
    &:hover {
      background-color: $accent-02-hover !important;
      border-color: $accent-02-hover !important;
    }
    &:active,
    &:focus {
      background-color: $accent-02-selected !important;
      border-color: $accent-02-selected !important;
    }
  }
  &-outline-accent2 {
    color: $accent-02-active !important;
    border-color: $accent-02-active !important;
    &:disabled {
      // background-color: $accent-01-inactive;
      border-color: $accent-02-inactive !important;
      color: $accent-02-inactive !important;
    }
    &:hover {
      background-color: $accent-02-hover !important;
      border-color: $accent-02-hover !important;
      color: #fff !important;
    }
    &:active,
    &:focus {
      background-color: $accent-02-selected !important;
      border-color: $accent-02-selected !important;
      color: #fff !important;
    }
  }
  &-secondary {
    color: $high-contrast-light !important;
    background-color: #eaedef !important;
    border-color: #eaedef !important;
    &:hover {
      background-color: #acbac3 !important;
      border-color: #acbac3 !important;
    }
    &:active,
    &:focus {
      background-color: #acbac3 !important;
      border-color: #acbac3 !important;
    }
  }
  &-outline-primary {
    color: $accent-01-active !important;
    border-color: $accent-01-active !important;
    &:disabled {
      // background-color: $accent-01-inactive;
      border-color: $accent-01-inactive !important;
      color: $accent-01-inactive !important;
    }
    &:hover {
      background-color: $accent-01-hover !important;
      border-color: $accent-01-hover !important;
      color: #ffffff !important;
    }
    // &:active,
    &:focus {
      background-color: $accent-01-active !important;
      border-color: $accent-01-selected !important;
      color: #fff !important;
    }
  }
  &-outline-secondary {
    color: $medium-contrast-light !important;
    border-color: $low-contrast-light !important;
    &:hover {
      background-color: #acbac3 !important;
      border-color: #acbac3 !important;
    }
    &:active,
    &:focus {
      background-color: #acbac3 !important;
      border-color: #acbac3 !important;
      color: #fff !important;
    }
  }
}
.btn-warning-2 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f38300;
  --bs-btn-border-color: #f38300;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f38300d6;
  --bs-btn-hover-border-color: #f38300d6;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e2aa03;
  --bs-btn-active-border-color: #e2aa03;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f38300;
  --bs-btn-disabled-border-color: #f38300;
}
.btn-white-accent1 {
  --bs-btn-color: #0078ce;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #479edc;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b8edf;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(255, 255, 255, 0.125);
  --bs-btn-disabled-color: #0078ce;
  --bs-btn-disabled-bg: #ffffffd9;
  --bs-btn-disabled-border-color: #ffffffd9;
}

.btn-square {
  @include sq($s: 2.5rem);
}
.btn-icon i {
  margin-right: 0.5rem;
  font-size: 0.75rem;
}
.btn-add {
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}
.btn-sm {
  border-radius: 0.125rem;
  height: 2.5rem;
  font-size: 0.875rem;
  min-width: 8.75rem;
  font-weight: 600;
}
.btn-reset {
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.btn-group .btn {
  white-space: nowrap;
}
.btn-check:checked + .btn-outline-accent2 {
  color: #fff !important;
  background-color: $accent-02-active;
  border-color: $accent-02-active;
}
.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}
// .bi-x-lg::before{    font-weight: 600!important;}
// .display-dots .bi-three-dots-vertical{
//   color: $low-contrast-light;
// }
.btn-outline-primary.disabled {
  background-color: #6dcdd8cc;
  border-color: none;
  color: #fff;
}

.search-btn {
  cursor: text !important;
}
.btn-c1 {
  border: 0.063rem solid #e4e8ea;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 2.5rem;
  min-width: 3rem;
  background-color: #ffffff;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  &.active {
    background-color: #0078ce;
    border-color: #0078ce;
    color: #ffffff;
  }
  &:hover {
    background-color: #eff4f6;
    border-color: #e4e8ea;
    color: #324552;
  }
}
.btn-white {
  --bs-btn-color: #324552;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #dee3e7;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-hover-border-color: #dee3e7;
  --bs-btn-hover-color: #324552;
  // --bs-btn-hover-bg;
  // min-height: 2.5rem;
}
// .btn-group > :not(.btn-check:first-child):disabled + .btn, .btn-group > .btn-group:not(:first-child):disabled{
//     margin-left: 0px!important;
// }
