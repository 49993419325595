.holiday-calender {
  font-family: "Work Sans", sans-serif;
  .mat-calendar-body-label {
    visibility: hidden;
  }
  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
  mat-calendar-header {
    display: none;
  }
  .mat-calendar-table-header-divider::after {
    display: none;
  }
  .mat-calendar-table-header th {
    font-weight: 600;
    font-size: 0.813rem;
  }
  .mat-calendar-body-cell-content {
    font-size: 0.813rem;
  }
  .mat-calendar-body-cell-content {
    width: 85% !important;
    height: 60% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mat-calendar-body-today {
    border-color: #0078ce !important ;
  }
  .mat-calendar-body-selected {
    background-color: initial;
    color: initial;
    border: 0.0625rem solid rgba(54, 157, 232, 0.87);
    &:before {
      @include sq(1.188rem);
      @extend .flex-center;
      border-radius: 50%;
      background: #0078ce;
      content: "\F272";
      font-family: bootstrap-icons !important;
      position: absolute;
      right: 0;
      top: 0;
      color: #fff;
    }
  }
  .week-off .mat-calendar-body-cell-content {
    background-color: #b1e3da;
  }
  .national-holiday .mat-calendar-body-cell-content {
    background-color: #fbefdd;
  }
  .floating-holiday .mat-calendar-body-cell-content {
    background-color: #dde8f5;
  }
  .national-holiday,
  .floating-holiday {
    .mat-calendar-body-cell-content:after {
      @extend .flex-center;
      @extend .shadow-sm;
      content: "Republic Day";
      position: absolute;
      top: 100%;
      padding: 0.5rem 1.5rem;
      background-color: #fff;
      box-shadow: 0rem 0rem 0.0625rem #eaeaea;
      min-height: 2rem;
      width: 8.75rem;
      border-radius: 0.25rem;
      line-height: 1.125rem;
      // white-space: nowrap;
      font-size: 0.875rem;
      z-index: 2;
      opacity: 0;
      transition: all 600ms;
      color: #324552;
    }
    &:hover .mat-calendar-body-cell-content:after {
      opacity: 1;
    }
  }
}
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  .mat-calendar-body-cell-content {
  background-color: #dceafb !important;
}
.calendar-control {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 2rem;
  .calendar-title {
    font-weight: 600;
    font-size: 1rem;
    text-transform: uppercase;
  }
  .calendar-button {
    position: absolute;
    top: 0;
    @extend .flex-center;
    @include sq(2rem);
    border: 0.0625rem solid #e4e8ea;
    color: #31aab7;
    border-radius: 50%;
    cursor: pointer;
  }
  .calendar-prev-button {
    left: 0.5rem;
  }
  .calendar-next-button {
    right: 0.5rem;
  }
}
.calender-highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem;
  border-top: 0.0625rem solid #eaedef;
  .special-day {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .indicator {
      width: 1.125rem;
      height: 0.625rem;
      border-radius: 7rem;
    }
    .name {
      font-weight: 600;
      font-size: 0.75rem;
    }
  }
  .week-off .indicator {
    background-color: #b1e3da;
  }
  .national-holiday .indicator {
    background-color: #fbefdd;
  }
  .floating-holiday .indicator {
    background-color: #dde8f5;
  }
}
// *********************************
//*********On Duty Calender*********
// *********************************
.fullcalendar-reset {
  font-family: "Work Sans", sans-serif;
  --fc-border-color: #eaedef;
  --fc-today-bg-color: #ecf2f5;
  //main header
  .fc-header-toolbar {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .fc-toolbar-title {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: normal;
      line-height: normal;
    }
    .fc-prev-button,
    .fc-next-button {
      @include sq(2rem);
      @extend .flex-center;
      color: #31aab7;
      background-color: #fff;
      border-radius: 50% !important;
      border: 0.0625rem solid #e4e8ea;
      .fc-icon {
        font-size: 1.2em;
      }
      &:hover,
      &:active {
        background-color: #31aab7 !important;
        color: #fff;
        border-color: #31aab7 !important;
      }
      &:focus,
      &:active {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
  // Week Name table header
  .fc-col-header-cell {
    background-color: #f8fafb;
    border-bottom: 0.125rem solid #eaedef;
    .fc-scrollgrid-sync-inner {
      @extend .flex-center;
      height: 2rem;
      font-size: 0.75rem;
      font-weight: 400;
      text-transform: capitalize;
      a {
        color: #222730;
      }
    }
  }
  //Day Cell in calender
  .fc-daygrid-day-frame {
    .fc-daygrid-day-number {
      color: #222730;
    }
  }
}
.fullcalender-border-0 {
  --fc-border-color: #fff0 !important;
  .fc-col-header-cell {
    background-color: unset !important;
    border: none !important;
  }
}
// **************************************
// Calendar for On-duty and Regularization
// **************************************
.fullcalendar-fulloption {
  .fc-header-toolbar {
    max-width: 22.875rem;
  }
  .fc-toolbar-title {
    font-size: 0.875rem !important;
  }
  .fc-daygrid-day-frame {
    // aspect-ratio: 12 / 10;
    min-height: 6rem !important;
  }
  .fc-daygrid-day-top {
    float: right;
    position: absolute;
    top: 0;
    left: 0;
    // height: 1.625rem;
    .fc-daygrid-day-number {
      padding: 0 0 0 0.5rem;
      line-height: 2rem;
    }
  }
  .fc-daygrid-day-frame {
    overflow: hidden;
  }
  .fc-daygrid-day-events {
    float: left;
    width: calc(100% - 2.5rem);
    padding-left: 0.25rem;
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .fc-daygrid-event {
    background-color: transparent !important;
    color: #566976 !important;
    border: none !important;
    margin: 0 !important;
    pointer-events: none;
  }
  .time-event {
    white-space: normal !important;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .start-time,
    .end-time,
    .duration,
    .overtime {
      padding-left: 1.125rem;
      color: #566976;
      white-space: nowrap;
      display: block;
      font-size: 0.688rem;
      line-height: 0.625rem;
      position: relative;
      font-weight: 500;
      &::before {
        font-family: "icomoon" !important;
        position: absolute;
        color: #0078ce;
        font-size: 0.625rem;
      }
    }
    .overtime {
      color: #e59e00;
      &::before {
        content: "\e965"; //stopwatch-fill
        // font-size: .625rem;
        left: 0.125rem;
        color: #e59e00;
      }
    }
    .start-time::before {
      content: "\e929"; //arrow-right
      // font-size: 1rem;
      left: 0.1875rem;
    }
    .end-time::before {
      content: "\e909"; //arrow-left
      // font-size: 1rem;
      left: 0.1875rem;
    }
    .duration::before {
      content: "\e92d"; //clock1
      // font-size: .625rem;
      left: 0.1875rem;
    }
  }
  .fc-day-other {
    .time-event,
    .first-cell-div {
      display: none;
    }
  }
  .selected-day {
    &::after,
    &::before {
      height: calc(1.5rem + 1px);
    }
    &:not(
        .status-leave-present,
        .status-absent-present,
        .status-present-leave,
        .status-present-absent
      ) {
      &::after,
      &::before {
        width: calc(100% + 1px);
      }
    }
    &.status-leave-present,
    &.status-absent-present,
    &.status-present-leave,
    &.status-present-absent {
      &::after {
        width: calc(50% + 1px) !important;
      }
    }
    .tag-cell {
      right: -0.125rem !important;
    }
  }
  .first-cell-div {
    position: absolute;
    height: 2rem;
    width: calc(100% - 1.75rem);
    // margin-left: auto;
    right: 0;
    display: flex;
    .tag-cell {
      position: absolute;
      background-color: #ffc107;
      color: #324552;
      font-weight: 500;
      font-size: 0.5rem;
      right: 0;
      height: 1rem;
      padding: 0 0.25rem 0 0.625rem;
      clip-path: polygon(100% 0, 100% 100%, 0% 100%, 20% 50%, 0% 0%);
      @extend .flex-center;
    }
    .type-indicator {
      // position: absolute;
      // top: 50%;
      // transform: translateY(-50%);
      @extend .sq-10;
      margin-top: .75rem;
      margin-right: 4px;
    }
  }
}
.fullcalendar-c1 {
  .selected-day .checkbox-icon:before {
    content: "\f26c" !important;
    color: #31aab7;
  }
  .checkbox-icon {
    position: absolute;
    top: 65%;
    left: calc(100% + 0.875rem);
    font-size: 1rem;
    color: #acbac3;
    background-color: #fff;
    line-height: 1;
  }
  .fc-day .checkbox-icon:before {
    content: "\F584";
  }
  .fc-header-toolbar {
    margin-bottom: 0.5rem !important;
  }
}
.fullcalendar-c3:not(.fullcalendar-c2) {
  .fc-header-toolbar {
    // margin-bottom: 0.5rem !important;
  }
  .fc-day-today {
    background-color: var(--bg--currentday) !important;
    outline: 1px solid #0078ce;
    outline-offset: -1px;
    z-index: 99;
  }
  .selected-day {
    background-color: #96b2e0 !important;
    &:not(first-cell-div) {
      *:not(.tag-cell),
      *::before,
      *::after {
        color: #ffffff !important;
      }
    }
  }
  .fc-daygrid-day {
    .fc-daygrid-day-frame:first-child {
      padding-top: 1.5625rem;
    }
  }
}

.fullcalendar-c2 {
  // .fc-header-toolbar .fc-toolbar-title {
  //   text-transform: uppercase;
  // }
  // .fc-scrollgrid {
  //   border-top-width: 0rem !important;
  //   border-left-width: 0rem !important;
  // }
  // .fc-scrollgrid-section-header > th {
  //   border-right-width: 0rem !important;
  // }
  // .fc-scrollgrid-section-header .fc-scroller {
  //   border: 1px solid rgba(109, 205, 216, 0.8);
  //   border-radius: 0.5rem 0.5rem 0px 0px;
  //   overflow: hidden !important;
  //   @include media(">=figma") {
  //     border: 0.0625rem solid rgba(109, 205, 216, 0.8);
  //   }
  // }
  // .fc-col-header-cell {
  //   background-color: rgba(49, 170, 183, 0.1);
  //   // background-color: #6dcdd8cc;
  //   border-color: transparent !important;
  //   .fc-scrollgrid-sync-inner {
  //     height: 3.75rem;
  //     font-weight: 600;
  //     font-size: 0.75rem;
  //     a {
  //       color: #31aab7 !important;
  //     }
  //   }
  // }
  .fc-scrollgrid-section-body {
    // & > td {
    //   border-width: 0;
    // }
    // .fc-scroller {
    //   border: 1px solid #e4e8ea;
    //   overflow: hidden;
    //   border-top-width: 0;
    //   border-radius: 0 0 0.5rem 0.5rem;
    //   @include media(">=figma") {
    //     border: 0.0625rem solid #e4e8ea;
    //   }
    // }
    .fc-daygrid-day {
      padding: 2px;
      position: relative;
      @include media(">=figma") {
        padding: 0.125rem;
      }
      .fc-daygrid-day-frame {
        // aspect-ratio: 1;
        height: 4.375rem;
      }
      .fc-daygrid-day-number {
        font-size: 0.75rem;
        color: #222730;
      }
      &.fc-day-other .fc-daygrid-day-number {
        color: #7d92a1;
      }
    }
  }
  .fc-scrollgrid-sync-inner {
    padding-top: 0 !important;
  }
  .fc-h-event {
    border: none;
    background-color: unset;
  }
  .fc-scrollgrid-sync-table tr {
    .fc-daygrid-day:first-child,
    .fc-daygrid-day:last-child {
      padding-left: 3px;
      @include media(">=figma") {
        padding-left: 0.1875rem;
      }
    }
  }
  .holiday-status {
    &.fc-day-today {
      background-color: inherit !important;
    }

    &:not(.fc-day-other) {
      .fc-daygrid-day-frame {
        .fc-daygrid-day-top {
          border-radius: 0.5rem;
          width: 100%;
          position: relative;
          border: 1px solid transparent;
        }
        &::after {
          content: attr(data-text);
          position: absolute;
          font-weight: 500;
          font-size: 0.625rem;
          inset: auto auto 0.625rem 0.625rem;
          width: calc(100% - (1.25rem));
          word-break: break-word;
        }
      }
    }
  }
  .holiday-status {
    .fc-daygrid-day-top {
      height: 100%;
    }
    &.cell-status {
      .fc-daygrid-day-top {
        height: calc(100% - (1.5rem + 0.125rem));
      }
      .fc-daygrid-day-frame::after {
        bottom: 2rem;
      }
    }
  }
  .national-holiday:not(.fc-day-other) {
    .fc-daygrid-day-frame::after {
      color: var(--c-national);
    }
    .fc-daygrid-day-frame .fc-daygrid-day-top {
      background-color: var(--bg-national);
    }
  }
  .floating-holiday:not(.fc-day-other) {
    .fc-daygrid-day-frame::after {
      color: var(--c-floating);
    }
    .fc-daygrid-day-frame .fc-daygrid-day-top {
      background-color: var(--bg-floating);
    }
  }
  .regular-holiday:not(.fc-day-other) {
    .fc-daygrid-day-frame::after {
      color: var(--c-regular);
    }
    .fc-daygrid-day-frame .fc-daygrid-day-top {
      background-color: var(--bg-regular);
    }
  }
  // .weekoff:not(.fc-day-other)::before {
  //   content: "";
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   border-color: transparent #dc3545 #dc3545 transparent;
  //   border-width: 0.4063rem;
  //   border-style: solid;
  //   z-index: 10;
  // }
  .fc-day-today .fc-daygrid-day-number {
    border: 1px solid #0078ce;
    background-color: #ffffff;
    @include media(">=figma") {
      border: 0.0625rem solid #0078ce;
    }
  }
  .selected-day {
    background-color: inherit !important;
    .fc-daygrid-day-top {
      border-color: #0078ce !important;
      animation: selected 1s normal forwards ease-in-out;
      animation-iteration-count: 1;
      animation-delay: 3s;
      @include media(">=figma") {
        outline: 0.0625rem solid #0078ce;
      }
    }
  }
  @keyframes selected {
    0% {
      border-color: #0078ce;
    }

    100% {
      border-color: transparent;
    }
  }
  .fc-daygrid-day-number {
    @include sq(1.375rem);
    @extend .flex-center;
    margin-top: 0.25rem;
    margin-left: 0.25rem;
    border-radius: 50%;
    padding: 4px !important;
    position: absolute !important;
    left: 0;
  }
}
// ============================
// ======== Date Range ========
// ============================
.md-drppicker {
  &.shown {
    font-family: "Work Sans", sans-serif !important;
    box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1) !important;
    border-radius: 0.25rem !important;
    padding: 0rem !important;
    max-width: 60.375rem !important;
    max-height: 22.875rem !important;
    min-height: 19.5rem !important;
    display: flex;
    transform: translateY(0.813rem) !important;
  }
  & > * {
    flex-shrink: 0;
  }
}
.md-drppicker {
  .ranges {
    width: 18.625rem !important;
    & + .calendar {
      border-left: 0.0625rem solid #e4e8ea;
    }
  }
  .ranges ul {
    padding: 1rem 0 !important;
  }
  .ranges ul li button {
    font-size: 0.875rem;
    padding: 0 1.5rem;
    color: #566976;
    height: 2.5rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    &.active {
      color: $accent-02-active !important;
      background: #0078ce1c !important;
      font-weight: 500;
      &::before {
        content: "";
        position: absolute;
        width: 0.25rem;
        height: 1.25rem;
        left: 0.0625rem;
        background-color: #0078ce !important;
        border-radius: 0rem 1rem 1.5rem 0rem;
      }
    }
    &:hover {
      background-color: #c0ced733 !important;
    }
  }
}
.md-drppicker {
  .calendar {
    height: 22.375rem;
    width: 20.125rem !important;
    width: 100%;
    padding: 1.375rem 1.625rem;
    margin: 0 !important;
    &.left,
    &.right {
      position: relative;
      &::before {
        content: "";
        height: 0.0625rem;
        width: 91%;
        position: absolute;
        background-color: #e4e8ea;
        right: 0;
        bottom: 4.5rem;
      }
    }
    &.right {
      &:before {
        left: 0;
      }
    }
    &.left {
      &:after {
        content: "";
        height: 16rem;
        width: 0.0625rem;
        position: absolute;
        top: 1.375rem;
        background-color: #e4e8ea;
        right: 0;
      }
    }
    .calendar-table {
      padding: 0 !important;
      .next.available,
      .prev.available {
        border: 0.0625rem solid #e4e8ea;
        border-radius: 5.125rem;
        color: #31aab7;
        width: 2rem;
        height: 2rem;
        min-width: 2rem;
        background-image: url(../../assets/icons/svg/ico-arrow-left.svg);
        // margin-bottom: .625rem;
      }
      .month {
        color: #324552;
        font-weight: 600;
        font-size: 0.875rem;
        // margin-bottom: .625rem;
      }
      .week-days {
        display: none;
      }
    }
    td {
      line-height: 1.9375rem;
      font-size: 0.75rem;
      color: #222730;
      &.active {
        background-color: #0078ce;
        &:hover {
          background-color: #0078ce;
        }
      }
      &.in-range:not(.end-date) {
        background: rgba(0, 120, 206, 0.3);
      }
      &.today {
        font-weight: 700;
        &:not(.in-range, .active) {
          background: #dee3e7;
        }
      }
    }
  }
}
.md-drppicker {
  .buttons {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 !important;
  }
  .buttons_input {
    display: flex;
    width: 33.75rem;
    padding: 1rem 1.5rem;
    justify-content: end;
    gap: 1rem;
    .btn.btn-default.clear {
      svg {
        display: none;
      }
    }
    .btn {
      width: 8.75rem;
      height: 2.5rem;
      border-radius: 0.125rem;
      text-transform: uppercase;
      box-shadow: none;
      font-size: 0.875rem;
    }
    .btn.btn-default {
      color: #7d92a1;
      border: 0.0625rem solid #acbac3;
    }
    .btn:not(.btn-default) {
      background: #31aab7;
      color: #ffffff;
    }
  }
}
.vertical-datepicker .md-drppicker {
  flex-direction: column;
  max-height: unset !important;
  .ranges {
    width: auto !important;
  }
  .calendar.left,
  .calendar.right {
    width: 100% !important;
    max-width: unset;
    height: unset;
    &::before {
      right: 50%;
      bottom: 0;
      transform: translateX(50%);
      left: unset;
    }
  }
  .calendar.left {
    border: none;
    &::after {
      content: none;
    }
  }
  .buttons {
    position: relative;
    .buttons_input {
      width: auto;
    }
  }
}
.mat-datepicker-content * {
  font-family: "Work Sans", sans-serif !important;
}
.mat-datepicker-content {
  .mat-calendar {
    height: auto !important;
  }
  .mat-calendar-spacer {
    display: none;
  }
  .mat-calendar-content {
    padding: 0 1rem 1rem 1rem;
  }
  .mat-calendar-header {
    // position: relative;
    padding: 1rem;
    .mat-calendar-controls {
      height: 2rem;
      margin: 0rem !important;
    }
    .mat-focus-indicator {
      position: absolute;
    }
    .mat-icon-button {
      position: absolute;
      width: 2rem;
      height: 2rem;
      border: 0.0625rem solid #e4e8ea;
      border-radius: 5.125rem;
      line-height: 0;
      &:after {
        border: none;
      }
      .mat-button-wrapper {
        &::after {
          font-family: "icomoon" !important;
          content: "\e927"; //chevron-left-lg
          left: 50%;
          top: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          color: #31aab7;
          font-size: 0.75rem;
          // margin: 0;
        }
      }
    }
    .mat-calendar-next-button {
      right: 1rem;
      left: unset;
      .mat-button-wrapper::after {
        transform: translate(-50%, -50%) rotateZ(180deg) !important;
      }
    }
    .mat-calendar-period-button {
      line-height: 2rem;
      font-weight: 600;
      font-size: 0.875rem;
      left: 50%;
      transform: translate(-50%);
    }
  }
  .mat-calendar-body-label {
    visibility: hidden;
  }
  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }
  mat-year-view .mat-calendar-body-label[colspan="4"] {
    display: none;
  }
  .mat-calendar-table-header th {
    font-weight: 400 !important;
    font-size: 0.75rem !important;
    color: #7d92a1;
    text-transform: capitalize;
  }
  .mat-calendar-table-header-divider {
    display: none;
  }
  .mat-calendar-body-cell-content {
    font-size: 0.813rem;
  }
  .mat-calendar-body-cell-content {
    width: 100% !important;
    height: 80% !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .mat-calendar-body-today {
    // border-color: #0078ce !important ;
    background-color: #dee3e7;
    border: none;
  }
  .mat-calendar-body-selected {
    background-color: #0078ce;
    color: #fff;
  }
}
.status-week-off .mat-calendar-body-cell-content {
  background-color: var(--bg-weekoff);
}
.status-holiday .mat-calendar-body-cell-content {
  background-color: var(--bg-holiday);
}

.status-first-week-off, .status-second-week-off {
  .mat-calendar-body-cell-content {
    border: none;
    position: relative;
    &::before{
      content: "";
      position: absolute;
      height: 100%;
      width: 51%;
      border: 1px solid #E4E8EA;
    }
  }
}

.status-second-week-off .mat-calendar-body-cell-content {
  background-image: linear-gradient(to right, transparent 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
  &::before{
    left: 0;
    border-radius: 999px 0 0 999px;
  }
}

.status-first-week-off .mat-calendar-body-cell-content {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, transparent 51%, transparent 100%);
  &::before{
    right: 0;
    border-radius: 0 999px 999px 0;
  }
}

//Fix calender resize issue in chrome
.fc-col-header,
.fc-timegrid-body {
  width: 100% !important;
}

.fc-timegrid-body {
  table {
    width: 100% !important;
  }
}
::ng-deep {
  table.fc-col-header,
  .fc-scroller > div,
  .fc-scroller > div > table {
    width: 100% !important;
  }
}
.td-today {
  background-color: #ecf2f5 !important;
}
