// @import '/src/styles/3-helpers/mixin';
// @import '/src/styles/0-base/variables';
$headerHeight: 3.625;
$footerHeight: 4.5rem;
$footerHeight: 4.5;
$sidebarWidth: 15.75;
$sidebarWidth1: 11.25;

.config-layout {
  margin-top: -#{$main-topbar-height}rem;
  animation: 500ms ease-in 0s 1 slideInFromLeft;
  z-index: $zIndex-ConfigLayout;
  position: relative;
}

.config-header {
  background-color: $high-contrast-dark;
  padding: 1rem 2rem;
  height: $headerHeight + rem;
  box-shadow: 0rem 0.0625rem 0.0625rem 0rem #6970751a;
  position: sticky;
  top: 0;
  z-index: $zIndex-configHeader;
  @include flex(space-between, center);
  position: fixed;
}

.main-sidebar.expand~.main-container .config-header {
  width: calc(100% - #{$sidebar-expanded-width}rem);
}

.main-sidebar:not(.expand)~.main-container .config-header {
  width: calc(100% - #{$sidebar-menuIcon-width}rem);
}

.config-topbar {
  background-color: #fff;
  z-index: 999;

  .nav-item {
    flex-grow: 1;
  }

  // .step{
  //    padding: 0;
  // }
}

.config-topbar~.config-container {
  .form-container {
    min-height: calc(100vh - (3.6875rem + 5.5rem + 6rem));
  }
}

.config-header~.config-container {
  .form-container {
    min-height: calc(100vh - (#{$headerHeight}rem + #{$footerHeight}rem));
  }
}

.config-header~.config-container.tabs-container {
  min-height: calc(100vh - (#{$headerHeight}rem + #{$footerHeight}rem + #{$menutab-height}rem));

  .form-container {
    min-height: calc(100vh - (#{$headerHeight}rem + #{$footerHeight}rem + #{$menutab-height}rem));
  }
}

.config-sidebar {
  background: #ffffff;
  width: $sidebarWidth + rem;
  position: fixed;

  top: $headerHeight + rem;
  height: calc(100vh - #{$headerHeight}rem);
  @include flex($dir: column);
}

.card-details {
  background: #f8fafb;
  border: 0.0625rem solid #e4e8ea;
  padding: 1rem;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 0.25rem;
  font-weight: 400;

  .highlight {
    color: $accent-02-active;
    font-weight: 700;
  }
}

.config-sidebar~.config-container {
  // height: 300vh;
  width: calc(100% - #{$sidebarWidth}rem);
  margin-left: $sidebarWidth + rem;

  .form-container {
    min-height: calc(100vh - (#{$headerHeight}rem + #{$footerHeight}rem));
  }
}

.config-container {
  top: $headerHeight + rem;
  min-height: calc(100vh - #{$headerHeight}rem);
  position: relative;

  .form-container {
    height: 100%;
  }

  .submit-footer {
    position: sticky;
    bottom: 0;
    @include flex($y: center);
    height: $footerHeight + rem;
    width: 100%;
    margin-top: auto;
    background: #ffffff;
    padding: 1rem 2rem;
    box-shadow: 0.0625rem 0rem 0.25rem rgba(105, 112, 17, 0.2);
    z-index: $zIndex-configFooter;
    border-left: 0.0625rem solid #eaeaea;
    justify-content: end;
  }
}

.sidebar-2 {
  width: $sidebarWidth1 + rem;

  &.config-sidebar~.config-container {
    // height: 300vh;
    width: calc(100% - #{$sidebarWidth1}rem);
    margin-left: $sidebarWidth1 + rem;
  }
}
.sidebar-collapse{
  overflow-y: auto;
  $sidebarOpenWidth:17.375;
  $sidebarCloseWidth:3;
  &:not(.active) > :not(.sidebar-collapse-btn){
    display: none;
  }
  &{
    width: $sidebarCloseWidth + rem;
    &.config-sidebar~.config-container {
      width: calc(100% - #{$sidebarCloseWidth}rem);
      margin-left: $sidebarCloseWidth + rem;
    }
  }
  &.active{
    width: $sidebarOpenWidth + rem;

    &.config-sidebar~.config-container {
      width: calc(100% - #{$sidebarOpenWidth}rem);
      margin-left: $sidebarOpenWidth + rem;
    }
  }
}
.steps-2 {
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  padding-left: 0;

  .nav-link {
    padding: 1rem 1.5rem;
    position: relative;
    padding-left: calc(1.5rem + 1.125rem);

    &::before {
      font-family: "icomoon" !important;
      content: "\e92d"; //clock1
      color: #0078ce;
      font-size: 0.875rem;
      position: absolute;
      left: 1.35rem;
    }
  }

  .nav-item .nav-link.active {
    background-color: #dceafb;
  }

  li+li {
    border-top: 1px solid #e4e8ea;
  }
}

.steps {
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  // padding: .5rem;
  // margin-bottom: 0;
  padding: 2rem;
  display: flex;

  li {
    width: 100%;
  }

  .step {
    @include flex;
    padding: 0;

    // padding-top: 1rem;
    // padding-bottom: 1rem;
    .count {
      @include sq(2.5rem);
      border: 0.0625rem solid #e4e8ea;
      border-radius: 50%;
      // font-size: 1rem;
      @include flex(center, center);
      margin-right: 0.5rem;
      color: $low-contrast-light;
      flex-shrink: 0;
    }

    .content {
      @include flex($x: center, $dir: column);
      // gap:.25rem;
      width: 100%;
      // flex-shrink: 0;
      flex-wrap: nowrap;

      span {
        font-size: 0.75rem;
        text-transform: uppercase;
        color: $low-contrast-light;
      }

      h6 {
        margin-bottom: 0;
        color: $low-contrast-light;
      }
    }

    &.active {
      .count {
        border: 0.0625rem solid $accent-02-active;
      }

      .count,
      .content span,
      .content h6 {
        color: $accent-02-active;
      }
    }

    &.success:not(.active) {

      .count,
      .content span {
        color: #28a745;
      }

      .content h6 {
        color: #28a745;
      }

      .count {
        background: #28a745;
        border: none;
        position: relative;

        &::before {
          // content: url(/assets/icons/Dark/Large/ico-check.svg);
          content: "\e91f"; //check
          font-family: "icomoon";
          position: absolute;
          inset: 0;
          color: #fff;
          @include flex(center, center);
          font-size: 0.75rem;
        }
      }
    }
  }
}

//profile view from hr
.config-container {
  .sticky-profile-card {
    top: calc(1.5rem + 3.625rem) !important;
  }

  .tab-scroll {
    max-height: calc(100vh - (#{$headerHeight}rem + 15.1875rem)) !important;
  }
}
.config-toolbar {
  background-color: white;
  flex-shrink: 0;
  height: calc(100vh - (#{$headerHeight}rem + #{$footerHeight}rem));
  overflow: auto;
}
