.ol-type-1 {
  padding: 0;
  margin: 0;
  li {
    counter-increment: steps;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    &::before {
      content: counter(steps);
      width: 1.5rem;
      // height: 1.5rem;
      aspect-ratio: 1;
      background-color: #80e0b4;
      @extend .flex-center;
      color: #fff;
      font-size: 0.875rem;
      border-radius: 50%;
      font-weight: 500;
      margin-right: 0.5rem;
    }
  }
}
.timeline-c2 {
  counter-reset: item;
  list-style-type: none;
  padding-left: 3.25rem;
  transition: all ease-in 400ms;
  li {
    // display: block;
    position: relative;
    min-height: 2.5rem;
  }
  li:before {
    content: counter(item) "  ";
    counter-increment: item;
    font-size: 0.875rem;
    @include sq(2.5rem);
    position: absolute;
    top: calc((5.938rem / 2) + 1rem);
    transform: translateY(-50%);
    left: -3.25rem;
    border: 0.0625rem solid #acbac3;
    border-radius: 50%;
    @extend .flex-center;
    background-color: #fff;
    z-index: 1;
    color: #acbac3;
    transition: all ease-in 400ms;
    background-position: center;
    background-size: 0.0625rem;
  }
  li:first-child::before {
    top: calc(5.938rem / 2);
  }

  li:not(:last-child)::after {
    position: absolute;
    content: "";
    left: -2rem;
    width: 0.0625rem;
    height: calc(100% - 2.5rem);
    background-color: #acbac3;
    top: 5.25rem;
  }
  li:first-child::after {
    top: 4.25rem;
    height: calc(100% - 1.5rem);
  }
  li.success:not(:hover, .disabled) {
    &::before {
      background-color: #28a745;
      border-color: transparent;
      font-size: 0;
      background-repeat: no-repeat;
      background-size: 0.875rem;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.66699L3.66667 8.33366L9.66667 1.66699' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
    }
    &::after {
      background-color: #28a745;
    }
  }
  li:hover:not(.disabled) {
    &::before {
      border-color: #0078ce;
      color: #0078ce;
    }
    .card-hover {
      border-color: #0078ce;
      box-shadow: none;
      transition: all ease-in 400ms;
    }
  }
  li.disabled {
    &::before,
    &::after {
      opacity: 0.5;
    }
    .card-hover {
      border-color: rgba(228, 232, 234, 0.5);
      pointer-events: none;
      & > * {
        opacity: 0.5;
      }
    }
    .card {
      position: relative;
      &::after {
        position: absolute;
        top: 0.75rem;
        right: 0.75rem;
        content: "COMING SOON";
        font-size: 0.5625rem;
        font-weight: 600;
        color: #fff;
        background: #ffc107;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
      }
    }
  }
}
.ul-leaderboard {
  --leader-boardcolor: transparent;
  counter-reset: item;
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: all ease-in 400ms;
  li + li {
    margin-top: 0.5rem;
  }
  li {
    & > .card-c2 {
      position: relative;
      &::before {
        // content: counter(item) "";
        // counter-increment: item;
        font-size: 1rem;
        font-weight: 600;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.125rem;
        transition: all ease-in 400ms;
      }
      .card-body {
        padding-left: 2.0625rem !important;
        border-left: 0.375rem solid var(--leader-boardcolor);
        border-radius: 0.25rem 0 0 0.25rem;
      }
      .avatar-circle {
        border: 2px solid #ffffff !important;
        outline: 2px solid var(--leader-boardcolor);
      }
    }
  }
  li.first-place {
    --leader-boardcolor: #ffc107;
    & > .card-c2:before {
      color: var(--leader-boardcolor);
      content: "1";
    }
  }
  li.second-place {
    --leader-boardcolor: #31aab7;
    & > .card-c2:before {
      color: var(--leader-boardcolor);
      content: "2";
    }
  }
  li.third-place {
    --leader-boardcolor: #0078ce;
    & > .card-c2:before {
      color: var(--leader-boardcolor);
      content: "3";
    }
  }
}
.li-reset {
  margin-bottom: 0;
  padding-left: 1rem;
}
.li-hover li{
  cursor: pointer;
  &:hover{
    background-color: #0078CE1A;
  }
}
.timeline-c3 {
  // margin-bottom:1rem ;
  padding: 1rem 1rem 1rem 1.5rem;
  position: relative;
  list-style-type: none;
  // overflow-y: auto;
  &::before {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #C3CBD1;
    content: '';
    left: 1rem;
    top: 0;
  }
  &> :first-child::before,
  &> :last-child::before {
    position: absolute;
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    content: '';
    background-color: #C3CBD1;
    left: 1rem;
    top: 0;
    transform: translateX(-50%);
  }
  &> :last-child::before {
    top: calc(100% - 8px);
  }
  .timeline-li-1 {
    padding: .75rem;
    display: flex;
    gap: .5rem;
    font-size: .75rem;
  }
  .timeline-li-2 {
    padding: 0.75rem 0 .75rem 0.5rem;
    &.active .card-c8 {
      background-color: #DCEAFB;
      border-color: #0066DD;
    }
  }
  .li-card {
    position: relative;
    // cursor: pointer;
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 1rem;
      height: 1rem;
      background-color: #0078CE;
      border-radius: 50%;
      left: -1rem;
      top: .75rem;
    }
    &::after {
      top: calc(100% - .75rem);
      transform: translateY(-100%);
    }
    &.active .card-c8 {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 2px;
        height: calc(100% - 1rem);
        background-color: #0078CE;
        left: calc(-1rem + -2px);
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
