.dropdown_progres {
  display: block;
  z-index: 201;
  min-width: 98%;
  width: 100%;
  min-height: 100%;
  font-size: 0.813rem;
  font-weight: 400;
  color: #989393;
  overflow: hidden;
}

.progress_drp {
  align-items: center;
  padding: 0rem;
  justify-content: center;
  list-style-type: none;
}

.progress__item {
  position: relative;
  min-height: 7.188rem;
  counter-increment: list;
  // padding-left: 0.2rem;
  // cursor: not-allowed;
}

.progress__item:before {
  content: "";
  position: absolute;
  left: calc(0.75rem - 0.094rem);
  height: 100%;
  width: 0.0625rem;
  border-left: 0.125rem solid #adb6b8;
}

.progress__item:after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #ffffff;
  border-radius: 0.125rem;
  font-family: "icomoon" !important;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress__item.progress__item--pending:after,
.progress__item.progress__item--pending:before {
  background-color: #e29f24 !important;
  border: 0.0625rem solid #e29f24 !important;
}

.progress__item.progress__item--accepted:after,
.progress__item.progress__item--accepted:before {
  background-color: #28a745 !important;
  border: 0.0625rem solid #28a745 !important;
}

.progress__item.progress__item--accepted:after {
  content: "\e974" !important; //check-bold
  font-size: 0.75rem;
}

.progress__item.progress__item--rejected:after,
.progress__item.progress__item--rejected:before {
  background-color: #dc3545 !important;
  border: 0.0625rem solid #dc3545 !important;
}

.progress__item.progress__item--rejected:after {
  content: "\e92f" !important; //close
}

.progress__item.progress__item--intimation:after,
.progress__item.progress__item--intimation:before {
  background-color: #e4e8ea !important;
  border: 0.0625rem solid #e4e8ea !important;
}

.progress__item.progress__item--multiple:after,
.progress__item.progress__item--multiple:before {
  background-color: #0078ce !important;
  border: 0.0625rem solid #0078ce !important;
  content: "";
}

.progress__title {
  font-size: 0.875rem;
  font-weight: 400;
  color: #324552;
  margin-bottom: 0.5rem;
}

.progress__item:last-child:before {
  display: none;
}

.custom-progress {
  position: relative;

  --radius: 1.313rem;
  background: #eaedef;
  border-radius: var(--radius);
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  height: 1rem;

  .progress-bar {
    // background-color: var(--bg);
    width: var(--percentage);
    height: inherit;

    // border-radius: .25rem;
    &.progressbar-shift {
      background-color: #b8d0ec;
    }

    &.progressbar-break {
      background-color: #c7acff;
    }

    // &:first-child:not(:last-child){border-radius: var(--radius) 0 0 var(--radius);}
    // &:last-child:not(:first-child){border-radius: 0 1.3125rem 1.3125rem 0;}
    &:hover {
      filter: brightness(95%);
    }
  }

  .progress-bar:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
}

.progress_vertical {
  margin-left: -1rem;

  .progress__item {
    float: left;
    min-height: unset;

    // & + &{margin-right: 1rem;}
    &::before {
      height: 0.188rem;
      width: 100%;
      top: calc(0.5rem - 0.094rem);
      left: calc(50% - 0.4rem);
    }

    &::after {
      left: 50%;
      width: 1rem;
      height: 1rem;
      border-radius: 0.125rem;
      transform: translate(-50%);
      font-size: 0.563rem !important;
    }
  }

  .progress__tile {
    margin: 1.5rem 1rem 0.5rem;
    max-width: 10rem;

    &>div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  // .progress__tile:first-child{margin-left:0rem!important;}
}

/* Shine */
//   .custom-progress:after {
// 	content:'';
//   top:0;
// 	transform:translateX(100%);
// 	width:100%;
// 	height:13.75rem;
// 	position: absolute;
// 	z-index:1;
// 	animation: slide 3s infinite;

//   /*
//   CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/
//   */
//   background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(128,186,232,0) 99%, rgba(125,185,232,0) 100%); /* FF3.6+ */
// 	background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.8)), color-stop(99%,rgba(128,186,232,0)), color-stop(100%,rgba(125,185,232,0))); /* Chrome,Safari4+ */
// 	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Chrome10+,Safari5.1+ */
// 	background: -o-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* Opera 11.10+ */
// 	background: -ms-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* IE10+ */
// 	background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,0.8) 50%,rgba(128,186,232,0) 99%,rgba(125,185,232,0) 100%); /* W3C */
// 	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#007db9e8',GradientType=1 ); /* IE6-9 */
// }

// /* animation */

// @keyframes slide {
// 	0% {transform:translateX(-100%);}
// 	100% {transform:translateX(100%);}
// }
.progress-type1 {
  height: 2.125rem;
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.5rem;
  background-color: #fff;
  position: relative;

  .progress-bar,
  .progress-details {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .progress-details {
    width: 100%;
    align-items: center;
    padding: 0 0.5rem;
  }

  .progress-bar {
    background-color: #dff0d8;
  }
}

// .progress-pending{
//  .progress-bar{
//    background-color: #F9F9F9;
//   }
//   .progress-details{
//     color: #7D92A1;
//   }
// }
// .progress-complete{
//  .progress-bar{
//    background-color: #FFF;
//   }
//   .progress-details{
//     color: #28A745;
//   }
// }
.progress-type3 {
  height: 1rem;
  border-radius: 0.75rem;
  background-color: #dee3e7 !important;
  display: flex;
  align-items: center;
  padding: 0.167rem;

  .progress-bar {
    height: 0.667rem;
    border-radius: 0.75rem;
  }

  .bg-success {
    background-color: #31aab7 !important;
  }
}

.progress-type4 {
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: #eff4f6 !important;
  display: flex;
  align-items: center;
  padding: 0rem;

  .progress-bar {
    height: 100%;
    border-radius: 0.5rem;
    background-color: #0078ce;

    &.bg-info {
      background-color: #6562cf !important;
    }

    &.bg-danger {
      background-color: #dc3545 !important;
    }

    &.bg-warning {
      background-color: #ffc107 !important;
    }
  }

  &.h-13 {
    height: 0.8125rem;
  }
}

.timeline {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  &.timeline-vetical {
    display: flex;
    flex-wrap: wrap;
    gap: 0.625rem;
    overflow: hidden;

    // gap: 3.125rem;
    .timeline-item:not(:last-child) {
      position: relative;
      padding-right: 3.125rem;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 0.0625rem;
        // background-color:  #ACBAC3;
        border-bottom: 0.0625rem dashed #acbac3;
        top: 0.813rem;
        left: 2.875rem;
      }
    }
  }

  .timeline-title {
    // background: #96B2E0;
    color: white;
    border-radius: 0.125rem;
    display: inline-flex;
    align-items: center;
    padding: 0 0.563rem;
    height: 1.375rem;
    font-size: 0.75rem;
    font-weight: 500;
    z-index: 9;
    position: relative;
    margin-bottom: 0.375rem;
  }

  .timeline-content {
    font-weight: 400;
    font-size: 0.875rem;
  }
}

.progress-type2 {
  width: 100%;
  background-color: #fff;
  border-radius: 0.25rem;

  .progress-bar {
    border-radius: 0.25rem;

    &.bg-primary {
      background-color: #31aab7 !important;
    }
  }
}

.vetification-progress {
  position: relative;
  width: 60%;

  div {
    z-index: 2;
  }

  &.progress-success,
  &.progress-danger {

    &::before,
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    &::before {
      content: "";
      background-color: var(--v-color);
      height: 2px;
      width: 100%;
    }

    &::after {
      font-family: "icomoon";
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      border: 2px solid var(--v-color);
      color: var(--v-color);
      outline: 3px solid #fff;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.875rem;
    }
  }

  &.progress-success {
    --v-color: #009722;

    &::after {
      content: "\e91f"; //tick-circle
    }
  }

  &.progress-danger {
    --v-color: #dc3545;

    &::after {
      content: "\e92f"; //close
      font-size: 1rem;
    }
  }

  &:not(.progress-pending) .lds-ellipsis {
    display: none;
  }

  &.progress-pending {
    .lds-ellipsis {
      width: 82px;
      height: 13px;
      position: relative;
      top: 0;
      left: 0;

      div {
        background-color: #c3cbd1 !important;
      }
    }

    // &::before {
    //   content: ".....";
    //   position: absolute;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   z-index: 1;
    //   width: calc(100% - (4.5rem * 2) - (2rem));
    //   font-size: 4.5rem;
    //   height: 4.5rem;
    //   line-height: 1.25rem;
    //   letter-spacing: 0.375rem;
    //   color: #c3cbd1;
    //   // padding: 0 1rem;
    //   // display: flex;
    //   // align-items: center;
    //   // justify-content: center;
    // }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: calc(100% - (4.5rem * 2) - (2rem));
    //   height: 4.5rem;
    //   background-color: #c3cbd1;
    //   width: 1rem;
    //   height: 1rem;
    //   border-radius: 50%;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-9px, -10px);
    // }
  }
}

.progress-vertical {
  --c-success: #28a745;
  --c-warning: #e29f24;
  --c-default: #e4e8ea;
  --c-primary: #0078ce;
  --c-danger: #dc3545;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  color: #566976;
  margin-left: -.75rem;
  margin-right: -.75rem;
  // li:first-child {
  //   padding-left: 0;
  // }

  // li:last-child {
  //   padding-right: 0;
  // }

  li {
    font-size: .75rem;
    text-align: center;
    position: relative;
    // padding-top: 1.5rem;
    padding: 1.5rem .75rem 0;



    &:not(:first-child)::before {
      content: '';
      position: absolute;
      width: 50%;
      height: .125rem;
      top: calc(1rem/2);
      left: 0%;
      transform: translateY(-50%);
      // background-color: var(--color);
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      width: 50%;
      height: .125rem;
      top: calc(1rem/2);
      left: 50%;
      transform: translateY(-50%);

    }

    &>*::before {
      position: absolute;
      content: var(--icon);
      font-family: bootstrap-icons !important;
      font-size: 1rem;
      top: calc(-.5rem/2);
      left: 50%;
      transform: translateX(-50%);
      font-weight: 500;
      background-color: #fff;
      z-index: 10;
    }
  }



  .progress-status-success {
    --icon: '\F26C';

    &::after {

      background-color: var(--c-success);
    }

    &~li::before {
      background-color: var(--c-success);
    }

    &>*::before {
      color: var(--c-success);
    }
  }

  .progress-status-warning {
    --icon: '\F582';

    &::after {

      background-color: var(--c-warning);
    }

    &~li::before {
      background-color: var(--c-warning);
    }

    &>*::before {
      color: var(--c-warning);
    }
  }

  .progress-status-default {
    --icon: '\F582';

    &::after {

      background-color: var(--c-default);
    }

    &~li::before {
      background-color: var(--c-default);
    }

    &>*::before {
      color: var(--c-default);
    }
  }

  .progress-status-primary {
    --icon: '\F582';

    &::after {

      background-color: var(--c-primary);
    }

    &~li::before {
      background-color: var(--c-primary);
    }

    &>*::before {
      color: var(--c-primary);
    }
  }

  .progress-status-danger {
    --icon: '\F628';

    &::after {

      background-color: var(--c-danger);
    }

    &~li::before {
      background-color: var(--c-danger);
    }

    &>*::before {
      color: var(--c-danger);
    }
  }
}