$card-width: 14.4819rem;
$card-gap: 2.5rem;
$line-color: #acbac3;
.custom-orgchart-container {
  margin: 0 !important;
  border-width: 1px !important;
  border-radius: 0 !important;
  overflow: hidden !important;
  @include media(">=figma") {
    border-width: 0.0625rem !important;
  }
  .orgchart {
    background-image: none !important;
    // width: 100% !important;
    //         display: flex!important;
    //         align-items: center;
    //         justify-content: center;
  }
  .oc-node {
    width: $card-width !important;
    padding: 0 !important;
    border: none !important;
    font-family: var(--bs-font-sans-serif) !important;
    // &:after{
    //     height: $card-gap!important;
    //     bottom:  -$card-gap!important;
    // }
  }
  // .oc-group{
  //     padding-top:calc(#{$card-gap} + .625rem)!important;
  //     &:not(:last-child)::before{
  //         top:calc(#{$card-gap} - 1px)!important;
  //     }
  // }
  // orgchart-node:not(:first-child) .oc-group:first-child > :first-child::before {
  //     height: calc((#{$card-gap} * 2) - 1px)!important;;
  //     top:calc(-#{$card-gap} - 9px)!important;
  // }
  // .oc-group::before{
  //     top:calc(#{$card-gap} - .0625rem)!important;
  // }
  .oc-group:before,
  orgchart-node::before,
  orgchart-node::after,
  .oc-node:after {
    border-color: $line-color !important ;
  }
  .oc-toggle-btn {
    inset: 0 !important;
    // width: 100%!important;
    // height:calc(100% + 1rem)!important;
    color: transparent !important;
    background-color: transparent !important;
    cursor: pointer;
  }
}
orgchart-node {
  padding-bottom: 20px !important;
}
.org-card {
  &.uniquebg1 {
    border-bottom-color: #8ad7e0 !important;
  }
  &.uniquebg2 {
    border-bottom-color: #e0bd75 !important;
  }
  &.uniquebg3 {
    border-bottom-color: #eac2ac !important;
  }
  &.uniquebg4 {
    border-bottom-color: #c0afdb !important;
  }
  &.uniquebg5 {
    border-bottom-color: #96b2e0 !important;
  }
  &.uniquebg6 {
    border-bottom-color: #8be0b0 !important;
  }
  &.uniquebg7 {
    border-bottom-color: #d2a1e0 !important;
  }
  &.uniquebg8 {
    border-bottom-color: #bce080 !important;
  }
}
.org-card {
  display: flex;
  align-items: center;
  border: 1px solid #e4e8ea;
  border-radius: 0.25rem;
  width: $card-width;
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-bottom-width: 0.3125rem;
  border-bottom-style: solid;
  border-bottom-color: #96b2e0;
  cursor: pointer;
  @include media(">=figma") {
    border: 0.0625rem solid #e4e8ea;
  }
  & > .org-detail {
    display: flex;
    flex-direction: column;
    align-items: start;
    overflow: hidden;
  }
  // .org-user-count {
  //     position: absolute;
  //     left: 50%;
  //     bottom: 0;
  //     transform: translate(-50%, 50%);
  //     font-weight: 500;
  //     font-size: 0.75rem;
  //     display: flex;
  //     flex-direction: row;
  //     align-items: center;
  //     gap: 0.375rem;
  //     z-index: 2;
  //     border-radius: 0.9413rem;
  //     padding: .25rem .625rem;
  //     border: 0.0625rem solid #dee3e7;
  //     background-color: #ffffff;
  //     // color: inherit;
  //     border-color: #dee3e7;
  //     // pointer-events: none;
  //     cursor: pointer;
  //     &::after {
  //         content: "\F282";
  //         font-family: bootstrap-icons !important;
  //     }
  // }
  &.active .org-user-count {
    background-color: #0078ce;
    color: #fff;
    border-color: #0078ce;
    &::after {
      content: "\F286";
    }
  }
  .org-title {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.1875rem;
    text-align: start;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 14ch;
  }
  .org-desc {
    font-weight: 500;
    font-size: 0.625rem;
    line-height: 1.125rem;
    color: #7d92a1;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 21ch;
  }
  .org-avatar {
    border: 2px solid #ffffff;
    filter: drop-shadow(0 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
    border-radius: 50%;
    width: 2.875rem;
    aspect-ratio: 1;
    object-fit: contain;
    margin-right: 1rem;
    @include media(">=figma") {
      border: 0.125rem solid #ffffff;
    }
  }
}
.oc-groups {
  position: relative;
}
.custom-orgchart-container > .orgchart > orgchart-node > .oc-groups::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: $line-color;
  top: 10px;
  left: 0;
}
.custom-chart {
  --size: 50px;
  position: relative;
  & > orgchart-node {
    padding-top: var(--size);
    &::after {
      all: unset !important;
    }
  }
  .chart-upperlevel {
    position: absolute;
    top: var(--size);
    left: 50%;
    transform: translate(-50%, -70%);
    aspect-ratio: 1;
    height: var(--size);
    @extend .flex-center;
    cursor: pointer;
    i {
      font-size: 1.5625rem;
      color: #acbac3;
    }
  }
}
// $node-x-gap: 1rem;
// $node-y-gap: 3.125rem;
// .org-card {
// display: flex;
// align-items: center;
// margin: 0 auto;
// border: 0.0625rem solid #e4e8ea;
// border-radius: 0.25rem;
// width: 14.4819rem;
// position: relative;
// background-color: #ffffff;
// padding: 0.75rem 1rem;
// text-decoration: none;
// border-bottom: .3125rem solid #96b2e0;
// & + ul {
//     margin-top: $node-y-gap;
// }
// .org-user-count {
// position: absolute;
// left: 50%;
// bottom: 0;
// transform: translate(-50%, 60%);
// display: flex;
// background-color: #0078CE;
// color: #fff;
// font-weight: 500;
// font-size: 0.75rem;
// flex-direction: row;
// border: 0.0625rem solid #0078CE;
// border-radius: 0.9413rem;
// padding: .25rem .625rem;
// i {
//     font-size: 0.625rem;
// }
// &::after {
//     content: "\F4E1";
//     font: var(--fa-font-solid);
// }
// }
// &:not(.oc-is-collapsed){
//     .org-user-count{
//         background-color: #ffffff;
//         color: inherit;
//         border-color:#dee3e7 ;
//         // &::after{
//         //     content: '\f107';
//         // }
//     }
//     &::after {
//         background-color: transparent;
//     }
//     & ~ ul{
//         transform: scale(0);
//         transition: transform 400ms ease 0ms;
//         transform-origin: top center;
//         height: 0;
//         width: 0;
//     }
// }
// &.active{
//     & ~ ul{
//         transform: scale(1);
//         transition: transform 400ms ease 0ms;
//         transform-origin: top center;
//     }
// }
// &:only-child{
//     // & .org-user-count{display: none;}
//     &::after{
//         background-color: transparent;
//     }
// }
// }
$connector: 2px;
$cardGap: 20px;
$connectorColor: #acbac3;
.oc-group {
  padding-top: calc(#{$cardGap} * 2) !important;
  padding-left: 0px !important;
  &::before {
    border-width: 0 !important;
  }
}
.oc-groups {
  gap: $cardGap;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  &::before {
    width: calc(100% - #{$card-width}) !important;
    top: $cardGap !important;
    left: 50% !important;
    transform: translateX(-50%);
    content: "" !important;
    height: $connector !important;
    background-color: $connectorColor;
    all: unset !important;
  }
  &::after {
    width: $connector !important;
    height: $cardGap !important;
    background-color: $connectorColor;
    left: 50% !important;
    transform: translateX(-50%) !important;
    top: 0 !important;
    position: absolute;
    content: "";
  }
}
// .oc-groups::before {
//     width: calc(100% - #{$card-width}) !important;
//     position: absolute !important;
//     top: $cardGap !important;
//     left: 50% !important;
//     transform: translateX(-50%);
//     content: '' !important;
//     height: $connector !important;
//     background-color: $connectorColor;
// }
orgchart-node {
  align-items: center;
  &:before {
    border-width: 0 !important;
    width: $connector !important;
    height: $cardGap !important;
    background-color: $connectorColor;
    left: 50% !important;
    transform: translateX(-50%) !important;
    bottom: 100% !important;
    top: unset !important;
  }
}
.oc-node::after {
  all: unset !important;
}
// .org-card:not(.no-child) {
//     &::after {
//         width: $connector !important;
//         height: $cardGap !important;
//         background-color: $connectorColor;
//         left: 50% !important;
//         transform: translateX(-50%) !important;
//         top: 100% !important;
//         position: absolute;
//         content: '';
//     }
// }
.oc-group {
  position: relative;
  &:not(:first-child)::before {
    position: absolute;
    content: "";
    width: calc(50% + #{$cardGap}) !important;
    // width: 50%!important;
    height: $connector;
    left: -20px !important;
    top: 20px !important;
    background-color: $connectorColor;
  }
  &:not(:last-child)::after {
    position: absolute;
    content: "";
    width: calc(50% + #{$cardGap}) !important;
    // width: 50%!important;
    height: $connector;
    left: 50% !important;
    top: 20px !important;
    background-color: $connectorColor;
  }
}
.oc-node {
  position: relative;
  .org-user-count {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 50%);
    font-weight: 500;
    font-size: 0.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.375rem;
    z-index: 2;
    border-radius: 0.9413rem;
    padding: 0.25rem 0.625rem;
    border: 1px solid #dee3e7;
    background-color: #ffffff;
    // color: inherit;
    border-color: #dee3e7;
    // pointer-events: none;
    cursor: pointer;
    @include media(">=figma") {
      border: 0.0625rem solid #dee3e7;
    }
    &::after {
      content: "\F282";
      font-family: bootstrap-icons !important;
    }
  }
  .oc-icon-minus ~ .org-user-count {
    background-color: #0078ce;
    color: #fff;
    border-color: #0078ce;
    &::after {
      content: "\F286";
    }
  }
}
