// @import '../0-base';
@import "/src/styles/0-base/variables";

// @import "~@ng-select/ng-select/themes/default.theme.css";
.form-label {
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-bottom: 0.375rem;
  // display: inline;
  // margin-right: .5rem;
}

.required::after {
  content: " *";
  color: $text-danger;
  font-size: 0.875rem;
}

.form-text {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.form-control,
.form-select,
.form-control.mat-input-element {
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.25rem;
  caret-color: $accent-02-active;
  color: #324552;
  font-size: 1rem;
  padding-left: 0.25rem;
  &:focus {
    border: 0.0625rem solid $accent-02-active;
    box-shadow: none !important;
    outline: none;
  }

  &:disabled,
  &:read-only {
    background: #f8fafb;
    border: 0.0625rem solid #dae2e8;
    color: #7d92a1;
  }

  &::placeholder {
    color: $low-contrast-light;
    font-weight: 400;
    // font-size: .875rem;
  }

  &:not(textarea) {
    height: 2.5rem;
  }
}

.ng-select .ng-select-container {
  border: 0.0625rem solid #dae2e8;
}

.form-select.is-invalid,
.form-select.is-valid {
  background-image: url("../../assets/icons/Light/Large/ico-tab\ dropdown.svg") !important;
  background-size: 1.25rem !important;
}

.form-select {
  background-image: url("../../assets/icons/Light/Large/ico-tab\ dropdown.svg");
  background-size: 1.25rem;

  option:disabled {
    color: $low-contrast-light;
  }
}

// To append icon left or right inside form
.form-icon {
  $icon-spacing: 1;
  position: relative;

  i,
  &>span {
    position: absolute;
    color: $high-contrast-light;
    z-index: 1000;
  }

  &.icon-left input,
  &.icon-left igx-time-picker {
    padding-left: calc(#{$icon-spacing}rem * 3);
  }

  &.icon-left i:not(.form-ngselect *),
  &.icon-left span:not(.form-ngselect *) {
    top: 50%;
    left: $icon-spacing + rem;
    transform: translateY(-51%);
  }

  &.icon-left>span {
    left: calc(#{$icon-spacing}rem/2);
    color: $low-contrast-light;
  }

  &.icon-right span:not(.form-ngselect *) {
    right: calc(#{$icon-spacing}rem/2);
  }

  &.icon-right input,
  &.icon-right igx-time-picker {
    padding-right: calc(#{$icon-spacing}rem * 3);
  }

  &.icon-right i:not(.form-ngselect *),
  &.icon-right span:not(.form-ngselect *) {
    top: 50%;
    right: .5rem;
    transform: translate(-3px, -51%);
  }
  &.icon-right-2 {
    & .icon-end-2 {
        right: 2rem !important;
      }
      & .ng-value-container{
        padding-right: 1.625rem;
      }
  }
}
.form-cell .form-icon {
  width: fit-content;
}

.sticky-header .form-icon i {
  z-index: 0;
}

.form-icon2 {
  position: relative;
  $icon-spacing: 1;

  .icon-left,
  .icon-right {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: $low-contrast-light;
  }

  .icon-left {
    left: $icon-spacing + rem;

    &~.form-control {
      padding-left: calc(#{$icon-spacing}rem * 3);
    }
  }

  .icon-right {
    right: $icon-spacing + rem;

    &~.form-control {
      padding-right: calc(#{$icon-spacing}rem * 3);
    }
  }
}

// .form-check[type=checkbox]{
//     padding-left: 2.1875rem;
// }
// .form-check-inline{
//    display: flex;
// }
.form-check-input[type="checkbox"] {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.form-check-input[type="radio"] {
  @include sq(1.125rem);
  margin-top: 0.188rem;
  border: 0.125rem solid #7d92a1;
  margin-right: 0.5rem;
}

//Upload Button
.upload-btn {
  &.uploaded {
    background-image: none;
  }

  cursor: pointer;
  width: 100%;
  // height: 100%;
  height: 8.125rem;
  background: #ffffff;
  border: 0.125rem dashed #dae2e8;
  box-sizing: border-box;
  border-radius: 1rem;
  background-image: url("/assets/images/ico-image.png");
  background-repeat: no-repeat;
  background-position: center 1.688rem;
  position: relative;
  background-size: 2rem;

  .upload-text {
    font-size: 0.875rem;
    position: absolute;
    left: 50%;
    bottom: 1rem;
    transform: translateX(-50%);
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;
    text-align: center;
  }

  .upload-img {
    position: absolute;
    left: 50%;
    top: 1.5rem;
    @include sq(3.875rem);
    border-radius: 0.5rem;
    transform: translateX(-50%);
    pointer-events: none;
    object-fit: contain;
  }

  .upload-delete {
    position: absolute;
    right: 0;
    top: 0.75rem;
    color: #324552;
    // @include sq(3.875rem);
    border-radius: 0.5rem;
    // transform: translateX(-50%);
    pointer-events: none;
    text-align: right;
    background-color: transparent;

    i {
      pointer-events: all;
      padding: 0.938rem 1.063rem;
    }
  }
}

//Form Validation Classes
.form-control {

  &.is-valid,
  &.is-invalid {
    background-image: none;
    padding-right: 0.25rem;

    .valid-feedback,
    .invalid-feedback {
      font-size: 0.875rem;
    }
  }

  &.is-valid {
    border-color: $text-success;

    &~.valid-feedback {
      color: $text-success;
    }
  }

  &.is-invalid {
    border-color: $text-danger;

    &~.invalid-feedback {
      color: $text-danger;
    }
  }
}

//To Avoid jumbing of layout when error msg is shown
// .form-row {
//     position: relative;
//     .valid-feedback,
//     .invalid-feedback {
//         position: absolute;
//         top: 2.125rem;
//     }
//     .form-label~.valid-feedback,
//     .form-label~.invalid-feedback {
//         top: 3.9375rem;
//     }
// }
.multiselect {
  height: 2.5rem;

  &.ng-select-focused .ng-select-container,
  &.ng-select-opened .ng-select-container {
    border: 0.0625rem solid $accent-02-active !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .ng-select-container {
    border: 0.0625rem solid #dae2e8;
    border-radius: 0.25rem;
    caret-color: $accent-02-active;
    color: #324552;
    font-size: 1rem;
    height: 2.5rem;

    .ng-value-container {
      flex-wrap: initial !important;
      width: 3.125rem;
      overflow: hidden;
    }

    .ng-clear-wrapper {
      width: 1.875rem;
      text-align: center;
      z-index: 3;
    }

    .ng-arrow {
      border-color: $high-contrast-light transparent transparent;
    }

    .ng-placeholder {
      top: 50% !important;
      padding: 0rem !important;
      transform: translateY(-50%);
      color: $low-contrast-light;
      font-weight: 400;
    }
  }

  .ng-option {
    font-size: 0.875rem !important;
    padding: 0.5rem 0.625rem;

    img {
      @include sq(1.5rem);
      border-radius: 12.5rem;
      margin-right: 0.5rem;
      object-fit: contain;
    }
  }
}

.selected-values {
  padding-left: 0;
  float: left;
  margin-bottom: 0;
  list-style-type: none;
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.25rem;
  width: 100% !important;
  .ng-value {
    float: left;
  }

  .ng-value {
    background: #e4e8ea;
    border-radius: 1rem;
    padding: 0.25rem 0.5rem;
    font-weight: 500;
    font-size: 0.75rem;
    // margin-right: 0.5rem;
    // margin-bottom: 0.5rem;
    @include flex($y: center);

    img {
      background-color: #fff;
    }

    .ng-value-label {
      @include flex($y: center);

      img,
      span.avatar-circle {
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.3125rem;
        margin-left: -0.25rem;
      }

      .d-inline-flex span {
        word-wrap: break-word !important;
        word-break: break-word !important;
      }
    }

    .ng-value-icon {
      font-size: 0.875rem;
      text-align: end;
      @include sq(0.938rem);
      @include flex(end, center);
      margin-left: 0.375rem;
      cursor: pointer;
    }
  }
}

.multiselect {
  .ng-value {
    background: #e4e8ea !important;
    border-radius: 12.5rem !important;
    padding: 0.25rem 0.5rem !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @include flex($y: center);

    .ng-value-label {
      display: flex !important;
      @include flex($y: center);
      padding: 0 !important;

      img {
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.5rem;
        margin-left: -0.25rem;
      }
    }

    .ng-value-icon {
      font-size: 1.25rem;
      text-align: end;
      @include sq(0.938rem);
      width: 1.25rem !important;
      display: flex !important;
      padding: 0 !important;
      @include flex(end, center);
      margin-left: 0.375rem;
      cursor: pointer;
    }
  }
}

::not(.form-switch) {
  .form-check-input:checked[type="checkbox"] {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.66699L3.66667 8.33366L9.66667 1.66699' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-size: 0.688rem;
  }
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25rem;
  border-color: $high-contrast-light;
}

.form-check-input:disabled {
  // background-color: $medium-contrast-light !important;
  border-color: $medium-contrast-light !important;
  border-width: 0.0625rem;
}

.form-check-input:disabled:checked {
  background-color: $medium-contrast-light !important;
}

.form-check-input:checked {
  background-color: $accent-01-active;
  border-color: $accent-01-active;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: $accent-01-active;
}

// .form-check-label,
.form-check-input:not(:disabled),
.form-check-input:not(:disabled)~.form-check-label {
  cursor: pointer;
}

// ng-select.form-select{
//     // all: unset;
//     background-image: none;
//     border: none;
//     padding: 0;
//     .ng-select-container{
//         // background-image: url('ico-tab dropdown.svg');
//         background-size: 1.25rem;
//         border: .0625rem solid #DAE2E8;
//         border-radius: .25rem;
//         color: #324552;
//         font-size: 1rem;
//         height: 2.5rem!important;
//     }
//     input{
//         border:none;
//         caret-color: #0078CE;
//         border: .3125rem;
//         margin-left: .0625rem;
//     }
// }
// .form-ng-select {
//     position: relative;
//     display: block;
//     .ng-select-container {
//         height: 2.5rem;
//         cursor: default;
//         display: flex;
//         outline: none;
//         overflow: hidden;
//         position: relative;
//         width: 100%;
//         color: #333;
//         background-color: #fff;
//         border-radius: .25rem;
//         border: .0625rem solid #ccc;
//         min-height: 2.25rem;
//         align-items: center;
//     }
//     .ng-value-container {
//         white-space: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: flex;
//         flex: 1;
//         align-items: center;
//         padding-left: .625rem;
//     }
//     .ng-placeholder,.place {
//         color: #999;
//     }
//     .ng-value-container .ng-input {
//         position: absolute;
//         left: 0;
//         width: 100%;
//         opacity: 1;
//         top: .3125rem;
//         left: 0;
//         padding-left: .625rem;
//         padding-right: 3.125rem;
//     }
//     .ng-input>input {
//         box-sizing: content-box;
//         background: none transparent;
//         border: 0 none;
//         box-shadow: none;
//         outline: none;
//         padding: 0;
//         cursor: default;
//         width: 100%;
//     }
//      .ng-arrow-wrapper {
//         cursor: pointer;
//         position: relative;
//         text-align: center;
//         -webkit-user-select: none;
//         -moz-user-select: none;
//         -ms-user-select: none;
//         user-select: none;
//         width: 1.5625rem;
// padding-right: .3125rem;
//     }
//     .ng-arrow {
//         pointer-events: none;
//         display: inline-block;
//         height: 0;
//         width: 0;
//         position: relative;
//     }
//     .ng-arrow {
//         border-color: #999 transparent transparent;
//         border-style: solid;
//         border-width: .3125rem .3125rem .1563rem;
//     }
// }
.form-ngselect,
app-master-select {

  &,
  & * {
    cursor: pointer !important;
  }

  .ng-select-container {
    height: 2.5rem !important;
    min-height: unset;
  }

  input {
    caret-color: transparent;
  }
}

.form-ngselect .ng-dropdown-panel {
  box-shadow: none !important;
}

.form-ngselect,
app-master-select,
.form-ngselect1 {
  .ng-select-container {
    // height: 2.5rem !important;
    box-shadow: none !important;

    &:hover {
      box-shadow: none;
    }
    .ng-input {
      // top:50%!important;
      // transform: translateY(-50%)!important;
      padding-left: 0.25rem !important;
    }
    .ng-value-container {
      padding-left: 0.25rem;
      position: relative;
      height: inherit;
    }
    .ng-value {
      width: 100%;
      width: -moz-available;/* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available;/* Mozilla-based browsers will ignore this. */
      width: fill-available;
      .ng-value-label{
        @extend .text-trim;
      }
    }
  }

  :not(td) {

    .form-ngselect,
    app-master-select,
    .form-ngselect1 {
      .ng-input {
        padding-left: 0.25rem !important;
      }
    }
  }

  .ng-placeholder {
    color: #7d92a1 !important;
  }

  &.is-invalid .ng-select-container {
    border-color: $text-danger !important;
  }

  &.ng-select-opened .ng-select-container,
  &:not(.is-invalid).ng-select-focused .ng-select-container {
    border-color: $accent-02-active !important;
    box-shadow: none !important;
  }

  .ng-arrow-wrapper {
    padding-right: 0.5rem;
    width: 20px;
  }

  .ng-arrow-wrapper .ng-arrow {
    border-color: #324552 transparent transparent;
    border-style: solid;
    border-width: 0.3125rem 0.3125rem 0.1563rem;
  }

  &.ng-select-opened>.ng-select-container .ng-arrow {
    top: -0.125rem;
    border-color: transparent transparent #324552;
    border-width: 0 0.3125rem 0.3125rem;
  }

  .ng-clear-wrapper {
    display: none;
  }
}

.form-ngselect.ng-select-disabled {
  .ng-select-container {
    background-color: transparent;
    border: 0.0625rem solid #dae2e8;
    color: #7d92a1;
  }

  .ng-arrow {
    border-color: #7d92a1 transparent transparent;
  }
}

.form-ngdatepicker {
  display: block;
  width: 100%;

  .dp-picker-input {
    width: 100% !important;
    height: 2.5rem !important;
    border: 0.0625rem solid #dae2e8;
    padding-left: 0.25rem;
    border-radius: 0.25rem;
    caret-color: #0078ce;
    color: #324552;
    font-size: 1rem !important;
  }

  .dp-open .dp-picker-input:focus-visible,
  .dp-open .dp-picker-input:focus {
    border: 0.0625rem solid #0078ce;
  }
}

.form-ngdatepicker.is-invalid .dp-picker-input {
  border: 0.0625rem solid #dc3545;
}

.form-ngselect-inline,
.form-ngselect-inline2 {
  &>* {
    box-shadow: none !important;
  }

  .ng-select-container {
    border: none;
    background-color: transparent;
    min-height: fit-content;
    height: auto !important;
    border-radius: 0;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
    color: $high-contrast-light;
  }

  .ng-clear-wrapper {
    display: none !important;
  }

  // .ng-value-container{
  //     padding-top:.125rem;
  // }
  .ng-input {
    top: 0 !important;
  }

  &.ng-select-opened .ng-select-container {
    border: none;
    background-color: transparent;
  }

  input {
    caret-color: transparent;
  }

  .ng-arrow {
    border: none !important;
    background-repeat: no-repeat;
    @include rect(0.625rem, 0.375rem);
    background-size: 0.625rem;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23324552' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    top: -0.0625rem;
  }

  &.ng-select-opened>.ng-select-container .ng-arrow {
    top: -0.0625rem;
  }
}

.form-ngselect-inline2 .ng-select-container {
  transform: none !important;
}

.input-group-combine .ng-select-container {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-right: none;
  // border-left: .0625rem solid #DAE2E8
}

.input-group-combine .form-control {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-left: none;
}

//*****************************
//****Bootstrap Date picker****
//*****************************
.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday,
[ngbDatepickerDayView] {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

[ngbDatepickerDayView] {
  line-height: 2.5rem !important;
  border-radius: 50% !important;
  font-size: 0.75rem !important;
}

.ngb-dp-week-number,
.ngb-dp-weekday {
  line-height: 2.5rem !important;
  font-style: normal !important;
  font-size: 0.75rem !important;
}

ngb-datepicker-navigation-select>.custom-select {
  background-color: transparent !important;
  border: 0.0625rem solid #dae2e8 !important;
  border-radius: 0.25rem !important;
}

.ngb-dp-navigation-select {
  gap: 0.625rem !important;
}

.ngb-dp-navigation-chevron {
  border-width: 0.1em 0.1em 0 0 !important;
  width: 0.5em !important;
  height: 0.5em !important;
}

.ngb-dp-arrow .btn {
  border: 0.0625rem solid #e4e8ea;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.ngb-dp-arrow.btn-link {
  color: #31aab7 !important;
}

ngb-datepicker.dropdown-menu {
  padding: 1rem !important;
  border-color: #e4e8ea !important;
}

.ngb-dp-header {
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
}

.ngb-dp-day {
  .bg-primary {
    background: #0078ce !important;
  }

  .btn-light:hover {
    background: rgba(0, 120, 206, 0.3) !important;
  }
}

.ngb-dp-today {
  .btn-light:not(.bg-primary) {
    color: #0078ce !important;
    font-weight: 500 !important;
  }
}

//upload doc css
.upload-doc {
  color: $accent-02-active;
  cursor: pointer;
}

.upload-doc:hover {
  color: $accent-02-inactive;
}

//upload doc css end
// ******************************
// ******Bootstrap Dropdown******
// ******************************
.dropdown-toggle::after {
  content: none !important;
}

.upload-border {
  border: 0.125rem dashed #dae2e8;
  background: #ffffff;
  border-radius: 1rem;
  cursor: pointer;
}

.ng-dropdown-panel {
  z-index: $zIndex-NgDropDown !important;
}

.error-list {
  font-size: 0.875rem;
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 1.5rem;
  color: #324552;

  li {
    position: relative;
    padding-top: 0.5rem;
  }

  li::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 0.875rem;
    left: -1.5rem;
    background-repeat: no-repeat;
    background-size: 0.875rem;
  }

  li.valid::before {
    background-image: url("/assets/icons/svg/ico-tick-green.svg");
  }

  li.invalid::before {
    background-image: url("/assets/icons/svg/ico-warning-danger.svg");
  }
}

.form-cell .form-ngselect1 {
  .ng-value {
    background: #e4e8ea !important;
    border-radius: 12.5rem !important;
    padding: 0.25rem 0.5rem !important;
    font-weight: 500 !important;
    font-size: 0.75rem !important;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @include flex($y: center);

    .ng-value-label {
      display: flex !important;
      @include flex($y: center);
      padding: 0 !important;

      img {
        object-fit: cover;
        border-radius: 50%;
        margin-right: 0.5rem;
        margin-left: -0.25rem;
      }
    }

    .ng-value-icon {
      font-size: 1.25rem;
      text-align: end;
      @include sq(0.938rem);
      width: 1.25rem !important;
      display: flex !important;
      padding: 0 !important;
      @include flex(end, center);
      margin-left: 0.375rem;
      cursor: pointer;
    }
  }
}

.textarea-comment {
  background: #f8fafb;
  color: #7d92a1;
  padding: 1rem 1.5rem;
  font-size: 0.75rem;
}

.form-check-input.checkbox-16 {
  width: 1rem;
  height: 1rem;
}

.form-check label {
  cursor: pointer;
  word-break: break-word;
}

.form-check-input:disabled {
  cursor: not-allowed;
  pointer-events: revert;
  opacity: 0.5;
}

input:autofill {
  background: #fff;
  /* or any other */
}

.timepicker-backdrop-overlay {
  z-index: 1001 !important;
}

.timepicker-overlay {
  z-index: 1002 !important;
}

.cdk-overlay-container {
  z-index: $zIndex-dateTimePicker;
}

.form-timepicker {
  --focused-secondary-color: none !important;
  --focused-bottom-line-color: none !important;

  igx-input-group {
    border-bottom: none !important;
  }

  padding: 0;

  // igx-suffix{
  //     .material-icons{
  //         font-size: 1rem;
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //     }
  // }
  igx-prefix,
  igx-suffix,
  .igx-input-group__border {
    display: none !important;
  }

  .igx-input-group__bundle {
    padding-top: 0rem;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
    }
  }

  // Input
  .igx-input-group__input {
    height: 2.5rem;
    padding: 0.375rem 0.75rem 0.375rem 0.25rem;
    border-radius: 0.125rem !important;
    border-top: none !important;
    // &:focus,&:active{border: .0625rem solid #0078CE!important;}
  }
}

.igx-overlay__content {
  min-width: 18.75rem !important;
}

.igx-input-group--invalid .igx-input-group__bundle {
  box-shadow: none !important;
}

.igx-time-picker {
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  width: 100% !important;

  .igx-time-picker__main {
    padding: 1.5rem;
  }

  .igx-time-picker__body,
  .igx-time-picker__buttons {
    padding: 0rem;
  }

  .igx-time-picker__item {
    font-size: 1rem;
    color: #7d92a1;
    height: 1rem;

    &:hover {
      color: #0078ce;
    }
  }

  .igx-time-picker__item--selected {
    // margin: .625rem 0;
    font-size: 1.625rem;
    color: #0078ce;
    border-radius: 0.25rem;
    width: 3.25rem;
    height: 2.75rem;

    &:hover {
      background: #eff4f6;
    }
  }

  .igx-time-picker__body {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      height: 3.625rem;
      width: 100%;
      border-top: 0.0625rem dashed #acbac3;
      border-bottom: 0.0625rem dashed #acbac3;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .igx-time-picker__column {
    height: 15.875rem;
  }

  .igx-time-picker__buttons {
    height: unset;
    margin-top: 0.75rem;
    gap: 1rem;

    .igx-button {
      width: 100%;
      height: 2.5rem;
      font-size: 0.875rem;
      text-transform: uppercase;
      border-radius: 0.125rem;
      margin: 0rem !important;
    }

    & :first-child {
      color: $low-contrast-light;
      border: 0.0625rem solid $low-contrast-light;

      &:hover {
        background-color: #acbac3;
        border-color: #acbac3;
        color: #fff;
      }
    }

    & :last-child {
      color: #fff;
      background-color: $accent-01-active;
      border-color: $accent-01-active;

      &:hover {
        background-color: $accent-01-hover;
        border-color: $accent-01-hover;
      }
    }
  }
}

//Dropdown inside button(Statuatory)
.btn {

  // .ng-select-opened{}
  .ng-select-container {
    border: none;
    background-color: transparent !important;
    min-width: 4.0625rem;
  }

  .ng-arrow-wrapper {
    padding-right: 0rem;
    // @extend .flex-center;
    width: 1.25rem;
    height: 1.25rem;

    .ng-arrow {
      border: none;
      position: relative;
      width: 1.25rem !important;
      height: 1.25rem !important;
      top: 0rem !important;

      &::after {
        font-family: "icomoon" !important;
        content: "\e97e"; //caret-down-fill
        left: 50%;
        top: 50% !important;
        position: absolute;
        transform: translate(-50%, -50%);
        color: #324552;
        font-size: 0.625rem;
        // margin: 0;
      }
    }
  }

  .ng-value {
    line-height: 0.75rem;
    top: -0.0625rem;
    position: relative;
  }

  .ng-value-label {
    font-size: 0.75rem;
    font-weight: 600;
  }

  .ng-select-opened .ng-arrow::after {
    content: "\e97e"; //caret-down-fill
  }

  input {
    caret-color: transparent;
  }

  .ng-option-label {
    font-size: 0.875rem;
  }
}

// .btn-outline-primary{
//     .ng-select-container{color: $accent-01-active}
//     .ng-arrow-wrapper .ng-arrow::after{color: $accent-01-active; }
//     &:focus,&:hover{
//         .ng-select-container{color: #fff!important;}
//         .ng-arrow-wrapper .ng-arrow::after{color: #fff!important; }
//     }
// }
.radio-group {
  display: block;

  .radio-check {
    display: none;
  }

  .radio-content {
    border-width: 0.0625rem;
    transition: all ease-in 400ms;
  }

  .radio-check:checked+.radio-content {
    position: relative;
    border: 0.0625rem solid #0078ce;

    &::after {
      position: absolute;
      content: "\e974"; //check-bold
      font-family: "icomoon";
      color: #fff;
      background-color: #0078ce;
      border-radius: 1.3125rem;
      right: 1rem;
      top: 1rem;
      @extend .flex-center;
      @include sq($s: 1.313rem);
      font-size: 0.625rem;
      padding-top: 0.0625rem;
      transition: right 400ms ease-in-out;
    }
  }
}

.checkbox-22 {
  .form-check-input {
    width: 1.375rem;
    height: 1.375rem;
    aspect-ratio: 1;
  }
}

.checkbox-24 {
  .form-check-input {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 0;
    background-size: 0.75rem;
    margin-right: 0.75rem;
  }
}

.label-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.check-btn-grp {
  .form-check-btn {
    border: 0.0625rem solid #31aab7;
    border-radius: 3.125rem;
    min-width: 7rem;
    min-height: 2.25rem;
    @extend .flex-center;
    cursor: pointer;
  }

  .form-check-input:checked+.form-check-btn {
    background: #31aab7;
    color: #fff;
  }
}

.form-check {
  margin-bottom: 0;
}

.multiselect {
  &.is-invalid .ng-select-container {
    border-color: $text-danger !important;
  }
}

.form-ngselect {
  .ng-value {
    display: flex;

    .d-inline-flex {
      height: 2.5rem;

      img.avatar-sm {
        background-color: unset;
        margin-left: 0.25rem;
      }

      span.avatar-sm {
        margin-left: 0.25rem;
      }
    }
  }
}

.form-ngselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0.5rem 0.625rem;
}

.config-app-master .ng-select-opened {
  z-index: 1000;
  position: relative;
}

.config-container .ng-select-opened {
  z-index: 2000;
  position: relative;
}

.form-ngselect .ng-dropdown-panel-items {
  .ng-option-label,.ng-option {
      font-size: 0.875rem;
    }
}

.search-c2 {
  input {
    border-bottom-color: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0rem;
    padding-top: 0;
    padding-bottom: 0rem;
    height: 1.875rem;
    font-size: 0.875rem;

    &:focus {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.slider-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.0625rem solid #dee3e7;
  border-radius: 0.5rem;
  min-height: 2.5rem;
  width: 100%;
  gap: 0.25rem;
  padding: 0 0.594rem;

  .slider-btn-left,
  .slider-btn-right {
    background-color: #eaedef;
    border-radius: 4.5625rem;
    @include sq(1.563rem);
    @extend .flex-center;
    border: none;
    color: #324552;
    font-size: 0.625rem;
  }

  // .slider-btn-left{}
  // .slider-btn-right{ }
  .slider-input {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .slider-input-value {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0.563rem 0 !important;
  }
}

.input-group1 {
  display: flex;

  .divider {
    position: relative;

    &:after {
      position: absolute;
      content: "";
      height: 1.25rem;
      width: 0.0625rem;
      background-color: #dae2e8;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .ng-select:not(:last-child):not(.ng-select-opened) .ng-select-container,
  .form-control:not(:last-child):not(:focus, .is-invalid) {
    border-top-right-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-right: none;
    position: relative;

    &::after {
      position: absolute;
      content: "";
      width: 0.0625rem;
      height: 65%;
      right: 0;
      background-color: #dae2e8;
    }
  }

  .ng-select:last-child:not(.ng-select-opened) .ng-select-container,
  .form-control:last-child:not(:focus, .is-invalid) {
    border-top-left-radius: 0rem;
    border-bottom-left-radius: 0rem;
    border-left: none;
  }
}

.ngselect-outline-primary {
  &.form-ngselect {
    .ng-select-container {
      border-color: $accent-01-active !important;
      background-color: transparent;
      color: $accent-01-active;
      font-size: 0.75rem;
      font-weight: 600;
    }

    .ng-arrow {
      border-color: $accent-01-active transparent transparent;
    }
  }

  &.ng-select-opened.ng-select-bottom {
    .ng-arrow {
      border-color: transparent transparent $accent-01-active;
    }
  }
}

.form-ngselect1 {
  .ng-value-container {
    .ng-placeholder {
      top: unset !important;
    }
  }
}

.form-ngselect2 {
  .ng-select-container {
    padding-left: 0.5rem;
  }
}

.form-switch {
  .form-check-input[type="checkbox"] {
    border-radius: 2.5rem;
    height: 1.5rem;
    width: 2.875rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-size: 1.25rem;
  }

  .form-check-input:not(:checked) {
    border-color: #7d92a1;
    background-color: #7d92a1;
  }

  .form-check-input:checked {
    background-color: #31aab7;
    border-color: #31aab7;
  }
}

.form-check-input:checked+.input-uniquebg {
  outline: 0.25rem solid rgba(54, 157, 232, 0.87);
  position: relative;

  &::after {
    content: "\f633";
    font-size: 0.5rem;
    font-family: bootstrap-icons !important;
    background-color: #0078ce;
    @include sq(1rem);
    border-radius: 50%;
    position: absolute;
    right: -0.3125rem;
    top: -0.3125rem;
    color: #fff;
    @extend .flex-center;
    padding-top: 0.0625rem;
  }
}

.h-36 {

  .ng-autocomplete,
  .autocomplete-container,
  input {
    height: 2.25rem !important;
  }
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: auto !important;
  display: flex;
}

.flag_div.is-invalid {
  border-color: #dc3545;
}

.form-control-2 {
  height: 38px;
  border-radius: 8px;
  background-color: #EFF4F6;
  border: 1px solid #DAE2E8;
}

.chat-input {
  position: relative;

  .form-control {
    border-radius: 0.25rem;
    border: 1px solid #c3cbd1;
    box-shadow: 0px 0.5rem 1rem 0px rgba(18, 27, 33, 0.1);
    font-size: 0.875rem;
    padding-left: 1.5rem;
    padding-right: 3.5rem;
    width: 100%;
    resize: none;
    box-sizing: border-box;
    padding-top: 1rem;
    height: 3.4rem;
    overflow: auto !important;

    &:focus {
      border: 0.0625rem solid $accent-02-active;
    }

    &::placeholder {
      color: #7d92a1;
    }

    &::-webkit-input-placeholder,
    &::-moz-input-placeholder,
    &:-moz-input-placeholder,
    &:-ms-input-placeholder {
      padding-top: 1.5rem;
    }
  }

  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.5rem;
    // bottom: 6px;
  }
}

td ng-select:not(.form-ngselect1) .ng-value-container {
  padding-left: 0px !important;
  .ng-placeholder,
  .ng-value,
  .ng-input {
    position: absolute;
    left: .5rem;
    padding-left: 0px !important;
  }
}
.selected-values .ng-value-danger{
  background-color: #FAE9E9;
    border: 1px solid #DC3545;
}
.form-rangepicker {
  color: #324552;
  font-size: 0.938em;
  font-family: "Work Sans", sans-serif;
  position: relative;
  input {
    caret-color: #0078ce;
  }
  .mat-form-field-wrapper {
    padding-bottom: 0;
  }
  .mat-form-field-underline {
    display: none;
  }
  .mat-form-field-infix {
    border-top: none;
    width: 100%;
    padding: 0px;
  }
  .mat-date-range-input {
    height: 2.5rem;
    border: 0.0625rem solid #dae2e8;
    border-radius: 0.25rem;
    background-color: #fff;
    padding: 0.375rem 3rem 0.375rem 0.25rem;
  }
  .mat-date-range-input-container {
    height: 100%;
  }
  &.mat-focused {
    .mat-date-range-input {
      border: 0.0625rem solid #0078ce;
    }
  }
  .mat-date-range-input-separator {
    color: $low-contrast-light;
  }
  i {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
  &.is-invalid {
    .mat-date-range-input {
      border-color: #dc3545;
    }
  }
}
.mat-input-element{
  background-color: #fff !important;
}

.form-check-inline{
  margin-right: 0;
  & + .form-check-inline{
    margin-left: 1rem;
  }
}

.form-ngselect-input {
  transition: all 1s ease-in;

  & .form-ngselect {
    width: 7rem;

    &.ng-select-opened {
      width: 12rem;
    }
  }

  & .form-ngselect:not(.ng-select-opened, :focus) {
    .ng-select-container {
      border-right: transparent;
      border-radius: 4px 0 0 4px;
    }
  }

  & .form-control:not(:focus) {
    border-left: transparent;
    border-radius: 0 4px 4px 0;
  }
}
