@mixin flex($x:initial,$y:initial,$dir:initial) {
    display: flex;
    flex-direction: $dir;
    justify-content: $x;
    align-items:$y;
}
@mixin rect($w,$h){
    width: $w!important;
    height: $h!important;
}
@mixin sq($s){
    width: $s !important;
    height: $s !important;
    aspect-ratio: 1;
    flex-shrink: 0;
}
.sq{
  --ratio:1.875rem;
  width: var(--ratio) !important;
  height: var(--ratio) !important;
  aspect-ratio: 1;
  flex-shrink: 0!important;
  min-height: unset!important;
}
@mixin scrollbars($size, $foreground-color, $background-color: lighten ($foreground-color, white)) {
    // For Google Chrome
    &::-webkit-scrollbar {
      width:  $size;
      height: $size;
      border-radius: 5.5rem;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $foreground-color;
      border-radius: 5.5rem;
    }
  
    &::-webkit-scrollbar-track {
      background: $background-color;
      border-radius: 5.5rem;
    }
  
    // For Internet Explorer
    & {
      scrollbar-face-color: $foreground-color;
      scrollbar-track-color: $background-color;
    }
    //firefox
    &{
      scrollbar-color: $foreground-color $background-color!important;
      scrollbar-width: thin;
    }
  }
