@import "../0-base/mediaquery";
html {
  height: 100%;
}
:root {
  font-size: 14px;
  @include media(">=figma") {
    font-size: 16px;
  }
  @include media(">=LGdesktop") {
    font-size: 18px;
  }
}
body {
  min-height: 100%;
  overflow-x: hidden;
  background-color: #eff4f6;
  position: relative;
  scroll-behavior: smooth;
  --bs-body-color: #324552;
  --bs-font-sans-serif: Work Sans;
  // word-break: break-word;
}
p {
  word-wrap: break-word;
  word-break: break-word;
}
a {
  text-decoration: none;
  cursor: pointer;
}

* {
  @include scrollbars(0.5rem, rgba(50, 69, 82, 0.35), #f8fafb);
}
body {
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes fontfix {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
