.toast-c1 {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a !important;
  background-color: #ffffff !important;
  width: 31rem !important;
  border-radius: 0.25rem !important;
  padding: 1rem 1.5rem 1rem 3rem !important;
  background-position: 1.125rem 1.25rem !important;
  background-size: 1.125rem !important;
  color: $high-contrast-light;
  &::hover {
    cursor: pointer;
  }
  .toast-title {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
    color: $high-contrast-light;
  }
  .toast-message {
    font-size: 0.875rem;
    color: $high-contrast-light;
  }
  .toast-close-button {
    font-weight: normal;
    color: $high-contrast-light;
  }
}

.toast-success {
  background-image: url(/assets/icons/svg/ico-tick-green.svg) !important;
  background-color: white !important;
  .toast-title {
    color: $text-success !important;
  }
}

.toast-error {
  background-image: url(/assets/icons/svg/ico-warning-danger.svg);
  background-color: white !important;
  .toast-title {
    color: $text-danger !important;
  }
}

.toast-warning {
  background-image: url(/assets/icons/svg/ico-warning-warning.svg);
  background-color: white !important;
  .toast-title {
    color: $text-warning !important;
  }
}

.toast-custom {
  background-image: none;
  background-color: transparent !important;
  width: 25rem;
}

.toast-custom .toast-close-button {
  position: relative;
  right: 0.2em;
  top: 0.2em;
  font-weight: 500;
  color: #191818;
  pointer-events: auto;
}
.notification-new {
  border-radius: 0.5rem;
  overflow: hidden;
  .toast-close-button {
    top: 0.625rem;
    font-weight: 400;
    // line-height: .0625rem;
  }
}
.notification-icon {
  i {
    font-family: "icomoon" !important;
    font-style: normal;
    font-weight: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: inherit;
  }
  /* Time and attendance */
  &.type-1 {
    background-color: #dbecf8;
    color: #0078ce;
    font-size: 1.25rem;
    i::before {
      content: "\e913"; //briefcase-clock
    }
  }
  /* Payroll */
  &.type-2 {
    background-color: #f6cece;
    color: #cb786c;
    font-size: 1.25rem;
    i::before {
      content: "\e954"; //paper
    }
  }
  /* HR */
  &.type-3 {
    background-color: #fdf4c4;
    color: #f6ad05;
    font-size: 1.1875rem;
    i::before {
      content: "\e95f"; //share2
    }
  }
  /* Letter */
  &.type-4 {
    background-color: #CBECBE;
    color: #129AA6;
    font-size: 1.25rem;
    i::before {
      content: "\e9ba"; //note-2
    }
  }
}
.toast-top-right {
  overflow: hidden;
  border-radius: 0.5rem;
}
.toast-top-right.toast-container {
  .toast-close-button {
    z-index: 100;
    font-weight: 400;
    top: 1rem;
    right: 1rem;
    position: absolute;
  }
}
.toast-container> :not(:last-child) {
  margin-bottom: 0.5rem;
}
