@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?5g8mim");
  src: url("../../assets/fonts/icomoon.eot?5g8mim#iefix") format("embedded-opentype"),
    url("../../assets/fonts/icomoon.ttf?5g8mim") format("truetype"),
    url("../../assets/fonts/icomoon.woff?5g8mim") format("woff"),
    url("../../assets/fonts/icomoon.svg?5g8mim#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i[class^="icon-"],
i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    text-transform: uppercase;
  }
}

// .icon-grid:before {
//   content: #{'"' + \e90e+ '"'};
// }
.icon-clock-repeat:before {
  content: "\e92e";
}
.icon-briefcase-clock-fill:before {
  content: "\e96a";
}
.icon-clipboard-clock:before {
  content: "\e96b";
}
.icon-clock:before {
  content: "\e907";
}
.icon-stopwatch1-fill:before {
  content: "\e9c1";
}
.icon-calendar-fill-clock:before {
  content: "\e9b4";
}
.icon-calendar-fill-x:before {
  content: "\e9aa";
}
.icon-calendar:before {
  content: "\e906";
}
.icon-stopwatch:before {
  content: "\e97a";
}
.icon-calendar-clock:before {
  content: "\e919";
}
.icon-clock1:before {
  content: "\e92d";
}
.icon-clock-checked:before {
  content: "\e988";
}
.icon-clock-fill:before {
  content: "\e989";
}
.icon-stopwatch-fill:before {
  content: "\e965";
}
.icon-crown:before {
  content: "\e9c5";
}
.icon-map-route:before {
  content: "\e9bc";
}
.icon-geo-alt-fill:before {
  content: "\e9bf";
}
.icon-geo-alt:before {
  content: "\e9b6";
}
.icon-geo1-alt-fill:before {
  content: "\e9ad";
}
.icon-megaphone3:before {
  content: "\e9bb";
}
.icon-megaphone2:before {
  content: "\e9b9";
}
.icon-note-2:before {
  content: "\e9ba";
}
.icon-megaphone:before {
  content: "\e9b8";
}
.icon-grid:before {
  content: "\e90e";
}
.icon-list-ul:before {
  content: "\e9b5";
}
.icon-copy:before {
  content: "\e9b2";
}
.icon-shield-lock:before {
  content: "\e9b3";
}
.icon-message-system:before {
  content: "\e99e";
}
.icon-ai:before {
  content: "\e999";
}
.icon-slip:before {
  content: "\e979";
}
.icon-papers-earmark-fill:before {
  content: "\e95c";
}
.icon-graph-square:before {
  content: "\e9ab";
}
.icon-chat-new:before {
  content: "\e901";
}
.icon-note:before {
  content: "\e902";
}
.icon-cash-stack:before {
  content: "\e903";
}
.icon-cash1:before {
  content: "\e905";
}
.icon-stack:before {
  content: "\e9ac";
}
.icon-loader:before {
  content: "\e9ae";
}
.icon-gallery:before {
  content: "\e9af";
}
.icon-camera:before {
  content: "\e9b0";
}
.icon-file-earmark-arrow-left:before {
  content: "\e93e";
}
.icon-files-arrow-in-right:before {
  content: "\e96c";
}
.icon-file-grid-fill:before {
  content: "\e941";
}
.icon-window-arrow-in-up:before {
  content: "\e96d";
}
.icon-tick-circle:before {
  content: "\e99a";
}
.icon-info-circle2:before {
  content: "\e90c";
}
.icon-question-circle:before {
  content: "\e900";
}
.icon-check-bold:before {
  content: "\e974";
}
.icon-check:before {
  content: "\e91f";
}
.icon-check-circle:before {
  content: "\e96f";
}
.icon-check-circle-bold:before {
  content: "\e921";
}
.icon-check2-circle:before {
  content: "\e975";
}
.icon-double-check-circle-fill:before {
  content: "\e920";
}
.icon-check-square-fill:before {
  content: "\e922";
}
.icon-info-circle:before {
  content: "\e97b";
}
.icon-info-circle-fill:before {
  content: "\e951";
}
.icon-exclamation-circle-fill:before {
  content: "\e938";
}
.icon-exclamation-triangle:before {
  content: "\e939";
}
.icon-exclamation-triangle-fill:before {
  content: "\e972";
}
.icon-plus:before {
  content: "\e95b";
}
.icon-plus-circle:before {
  content: "\e96e";
}
.icon-plus-square:before {
  content: "\e977";
}
.icon-plus-square-fill:before {
  content: "\e971";
}
.icon-minus-square:before {
  content: "\e976";
}
.icon-close:before {
  content: "\e92f";
}
.icon-close-lg:before {
  content: "\e930";
}
.icon-close-circle:before {
  content: "\e970";
}
.icon-question-circle-fill:before {
  content: "\e9a7";
}
.icon-cloud-check:before {
  content: "\e931";
}
.icon-cloud-upload:before {
  content: "\e932";
}
.icon-file-earmark-arrow-up:before {
  content: "\e93f";
}
.icon-graph-up:before {
  content: "\e949";
}
.icon-file-earmark-text-fill:before {
  content: "\e940";
}
.icon-download:before {
  content: "\e936";
}
.icon-eye:before {
  content: "\e93b";
}
.icon-eye-slash:before {
  content: "\e93c";
}
.icon-filter-circle:before {
  content: "\e943";
}
.icon-filter-left:before {
  content: "\e944";
}
.icon-image:before {
  content: "\e94f";
}
.icon-pencil-square:before {
  content: "\e955";
}
.icon-search:before {
  content: "\e95e";
}
.icon-three-dots:before {
  content: "\e966";
}
.icon-three-dots-vertical:before {
  content: "\e967";
}
.icon-trash:before {
  content: "\e969";
}
.icon-min-fullscreen:before {
  content: "\e9c0";
}
.icon-fullscreen:before {
  content: "\e9c2";
}
.icon-arrows-minimize:before {
  content: "\e982";
}
.icon-arrows-fullscreen:before {
  content: "\e983";
}
.icon-graph-upwards:before {
  content: "\e985";
}
.icon-arrow-left-circle:before {
  content: "\e90d";
}
.icon-arrow-repeat-counterclockwise:before {
  content: "\e911";
}
.icon-arrow-right-circle:before {
  content: "\e912";
}
.icon-return-left-fill:before {
  content: "\e95d";
}
.icon-arrow-repeat:before {
  content: "\e90a";
}
.icon-arrow-return-left-square:before {
  content: "\e9b1";
}
.icon-arrow-anti-clockwise:before {
  content: "\e904";
}
.icon-arrow-right1:before {
  content: "\e99b";
}
.icon-send:before {
  content: "\e99c";
}
.icon-chevron-right-circle:before {
  content: "\e9c3";
}
.icon-chevron-left-circle:before {
  content: "\e9c4";
}
.icon-arrow-left-circle-fill:before {
  content: "\e9be";
}
.icon-arrow-right-circle-fill:before {
  content: "\e9bd";
}
.icon-caret-down-fill:before {
  content: "\e97e";
}
.icon-arrow-down:before {
  content: "\e908";
}
.icon-arrow-left:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e929";
}
.icon-arrow-up:before {
  content: "\e9b7";
}
.icon-chevron-double-left:before {
  content: "\e923";
}
.icon-chevron-double-right:before {
  content: "\e924";
}
.icon-chevron-down:before {
  content: "\e925";
}
.icon-chevron-down-lg:before {
  content: "\e926";
}
.icon-chevron-left-lg:before {
  content: "\e927";
}
.icon-chevron-right:before {
  content: "\e928";
}
.icon-chevron-right-lg:before {
  content: "\e973";
}
.icon-chevron-down-lg1:before {
  content: "\e97c";
}
.icon-chevron-up-lg:before {
  content: "\e92a";
}
.icon-arrow2-up-circle:before {
  content: "\e90b";
}
.icon-bank:before {
  content: "\e90f";
}
.icon-briefcase-grid:before {
  content: "\e915";
}
.icon-family:before {
  content: "\e93d";
}
.icon-graduation-cap:before {
  content: "\e948";
}
.icon-person2:before {
  content: "\e958";
}
.icon-person-badge-settings:before {
  content: "\e959";
}
.icon-person-lines-fill:before {
  content: "\e95a";
}
.icon-shield-plus:before {
  content: "\e960";
}
.icon-calendar-fill:before {
  content: "\e91b";
}
.icon-cash-stack-fill:before {
  content: "\e91d";
}
.icon-people-fill:before {
  content: "\e956";
}
.icon-card-add-fill:before {
  content: "\e97d";
}
.icon-buildings-fill:before {
  content: "\e97f";
}
.icon-cash-fill:before {
  content: "\e98a";
}
.icon-My-team-hierarchy-icon-svg:before {
  content: "\e978";
}
.icon-person1:before {
  content: "\e957";
}
.icon-bank2:before {
  content: "\e910";
}
.icon-calendar-check-fill:before {
  content: "\e918";
}
.icon-calendar-event-fill:before {
  content: "\e91a";
}
.icon-clipboard-data-fill:before {
  content: "\e92c";
}
.icon-square:before {
  content: "\e962";
}
.icon-hourglass:before {
  content: "\e980";
}
.icon-qr-code:before {
  content: "\e981";
}
.icon-newspaper:before {
  content: "\e984";
}
.icon-home-building:before {
  content: "\e986";
}
.icon-person-helmet:before {
  content: "\e987";
}
.icon-calendar-edit:before {
  content: "\e997";
}
.icon-bell-2-fill:before {
  content: "\e98b";
}
.icon-cake:before {
  content: "\e98c";
}
.icon-calendar-close-circle:before {
  content: "\e98d";
}
.icon-file-invalid:before {
  content: "\e98e";
}
.icon-inbox-fill:before {
  content: "\e98f";
}
.icon-gift-fill:before {
  content: "\e990";
}
.icon-zoom-in:before {
  content: "\e991";
}
.icon-person-hexagon:before {
  content: "\e992";
}
.icon-grid-fill:before {
  content: "\e94a";
}
.icon-coffee:before {
  content: "\e933";
}
.icon-handwave:before {
  content: "\e94b";
}
.icon-snooze-fill:before {
  content: "\e961";
}
.icon-star2:before {
  content: "\e963";
}
.icon-stop-fill:before {
  content: "\e964";
}
.icon-share2:before {
  content: "\e95f";
}
.icon-paper:before {
  content: "\e954";
}
.icon-clipboard2-check:before {
  content: "\e92b";
}
.icon-chat-square-star:before {
  content: "\e91e";
}
.icon-briefcase-clock:before {
  content: "\e913";
}
.icon-app-indicator:before {
  content: "\e914";
}
.icon-house-door:before {
  content: "\e94d";
}
.icon-envelope:before {
  content: "\e937";
}
.icon-list-check:before {
  content: "\e952";
}
.icon-bar-chart-line:before {
  content: "\e993";
}
.icon-person-gear:before {
  content: "\e994";
}
.icon-person-directory:before {
  content: "\e934";
}
.icon-person-group:before {
  content: "\e916";
}
.icon-diagram-3:before {
  content: "\e917";
}
.icon-gear:before {
  content: "\e945";
}
.icon-bell:before {
  content: "\e91c";
}
.icon-box-arrow-in-fill:before {
  content: "\e935";
}
.icon-gear2:before {
  content: "\e946";
}
.icon-gear-fill:before {
  content: "\e947";
}
.icon-palette:before {
  content: "\e953";
}
.icon-manage-system:before {
  content: "\e93a";
}
.icon-bell-2:before {
  content: "\e942";
}
.icon-wallet-plus:before {
  content: "\e9c6";
}
.icon-resignation:before {
  content: "\e9c7";
}
.icon-dollar-wallet:before {
  content: "\e9a9";
}
.icon-calendar-fill-dollar:before {
  content: "\e998";
}
.icon-calendar-fill1:before {
  content: "\e99d";
}
.icon-calendar-fill-user:before {
  content: "\e99f";
}
.icon-briefcase-timer-fill:before {
  content: "\e950";
}
.icon-coins:before {
  content: "\e995";
}
.icon-gold-bars:before {
  content: "\e996";
}
.icon-align-bottom:before {
  content: "\e94c";
}
.icon-briefcase-fill:before {
  content: "\e94e";
}
.icon-bullseye:before {
  content: "\e968";
}
.icon-bus-fill:before {
  content: "\e9a0";
}
.icon-cash:before {
  content: "\e9a1";
}
.icon-dollor:before {
  content: "\e9a2";
}
.icon-expense-fill:before {
  content: "\e9a3";
}
.icon-file-pencil:before {
  content: "\e9a4";
}
.icon-holiday:before {
  content: "\e9a5";
}
.icon-house-fill:before {
  content: "\e9a6";
}
.icon-ticket-fill:before {
  content: "\e9a8";
}
