.mail-container{
    background-color: white;
    // width: 36.875rem ;
    margin: auto;
    .mail-body{
        border: .0625rem solid #6970751A;
        .mail-header{
            display: flex;
            background-color: #F8FAFB;
            
            .header-img{
                padding-left: 0.625rem;
                padding-right: 0.625rem;
                // background-image: url('/assets/images/mail_images/template-bg2.png');
                // min-height: 9.375rem;
                // width: 4.75rem;
                // background-position-x:center ;
                // background-repeat: repeat-y;
                // height: 100%;
            }
            .mail-title{
                padding-top: 5.1875rem;
                max-width: 21.125rem;
            }
        }
        .mail-content{

            //  display: flex;
             padding: 2rem 5rem;
             p{
                font-size: 0.875rem;
             }
        }
    }
    .mail-footer{
        display: flex;
        margin-top: 1rem;
        p{
            font-size:0.75rem;
            color: $low-contrast-light;
            margin-bottom: 0;
            line-height: 1rem;
        }
    }
}