// @import 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap';
@import "/src/styles/0-base/mediaquery";
$font-family: "Work Sans", sans-serif;
$display: 4;

@mixin text-style($fw: normal) {
  font-family: $font-family;
  font-style: normal;
  font-weight: $fw;
  // color: $high-contrast-light;
}

body {
  @include text-style;
}
.display {
  @for $i from 1 through $display {
    &-#{$i} {
      @include text-style;
    }
  }
  &-1 {
    font-size: 6rem;
    line-height: 6.875rem;
  }
  &-2 {
    font-size: 5.5rem;
    line-height: 6.313rem;
    // letter-spacing: -0.04rem;
  }
  &-3 {
    font-size: 4.5rem;
    line-height: 5.188rem;
    // letter-spacing: -0.04rem;
  }
  &-4 {
    font-size: 3.5rem;
    line-height: 4.125rem;
    // letter-spacing: -0.04rem;
  }
}

h1,
.h1 {
  @include text-style;
  font-size: 2.5rem;
  line-height: 3rem;
}
h2,
.h2 {
  @include text-style;
  font-size: 2rem;
  line-height: 2.438rem;
}
h3,
.h3 {
  @include text-style;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-bottom: 0.2rem;
}
h4,
.h4 {
  @include text-style;
  font-size: 1.5rem;
  line-height: 1.875rem;
}
h5,
.h5 {
  @include text-style;
  font-size: 1.25rem;
  line-height: 1.625rem;
}
h6,
.h6 {
  @include text-style($fw: 500);
  font-size: 0.9rem;
  line-height: 1.3rem;
}
.lead {
  @include text-style;
  font-size: 1.25rem;
  line-height: 1.875rem;
}
p {
  @include text-style;
  font-size: 1rem;
  line-height: 1.375rem;
}
.text-helper {
  @include text-style($fw: 400);
  font-size: 0.85rem;
  line-height: 1.4rem;
  letter-spacing: 0rem;
  color: $medium-contrast-light;
}
.label-xl {
  @include text-style($fw: 400);
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0rem;
}
.label-lg {
  @include text-style($fw: 400);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0rem;
}
label .label-md {
  @include text-style($fw: 400);
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}
.label-sm {
  @include text-style($fw: 500);
  font-size: 0.75rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}
small {
  @include text-style($fw: 400);
  font-size: 0.75rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}
.note {
  @include flex($x: center, $y: center);
  width: 100%;
  color: var(--bs-gray-300);
  text-align: center;
  font-size: 0.75rem;
  margin-bottom: 0;
  &::before {
    content: "<-------";
  }
  &::after {
    content: "-------->";
  }
}
