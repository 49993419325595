.card-ribbon{
    position: relative;
    .ribbon{
        position: absolute;
        &.ribbon-right{right: 0;}
    }
}
.ribbon{
    height: 1.35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.519rem 0 1.526rem;
    font-weight: 500;
    font-size: 0.75rem;
    clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 12% 50%, 0% 0%);
    &.ribbon-sm{
        height: 1rem;
        font-size: 0.5rem;
        padding: 0 0.449rem 0 0.762rem;
    }
    &.ribbon-secondary{
        background-color: #566976;
        color: #fff;
    }
    &.ribbon-danger{
        background-color: #DC3545;
        color: #fff;
    }
    &.ribbon-primary{
        background-color: #0078CE;
        color: #fff;
    }
}
