// @import '/src/styles/3-helpers/mixin';
.vetical-tab {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  display: block !important;

  .tab-link {
    $color: $medium-contrast-light;
    position: relative;
    height: 2.5rem;
    display: block;
    line-height: 2.5rem;
    padding: 0 1.5rem;
    font-size: 0.875rem;
    color: $color;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.success {
      color: #28a745;
      padding-right: 1.875rem !important;

      &::after {
        content: "\e974"; //check-bold
        font-family: "icomoon" !important;
        color: #28a745;
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        position: absolute;
        right: 0.75rem;
        font-size: 0.75rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    &.active {

      font-weight: 500;

      &::before {
        content: "";
        position: absolute;
        width: 0.25rem;
        height: 1.25rem;
        left: 0rem;
        background-color: #0078ce;
        border-radius: 0 1rem 1.5rem 0rem;
        top: 50%;
        transform: translateY(-50%);
      }

      // &::after {
      //     border-top: 0.125em solid $accent-02-active;
      //     border-right: 0.125em solid $accent-02-active;
      // }
    }

    &:hover {
      background-color: #c0ced733;
    }
  }
}

.vetical-tab {
  .tab-link {
    &.active {
      color: $accent-02-active;
      background: #0078ce1c;
    }

    &:hover {
      background-color: #c0ced733;
    }
  }
}

.vetical-tab-inverted {
  .tab-link {
    &.active {
      color: $accent-02-active;
      background-color: #fff;
    }

    &:hover {
      background-color: #ffffff21;
    }
  }
}

.tabs {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;

  li {
    float: left;
  }
}

.tab-pill {
  .pill {
    background: $primary-background-light;
    border: 0.0625rem solid darken(#e4e8ea, 10%);
    border-radius: 0.25rem;
    height: 1.5rem;
    padding: 0.188rem 0.5rem;
    font-size: 0.75rem;
    color: $high-contrast-light;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    @include flex($y: center);

    &:hover:not(.success, .active) {
      border-color: $accent-02-active;
      color: $accent-02-active;
    }

    &.active {
      background: $accent-02-active;
      border-color: $accent-02-active;
      color: white;
      font-weight: 600;

      &:hover {
        border-color: $accent-02-active;
        background: transparent;
        color: $accent-02-active;
      }
    }

    &.success:not(.active) {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%2328A745'/%3E%3Cpath d='M5.6001 8.4L7.2001 10L10.8001 6' stroke='white' stroke-width='0.8' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 1rem;
      padding-left: 2rem;
      background-position-y: center;
      background-position-x: 0.5rem;

      &:hover {
        border-color: $text-success;
        color: $text-success;
      }
    }
  }
}

.nav-custom {
  background-color: $component-background-01-light;
  min-height: $menutab-height + rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0.25rem 0 0.3125rem rgba(0, 0, 0, 0.08);

  // gap: 1.5rem;
  &.sticky {
    position: sticky;
    top: 0rem;
    z-index: $zIndex-MenuTab;
  }

  & .nav-link {
    font-size: 0.785rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    color: $medium-contrast-light;
    border-top: 0.125rem solid transparent;
    border-bottom: 0.125rem solid transparent;
    transition: border-bottom 200ms ease-in-out;
    height: 2.5rem;
    min-width: 6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 50ch;
    overflow: hidden;
    display: block;
    text-align: center;
  }

  & .nav-link+.nav-link {
    margin-left: 1.5rem;
  }

  & .nav-link:hover {
    // border-bottom: .125rem solid $accent-02-hover ;
    background-color: $primary-background-light;
  }

  & .nav-link.active {
    font-weight: 500;
    color: $accent-02-active;
    border-bottom-color: $accent-02-active;
  }

  &.nav-inline {
    flex-wrap: unset;

    .nav-link {
      flex: 1;
      text-align: center;
    }
  }
}

.top-48 {
  top: 3rem !important;
}

.nav-c2 {
  counter-reset: item;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;

  li {
    width: 100%;

    &:last-child {
      width: 1.5rem;
      aspect-ratio: 1;
    }

    &:not(:last-child) {
      a::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 1px;
        background-color: #e4e8ea;
        top: 50%;
        transform: translateY(-50%);
        left: calc(1.5rem / 2);
        z-index: 9;

        @include media(">=figma") {
          height: 0.0625rem;
        }
      }
    }

    a {
      height: 1.5rem;
      width: 100%;
      padding: 0;
      position: relative;

      &::before {
        content: counter(item) "";
        counter-increment: item;
        position: absolute;
        width: 1.5rem;
        border: 0.0625rem solid #e4e8ea;
        border-radius: 50%;
        aspect-ratio: 1;
        @extend .flex-center;
        font-size: 0.875rem;
        font-weight: 500;
        color: #e4e8ea;
        transition: all ease-in 400ms;
        left: 0;
        z-index: 10;
        background: #fff;
        pointer-events: none;
      }

      &.success {
        &::before {
          content: "\e974"; //check-bold
          font-family: "icomoon" !important;
          color: #fff;
          background-color: #009722;
          border-color: #009722;
          font-size: 0.625rem;
        }

        &::after {
          background-color: #009722 !important;
        }
      }

      &.active {
        &::before {
          background-color: #0078ce;
          border-color: #0078ce;
          color: #fff;
        }
      }
    }
  }

  // li {

  //   // a {
  //   //   padding: 0.8rem;
  //   //   height: 1.5rem;
  //   //   position: absolute;
  //   //   width: 100%;
  //   //   z-index: 11;
  //   // }
  // }
}

.tab-pane {
  height: 100%;
}

.multilevel-tab {

  &,
  ul {

    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  a {
    font-size: .75rem;
    padding: 0 1.5rem;
    white-space: nowrap;
    display: flex;
    position: relative;

    &:hover {
      background: #0078ce1a;
    }

    & :last-child:not(:first-child) {
      width: 4ch;
      text-align: right;
      white-space: nowrap;
      margin-left: 0.5rem;
    }

    & :first-child {
      width: calc(100% - 5ch);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // text-align: left;
    }



    &.active {
      background: #0078ce1a;
      color: #0078ce !important;
      font-weight: 600;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0rem;
        top: 50%;
        transform: translateY(-50%);
        border-right: 0.25rem solid #0078ce;
        height: 50%;
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        font-weight: 400;
      }
    }
  }

  &>li a {
    font-weight: 600;
    line-height: 2.5rem;
    height: 2.5rem;
  }

  li.hasSubmenu>a {
    padding-right: 2.5rem;

    &::after {
      content: '\e973'; //chevron-right-lg
      font-family: "icomoon";
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: .5rem;
      font-weight: 400;
    }
  }


  .sub-tab {
    border-bottom: 1px solid #ACBAC3;
    padding-bottom: .5rem;
    margin-bottom: .5rem;

    &>li a {
      font-weight: 500;
      line-height: 2rem;
      height: 2rem;
      color: #566976;
      padding-left: 2rem;
    }
  }
}