@import "/src/styles/0-base/variables";
.modal, .note-modal {
  z-index: $zIndex-modal !important;
  &.show {
    display: block;
  }
  &::after {
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    inset: 0;
    pointer-events: none;
    background-color: #000000aa;
    z-index: -1;
  }
}
.modal-custom,
.modal-alert {
  .modal-dialog:not(.modal-lg) {
    --bs-modal-width: 31rem;
  }
  .modal-content {
    border: none;
    box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
    border-radius: 1.5rem;
  }
  .modal-title {
    line-height: 1.875rem;
  }
  .modal-header {
    padding: 1.5rem 1.5rem 1rem 2rem;
    justify-content: start;
    border: none;
    gap: 1rem;
    i {
      font-size: 1.25rem;
    }
  }
  .modal-body {
    padding: 1rem 2rem;
  }
  .modal-footer {
    border: none;
    padding: 1.5rem 2rem;
    justify-content: center;
    gap: 1rem;
    & > * {
      margin: 0;
    }
  }
  //   &::after {
  //     content: "";
  //     position: fixed;
  //     width: 100vw;
  //     height: 100vh;
  //     inset: 0;
  //     pointer-events: none;
  //     background-color: #000000aa;
  //     z-index: -1;
  //   }
}
.modal-custom1 {
  .modal-dialog {
    max-width: 43.1875rem !important;
  }
  .modal-content {
    border-radius: 0.5rem !important;
  }
  .modal-body {
    background: #f8fafb !important;
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
}
.qr-modal {
  .modal-dialog {
    max-width: 24.6875rem !important;
  }
  .modal-header {
    border-bottom: 0.0625rem solid #e4e8ea;
  }
}
.modal-c1 {
  --bs-modal-header-padding: 1rem 1.5rem;
  --bs-modal-padding: 1rem 1.5rem;
  // --bs-modal-border-color:transparent;
  --bs-modal-border-radius: 0.25rem;
  --bs-modal-border-width: 0;
  --bs-modal-header-border-width: 0;
  .modal-content {
    box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  }
  .modal-header {
    box-shadow: 0px 1px 1px rgba(105, 112, 117, 0.1);
  }
}
.modal {
  --bs-modal-header-border-color: #e4e8ea;
}

.modal-dialog-lg{
  height: 500px;
  min-height: unset;
}
