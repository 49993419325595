@import "/src/styles/0-base/variables";

.config-summernote {
  .note-frame {
    border-radius: 0;
    border: none;
    height: calc(100vh - (3.625rem + 4.5rem));
    font-family: unset;
  }

  .note-editing-area {
    padding: 1.5rem;
    height: calc(100vh - (3.625rem + 8.5rem));
    overflow: auto !important;
  }

  .note-editable {
    padding: 3.125rem 2.5rem !important;
    min-height: calc(100vh - 16rem);
    background-color: #fff;

    p {
      margin-bottom: 0;
    }
  }

  .note-statusbar {
    display: none;
    // visibility: hidden;
  }

  .note-toolbar {
    padding: 0px !important;
    border: none;
    background-color: #fff;

    .note-btn-group {
      margin-top: 0px !important;
      margin-right: 0px !important;

      & :first-child {
        border-left-color: transparent;
      }
    }

    .note-btn {
      border-radius: 0px;
      border-top-color: transparent;
      // border-bottom-color: transparent;
    }
  }
}

.table-summernote {

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-color: black !important;
    color: black;
  }
}

.note-dropzone,
.note-placeholder {
  display: none !important;
}

.note-modal-content {
  border: none !important;
  box-shadow: none !important;
  border-radius: 1.5rem !important;

  .note-modal-title {
    color: #324552;
  }

  .note-modal-header {
    border: none;
    padding: 1.5rem 1.5rem 1rem 2rem;
  }

  .note-modal-body {
    padding: 0rem 1.5rem 1rem 2rem;
  }

  .note-modal-footer {
    text-align: right;
    height: unset;
    padding: 1.5rem 1.5rem 1rem 2rem;

    .note-btn {
      float: unset;
      border-radius: 0.125rem;
      height: 2.5rem;
      font-size: 0.875rem;
      min-width: 8.75rem;
      font-weight: 600;
      font-family: 'work sans';
    }
  }

  .note-form-group {
    padding-bottom: 1rem;
  }

  .note-form-label {
    font-size: 0.875rem;
    line-height: 1.313rem;
    margin-bottom: 0.375rem !important;
    padding: 0 !important;
    font-weight: 400;
    font-family: 'work sans';
    color: #324552;
  }

  // summer
  .note-form-control {
    border: 0.0625rem solid #dae2e8;
    border-radius: 0.25rem;
  }

}

.note-modal-backdrop {
  display: none !important;
}

.note-editor .note-toolbar .note-color-all .note-dropdown-menu {
  min-width: auto !important;
  transform: translatex(calc(-50% + 28px));
}

.note-btn-group.open.note-color-all .note-dropdown-menu {
  display: flex !important;
}
