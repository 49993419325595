.card {
  border: none;
  border-radius: .25rem;
  // overflow: hidden;
  // padding: 2rem;
  box-shadow: 0rem .5rem 1rem rgba(18, 27, 33, 0.1);
  // word-break: break-word;
  .card-body {
    padding: 1rem;
  }
}
.card-body+.card-body {
  border-top: .0625rem solid #e6ecee;
}
.card-hover {
  .view-section {
    max-width: calc(100% - 1.625rem);
    width: 100%;
    padding: 1rem;
  }
  .dropdown-section {
    width: 1.625rem;
    padding: 1rem 1rem 1rem 0;
    visibility: hidden;
    .dropdown-toggle {
      font-size: 1rem;
      color: $low-contrast-light;
    }
    .dropdown-menu {
      font-size: .875rem;
    }

    //     min-height: 100%;
  }
}

.card-hover:hover .dropdown-section {
  visibility: visible;
}
.card-custom1 {
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  border: .0625rem solid #dee3e7;
  transition: transform 100ms ease-in-out;
  // &:hover{
  //     box-shadow: 0rem .5rem 1rem rgba(18, 27, 33, 0.1);
  //     transform: translateY(-0.125rem);
  //     z-index: 99;
  // }
  // .overflow-hidden{
  //     max-width: 9.6875rem;
  // }

  .card-body {
    @include flex($y: center);
    .logo-img {
      @include sq($s: 3rem);
      border-radius: .5rem;
      object-fit: contain;
    }
    div.logo-img {
      @include flex(center, center);
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 500;
      background-color: $bg-success;
      color: $high-contrast-light;
    }
    .user-img {
      @include sq($s: 1.5rem);
      border-radius: .25rem;
      border: .125rem solid #fff;
      box-shadow: 0rem .125rem .25rem 0rem #69707533;
      object-fit: cover;
    }
    div.user-img {
      @include flex(center, center);
      font-size: .688rem;
      font-weight: 500;
      background-color: $bg-success;
      position: relative;
      span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .logo-img+div {
      margin-left: 1rem;
    }
    .user-img+div {
      margin-left: .5rem;
    }
  }
  .card-body+.card-body {
    border-top: .0625rem solid #e6ecee;
  }
}
.details-card {
  box-shadow: none;
  border: .0625rem solid #dee3e7;

  .title {
    color: $high-contrast-light;
    font-weight: 600;
    font-size: .75rem;
    text-transform: uppercase;
    position: relative;
    display: block;
    &::after {
      content: "";
      width: 2.9375rem;
      position: absolute;
      height: .125rem;
      bottom: -0.5rem;
      background-color: black;
      left: 0;
    }
  }

  .detail-title {
    font-size: .75rem;
    line-height: 1.25rem;
    color: $low-contrast-light;
    img {
      padding-left: .5rem;
    }
  }
  .detail-desc {
    font-size: .875rem;
    line-height: 1.25rem;
    color: $high-contrast-light;
    display: flex;
    align-items: center;
    word-break: break-word;
  }
}
.card-custom2 {
  box-shadow: none;
  border-radius: .25rem;
  border: .0625rem solid #e4e8ea;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  transition: transform 100ms ease-in-out;

  .card-body {
    padding: 1rem;
  }
  .card-body.status {
    padding-right: 4.188rem;
    position: relative;
  }
  .status-define {
    content: "";
    @include sq(1.625rem);
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: .25rem;
  }
  .status.status-pending:after {
    @extend .status-define;
    // @extend .clock-bg;
    @extend .hourglass-bg;
    background-color: $text-warning;
  }
  .status.status-success:after {
    @extend .status-define;
    @extend .check-bg;
    background-size: 1.25rem;
    background-color: $text-success;
  }
}
.status-icon {
  @include sq(1.25rem);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.25rem;
  border-radius: .25rem;
  display: inline-block;
  &.status-success {
    @extend .check-bg;
    background-size: 1.25rem;
    background-color: $text-success;
  }

  &.status-pending {
    @extend .hourglass-bg;
    background-size: .875rem;
    background-color: $text-warning;
  }
}
.clock-bg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-clock-history' viewBox='0 0 16 16'%3E%3Cpath d='M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z'/%3E%3Cpath d='M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z'/%3E%3Cpath d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z'/%3E%3C/svg%3E");
}
.check-bg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/%3E%3C/svg%3E");
}
.hourglass-bg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-hourglass' viewBox='0 0 16 16'%3E%3Cpath d='M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z'/%3E%3C/svg%3E");
}
.card-hover {
  &:hover {
    box-shadow: 0rem .5rem 1rem rgba(18, 27, 33, 0.1);
    cursor: pointer;
  }
}
.card-hover1 {
  &:hover {
    box-shadow: 0rem .1rem 1rem rgba(18, 27, 33, 0.1);
    cursor: pointer;
  }
}
.card-c2 {
  box-shadow: none;
  border: .0625rem solid #e4e8ea;
  .card-body+.card-body {
    border-top: .0625rem solid #eaedef;
  }
}
.card-c2.active {
  border: .125rem solid $accent-02-active;
}
.hr-1 {
  min-height: .083rem !important;
  height: .063rem !important;
  margin: 0;
  // border-width: 1;
  color: #eaedef;
  background-color: #eaedef;
  opacity: 1;
}
.card-c1 {
  border: .0625rem solid #e4e8ea;
  border-radius: .25rem;
  box-shadow: none;
}
.card-c3 {
  // --color:#EAEDEF;
  cursor: pointer;
  box-shadow: none;
  border: .0625rem solid #eaedef;
  border-radius: .25rem;
  text-align: center;
  .date {
    @extend .flex-center;
    flex-direction: column;
    max-height: 3.563rem;
  }
  .status {
    background: #eaedef;
    height: 1.375rem;
    @extend .flex-center;
    color: #7d92a1;
  }
  &.current .status {
    color: #0066dd;
  }
  // &.success .status{
  //     color: #28A745;
  // }
  &.active {
    // --color: $accent-01-active;
    border: .0625rem solid $accent-01-active;
    position: relative;
    .status {
      background: $accent-01-active;
      color: #fff !important;
    }
    &::after {
      content: "";
      position: absolute;
      border-color: transparent transparent $accent-01-active transparent;
      border-width: .6563rem;
      border-style: solid;
      top: 91%;
      z-index: 100;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  &.success .status {
    color: #28a745;
  }
}
.card-highlight {
  border-left-color: #e4e8ea;
  border-left-width: .25rem;
}
.card-highlight-top {
  border-top-color: #0078ce;
  border-top-width: .25rem;
}
.highlight-primary {
  border-left-color: #0078ce !important;
}
.highlight1 {
  border-left-color: #e0aaa2 !important;
}
.highlight2 {
  border-left-color: #e0bd75 !important;
}
.highlight3 {
  border-left-color: #96b2e0 !important;
}
.highlight4 {
  border-left-color: #88d5de !important;
}
.highlight5 {
  border-left-color: #80e0b4 !important;
}

// .border-primary{}
.card-c4 {
  box-shadow: none;
  border: .0625rem solid #e4e8ea;
  border-radius: .5rem;
}

.card-hide {
  // transform: translateX(120%);
  // opacity: 0;
  // height: 0;
  // position: relative;
  // // transition: tranform 1s ease-in-out;
  // transition: transform 400ms ease-in-out,
  //             opacity 400ms ease-in-out,
  //             height  900ms ease-in-out 400ms;
  display: none;

  &.show {
    // transform: translateX(0);
    // opacity: 1;
    // height: auto;
    display: block;
  }
}
.notification-new {
  .notification-icon {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: #0078ce;
      @include sq(.75rem);
      right: 0rem;
      top: 0;
      border-radius: 50%;
      // border: .125rem solid #fff;
    }
  }
}

.indicater {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    left: 0rem;
    top: 50%;
    transform: translateY(-50%);
    background-color: #0078ce;
    width: .25rem;
    height: 1.5rem;
  }
  &:not(.card, .card-body)::before {
    left: -0.25rem;
  }
}
.indicater {
  &.uniquebg1::before {
    background-color: #8ad7e0 !important;
  }
  &.uniquebg2::before {
    background-color: #e0bd75 !important;
  }
  &.uniquebg3::before {
    background-color: #e0aaa2 !important;
  }
  &.uniquebg4::before {
    background-color: #c0afdb !important;
  }
  &.uniquebg5::before {
    background-color: #96b2e0 !important;
  }
  &.uniquebg6::before {
    background-color: #8be0b0 !important;
  }
  &.uniquebg7::before {
    background-color: #d2a1e0 !important;
  }
  &.uniquebg8::before {
    background-color: #bce080 !important;
  }
}

.indicater1 {
  @extend .indicater;
  &::before {
    top: 1rem;
    transform: none;
    height: 1.438rem;
  }
}
.indicater2 {
  @extend .indicater;
  &::before {
    height: 2.5rem;
  }
}
.indicater3 {
  @extend .indicater;
  &::before {
    height: 2.813rem;
  }
}
.indicater4 {
  @extend .indicater;
  &::before {
    height: 100%;
    border-radius: 1.25rem;
  }
}
.indicater4 {
  @extend .indicater;
  &::before {
    height: 100%;
    border-radius: 1.25rem 0 0 1.25rem;
    width: .3125rem;
  }
}
.indicater5 {
  @extend .indicater;
  &::before {
    height: 100%;
    width: 2px;
  }
}
.indicater6 {
  @extend .indicater;
  padding-left: 10px !important;
  &::before {
    width: 6px;
    height: 33px;
    border-radius: 6px;
  }
}
.indicater-bg1::before {
  background-color: #0078ce !important;
}
.indicater-bg2::before {
  background-color: #c99b36 !important;
}
.indicater-bg3::before {
  background-color: #28a745 !important;
}
.indicater-bg4::before {
  background-color: #6562cf !important;
}
.indicater-bg5::before {
  background-color: #e0aaa2 !important;
}
.indicater-bg6::before {
  background-color: #e0bd75 !important;
}
.indicater-bg7::before {
  background-color: #80e0b4 !important;
}
.indicater-bg8::before {
  background-color: #96b2e0 !important;
}
.indicater-bg9::before {
  background-color: #88d5de !important;
}
.indicater-bg10::before {
  background-color: #31aab7 !important;
}
.indicater-warning::before {
  background-color: #ffc107 !important;
}
.indicater-success::before {
  background-color: #009722 !important;
}
.indicater-bg11::before {
  background-color: #566976 !important;
}
.indicater-bg12::before {
  background-color: #0066DD !important;
}
.card-c5 {
  // display: flex;
  flex-direction: row;
  overflow: hidden;
  border: .0625rem solid #e4e8ea;
  border-radius: .25rem;
  box-shadow: none;
  &>.card-body:first-child {
    width: 5.625rem;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    min-height: 5.625rem;
  }
  &>.card-body+.card-body {
    border-top: none;
  }
}
.card-holiday {
  box-shadow: none;
  background: #fcfcfc;
  border: .0625rem solid #dee3e7;
  border-radius: .25rem;
  padding: 1rem 1rem;
  flex-direction: row;
  .holiday-day {
    background: #fbefdd;
    border-radius: .25rem;
    @extend .flex-center;
    @include sq(3.25rem);
    margin-right: 1rem;
    flex-direction: column;
    line-height: 1.125rem;
    color: #000;
  }
  .card-date {
    @include sq(4.5rem);
  }
  .holiday-desc {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  &.active {
    border-color: #0066dd;
  }
}
.card-holiday1 {
  display: flex;
  padding: 1rem;
  border: .0625rem solid #e4e8ea;
  box-shadow: none;
  border-radius: .25rem;
  gap: .5rem;
  .holiday-type {
    display: flex;
    position: relative;
    padding-left: 1.75rem;
    i {
      @include sq(1.25rem);
      @extend .flex-center;
      color: #fff;
      position: absolute;
      left: 0rem;
      background: #0078ce;
      border-radius: 4.3125rem;
    }
  }
  .holiday-day {
    @extend .flex-center;
    width: 100%;
    height: 2rem;
    border-radius: .625rem;
  }
}

.national-holiday .holiday-day {
  background-color: var(--bg-national);
}
.floating-holiday .holiday-day {
  background-color: var(--bg-floating);
}
.regular-holiday .holiday-day {
  background-color: var(--bg-regular);
}
.card-holiday1.national-holiday {
  i {
    background-color: var(--c-national);
  }
  .holiday-day {
    background-color: var(--bg-national);
    color: var(--c-national);
  }
}
.card-holiday1.floating-holiday {
  i {
    background: var(--c-floating);
  }
  .holiday-day {
    background: var(--c-floating);
    color: var(--c-floating);
  }
}
.card-img {
  border-radius: .25rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
}
.card-hover:hover {
  .img-hover {
    color: #fff;
    @extend .flex-center;
    position: relative;
    &::before {
      border-radius: .25rem;
      position: absolute;
      inset: 0;
      content: "";
      background: #7d92a1;
      opacity: 0.75;
      z-index: 1;
    }
    .img-desc {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      font-size: .75rem;
      z-index: 2;
    }
  }
}
.img-hover {
  width: 100%;
  height: 8.875rem;
  overflow: hidden;
  border-radius: .5rem;
  position: relative;
}
.card-hover {
  .img-desc {
    display: none;
  }
}
.card-img {
  position: absolute;
  top: 0;
  z-index: 0;
}
.card-c6 {
  position: relative;
  &::after {
    position: absolute;
    content: "\e974"; //check-bold
    font-family: "icomoon";
    color: #fff;
    background-color: #7d92a1;
    border-radius: 1.3125rem;
    right: 1rem;
    top: 1rem;
    @extend .flex-center;
    @include sq($s: 1.3125rem);
    font-size: .625rem;
    padding-top: .0625rem;
  }
}
.horizontal-body {
  flex-direction: row;
  .card-body+.card-body {
    border-left: .0625rem solid #dee3e7;
    border-top: none;
  }
}
.card-c7 {
  box-shadow: none;
  background: #f8fafb;
  /* Stroke */
  border: .0625rem solid #e4e8ea;
  border-radius: .25rem;
  &.active {
    background-color: #eff4f6;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      background: #0078ce;
      border-radius: 0 .188rem .188rem 0;
      width: .188rem;
      height: .938rem;
      top: .5rem;
      left: .063rem;
    }
  }
}
.highlight-top {
  border-top: .3125rem solid #0078ce;
}
.card-c2 {
  &.success {
    position: relative;
    // border: .0625rem solid #0078CE;
    &::after {
      position: absolute;
      content: "\e974"; //check-bold
      font-family: "icomoon";
      color: #fff;
      background-color: #28a745;
      border-radius: 1.3125rem;
      right: 1rem;
      top: 1rem;
      @extend .flex-center;
      @include sq($s: 1.313rem);
      font-size: .625rem;
      padding-top: .0625rem;
    }
  }
}
.form-card {
  max-width: 28.125rem;
  width: 100%;
  box-shadow: 0rem .5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: .5rem;
  input {
    border-radius: .5rem;
    height: 2.875rem;
  }

  .org-logo {
    width: 12.5rem !important;
    max-height: 3.125rem;
    object-fit: contain;
  }
}
.card-cell {
  position: relative;
  // border-right: 1px solid #fff;
  // border-bottom: 1px solid #fff;
  height: 4.675rem;
  .card-body:first-child {
    border-bottom: 1px solid #96b2e0;

    @include media(">=figma") {
      border-bottom: .0625rem solid #96b2e0;
    }
  }
  .card-body:nth-child(2) {
    border-width: 0px;
  }
  &.shift-night {
    background-color: #96b2e066;
  }
  &.shift-general {
    background-color: #6dcdd899;
  }
  &.shift-weekoff::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    border-color: transparent #dc3545 #dc3545 transparent;
    border-width: .4063rem;
    border-style: solid;
    z-index: 10;
  }
  // max-width: 7.375rem;
  .card-body {
    border-color: #96b2e069 !important;
  }
}
.card-cell-2 {
  position: relative;
  height: 4.5rem;
  .time-event {
    white-space: normal !important;
    display: flex;
    flex-direction: column;
    gap: .5rem;
    .start-time,
    .end-time,
    .duration,
    .overtime {
      padding-left: 1.125rem;
      color: #566976;
      white-space: nowrap;
      display: block;
      font-size: .688rem;
      line-height: .625rem;
      position: relative;
      &::before {
        font-family: "icomoon" !important;
        position: absolute;
        color: #0078ce;
        font-size: .525rem;
      }
    }
    .overtime {
      color: #e59e00;
      &::before {
        content: "\e965"; //stopwatch-fill
        font-size: .625rem;
        // left: .188rem;
        color: #e59e00;
      }
    }
    .start-time::before {
      content: "\e929"; //arrow-right
      // font-size: 1rem;
      left: 0;
    }
    .end-time::before {
      content: "\e909"; //arrow-left
      // font-size: 1rem;
      left: 0;
    }
    .duration::before {
      content: "\e92d"; //clock1
      // font-size: .625rem;
      left: .25rem;
    }
  }
  //   position: relative;
}
.card-lb {
  .card-body {
    width: 4rem;
  }
}
.bg-welcome-card {
  background: url(/assets/images/bg/semicircle.svg) bottom left,
    url(/assets/images/bg/semicircle1.svg) bottom right,
    url(/assets/images/bg/star.svg) 26% 20%,
    url(/assets/images/bg/star.svg) 61% 90%,
    url(/assets/images/bg/star.svg) 99.5% -25%, #0078ce;
  background-repeat: no-repeat;
  background-size: contain, auto, 1.25rem, 1.625rem, 3.25rem;
}
.bg-glass {
  background: rgba(243, 245, 248, 0.1);
  border: 1px solid rgba(231, 231, 231, 0.4);
  backdrop-filter: blur(3px);
}
.card-template {
  box-shadow: none;
  --bs-card-border-color: #dee3e7;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  height: 17rem;
  position: relative;
  transition: all 300ms ease-in-out;
  overflow: hidden;
  // aspect-ratio: 1/1.2;
  img {
    object-fit: contain;
  }
  &.active {
    --bs-card-border-color: #{$accent-02-active};
  }
  &:hover {
    --bs-card-border-color: #{$accent-02-active};
  }
}
.card-disable {
  background-color: rgba($color: #fff, $alpha: .5);
  // pointer-events: none;
  color: #566976;
}
.card-c8 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #E4E8EA;
  border-radius: .25rem;
}
.divider-c1 {
  position: relative;
  font-size: .625rem;
  color: #0078CE;
  // display: inline-flex;
  // margin: auto;
  text-align: center;
  &>span {
    border: 1px solid #C3CBD1;
    border-radius: 4px;
    width: max-content;
    padding: 2px 4px;
    background-color: #fff;
      display: inline-flex;
      gap: 5px;
      align-items: center;
  }
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #E4E8EA;
    top: 50%;
    left: 0;
    z-index: -1;
  }
}
