// .section-layout {
//   display: flex;
//   height: calc(100vh - 3rem);
// }
// .section-main {
//   // background-color: aquamarine;
//   width: 100%;
//   overflow-y: scroll;
//   @include scrollbars(0.5rem, #eff4f6, #eff4f6);
//   &:hover {
//     @include scrollbars(0.5rem, rgba(50, 69, 82, 0.35), #f8fafb);
//   }
// }
// .section-sidebar {
//   background-color: #fff;
//   width: 24.5rem;
//   overflow-y: scroll;
//   flex-shrink: 0;
//   @include scrollbars(0.5rem, #eff4f6, #eff4f6);
//   &:hover {
//     @include scrollbars(0.5rem, rgba(50, 69, 82, 0.35), #f8fafb);
//   }
// }
.section-layout {
  display: flex;
  position: relative;
  // height: calc(100vh - 3rem);
}
.section-main {
  // background-color: aquamarine;
  width: calc(100% - var(--section-sideabar-width));
  // overflow-y: scroll;// scroll issue temp fix
  @include scrollbars(0.5rem, #eff4f6, #eff4f6);
  &:hover {
    @include scrollbars(0.5rem, rgba(50, 69, 82, 0.35), #f8fafb);
  }
}
.section-sidebar {
  background-color: #fff;
  width: var(--section-sideabar-width);
  overflow-y: scroll;
  flex-shrink: 0;
  height: calc(100vh - 3rem);
  position: sticky;
  top: 3rem;
  @include scrollbars(0.5rem, #eff4f6, #eff4f6);
  &:hover {
    @include scrollbars(0.5rem, rgba(50, 69, 82, 0.35), #f8fafb);
  }
}
