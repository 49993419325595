.badge {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem;
  background: #dde1e3;
  border-radius: 12.5rem;
  color: #324552;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}
.badge2 {
  @extend .badge;
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  display: flex;
  align-items: center;
  .avatar-circle {
    margin-right: 0.25rem;
  }
}
.badge-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.badge-float {
  position: absolute;
  background: #0078ce;
  @include sq(0.625rem);
  right: -0.125rem;
  top: -0.125rem;
  border-radius: 1.875rem;
}
.badge3 {
  @extend .badge;
  line-height: 1;
  text-overflow: ellipsis;
  white-space: break-spaces;
  text-align: start;
  border-radius: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
}
.badge-custom {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  min-height: 1.313rem;
  color: #ffffff;
  padding: 0rem 0.5rem;
  background-color: #e0aaa2;
  // text-transform: capitalize;
}
.badge4 {
  @extend .flex-center;
  height: 1.125rem;
  padding: 0 0.375rem;
  font-size: 0.625rem;
  font-weight: 500;
}
.badge-primary {
  color: #0078ce;
  background-color: rgba(0, 120, 206, 0.1);
}
.badge-success {
  color: #28a745;
  background-color: #dff0d8;
}
.badge-success1 {
  color: #fff;
  background-color: #28a745;
}
.badge-warning {
  color: #eab004;
  background-color: #f9f0c4;
}
.badge-danger {
  color: #dc3545;
  background-color: #f8e0e0;
}
.badge-danger1 {
  color: #fff;
  background-color: #dc3545;
}
.badge-secondary {
  color: #324552;
  background-color: #e4e8ea;
}
// .badge-secondary {
//   color: #566976;
//   background-color: #e4e8ea;
// }
.badge-weekoff {
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.badge-holiday {
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.badge-shift-not-assigned {
  color: var(--c-unknown);
  background-color: var(--bg-unknown);
}
.badge-employee {
  border-radius: 1rem;
  padding: 0.125rem 0.625rem;
  text-transform: uppercase;
  font-size: 0.625rem;
  &.badge-float-2 {
    position: absolute;
    top: 2.75rem;
    right: 0.5rem;
  }
}
.badge-c1 {
  color: #31aab7;
  background-color: #31aab71a;
}
.badge-c2 {
  color: #e29f24;
  background-color: #e29f241a;
}
.badge-c3 {
  color: #6562cf;
  background-color: #6562cf1a;
}
.badge-c4 {
  color: #129aa6;
  background-color: #129aa61a;
}

.status1 {
  position: relative;
  padding-left: 0.875rem;
  &::before {
    position: absolute;
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 0.125rem;
    content: "";
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.status-success::before {
    background-color: #28a745;
  }
  &.status-warning::before {
    background-color: #ffc107;
  }
  &.status-danger::before {
    background-color: #dc3545;
  }
  &.status-primary::before {
    background-color: #31aab7;
  }
  &.status-secondary::before {
    background-color: #dee3e7;
  }
  &.status-cancel::before {
    background-color: #7d92a1;
  }
}
.badge-hover {
  position: relative;
  padding: 0 0.25rem;
  transition: all ease-in-out 400ms;
  .icon-close {
    opacity: 0;
    pointer-events: none;
    position: absolute;
  }
  &:hover {
    background-color: #e4e8ea;
    padding-right: 0.875rem;
    line-height: 1.313rem;
    font-size: 0.875rem;
    border-radius: 0.625rem 0 0.625rem 0.625rem;
    .icon-close {
      background-color: #e4e8ea;
      border-radius: 0.625rem 0.625rem 0 0;
      z-index: 1;
      top: -0.625rem;
      right: 0;
      font-size: 0.75rem;
      padding: 0.125rem;
      opacity: 1;
      pointer-events: all;
    }
  }
}
.badge-c5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background-color: #eff4f6;
  height: 2rem;
  &:hover {
    background-color: #acbac3;
    cursor: pointer;
  }
}
.draggable {
  position: relative;
  padding-left: 24px;
  &::before {
    position: absolute;
    content: url("data:image/svg+xml,%3Csvg width='6' height='14' viewBox='0 0 6 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(4)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(0 6)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(4 6)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(0 12)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(4 12)' fill='%23324552'/%3E%3C/svg%3E%0A");
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
  }
}
.badge-circle {
  display: inline-block !important;
  background-color: var(--badge-bg);
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
}
.badge-yellow {
  --badge-bg: #ffc107;
}
.badge-orange {
  --badge-bg: #ff8d07;
}
.badge-red {
  --badge-bg: #dc3545;
}
.badge-green {
  --badge-bg: #009722;
}
.badge-improvement {
  color: var(--c-improvement) !important;
  background-color: var(--bg-improvement) !important;
}
.badge-fixation {
  color: var(--c-fixation) !important;
  background-color: var(--bg-fixation) !important;
}
.badge-feature {
  color: var(--c-feature) !important;
  background-color: var(--bg-feature) !important;
}
.badge-c6 {
  color: #622776 !important;
  background-color: #E8BCF7 !important;
}