// $light:(
//     "base":#FFFFFF,
//     "300":#F9F9F9,
//     "400":#F8FAFB,
//     "500":#7D92A1,
//     "600":#566976,
//     "700":#324552,
//     );
// $light:(
//     "": ,
//     "": ,
//     "": ,
//     "": ,
//     "": ,
// );
//Light Theme
$component-background-01-light: #ffffff;
$component-background-02-light: #f9f9f9;
$primary-background-light: #f8fafb;
$low-contrast-light: #7d92a1;
$medium-contrast-light: #566976;
$high-contrast-light: #324552;
//Dark Theme
$primary-background-dark: #2e3438;
$component-background-01-dark: #111b22;
$component-background-02-dark: #17222b;
$high-contrast-dark: #ffffff;
$medium-contrast-dark: #acbac3;
$low-contrast-dark: #566976;

//Normal

$accent-01-inactive: #6dcdd8cc;
$accent-01-active: #31aab7;
$accent-01-hover: #129aa6;
$accent-01-selected: #21717a;

$accent-02-inactive: #0078cecc;
$accent-02-active: #0078ce;
$accent-02-hover: #0069b4;
$accent-02-selected: #005a9b;

$text-danger: #dc3545;
$text-success: #28a745;
$text-warning: #ffc107;
$text-info: #0066dd;

$bg-danger: #f8e0e0;
$bg-success: #dff0d8;
$bg-warning: #fcf8e3;
$bg-primary: #b8d0ec;
$bg-1: rgba(109, 205, 216, 0.8);
$bg-2: #96b2e0;
$bg-3: #e0aaa2;
$bg-4: #e0bd75;
$bg-0: #80e0b4;

:root {
  --c-national: #0066dd;
  --bg-national: #e1ebf6;
  --c-floating: #009722;
  --bg-floating: #bef2ca;
  --c-regular: #7252a4;
  --bg-regular: #dfd0f6;
  --c-weekoff: #7f5539;
  --bg-weekoff: #f2c7ab;
  --c-leave: #d8a123;
  --bg-leave: #fff4ba;
  --c-restricted-holiday: #566976;
  --bg-restricted-holiday: #fafafa;
  --c-holiday: #013a63;
  --bg-holiday: #93c1e2;
  --c-absent: #dc3545;
  --bg-absent: #fdd3d3;
  --c-present: #28a745;
  --bg-present: #dff0d8;
  --c-unassigned: #324552;
  --bg-unassigned: #e4e8ea;
  // --c-unknown: #324552;
  --bg-unknown: transparent;
  --bg-regularized: #009722;
  --bg-deduction: #448fe5;
  --bg-override: #8AD7E0;
  --bg-punch: #ee9090;
  --bg-onduty: #bdae5e;
  --bg-late: #ffc107;
  --bg--WFH: #9207FF;
  --bg--currentday: #ecf2f5;
  --bg--overdue: #DC3545;
  --bg--on-behalf-request: #31AAB7;
  --bg--early-checkout:#7F5539;
  --c-improvement:#622776;
  --bg-improvement:#E8BCF7;
  --c-fixation:#F38300;
  --bg-fixation:#FCF8E3;
  --c-feature:#0066DD;
  --bg-feature:#DCEAFB;}

.text-dark-700 {
  color: $primary-background-dark;
}

.link-primary {
  cursor: pointer;
  color: $accent-01-active !important;

  &:hover {
    color: $accent-01-hover !important;
  }
}

.link-primary1 {
  cursor: pointer;
  color: $accent-02-active;

  &:hover {
    color: $accent-02-hover;
  }
}

.link-danger {
  cursor: pointer;
  color: $text-danger;

  &:hover {
    color: darken($text-danger, 10%);
  }
}

.text-light-400 {
  color: $low-contrast-light !important;
}

.text-light-500 {
  color: $medium-contrast-light !important;
}

.text-light-600 {
  color: $high-contrast-light !important;
}

.bg-darkgrey {
  background: #e4e8ea !important;
}

.text-success {
  color: #28a745 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-accent1 {
  color: $accent-01-active !important;
}

.text-accent2 {
  color: $accent-02-active !important;
}

.text-accent3 {
  color: #5654ab !important;
}

.text-accent4 {
  color: #566976 !important;
}

.text5 {
  color: #cb786c !important;
}

.text6 {
  color: #2b31ba !important;
}

.text7 {
  color: #6562cf !important;
}

.text8 {
  color: #c36f0b !important;
}

.text9 {
  color: #c7645d !important;
}

.text10 {
  color: #f1921b !important;
}

.text11 {
  color: #e91053 !important;
}

.text12 {
  color: #110580 !important;
}

.text13 {
  color: #e27575 !important;
}

.text14 {
  color: #625fd7 !important;
}

.text15 {
  color: #d18176 !important;
}

.text16 {
  color: #af64cc !important;
}

.text17 {
  color: #d1639a !important;
}

.text18 {
  color: #129aa6 !important;
}

.text19 {
  color: #6166db !important;
}

.text20 {
  color: #009722 !important;
}

.text21 {
  color: #ffb000 !important;
}

.text22 {
  color: #a8d257 !important;
}
.text23 {
  color: #622776 !important;
}
.text24 {
  color: #7D92A1 !important;
}
.text25 {
  color: #E29F24 !important;
}

.bg-accent1 {
  background-color: $accent-01-active !important;
}

.bg-accent2 {
  background-color: $accent-02-active !important;
}

.bg-dark-500 {
  background-color: #f8f8f8;
}

.bg-dark-400 {
  background-color: #f6f6f6;
}

.bg-dark-300 {
  background-color: #fcfcfc;
}

.bg-info {
  background-color: rgba(109, 205, 216, 0.8) !important;
}

.bg-warning {
  background-color: $bg-warning !important;
}

.bg-secondary {
  background-color: $primary-background-light !important;
}

.bg0 {
  background-color: $bg-0 !important;
}

.bg1 {
  background-color: $bg-1 !important;
}

.bg2 {
  background-color: $bg-2 !important;
}

.bg3 {
  background-color: $bg-3 !important;
}

.bg4 {
  background-color: $bg-4 !important;
}

.bg5 {
  background-color: #f8e0e0 !important;
}

.bg6 {
  background-color: #dff0d8 !important;
}

.bg7 {
  background-color: #fff2cf !important;
}

.bg8 {
  background-color: #dbecf8 !important;
}

.bg9 {
  background-color: #dcdbf2 !important;
}

.bg10 {
  background-color: #f8e0e0 !important;
}

.bg11 {
  background-color: #d9d5fe !important;
}

.bg12 {
  background-color: #eddde2 !important;
}

.bg13 {
  background-color: #dbebdf !important;
}

.bg14 {
  background-color: #e2f5f7 !important;
}

.bg15 {
  background-color: #d1e1ec !important;
}

.bg16 {
  background-color: #fdf9d3 !important;
}

.bg17 {
  background-color: #d0f5d6 !important;
}

.bg18 {
  background-color: #ffe4e7 !important;
}

.bg19 {
  background-color: #d1d3ed !important;
}

.bg20 {
  background-color: #ebd8ff !important;
}

.bg21 {
  background-color: #cbe4f6 !important;
}

.bg22 {
  background-color: #e07ea1 !important;
}

.bg23 {
  background-color: #bb80d7 !important;
}

.bg24 {
  background-color: #6dcdd833 !important;
}

.bg25 {
  background-color: #f6e3fc !important;
}

.bg26 {
  background-color: #f8e0ec !important;
}

.bg27 {
  background-color: #fa8687 !important;
}

.bg28 {
  background-color: #0078ce1a;
}

.bg29 {
  background-color: #eff4f6;
}

.bg30 {
  background-color: #6166db33;
}

.bg31 {
  background-color: #6dcdd880 !important;
}

.bg32 {
  background-color: #ecac7e !important;
}

.bg33 {
  background-color: #8880e0 !important;
}

.bg34 {
  background-color: #bce080 !important;
}

.bg35 {
  background-color: #0078ce !important;
}

.bg36 {
  background-color: #009722 !important;
}

.bg37 {
  background-color: #ffc107 !important;
}

.bg38 {
  background-color: #dc3545 !important;
}

.bg39 {
  background-color: #9207ff !important;
}

.bg40 {
  background-color: #ffc107cc !important;
}

.bg41 {
  background-color: #c3e1f5cc !important;
}

.bg42 {
  background-color: #dff0d8cc !important;
}

.bg43 {
  background-color: #e2e2efcc !important;
}

.bg44 {
  background-color: #cceba766 !important;
}

.bg45 {
  background-color: #fef7d1cc !important;
}

.bg46 {
  background-color: #d6456814 !important;
}

.bg47 {
  background-color: #cce4f5 !important;
}

.bg48 {
  background-color: #ebf7d5 !important;
}

.bg49 {
  background-color: #324552 !important;
}

.bg50 {
  background-color: #daedfb;
}

.bg51 {
  background-color: #31AAB733 !important;
}
.bg52 {
  background-color: #31AAB7 !important;
}

.bg53 {
  background-color: #999DA0 !important;
}

.bg54 {
  background-color: #00C2CE !important;
}

.bg55 {
  background-color: #CE00AD !important;
}

.bg56 {
  background-color: #0400CE !important;
}

.bg57 {
  background-color: #622776 !important;
}

.bg58 {
  background-color: #53A71F !important;
}

.bg59 {
  background-color: #10A833 !important;
}

.bg60 {
  background-color: #CB6619 !important;
}

body {
  color: $high-contrast-light;
}

// .bg-opacity-30 {
//   $opacity: 30%;
// }

$uniqueBg1: #8ad7e0;
$uniqueBg2: #e0bd75;
$uniqueBg3: #e0aaa2;
$uniqueBg4: #c0afdb;
$uniqueBg5: #96b2e0;
$uniqueBg6: #8be0b0;
$uniqueBg7: #d2a1e0;
$uniqueBg8: #bce080;

:not(.profile-card, .org-card, .indicater, .td-today) {
  $opacity: 100%;

  &.uniquebg1 {
    background-color: rgba($uniqueBg1, $opacity) !important;

    .card-body {
      border-color: $uniqueBg1 !important;
    }
  }

  &.uniquebg2 {
    background-color: rgba($uniqueBg2, $opacity) !important;

    .card-body {
      border-color: $uniqueBg2 !important;
    }
  }

  &.uniquebg3 {
    background-color: rgba($uniqueBg3, $opacity) !important;

    .card-body {
      border-color: $uniqueBg3 !important;
    }
  }

  &.uniquebg4 {
    background-color: rgba($uniqueBg4, $opacity) !important;

    .card-body {
      border-color: $uniqueBg4 !important;
    }
  }

  &.uniquebg5 {
    background-color: rgba($uniqueBg5, $opacity) !important;

    .card-body {
      border-color: $uniqueBg5 !important;
    }
  }

  &.uniquebg6 {
    background-color: rgba($uniqueBg6, $opacity) !important;

    .card-body {
      border-color: $uniqueBg6 !important;
    }
  }

  &.uniquebg7 {
    background-color: rgba($uniqueBg7, $opacity) !important;

    .card-body {
      border-color: $uniqueBg7 !important;
    }
  }

  &.uniquebg8 {
    background-color: rgba($uniqueBg8, $opacity) !important;

    .card-body {
      border-color: $uniqueBg8 !important;
    }
  }

  &.uniquebg9 {
    background-color: #8880e0 !important;
  }

  &.uniquebg10 {
    background-color: #31aab7 !important;
  }

  &.uniquebg11 {
    background-color: #ecac7e !important;
  }

  &.uniquebg12 {
    background-color: #e07ea1 !important;
  }

  &.uniquebg13 {
    background-color: #009722 !important;
  }

  &.uniquebg14 {
    background-color: #ffc107 !important;
  }

  &.uniquebg15 {
    background-color: #dc3545 !important;
  }

  &.uniquebg16 {
    background-color: #9207ff !important;
  }
}

$uniqueBg: (
  #8ad7e0,
  #e0bd75,
  #e0aaa2,
  #c0afdb,
  #96b2e0,
  #8be0b0,
  #d2a1e0,
  #bce080
);

// .bg-opacity-30:not(.td-today) {
//   $opacity: 30%;

//   @each $color in $uniqueBg {
//     $i: index($uniqueBg, $color);

//     &.uniquebg#{$i} {
//       background-color: rgba($color, $opacity) !important;
//     }
//   }
// }

$shiftColor: (
  #6dcdd8cc,
  #e0aaa2,
  #96b2e0,
  #80e0b4,
  #e0bd75,
  #d2a1e0,
  #bce080,
  #ecac7e,
  #e08080,
  #8880e0
);

@each $color in $shiftColor {
  $i: index($shiftColor, $color);

  .shift-bg#{$i} {
    background-color: #{$color} !important;
  }
}

// .icon-color-1 {
//   color: #4a1998;
//   background-color: #c0afdb;
// }
// .icon-color-2 {
//   color: #ff7e07;
//   background-color: #fbefdd;
// }
// .icon-color-3 {
//   color: #6562cf;
//   background-color: #6562cf4d;
// }
// .icon-color-4 {
//   color: #c08c3c;
//   background-color: #fbefdd;
// }
// .icon-color-5 {
//   color: #2b31ba;
//   background-color: #2b31ba4d;
// }
.cbg-accent1 {
  color: #31aab7 !important;
  background-color: rgba(49, 170, 183, 0.1) !important;
}

.cbg-accent2 {
  color: #0078ce !important;
  background-color: #dbecf8 !important;
}

.cbg-warning {
  color: #ffc107 !important;
  background-color: #f9edb8 !important;
}

.cbg-secondary {
  color: #2b31ba !important;
  background-color: #d1d3ed !important;
}

.cbg-success {
  color: #009722 !important;
  background-color: #dbf8e3 !important;
}

.cbg-danger {
  color: #ff162c !important;
  background-color: #ffdede !important;
}

.cbg-1 {
  color: #4a1998;
  background-color: #e0d9ea;
}

.cbg-2 {
  color: #ff7e07;
  background-color: #f7e1c0;
}

.cbg-3 {
  color: #6562cf;
  background-color: #6562cf4d;
}

.cbg-4 {
  color: #c08c3c;
  background-color: #fbefdd;
}

.cbg-5 {
  color: #2b31ba;
  background-color: #2b31ba4d;
}

.cbg-6 {
  color: #c7645d;
  background-color: #fbe1e0;
}

.cbg-7 {
  color: #622776 !important;
  background-color: #E8BCF766 !important;
}

.cbg-8 {
  color: #7d92a1 !important;
  background-color: #7d92a11a !important;
}

$uniquecbg: (
  #1d7f8a: #8ad7e0a6,
  #a17316: #e0bd75a6,
  #aa3b2a: #e0aaa2a6,
  #7344bd: #c0afdba6,
  #0078ce: #96b2e0a6,
  #139c4f: #8be0b0a6,
  #9b46b3: #d2a1e0a6,
  #5d8619: #bce080a6,
);
$i: 1;

@each $color, $bg in $uniquecbg {

  // $i: index($uniquecbg, $color);
  .unique-cbg#{$i} {
    background-color: #{$bg} !important;
    color: #{$color} !important;
  }

  $i: $i + 1;
}
.bgwhite {
  background-color: #ffffff !important;
}
.bg-grad1 {
  background: linear-gradient(0deg,
        #31AAB7 0%,
        #0FA0B0 100%);
}
