@-webkit-keyframes animate-rotate /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes animate-rotate {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animate-rotate {
  $delay: 1s;
  -webkit-animation: animate-rotate $delay linear infinite;
  -moz-animation: animate-rotate $delay linear infinite;
  -ms-animation: animate-rotate $delay linear infinite;
  -o-animation: animate-rotate $delay linear infinite;
  animation: animate-rotate $delay linear infinite;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
    //   opacity: 0;
  }

  100% {
    transform: translateX(0);
    //   opacity: 1;
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
    //   opacity: 0;
  }

  100% {
    transform: translateY(0);
    //   opacity: 1;
  }
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #31aab7;
  color: #31aab7;
  box-shadow: 9999px 0 0 -5px #31aab7;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #31aab7;
  color: #31aab7;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #31aab7;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #31aab7;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #31aab7;
  }
  30% {
    box-shadow: 9984px 0 0 2px #31aab7;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px #31aab7;
  }
}

@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #31aab7;
  }
  30% {
    box-shadow: 9999px 0 0 2px #31aab7;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px #31aab7;
  }
}

@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #31aab7;
  }
  30% {
    box-shadow: 10014px 0 0 2px #31aab7;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px #31aab7;
  }
}
.wave-loader {
  gap: 0.625rem;
  @extend .flex-center;
  .wave {
    width: 8px;
    height: 56px;
    // background: linear-gradient(45deg, cyan, #fff);
    // margin: .625rem;
    animation: wave 1s linear infinite;
    border-radius: 1.25rem;
  }
  .wave:nth-child(odd) {
    background-color: $accent-01-active;
  }
  .wave:nth-child(even) {
    background-color: $accent-02-active;
  }
  .wave:nth-child(2) {
    animation-delay: 0.1s;
  }
  .wave:nth-child(3) {
    animation-delay: 0.2s;
  }
  .wave:nth-child(4) {
    animation-delay: 0.3s;
  }
  .wave:nth-child(5) {
    animation-delay: 0.4s;
  }
  .wave:nth-child(6) {
    animation-delay: 0.5s;
  }
  // .wave:nth-child(7) {
  //   animation-delay: 0.6s;
  // }
  // .wave:nth-child(8) {
  //   animation-delay: 0.7s;
  // }
  // .wave:nth-child(9) {
  //   animation-delay: 0.8s;
  // }
  // .wave:nth-child(10) {
  //   animation-delay: 0.9s;
  // }
}

@keyframes wave {
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.5);
  }
}

lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  // top: 33px;
  width: 0.8125rem;
  height: 0.8125rem;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
