.accordion-reset {
  & > .card {
    box-shadow: none;
    // background-color: transparent;
    .card-header {
      --bs-card-cap-padding-y: 0;
      --bs-card-cap-padding-x: 0;
      --bs-card-border-color: #dee3e7;
      // --bs-card-cap-bg:transparent;
      button {
        width: 100%;
      }
    }

    .card-body {
      padding: 0;
    }
  }
}
.custom-accordion,
.custom-accordion1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .card {
    box-shadow: none;
    border: 0.0625rem solid #e4e8ea;
    border-radius: 0.25rem;
  }
  .card-header {
    padding: 0;
  }
  .accordion-button {
    &:focus {
      box-shadow: none;
    }
  }
}
.custom-accordion {
  .card-header {
    // padding: 0;
    border-bottom: none;
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .accordion-button {
    border-radius: 0.25rem 0.25rem 0 0;
    padding: 1rem;
    &.collapsed {
      border-radius: 0.25rem;
    }
    // &.collapsed:focus{box-shadow:none;}
    &:not(.collapsed):focus {
      box-shadow: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.125);
    }
    &::after {
      font-family: "icomoon" !important;
      content: "\e97c"; //chevron-down-lg1
      background-image: none;
      @extend .flex-center;
    }
    &:not(.collapsed) {
      background-color: #fff;
      color: #324552;
    }
  }
  .card-body {
    background: #f8fafb;
    padding: 1rem;
    border-radius: 0 0 0.25rem 0.25rem;
  }
}
.custom-accordion1 {
  & > .card {
    padding: 0 1.5rem;
  }
  .card-header {
    background-color: #fff;
    border: none;
  }
  .accordion-button {
    padding: 1rem 0;
    background-color: #fff;
    color: #324552;
    border-bottom: 0.0625rem solid #dee3e7;
    box-shadow: none;
    line-height: 1.313rem;
    &.collapsed {
      border: none;
    }
    &::before {
      flex-shrink: 0;
      width: 0.875rem;
      height: 0.875rem;
      // margin-left: auto;
      content: "";
      background-repeat: no-repeat;
      background-size: 0.875rem;
      transition: transform 0.2s ease-in-out;
      background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6852 12.9077L1.35189 12.9077C0.998266 12.9077 0.659127 12.7672 0.409079 12.5172C0.15903 12.2671 0.0185547 11.928 0.0185547 11.5744L0.0185547 2.24105C0.0185547 1.88743 0.15903 1.54829 0.409079 1.29824C0.659127 1.04819 0.998266 0.907715 1.35189 0.907715L10.6852 0.907715C11.0388 0.907715 11.378 1.04819 11.628 1.29824C11.8781 1.54829 12.0186 1.88743 12.0186 2.24105L12.0186 11.5744C12.0186 11.928 11.8781 12.2671 11.628 12.5172C11.378 12.7672 11.0388 12.9077 10.6852 12.9077ZM1.35189 2.24105L1.35189 11.5744L10.6852 11.5744L10.6852 2.24105L1.35189 2.24105ZM6.68522 10.241L5.35189 10.241L5.35189 7.57438L2.68522 7.57438L2.68522 6.24105L5.35189 6.24105L5.35189 3.57438L6.68522 3.57438L6.68522 6.24105L9.35189 6.24105L9.35189 7.57438L6.68522 7.57438L6.68522 10.241Z' fill='%2331AAB7'/%3E%3C/svg%3E%0A");
      margin-right: 0.5rem;
    }
    &:not(.collapsed)::before {
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.9005 1.52515L2.1288 1.52515C1.51563 1.52515 1.01855 2.02222 1.01855 2.63539L1.01855 10.4071C1.01855 11.0203 1.51563 11.5173 2.1288 11.5173L9.9005 11.5173C10.5137 11.5173 11.0107 11.0203 11.0107 10.4071L11.0107 2.63539C11.0107 2.02222 10.5137 1.52515 9.9005 1.52515Z' stroke='%2331AAB7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.79395 6.52124L8.23492 6.52124' stroke='%2331AAB7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
    &::after {
      content: none;
    }
  }
  .card-body {
    padding: 1rem 0;
  }
}
// .group-accordian{
// }
.custom-accordion1,
.custom-accordion {
  &.border-0 {
    & > .card {
      border: none !important;
    }
  }
}
.custom-accordion3 {
  & > .card {
    --bs-card-border-radius: 0.25rem;
    --bs-card-inner-border-radius: 0.25rem;
    box-shadow: none;
    border: none;
    .card-header {
      --bs-card-cap-padding-y: 0;
      --bs-card-cap-padding-x: 0;
      --bs-card-border-width: 0;
    }
  }
  .accordion-button {
    --bs-accordion-btn-focus-box-shadow: none;
    --bs-accordion-active-color: $high-contrast-light;
    --bs-accordion-active-bg: #fff;
    --bs-accordion-btn-focus-border-color: none;
    border-width: 0 !important;
    box-shadow: none !important;
    flex-direction: row;
  }
  .accordion-button::after {
    background-image: none !important;
    font-family: "icomoon" !important;
    content: "\e977"; //plus-square
    // border: 2px solid #31AAB7;
    // width: 24px;
    // height: 24px;
    font-size: 1.5rem;
    @extend.flex-center;
    color: #31aab7;
    // border-radius: 4px;
  }
  .accordion-button:not(.collapsed)::after {
    transform: none;
    content: "\e976"; //minus-square
    // border-bottom:1px solid
  }
}
.accordion-c1 {
  & > .card button {
    position: relative;
    padding-right: 3rem !important;
    &::after {
      position: absolute;
      content: "\F235";
      font-family: bootstrap-icons !important;
      right: 2.0625rem;
      top: 50%;
      transform: translateY(-50%);
      transition: all ease-in-out 200ms;
      color: #324552;
      font-size: 12px;
    }
    &.collapsed::after {
      transform: translateY(-50%) rotateZ(180deg);
    }
  }
}
.panel-white {
  & > .card .card-body {
    background-color: #fff;
  }
}
.panel-p-0 {
  & > .card .card-body {
    padding: 0;
  }
}
