@import "../0-base/variables";
.drop-down {
  position: relative;
  z-index: $zIndex-DropDown;
  &-menu {
    position: absolute;
    margin-top: 0.313rem;
    background-color: $component-background-01-light;
    box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
    padding: 0rem;
    border-radius: 0.25rem;
    min-width: 10rem;
    &-start {
      left: 0;
    }
    &-end {
      right: 0;
    }
  }
  &-item {
    @include flex($y: center);
    padding: 0.75rem;
    border-radius: 0.25rem;
    cursor: pointer;
    &:hover {
      background-color: $component-background-02-light;
    }
    .item-image {
      @include sq($s: 1.5rem);
      object-fit: cover;
      border-radius: 0.25rem;
    }
    div.item-image {
      background-color: $bg-primary;
      @include flex(center, center);
      font-size: 0.75rem;
    }
    .item-text {
      font-size: 0.875rem;
      color: $high-contrast-light;
    }
    .item-image + .item-text {
      padding-left: 0.5rem;
    }
  }
  &-menu {
    // display: none;
    visibility: hidden;
    opacity: 0;

    pointer-events: none;
    transform: translateY(15%);
    transition: transform 250ms ease-in, opacity 200ms ease-in;
  }
  &-menu.search-result {
    left: 0;
    right: 0;
    max-height: 13.5rem;
    overflow-y: hidden;
    &:hover {
      overflow-y: auto;
    }
  }
  .search-result.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0%);
    pointer-events: auto;
  }
}
.drop-down:hover .drop-down-toggle + .drop-down-menu:not(.search-result),
.drop-down-toggle:focus + .drop-down-menu:not(.search-result),
.drop-down-toggle:hover + .drop-down-menu:not(.search-result) {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  pointer-events: auto;
}
.dropdown-menu {
  padding: 0rem;
  border: none;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 0.25rem;

  .dropdown-item {
    padding: 0.75rem;
    border-radius: 0rem;
    color: #324552;
    display: flex;
    align-items: center;
    position: relative;
    &.active {
      padding-right: 2.125rem !important;
    }
    &:hover {
      background-color: #eff4f6;
    }
    &.active:not(:hover) {
      background: rgba(0, 120, 206, 0.1);
      color: $accent-02-active;
    }
    &.active::after {
      content: "\e974"; //check-bold
      font-family: "icomoon" !important;
      color: rgba(0, 120, 206, 1);

      font-style: normal;
      font-weight: normal;
      line-height: 1;
      position: absolute;
      right: 0.75rem;
      font-size: 0.75rem;
    }
  }
  .dropdown-item:first-child {
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .dropdown-item:last-child {
    border-radius: 0 0 0.25rem 0.25rem;
  }
  &.bottom-left {
    left: unset !important;
    right: 0 !important;
  }
}
// .dropdown-item.form-check{
//     padding-left: 1.875rem;
//     text-transform: capitalize;
// }inbox
.dropdown-menu-end {
  right: 0;
  left: unset !important;
}
.filter-dropdown {
  left: -20rem !important;
  position: absolute !important;
}
.dropdown-item .form-check-input {
  margin-left: 0rem;
}
.no-item-image .item-image {
  display: none !important;
}

.input-dropdown {
  position: relative;
  &.dropdown,
  &.dropup {
    &:not(.show) {
      .dropdown-menu {
        display: none;
      }
    }
  }
  &::after {
    content: "";
    position: absolute;
    right: 0.875rem;
    top: 55%;
    transform: translateY(-50%);
    border-color: #324552 transparent transparent;
    border-style: solid;
    border-width: 0.3125rem 0.3125rem 0.1563rem;
  }
  .dropdown-toggle {
    height: 2.5rem;
    padding-right: 1.75rem;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    color: #324552;
    &.is-invalid {
      border-color: #dc3545;
    }
    .btn-placeholder {
      color: $low-contrast-light;
    }
  }
  .btn-placeholder {
    color: $low-contrast-light;
  }
  .dropdown-menu {
    box-shadow: none;
    border: 0.0625rem solid #dae2e8;
  }
}
.input-dropdown,
.dropdown-toggle {
  &:focus-visible,
  &:focus {
    // border: none;
    // box-shadow: none;
    outline: none;
    border: 0.0625rem solid #0078ce;
  }
}
.four-dropdown {
  .ng-dropdown-panel-items {
    max-height: calc(2.1875rem * 4) !important;
  }
}
.dropdown-scroll {
  max-height: 300px;
  overflow: auto;
}
.input-dropdown .dropdown-toggle:disabled{
  background-color: transparent;
  border: 0.0625rem solid #dae2e8;
  color: #7d92a1;
}

.dropdown-item.disabled{
  background-color: #f5f5f5;
    color: #7d92a1;
}
