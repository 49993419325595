.custom-doughnut {
  // &  *{stroke-width: 24;}
  .advanced-pie-legend {
    display: flex;
    flex-direction: column-reverse;
    width: 100% !important;
    // top: unset!important;
    // transform: unset!important;
    padding-bottom: 2.5rem;
  }
  .advanced-pie.chart,
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .total-value,
  .total-label {
    font-size: 1rem !important;
    // color: black;
    position: absolute !important;
    // top: calc(100% + 1rem);
    margin: 0 !important;
    bottom: 0;
  }
  .total-label {
    position: relative;
    padding-left: 1rem;
    &::before {
      position: absolute;
      content: "";
      left: 0rem;
      width: 0.5rem !important;
      height: 1.5rem !important;
      background-color: #eee;
    }
  }
  .total-value {
    right: 0;
  }
  .legend-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: 6.875rem;
    overflow: auto;
  }
  .legend-item {
    display: flex !important;
    align-items: center;
    position: relative;
    margin: 0 !important;
    .item-percent {
      display: none;
    }
    .item-color {
      border-width: 0.5rem !important;
      height: 1.5rem !important;
      margin-right: 0.5rem !important;
    }
    .item-value,
    .item-label {
      font-size: 1rem !important;
      // color: black;
      opacity: 1 !important;
    }
    .item-value {
      position: absolute;
      right: 0;
    }
    .item-label {
      margin: 0 !important;
    }
  }
  .advanced-pie {
    height: auto !important;
    position: relative;
    .total-count {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 1.875rem;
      color: #0078ce;
    }
  }
  .advanced-pie.chart {
    .pie.chart {
      & stop {
        stop-opacity: 1 !important;
      }
    }
  }
}
.custom-doughnut-type2 {
  ngx-charts-advanced-pie-chart > div {
    display: flex;
  }
  .advanced-pie.chart {
    width: 60% !important;
    height: 100% !important;
    position: relative;
    .pie.chart {
      & stop {
        stop-opacity: 1 !important;
      }
    }
  }
  .advanced-pie-legend-wrapper {
    width: 40% !important;
    overflow-x: hidden;
    overflow-y: auto;
    .advanced-pie-legend {
      // top: 3.125rem;
      .total-label,
      .item-label {
        font-weight: 400;
        font-size: 0.75rem !important;
        line-height: 1.5rem;
        color: #324552;
      }
      .total-value,
      .item-value {
        font-weight: 500;
        font-size: 1rem !important;
        line-height: 1.5rem;
        color: #324552;
        margin-top: 0 !important;
      }
      .total-value {
        position: absolute;
        left: 1rem;
        top: 1.125rem;
      }
      .total-label {
        margin-bottom: 0;
        position: relative;
        top: 0;
        padding-left: 1rem;
        &::before {
          position: absolute;
          content: "";
          width: 0.25rem;
          height: 2.0625rem;
          background-color: #e4e8ea;
          left: 0;
          top: 0.5rem;
        }
      }
      .legend-items-container {
        padding-top: 1.625rem;
        .legend-items {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .legend-item {
            margin-right: 0;
            position: relative;
            display: flex;
            flex-direction: column-reverse;
            .item-percent {
              display: none;
            }
            .item-color {
              border-left-width: 0.25rem;
              width: 0.25rem;
              height: 2.0625rem;
              margin-right: 0;
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
            }
            .item-value {
              margin-left: 1rem;
            }
            .item-label {
              margin-left: 1rem;
              margin-top: 0;
              opacity: 1;
              line-height: 1;
            }
          }
        }
      }
    }
  }
  .dateDiv {
    position: absolute;
    font-weight: 500;
    text-align: center;
    display: flex;
    height: initial;
    bottom: 0;
    top: 0;
    align-items: center;
    left: 17px;
  }
}
.chart-doughnut1 {
  // .pie.chart{transform: translate(9%, 29%)!important; }
  .advanced-pie.chart,
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .advanced-pie.chart {
    width: 50% !important;
    .pie.chart {
      width: 100%;
      @include media(">=medium") {
        transform: scale(170%, 170%) translate(8.5%, 30%);
      }
      @include media(">tablet", "<=desktop") {
        transform: scale(250%, 250%) translate(8.5%, 20%);
      }
    }
  }
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .total-label,
  .legend-item .item-label {
    font-size: 0.875rem !important;
  }
}
.chart-doughnut2 {
  // .pie.chart{transform: translate(9%, 29%)!important; }
  .advanced-pie.chart,
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .advanced-pie.chart {
    width: 50% !important;
    .pie.chart {
      width: 100%;
      @include media(">=medium") {
        transform: scale(170%, 170%) translate(8.5%, 30%);
      }
      @include media(">tablet", "<=desktop") {
        transform: scale(230%, 230%) translate(9.5%, 22%);
      }
    }
  }
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .total-label,
  .legend-item .item-label {
    font-size: 0.875rem !important;
  }
}
.chart-doughnut3 {
  // .pie.chart{transform: translate(9%, 29%)!important; }
  .advanced-pie.chart {
    width: 50% !important;
  }
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .advanced-pie.chart {
    // width: 50%!important;
    .pie.chart {
      width: 100%;
      @include media(">=medium") {
        transform: scale(170%, 170%) translate(8.5%, 30%);
      }
      @include media(">tablet", "<=desktop") {
        transform: scale(200%, 200%) translate(9.5%, 25%);
      }
    }
  }
  .advanced-pie-legend-wrapper {
    width: 50% !important;
  }
  .total-label,
  .legend-item .item-label {
    font-size: 0.875rem !important;
  }
}
.custom-doughnut.hide-total {
  .advanced-pie-legend {
    padding-bottom: 0 !important;
    .total-value,
    .total-label {
      display: none;
    }
  }
}
.custom-linearea-chart {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .x.axis .tick text {
    font-size: 0.625rem !important;
    fill: #566976;
  }
  .y.axis .tick text {
    font-size: 0.75rem !important;
    fill: #566976;
  }
  &.no-x-axis .x.axis > g > g .gridline-path {
    stroke: transparent;
  }
  &.no-x-axis .tooltip-anchor {
    fill: #80808085;
  }
  .y.axis .gridline-path {
    stroke: #eaedef !important;
  }
}
.ngx-charts-tooltip-content {
  background-color: white !important;
  padding: 0.375rem 0.5rem !important;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  .tooltip-caret {
    &.position-right {
      border-right-color: white !important;
    }
    &.position-top {
      border-top-color: white !important;
    }
    &.position-bottom {
      border-bottom-color: white !important;
    }
    &.position-left {
      border-left-color: white !important;
    }
  }
  .tooltip-content {
    background-color: white;
    color: #324552;
  }
}
.chart-stamp {
  width: 0.75rem;
  margin-right: 0.5rem;
  background-color: #0078ce;
  aspect-ratio: 1;
  display: inline-block;
  border-radius: 0.25rem;
}
.line-chart-1 .line-chart.chart {
  transform: translate(20%, 5%);
}
.chart-line-reset {
  .chart-legend .legend-label-color {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.3125rem;
    border-radius: 0;
  }
  .chart-legend .legend-label-text {
    width: calc(100% - 0.8125rem);
  }
  .x.axis text,
  .y.axis text {
    color: #566976;
    font-size: 0.75rem !important;
  }
  .chart-legend .legend-labels {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
  .chart-legend .legend-label {
    margin: 0;
  }
  .line-chart.chart {
    transform: translateX(62px);
  }
}
ngx-charts-bar-vertical-stacked .gridline-path,
.ngx-charts .gridline-path {
  stroke: #eaedef !important;
}
/**********************************************/
//***********Chart Js Doughnut Chart***********
/**********************************************/
.chart-container {
  --h: 11.5rem;
  max-height: var(--h, 11.5rem);
  position: relative;
  .total-legend {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #0078ce;
    font-size: 1.125rem;
  }
}
.label-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .label-items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: auto;
    max-height: calc(var(--h, 11.5rem) - 2rem);
  }
  .label-item {
    display: flex;
    .item-color {
      background-color: #eee;
    }
    .item-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
.c1-label-wrap {
  width: 100%;
  .label-item {
    padding-right: 0.5rem;
    align-items: center;
    min-height: 1.5rem;
    .item-color {
      width: 0.5rem;
      height: 1.5rem;
      background-color: #eee;
      margin-right: 0.5rem;
    }
    .item-name {
      font-size: var(--fs, 0.75rem);
    }
    .item-value {
      font-size: var(--fs, 0.75rem);
      margin-left: auto;
      font-weight: 500;
    }
  }
}
.c2-label-wrap {
  .label-item {
    flex-direction: column;
    align-items: start;
    position: relative;
    padding-left: calc(0.1875rem + 1rem);
    .item-color {
      position: absolute;
      width: 0.1875rem;
      height: 2.0625rem;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .item-name {
      font-size: 0.75rem;
    }
    .item-value {
      font-size: 1.125rem;
      font-weight: 500;
    }
  }
}
