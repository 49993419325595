.avatar-box {
  @include flex(center, center);
  background-color: rgba(109, 205, 216, 0.8);
  border-radius: 0.5rem;
  @include sq(3rem);
  // margin-right: .5rem;
}
.overlap-avatar {
  $overlay-gap: 1.625;
  height: 2rem;
  position: relative;
  width: 100%;
  z-index: 0;
  .avatar-sm {
    position: absolute;
    // inset: 0;
    z-index: 1000;
  }
}
.overlap-left {
  $overlay-gap: 1.625;
  left: calc(#{$overlay-gap}rem * (-1));
  $children: (1, 2, 3, 4);
  .avatar-sm {
    @each $child in $children {
      &:nth-child(#{$child}) {
        left: calc(#{$overlay-gap}rem * #{$child});
      }
    }
  }
}
.overlap-right {
  $overlay-gap: 1.625;
  right: calc(#{$overlay-gap}rem * (-1));
  $children: (1, 2, 3, 4);
  .avatar-sm {
    @each $child in $children {
      &:nth-child(#{$child}) {
        right: calc(#{$overlay-gap}rem * #{$child});
      }
    }
  }
}
.overlap-avatar {
  .avatar-sm:hover {
    outline: 2px solid rgba(0, 120, 206, 1);
    z-index: 9999;
    transform: translateY(-4%);
    transition: transform ease-in-out 250ms;
    @include media(">=figma") {
      outline: 0.125rem solid rgba(0, 120, 206, 1);
    }
  }
}
.avatar-overlap {
  --size: 2rem;
  --count: 5;
  $counts: 10;
  --gap: 1.5rem;
  height: var(--size);
  width: calc((var(--gap) * var(--count)) + (var(--size) - var(--gap)));
  position: relative;
  .avatar {
    width: var(--size) !important;
    height: var(--size) !important;
    min-height: unset !important;
    aspect-ratio: 1;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    text-transform: uppercase;
    color: #324552;
    font-size: 0.75rem;
    border: 2px solid #ffffff;
    &:not(img) {
      background-color: #80c8fb;
    }
    position: absolute;
    &:hover {
      outline: 2px solid rgba(0, 120, 206, 1);
      z-index: 100;
      transform: translateY(-4%);
      transition: transform ease-in-out 250ms;
      @include media(">=figma") {
        outline: 0.125rem solid rgba(0, 120, 206, 1);
      }
    }
  }
  &.avatar-overlap-left .avatar {
    @for $i from 1 through $counts {
      &:nth-child(#{$i}) {
        left: calc(((#{$i} - 1)) * var(--gap));
      }
    }
  }
  &.avatar-overlap-right .avatar {
    @for $i from 1 through $counts {
      &:nth-child(#{$i}) {
        right: calc(((#{$i} - 1)) * var(--gap));
      }
    }
  }
}
.avatar-second {
  --color: #31aab7;
  width: 5rem;
  &::after {
    content: "2nd";
    bottom: 0.5rem;
  }
}
.avatar-first {
  --color: #ffc107;
  width: 5.5rem;
  &::after {
    content: "1st";
    bottom: 0.1875rem;
  }
}
.avatar-third {
  --color: #0078ce;
  width: 5rem;
  &::after {
    content: "3rd";
    bottom: 0.5rem;
  }
}
.avatar-second,
.avatar-first,
.avatar-third {
  position: relative;
  border-radius: 50%;
  background-color: #f8fafb;
  aspect-ratio: 1;
  @extend .flex-center;
  & > * {
    outline-width: 2px;
    outline-style: solid;
    border: 2px solid #fff;
    outline-color: var(--color);
  }
  &::after {
    position: absolute;
    font-weight: 600;
    font-size: 0.625rem;
    color: #fff;
    background-color: var(--color);
    padding: 0.0625rem 0.375rem;
    border-radius: 16px;
  }
}
.avatar-circle {
  object-fit: cover;
  @include flex(center, center);
  // background-color: #f8fafb;
  border-radius: 50%;
  text-transform: uppercase;
}
.avatar-border {
  border: 2px solid #ffffff;
  filter: drop-shadow(0rem 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
  @include media(">=figma") {
    border: 0.125rem solid #ffffff;
  }
}
.avatar-border2 {
  border: 1px solid #e4e8ea;
  @include media(">=figma") {
    border: 0.0625rem solid #e4e8ea;
  }
}
.avatar-border4 {
  border: 4px solid #ffffff;
  filter: drop-shadow(0rem 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
  @include media(">=figma") {
    border: 0.25rem solid #ffffff;
  }
}
.avatar-sm {
  color: #fff;
  color: #324552;
  font-size: 0.75rem;
  object-fit: cover;
  border: 2px solid #ffffff;
  @include flex(center, center);
  box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
  text-transform: uppercase;
  @include media(">=figma") {
    border: 0.125rem solid #ffffff;
  }
  // &.sq-24 {
  //   border-width: 0 !important;
  // }
}
ng-dropdown-panel .sq-24{
  border-width: 0 !important;
}
.avatar-sm:not(img) {
  background-color: $accent-02-active;
}
.avatar-success {
  position: relative;
}
.avatar-success::before {
  // content: url(/assets/icons/Dark/Large/ico-check.svg);
  content: "\e91f"; //check
  font-family: "icomoon";
  position: absolute;
  inset: 0;
  color: #fff;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  @include sq(inherit);
  background-color: #28a745;
  border-radius: 50%;
  // z-index: 1;
}
.avatar-1st {
  --color: #ffc107;
  &::after {
    content: "1st";
  }
}
.avatar-2nd {
  --color: #31aab7;
  &::after {
    content: "2nd";
  }
}
.avatar-3rd {
  --color: #0078ce;
  &::after {
    content: "3rd";
  }
}
.avatar-1st,
.avatar-2nd,
.avatar-3rd {
  // padding: 6px;
  width: 5rem;
  padding: 0.375rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8fafb;
  position: relative;
  border-radius: 50%;
  &::after {
    position: absolute;
    font-weight: 600;
    font-size: 0.625rem;
    color: #fff;
    background-color: var(--color);
    padding: 0.0625rem 0.375rem;
    border-radius: 16px;
    bottom: 0.5rem;
  }
  .avatar {
    outline: 2px solid var(--color);
    @include media(">=figma") {
      outline: 0.125rem solid var(--color);
    }
  }
}
.append-end {
  position: relative;
  &::after {
    content: "+3";
    color: #31aab7;
    z-index: 10;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
.avatar-map-marker {
  display: inline-block;
  border-radius: 50%;
  border: 3px solid white;
  //   overflow: hidden;
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 0px;
    height: 0px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid #fff;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
.map-marker-img {
  width: 1.625rem; /* Adjust the size as needed */
  height: 1.625rem; /* Adjust the size as needed */
  object-fit: cover;
  border-radius: 50%;
}
.avatar-marker {
  border-radius: 50%;
  border: 4px solid #fff;
  // box-shadow: 0rem 0.1rem 0.1rem 0rem #121b211a;
  position: relative;
  outline: 1px solid #00000045;
  &:after {
    width: 0;
    height: 0;
    content: "";
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 7px solid #fff;
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
  }
  &::before {
    width: 8px;
    height: 8px;
    content: "";
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: -10px;
    outline: 1px solid #00000045;
    background-color: #fff;
    z-index: -1;
    transform: rotate(45deg) translate(-5px, 1px);
    transform-origin: center;
  }
}
