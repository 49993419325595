@import "../0-base/variables";
.side-panel {
  $sidepanelHeadHeight: 3.625;
  $radius: 0.5;
  position: fixed;
  top: 0;
  // right: calc(var(--sidepanel-width)* -1);
  right: -1000vw;
  bottom: 0;
  z-index: $zIndex-SidePanel;
  @include flex;
  border-radius: $radius + rem 0rem 0rem $radius + rem;
  box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
  transition: right 900ms cubic-bezier(0.73, 0.96, 0, 1.01);
  margin-left: $sidebar-menuIcon-width + rem;

  &.side-pane-active {
    right: 0;
  }
  &-container {
    @include flex($dir: column);
    flex: 1;
    // max-width:var(--sidepanel-width);
    width: var(--sidepanel-width);
    background-color: #f8fafb;
    background-color: #eff4f6;
    border-radius: $radius + rem 0rem 0rem $radius + rem;
    z-index: 1002;
  }
  &-head,
  &-footer {
    background-color: $component-background-01-light;
    box-shadow: 0rem 0.0625rem 0.0625rem 0rem #6970751a;
  }
  &-head {
    padding: 1rem 2rem;
    height: $sidepanelHeadHeight + rem;
    // height: 3.625rem;
    border-top-left-radius: $radius + rem;
    border-bottom: 0.0625rem solid #eaedef;
    @include flex($y: center, $x: space-between);
    h5 {
      margin-bottom: 0;
      font-weight: 400;
      white-space: nowrap;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .toggle-panel {
      // font-size: 1.5625rem;
      color: $high-contrast-light;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &-body {
    height: calc(100vh - #{$sidepanelHeadHeight}rem);
  }
  &-footer {
    border-bottom-left-radius: $radius + rem;
    padding: 1rem 2rem;
    @include flex($y: center);
  }
}
@media only screen and (max-width: 768px) {
  .side-panel-container {
    max-width: 48rem;
    width: 100%;
  }
}
@media only screen and (max-width: 945px) {
  .side-panel-container {
    max-width: 59.0625rem;
    width: 100%;
  }
}
.notification-panel {
  max-height: 100vh;
}
.side-panel.side-pane-active::after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  pointer-events: auto;
  background-color: #000000aa;
  z-index: 1000;
}
// .side-panel.side-pane-active~form{
//     pointer-events: none;
// }
.change-theme-sidepanel {
  z-index: 9000;
}
.nesteded-config {
  width: 100% !important;
  .config-layout {
    margin-left: auto;
  }
  .config-container {
    top: 3rem;
    min-height: calc(100vh - 3rem);
  }
}
.main-sidebar.expand ~ .main-container .nesteded-config .config-header,
.main-sidebar.expand ~ .main-container .nesteded-config .config-layout {
  width: calc(100% - #{$sidebar-expanded-width}rem);
}
.main-sidebar:not(.expand) ~ .main-container .nesteded-config .config-header,
.main-sidebar:not(.expand) ~ .main-container .nesteded-config .config-layout {
  width: calc(100% - #{$sidebar-menuIcon-width}rem);
}
