//Layout Submenu
$sidebar-menuIcon-width: 3.75;
$sidebar-menuList-width: 11.25;
$sidebar-expanded-width: $sidebar-menuIcon-width + $sidebar-menuList-width;
$main-topbar-height: 3;
$main-footer-height: 2.1875;

//top menu bar in Organization structure
$menutab-height: 2.5;



//z-index
$zIndex-Notification: 9999;
$zIndex-popover: 9999;
$zIndex-modal: 7000;
$zIndex-Sidebar: 7000;
$zIndex-configHeader: 1500;
$zIndex-configFooter: 1500;
$zIndex-dateTimePicker: 7000;
$zIndex-NgDropDown: 5000;
$zIndex-ConfigLayout: 1000;
$zIndex-SidePanel: 1600;
$zIndex-TopBar: 1000;
$zIndex-MenuTab: 888;
$zIndex-DropDown: 100;