.table,
table {
  width: 100%;
  margin-bottom: 0;
  font-family: "Work Sans", sans-serif !important;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.02);
  thead th {
    padding: 1rem;
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: #324552;
    background-color: #fff;
    white-space: nowrap;
  }

  tbody td {
    padding: 0.5rem 1rem;
    vertical-align: middle;
  }

  &> :not(:last-child)> :last-child>* {
    border-color: #e4e8ea;
  }
}

.mat-sort th,
.mat-sort td {
  padding: 1rem 1rem 1rem 1.5rem !important;
}

.table> :not(:first-child) {
  border-top: none;
}

.form-table {
  .form-cell {
    padding-bottom: 0.625rem;
    padding-top: 0.625rem;
    text-align: left;
    vertical-align: middle;
  }

  .form-cell .form-select {
    border-color: transparent;
    background-color: transparent;
    padding-left: 0;
    padding-right: 1.563rem;
    background-position: right 0.188rem center;
    font-size: 1rem;
    min-width: 9.125rem;

    // color: #000;
    &:focus {
      border-color: $accent-01-active;
    }
  }

  .form-cell {
    label {
      width: 100%;
      min-width: 2.5rem;
      height: 2.5rem;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .form-check-input {
      background-size: 0.813rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .form-cell .form-control {
    background-color: transparent;
    // border-color: transparent;
    // padding-left: .25rem;
    // padding-right:0;
    min-width: 5rem;
    // &:focus {
    //     border-color: $accent-01-active;
    // }
  }

  input[type="checkbox"] {
    margin: 0;
    border-color: #acbac3;
  }
}

.cell-16 td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.table-sm {
  font-size: 0.8rem;

  th {
    font-weight: 600;
    padding: 1rem;
    white-space: nowrap;
  }

  td {
    font-weight: 400;
    padding: 0.75rem 1rem;
    color: #324552 !important;
  }

  //   tr th:first-child,
  //   tr td:first-child{
  //       padding-left: 1.5rem;
  //   }
  //   tr th:last-child,
  //   tr td:last-child{
  //       padding-right: 1.5rem;
  //   }
  tr:last-child td {
    border-bottom: none;
  }
}

[class*="highlight"] {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    width: 0.25rem;
    height: 1.5rem;
    left: 0;
  }
}

.highlight-success:before {
  background: #28a745;
}

.highlight-danger:before {
  background: #dc3545;
}

// .table-sm td{
//     white-space: nowrap;
// }
.vertical-align-top tbody td {
  vertical-align: top !important;
}

// .form-cell .form-control.is-invalid{
//     background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")!important;
// }
.form-cell .form-ngselect.is-invalid {
  .ng-select-container {
    // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e")!important;
    background-repeat: no-repeat;
    background-position: right 1.875rem center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .ng-value-label {
    padding-right: 1.5rem;
  }
}

.form-cell {

  & ng-select,
  & input:not([type="checkbox"]):not([type="radio"]),
  & app-master-select ng-select {
    min-width: 12.5rem !important;
    max-width: 15.625rem;
  }
}

.form-cell-sm {

  & ng-select,
  & input:not([type="checkbox"]):not([type="radio"]),
  & app-master-select ng-select {
    width: 9rem !important;
  }
}

.sticky-header thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky-header thead tr th,
.sticky-header thead {
  box-shadow: 0rem 0rem 0.125rem #e4e8ea;
  z-index: 11;
}

.sticky-first-col tr th:first-child,
.sticky-first-col tr td:first-child {
  position: sticky;
  left: 0;
  // box-shadow: 0rem 0rem 0.125rem #e4e8ea;
  z-index: 1;
  // background-color: #fff;
}

.sticky-last-col tr th:last-child,
.sticky-last-col tr td:last-child {
  position: sticky;
  right: 0;
  // box-shadow: 0rem 0rem 0.125rem #e4e8ea;
  z-index: 1;
  // background-color: #fff;
}

.td-white-space td {
  white-space: nowrap !important;
}

// .th-10 th {
//   padding: 0.625rem !important;
// }

// .th-12 th {
//   padding: 0.75rem !important;
// }

// .th-16 th {
//   padding: 1rem !important;
// }

// .td-0 td {
//   padding: 0rem !important;
// }

// .td-12 td {
//   padding: 0.75rem !important;
// }

// .td-10 td {
//   padding: 0.625rem !important;
// }

// .td-16 td {
//   padding: 1rem !important;
// }

// .td-fs-12 td {
//   line-height: 0.75rem;
//   font-size: 0.75rem;
// }

// .tr-fs-12 tr {
//   line-height: 0.75rem;
//   font-size: 0.75rem;
// }

// .th-fs-12 th {
//   line-height: 0.75rem;
//   font-size: 0.75rem;
// }

// .td-fs-14 td {
//   line-height: 0.875rem;
//   font-size: 0.875rem;
// }

// .tr-fs-14 tr {
//   line-height: 0.875rem;
//   font-size: 0.875rem;
// }

// .th-fs-14 th {
//   line-height: 0.875rem;
//   font-size: 0.875rem;
// }

.th-vertical-middele th {
  vertical-align: middle;
}

.table-responsive {
  scroll-behavior: smooth;
  @extend .scrollbar-10;
}

.cdk-drag-preview {
  background: #fafafa;
  border: 0.0625rem solid #e4e8ea;
  opacity: 1;
  border-radius: 0.25rem;
  min-height: 3.5rem;
  padding: 1rem !important;
  font-size: 0.875rem;
  vertical-align: middle;
  text-transform: uppercase;
  // line-height: 0rem;
  white-space: nowrap;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  @extend .flex-center;
  position: relative;

  .mat-sort-header-container {
    padding-left: 1.5rem;
  }

  &::before {
    position: absolute;
    content: "\e94a"; //grid-fill
    font-family: "icomoon";
    left: 1rem;
    color: #7d92a1;
    font-size: 0.625rem;
    transform: translateX(-50%);
  }
}

.cdk-drag-dragging {
  background-color: #000;
  width: 0.3125rem;
  height: 100%;
  border: 0.0625rem solid #000;
}

.card-c2 .table tr:last-child td {
  border-bottom: 0rem;
}

.example-custom-placeholder {
  //   background-color: #EAEDEF !important;
  border: dotted 0.1875rem #999 !important;
  min-height: 3.5rem;
  width: 100%;
  height: 100%;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: relative;
  //  &::before{
  //      position: absolute;
  //      content: '';
  //      width: .1875rem;
  //      top: 0;
  //      left: -0.1875rem;
  //      bottom: 0;
  //      background-color: #0078CE;
  //      height: 100%;
  //  }
}

.cdk-drop-list:not(.cdk-drop-list-dragging) {
  th.cdk-drag:hover {
    position: relative;

    // position: relative;
    &::before {
      position: absolute;
      content: "\e94a"; //grid-fill
      font-family: "icomoon";
      left: 10px;
      color: #E4E8EA;
      font-size: 10px;
      transform: translateX(-50%);
    }
  }
}

.table-navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
  width: calc(100% - 1.375rem);
  // height: 100%;
  display: flex;
  justify-content: space-between;

  .btn-left,
  .btn-right {
    i {
      pointer-events: none;
    }

    // height: 100%;
    width: 2.375rem;
    height: 4.125rem;
    background: #acbac3;
    opacity: 0.5;
    border-radius: 0.25rem;
    color: #566976;
    font-size: 0.875rem;
    @extend .btn-reset;

    &:hover {
      opacity: 1;
    }
  }

  // .btn-left{}
  .btn-right {
    transform: rotate(180deg);
  }
}

.add-column {
  position: absolute;
  top: 0.75rem;
  right: 0.438rem;
  z-index: 50;

  .dropdown-toggle {
    aspect-ratio: 1;
    z-index: 3;
    position: relative;
  }

  .dropdown-toggle:not(:hover) {
    background-color: #fff;
    color: #31aab7;
  }
}

.table-filter {
  max-height: 12.5rem;
  overflow-y: auto;
}

//   .td-danger{
//     padding: 0!important;
//     background-color: #F8E0E0!important;
//     & >  div{
//         background-color: #F8E0E0;
//         padding: .75rem 1rem;
//     }
//   }
.cell-error {
  background-color: #f8e0e0 !important;
  padding: 0.5rem;
  border-radius: 0.25rem;
  min-height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.td-dropdown {
  .dropdown {
    &:not(.show) {
      .dropdown-menu {
        display: none;
      }
    }
  }

  .dropdown-toggle {
    height: 2.5rem;
    // line-height: 2.125rem ;
    // padding-left:.625rem;
    padding-right: 1.75rem;
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: center;
    color: #324552;
  }
}

body>.dropdown {
  z-index: 2000 !important;
}

.table-sm {

  tr.mat-header-row,
  thead tr {
    height: 2.75rem;
    vertical-align: middle;
  }

  tr.mat-row,
  tbody tr {
    height: 2.5rem;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 0.875rem;
  }
}

.td-text-break td {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

td {

  .form-control,
  .form-select {
    font-size: 0.875rem;
  }
}

.table-striped {
  --bs-table-striped-color: #324552;
  --bs-table-striped-bg: #f3f3f3;
}

.table-striped>tbody>tr.td-alert>* {
  background-color: #f8e0e0 !important;
  --bs-table-accent-bg: #f8e0e0 !important;
  --bs-table-bg-type: #f8e0e0 !important;
}

.td-width-15ch td {
  max-width: 15ch;
  text-overflow: ellipsis;
  overflow: hidden;
}

.td-checkbox-16 {
  width: 3rem;
}

$dayStatus: (
  unknown: (text: " ",
    color: " ",
    bg_color: "--bg-unknown",
    combine: true,
  ),
  shift-unassigned: (text: "Shift not assigned",
    color: "--c-unassigned",
    bg_color: "--bg-unassigned",
    combine: false,
  ),
  leave: (text: "Leave",
    color: "--c-leave",
    bg_color: "--bg-leave",
    combine: true,
  ),
  present: (text: "Present",
    color: "--c-present",
    bg_color: "--bg-present",
    combine: true,
  ),
  absent: (text: "Absent",
    color: "--c-absent",
    bg_color: "--bg-absent",
    combine: true,
  ),
  weekoff: (text: "Weekoff",
    color: "--c-weekoff",
    bg_color: "--bg-weekoff",
    combine: true,
  ),
  holiday: (text: "Holiday",
    color: "--c-holiday",
    bg_color: "--bg-holiday",
    combine: true,
  ),
  onduty: (text: "",
    color: "",
    bg_color: "--bg-onduty",
    combine: false,
  ),
  wfh: (text: " ",
    color: " ",
    bg_color: "--bg--WFH",
    combine: false,
  ),
);

.cell-status {
  position: relative;

  &::before,
  &::after {
    position: absolute;
    width: calc(100% + 1px);
    // width: 100%;
    font-size: 0.625rem;
    height: 1.5rem;
    top: calc(100% - 1.5rem);
    left: 0;
    white-space: nowrap;
    display: block;
    text-align: center;
    padding: 0 0.3125rem;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5rem;
    z-index: 10;
  }
  &.fc-day-today {
    &::before,
    &::after {
      width: calc(100% - 2px) !important;
      left: 1px;
      top: calc(100% - 1.5rem - 1px);
    }
  }

  @mixin generate-status-style($status, $content, $text-color, $bg-color) {
    &.status-#{$status}::before {
      content: " #{$content}";
      color: var(#{$text-color});
      background-color: var(#{$bg-color});
    }
  }

  // Loop to generate individual status styles for all statuses
  @each $status, $values in $dayStatus {
    $content: map-get($values, text);
    $text-color: map-get($values, color);
    $bg-color: map-get($values, bg_color);
    @include generate-status-style($status, $content, $text-color, $bg-color);
  }

  // Loop to generate combination classes for statuses with 'combine: true' value
  @each $status1, $values1 in $dayStatus {
    $combine1: map-get($values1, combine);

    @if $combine1 {
      @each $status2, $values2 in $dayStatus {
        $combine2: map-get($values2, combine);

        @if $combine2 {

          // Skip combination if $status1 and $status2 are the same
          @if $status1 !=$status2 {
            &.status-#{$status1}-#{$status2} {
              $content1: map-get($values1, text);
              $text-color1: map-get($values1, color);
              $bg-color1: map-get($values1, bg_color);
              $content2: map-get($values2, text);
              $text-color2: map-get($values2, color);
              $bg-color2: map-get($values2, bg_color);

              &::before {
                width: 50% !important;
                left: 0rem;
                content: "#{$content1}";
                color: var(#{$text-color1});
                background-color: var(#{$bg-color1});
              }

              &::after {
                width: 50% !important;
                left: 50%;
                content: "#{$content2}";
                background-color: var(#{$bg-color2});
                color: var(#{$text-color2});
              }
              &.fc-day-today {
                &::before {
                  left: 1px;

                }
                &::after {
                  left: calc(50% - 1px);
                }
              }
            }
          }
        }
      }
    }
  }
}

.sticky-first-col {

  .cell-status::before,
  .cell-status::after {
    z-index: 0;
  }
}

// .fullcalendar-c3 .fc-day-today.cell-status {
//   &::before {
//     width: calc(100% - 1px);
//     left: 0px;
//   }
// }

// .cell-status {
//   position: relative;
//   &::after,
//   &::before {
//     position: absolute;
//     width: calc(100% + 1px);
//     font-size: 0.625rem;
//     @extend .flex-center;
//     height: 1.5rem;
//     top: calc(100% - 1.5rem);
//     left: 0;
//   }
//   &.status-shift-unassigned::after {
//     content: "Shift not assigned";
//     color: var(--c-unknown);
//     background-color: var(--bg-unknown);
//   }
//   &.status-leave-present,
//   &.status-leave-weekoff,
//   &.status-leave-absent,
//   //
//   &.status-present-leave,
//   &.status-present-weekoff,
//   &.status-present-absent,
//   //
//   &.status-absent-present,
//   &.status-absent-weekoff,
//   &.status-absent-leave,
//   //
//   &.status-weekoff-leave,
//   &.status-weekoff-present,
//   &.status-weekoff-absent {
//     &::before {
//       width: 50% !important;
//       left: 0rem;
//     }
//     &::after {
//       width: 50% !important;
//       left: 50%;
//     }
//   }
//   &.status-present::after,
//   &.status-leave-present::after,
//   &.status-absent-present::after,
//   &.status-weekoff-present::after,
//   //
//   &.status-present-leave::before,
//   &.status-present-absent::before,
//   &.status-present-weekoff::before {
//     content: "Present";
//     background-color: var(--bg-present);
//     color: var(--c-present);
//   }
//   &.status-absent::after,
//   &.status-present-absent::after,
//   &.status-leave-absent::after,
//   &.status-weekoff-absent::after,
//   //
//   &.status-absent-present::before,
//   &.status-absent-weekoff:before,
//   &.status-absent-leave:before {
//     content: "Absent";
//     color: var(--c-absent);
//     background-color: var(--bg-absent);
//   }
//   &.status-weekoff::after,
//   &.status-leave-weekoff::after,
//   &.status-present-weekoff::after,
//   &.status-absent-weekoff::after,
//   //
//   &.status-weekoff-leave::before,
//   &.status-weekoff-present::before,
//   &.status-weekoff-absent::before {
//     content: "Week off";
//     color: var(--c-weekoff);
//     background-color: var(--bg-weekoff);
//   }
//   &.status-leave::after,
//   &.status-present-leave::after,
//   &.status-absent-leave::after,
//   &.status-weekoff-leave::after,
//   //
//   &.status-leave-present::before,
//   &.status-leave-weekoff::before,
//   &.status-leave-absent::before {
//     content: "Leave";
//     color: var(--c-leave);
//     background-color: var(--bg-leave);
//   }
//   &.status-holiday::after {
//     content: "Holiday";
//     background-color: var(--bg-holiday);
//     color: var(--c-holiday);
//   }
//   &.status-restricted-holiday::after {
//     content: "Restricted Holiday";
//     background-color: var(--bg-restricted-holiday);
//     color: var(--c-restricted-holiday);
//   }
// }
.cell-status-2 {
  position: relative;

  .status-span {
    width: 80%;
    height: 55%;
    border-radius: 2rem;
    @extend .flex-center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
  }

  @mixin generate-status2-style($status, $bg-color) {
    &.status-#{$status} .status-span {
      background-color: var(#{$bg-color});
    }

    &.status-#{$status}+&.status-#{$status} {
      .status-span {
        &::before {
          content: "";
          width: calc(20% + 2.25rem);
          height: 100%;
          background-color: inherit;
          position: absolute;
        }

        &::before {
          right: calc(100% - 16px);
        }
      }
    }
  }

  // Loop to generate individual status styles for all statuses
  @each $status, $values in $dayStatus {
    $bg-color: map-get($values, bg_color);
    @include generate-status2-style($status, $bg-color);
  }

  // Loop to generate combination classes for statuses with 'combine: true' value
  @each $status1, $values1 in $dayStatus {
    $combine1: map-get($values1, combine);

    @if $combine1 {
      @each $status2, $values2 in $dayStatus {
        $combine2: map-get($values2, combine);

        @if $combine2 {

          // Skip combination if $status1 and $status2 are the same
          @if $status1 !=$status2 {
            &.status-#{$status1}-#{$status2} .status-span {
              $bg-color1: map-get($values1, bg_color);
              $bg-color2: map-get($values2, bg_color);
              background-image: linear-gradient(to right,
                var(#{$bg-color1}) 50%,
                var(#{$bg-color2}) 51%,
                var(#{$bg-color2}) 100%);
            }
          }
        }
      }
    }
  }
}

// .cell-status {
//   position: relative;
//   &::after,
//   &::before {
//     position: absolute;
//     width: calc(100% + 1px);
//     font-size: 0.625rem;
//     @extend .flex-center;
//     height: 1.5rem;
//     top: calc(100% - 1.5rem);
//     left: 0;
//   }
//   &.status-shift-unassigned::after {
//     content: "Shift not assigned";
//     color: var(--c-unknown);
//     background-color: var(--bg-unknown);
//   }
//   &.status-leave-present,
//   &.status-leave-weekoff,
//   &.status-leave-absent,
//   //
//   &.status-present-leave,
//   &.status-present-weekoff,
//   &.status-present-absent,
//   //
//   &.status-absent-present,
//   &.status-absent-weekoff,
//   &.status-absent-leave,
//   //
//   &.status-weekoff-leave,
//   &.status-weekoff-present,
//   &.status-weekoff-absent {
//     &::before {
//       width: 50% !important;
//       left: 0rem;
//     }
//     &::after {
//       width: 50% !important;
//       left: 50%;
//     }
//   }
//   &.status-present::after,
//   &.status-leave-present::after,
//   &.status-absent-present::after,
//   &.status-weekoff-present::after,
//   //
//   &.status-present-leave::before,
//   &.status-present-absent::before,
//   &.status-present-weekoff::before {
//     content: "Present";
//     background-color: var(--bg-present);
//     color: var(--c-present);
//   }
//   &.status-absent::after,
//   &.status-present-absent::after,
//   &.status-leave-absent::after,
//   &.status-weekoff-absent::after,
//   //
//   &.status-absent-present::before,
//   &.status-absent-weekoff:before,
//   &.status-absent-leave:before {
//     content: "Absent";
//     color: var(--c-absent);
//     background-color: var(--bg-absent);
//   }
//   &.status-weekoff::after,
//   &.status-leave-weekoff::after,
//   &.status-present-weekoff::after,
//   &.status-absent-weekoff::after,
//   //
//   &.status-weekoff-leave::before,
//   &.status-weekoff-present::before,
//   &.status-weekoff-absent::before {
//     content: "Week off";
//     color: var(--c-weekoff);
//     background-color: var(--bg-weekoff);
//   }
//   &.status-leave::after,
//   &.status-present-leave::after,
//   &.status-absent-leave::after,
//   &.status-weekoff-leave::after,
//   //
//   &.status-leave-present::before,
//   &.status-leave-weekoff::before,
//   &.status-leave-absent::before {
//     content: "Leave";
//     color: var(--c-leave);
//     background-color: var(--bg-leave);
//   }
//   &.status-holiday::after {
//     content: "Holiday";
//     background-color: var(--bg-holiday);
//     color: var(--c-holiday);
//   }
//   &.status-restricted-holiday::after {
//     content: "Restricted Holiday";
//     background-color: var(--bg-restricted-holiday);
//     color: var(--c-restricted-holiday);
//   }
// }
// .cell-status-2 {
//   position: relative;
//   .status-span {
//     width: 80%;
//     height: 55%;
//     border-radius: 2rem;
//     @extend .flex-center;
//     position: absolute;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     font-size: 0.75rem;
//   }
//   &.status-weekoff .status-span {
//     background-color: var(--bg-weekoff);
//   }
//   &.status-leave .status-span {
//     background-color: var(--bg-leave);
//   }
//   &.status-present .status-span {
//     background-color: var(--bg-present);
//   }
//   &.status-absent .status-span {
//     background-color: var(--bg-absent);
//   }
//   &.status-holiday .status-span {
//     background-color: var(--bg-holiday);
//   }
//   &.status-absent-present .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-absent) 50%,
//       var(--bg-present) 51%,
//       var(--bg-present) 100%
//     );
//   }
//   &.status-absent-weekoff .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-absent) 50%,
//       var(--bg-weekoff) 51%,
//       var(--bg-weekoff) 100%
//     );
//   }
//   &.status-absent-leave .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-absent) 50%,
//       var(--bg-leave) 51%,
//       var(--bg-leave) 100%
//     );
//   }
//   &.status-present-absent .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-present) 50%,
//       var(--bg-absent) 51%,
//       var(--bg-absent) 100%
//     );
//   }
//   &.status-present-weekoff .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-present) 50%,
//       var(--bg-weekoff) 51%,
//       var(--bg-weekoff) 100%
//     );
//   }
//   &.status-present-leave .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-present) 50%,
//       var(--bg-leave) 51%,
//       var(--bg-leave) 100%
//     );
//   }
//   &.status-leave-present .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-leave) 50%,
//       var(--bg-present) 51%,
//       var(--bg-present) 100%
//     );
//   }
//   &.status-leave-weekoff .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-leave) 50%,
//       var(--bg-weekoff) 51%,
//       var(--bg-weekoff) 100%
//     );
//   }
//   &.status-leave-absent .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-leave) 50%,
//       var(--bg-absent) 51%,
//       var(--bg-absent) 100%
//     );
//   }
//   &.status-weekoff-leave .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-weekoff) 50%,
//       var(--bg-leave) 51%,
//       var(--bg-leave) 100%
//     );
//   }
//   &.status-weekoff-present .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-weekoff) 50%,
//       var(--bg-present) 51%,
//       var(--bg-present) 100%
//     );
//   }
//   &.status-weekoff-absent .status-span {
//     background-image: linear-gradient(
//       to right,
//       var(--bg-weekoff) 50%,
//       var(--bg-absent) 51%,
//       var(--bg-absent) 100%
//     );
//   }
//   &.status-present + &.status-present,
//   &.status-weekoff + &.status-weekoff,
//   &.status-leave + &.status-leave,
//   &.status-absent + &.status-absent,
//   &.status-holiday + &.status-holiday {
//     .status-span {
//       &::before {
//         content: "";
//         width: calc(20% + 2.25rem);
//         height: 100%;
//         background-color: inherit;
//         position: absolute;
//       }
//       &::before {
//         right: calc(100% - 16px);
//       }
//     }
//   }
// }
.table-bordered {
  --bs-border-color: #dee3e78f;
}

td[class^="bg"] {
  box-shadow: none !important;
}

.current-day {
  background-color: var(--bg--currentday) !important;
  // outline: 1px solid #0078ce;
  // outline-offset: -1px;
  // box-shadow: 0 -1px 0 #0078ce !important;
}

// th.current-day {
// outline-width: 1px 1px 0 1px;
// box-shadow: 0 1px 0 #0078c inset,
//           1px 0 0 #0078c inset;
// }
th.current-day {
  position: relative;

  &::before {
    outline: 1px solid #0078ce;
    content: "";
    height: 100%;
    width: 4.875rem;
    outline-offset: -1px;
    position: absolute;
    // z-index: 999;
    top: 0;
    left: 0;
  }
}

// .table-striped,
// .table-striped.sticky-first-col,
// .table-striped.sticky-last-col {
//   color: #000000;
//   tr:nth-of-type(even) {
//     background-color: greenyellow !important;
//     // background-color: #f3f3f3 !important;
//     // background-color: #e4eaed!important;
//   }
//   tr:nth-of-type(odd) {
//     --bs-table-accent-bg: #fff;
//     background-color: #fff !important;
//     color: #000000;
//   }
// }
// .table-striped > tbody > tr:nth-of-type(2n + 1):not(.td-alert) {
//   --bs-table-accent-bg: #fff;
//   color: #000000;
// }
// .table-striped > tbody > tr:nth-of-type(2n + 1) > * {
//   --bs-table-accent-bg: #fff;
//   color: var(--bs-table-striped-color);
// }
.th-sort {
  cursor: pointer;

  .icon-th-sort::before {
    font-family: "icomoon" !important;
    content: #{'"' + \e9b7+ '"'};
  }

  .icon-th-sort:not(.th-sort-ascend, .th-sort-descend) {
    visibility: hidden;
  }

  &:hover .icon-th-sort {
    visibility: visible !important;
    opacity: 0.65;
    animation: bounce 0.6s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    display: inline-block;
  }

  .th-sort-ascend.icon-th-sort::before {
    content: '\e9b7';
  }

  .th-sort-descend.icon-th-sort::before {
    content: '\e908';
  }
}

@keyframes bounce {
  from {
    transform: translate3d(0, -2px, 0);
  }

  to {
    transform: translate3d(0, 2px, 0);
  }
}
.sticky-header.sticky-first-col thead tr th:first-child {
  z-index: 12;
}