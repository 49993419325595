.formula-editor{
    height: 5.625rem;
    display: flex;
    flex-direction: row;
    overflow-y: auto;
    flex-wrap: wrap;
    align-items: start;

    .edit-curser{
        color: $accent-01-active;
        font-size: 1.25rem;
        padding-left: .25rem; 
        // position: absolute;
        line-height: 1.25rem;
        animation: cursor-blink 1.5s steps(2) infinite;
    }
    .condition,.operator,.number,.tags,.component{
        padding-right:.25rem ;
        transition: all 400ms ease-in-out;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        .icon-close{
            opacity: 0;
            color:$medium-contrast-light ;
            position: absolute;
            right: 0rem;
            top: -0.688rem;
           
            border-radius: 1.25rem 1.25rem 0 0;        
        }
        &:hover{
            background-color: #f0f0f0eb;
            border-radius: .25rem;
            padding-left: 0.25rem;
            padding-right: 0.5rem;
            border-radius: 1.25rem 0 1.25rem 1.25rem;            
            cursor: pointer;
            font-size: 0.75rem;
            margin-top: 0.063rem;
            word-break: break-word;
            overflow: unset;
            white-space: unset;
            .icon-close{
                opacity: .8;
                padding: 0.125rem;
                background-color: #f0f0f0eb;
                // padding: .25rem;
            }
        }
    }
    .condition{color:#28A745;}
    .operator{color:$high-contrast-light;}
    .number{color: #0078CE;}
    .tags{color: #CE00C6;}
    .component{color: #CEA100;}
}

@keyframes cursor-blink {
    0% {
      opacity: 0;
    }
}
.btn-editor {
    background-color: #F8FAFB;
    border: .0625rem solid #E4E8EA;
    cursor: pointer;
    &:hover{
        background-color: #F0F0F0;
    }
    &:active {
        background-color: #ECECEC;
    }
}
.btn-operator{
    max-width: 3.438rem;
    width: calc(33.33% - 0.375rem);
    aspect-ratio: 1;
    @extend .flex-center;
    border-radius: .5rem;
}
.btn-condition{
    // height: 2.1875rem;
    border-radius: .25rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
}
.btn-tags{
    border-radius: .25rem;
    padding:0.5rem 1rem;
    font-size: 0.875rem;
    @extend .flex-center;

}
.operator-box{
    height: calc( 100vh - (3.625rem + 4.5rem + 1.5rem + 1.5rem + 1.5rem + 4.5rem + 4rem + 5.625rem + 3.5rem + 2.625rem ));
    // min-height:17.563rem;
    overflow-y: auto;
}
.formula-box{
  height:calc( 100vh - (3.625rem + 4.5rem + 1.5rem + 1.5rem + 1.5rem + 4.5rem + 4rem + 5.625rem + 3.5rem + 3rem + 2.188rem));
//   min-height: 14.9375rem;
  overflow-y: auto;  
}
.btn-validate{
    overflow: hidden;
    position: relative;
    border: none;
    &:after{
        content: 'VALIDATED';
        background: #DFF0D8;
        inset: 0;
        color: #28A745;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: .125rem;
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L14 1' stroke='%2328A745' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 1.125rem center;
        padding-left: 1.3125rem;
        background-size: 0.8125rem;
        transition: all 200ms ease-in;
        transition-delay: 400ms;
 
    }
}
// Animation 3
.btn-validate{
    &::before{
        position: absolute;
        content: '';
        background: #DFF0D8;
        border-radius: 3.125rem;
        transition: all 300ms ease-in;
        z-index: 2;
        // inset: 0;
        width: 0;
        height: 0;
        scale:0;
    }
    &:after{left: 100%;opacity: 0;z-index: 4}
}
.btn-validated{
    &::before{
        scale: 1;
        border-radius:.125rem ;
        width: 8.75rem;
        height: 8.75rem;
        
    }
    &::after{left:0;opacity: 1;}
}
