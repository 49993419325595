// @import '/src/styles/3-helpers/mixin';
.alert {
  @include flex($x: space-between, $y: center);
  padding: 1rem;
  position: relative;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  border: none;
  font-size: 0.875rem;
  color: #324552;
  display: none;
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: opacity 500ms ease-in, height 1000ms ease-in;
  & > p {
    font-size: 0.875rem;
  }
  &-info {
    background-color: #cde2ff;
  }
  &-success {
    background-color: #dff0d8;
  }
  &-warning {
    background-color: #fcf8e3;
  }
  &-danger {
    background-color: #f8e0e0;
  }
  &-premium{
    background-color: #FCF8E3;
    @extend .icon-crown;
    border:1px solid #E29F24;
    border-width: 1px 1px 1px 8px;
  }
  &-success:before,
  &-warning:before,
  &-info:before,
  &-danger:before,
  &-premium::before {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    line-height: 0;
    background-size: 1.25rem;
  }
  &-success:before {
    content: url(/assets/icons/svg/ico-tick-green.svg);
  }
  &-warning:before {
    content: url(/assets/icons/svg/ico-warning-warning.svg);
  }
  &-danger:before {
    content: url(/assets/icons/svg/ico-warning-danger.svg);
  }
  &-info:before {
    content: "\f431";
    font-family: bootstrap-icons !important;
    color: #3d84e5;
    font-size: 1.125rem;
    left: 1.25rem !important;
    font-weight: 500;
  }
  &-premium:before {
    font-family: "icomoon" !important;
    color: #E29F24;
    font-size: 22px;
      left: 1rem !important;
    font-weight: 500;
  }
  .btn-close {
    font-size: 0.55rem;
    box-shadow: none;
    position: absolute;
    top: 50%;
    right: 1.5rem;
    transform: translateY(-50%);
  }
  // & .btn-close{

  // }
  &.show {
    display: flex;
    opacity: 1;
    visibility: visible;
    height: auto;
  }
}
.alert-bg-white {
  padding-left: 1.75rem;
  background-color: white !important;
  &::before {
    left: 0 !important;
  }
}
.alert-icon-top {
  &::before {
    left: 0 !important;
  }
}
