@import "/src/styles/3-helpers/mixin";
@import "/src/styles/0-base/variables";
$mail-category: 14.25rem;
$mail-list: 26.125rem;
// $mail-view:14.25rem;
.mail-wrap {
  display: flex;
  width: 100%;
  & > * {
    // flex: 1;
    overflow-y: auto;
    height: calc(100vh - #{$main-topbar-height}rem);
    flex-shrink: 0;
  }
}
.mail-category {
  background-color: #fff;
  width: $mail-category;
  // box-shadow: inset(0rem .125rem .25rem rgba(105, 112, 117, 0.2));
  box-shadow: 0rem 0rem 0.25rem rgba(105, 112, 117, 0.2) inset;
  .title {
    font-size: 0.75rem;
    font-weight: 500;
    text-transform: uppercase;
    padding: 1.5rem 1.5rem 0.75rem 1.5rem;
    color: #7d92a1;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    a {
      position: relative;
      display: flex;
      padding: 0 1.5rem;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 0.75rem;
      height: 2.5rem;
      color: #566976;
      transition: 400ms ease-in;

      & :first-child {
        width: calc(100%- 5ch);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & :last-child {
        width: 4ch;
        text-align: right;
        white-space: nowrap;
        margin-left: 0.5rem;
      }
      &.active {
        background: #0078ce1a;
        color: #0078ce;
        font-weight: 600;
        &::before {
          content: "";
          position: absolute;
          left: 0rem;
          top: 50%;
          transform: translateY(-50%);
          border-right: 0.25rem solid #0078ce;
          height: 50%;
          border-top-right-radius: 0.3125rem;
          border-bottom-right-radius: 0.3125rem;
        }
      }
    }
  }
}
.mail-list {
  width: $mail-list;
  // overflow:hidden !important;
  padding-bottom: 2rem;
  // height: 80%;

  .mail-list--container {
    // height: calc(100vh - 2.5rem + 4.0625rem) ;
    overflow-y: auto;
    & > * {
      margin-top: 0.5rem;
    }
  }
  .mail--date {
    font-size: 0.75rem;
    font-weight: 400;
    color: $medium-contrast-light;
    text-transform: uppercase;
  }
}
.mail-card {
  background-color: #fff;
  border: 0.0625rem solid #eaedef;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  align-items: flex-start;
  &:hover {
    border-color: $accent-02-hover;
    cursor: pointer;
  }
  .mail-card-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
  }
  &.mail-active {
    border-color: $accent-02-active;
    &::before {
      content: "";
      position: absolute;
      left: 0rem;
      top: 50%;
      transform: translateY(-50%);
      border-right: 0.25rem solid $accent-02-active;
      height: 50%;
      border-top-right-radius: 0.3125rem;
      border-bottom-right-radius: 0.3125rem;
    }
  }
  &.mail-new:not(.mail-active) {
    &::before {
      $size: 0.4375rem;
      content: "";
      position: absolute;
      right: 0rem;
      top: 0;
      // border-right: $size solid #0078CE;
      // height: $size;
      // width: $size;
      border-width: $size;
      border-style: solid;
      border-color: $accent-01-active $accent-01-active transparent transparent;
      border-top-right-radius: 0.25rem;
    }
  }
}
.mail-view {
  background-color: #fff;
  width: 100%;
  flex: 1;
  box-shadow: 0rem 0rem 0.25rem rgba(105, 112, 117, 0.2) inset;
}
.bg-grad {
  background-image: linear-gradient(#f8fafb 80%, #f8fafba3);
}
.toggle-star::before {
  content: "\f588";
}
.btn-star:hover {
  .toggle-star::before {
    color: #ffb000;
  }
}
.mail-stared {
  .toggle-star::before {
    content: "\f586";
    color: #ffb000;
  }
}
.star-button {
  position: absolute;
  right: 0;
}
