.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  @include flex;
  z-index: $zIndex-Sidebar;
  &--menu-icon {
    @include flex($dir: column);
    flex: 1 1 0%;
    z-index: 1000;
    transition: background-color 400ms ease-in-out;
    .brand-logo {
      @include flex($x: center, $y: center);
      width: $sidebar-menuIcon-width + rem;
      min-height:calc(#{$sidebar-menuIcon-width}rem - 0.75rem);
      // padding: 1rem 0;
      // img {
      //   // width: $sidebar-menuIcon-width - 23 + rem;
      //   // width: calc(#{$sidebar-menuIcon-width}rem - 1.438rem) ;
      //   // width: 2rem;
      // }
    }
    .menu-icon {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 1000;
      height: calc(100vh - #{$sidebar-menuIcon-width}rem);
      display: flex;
      flex-direction: column;
      padding-top: .5rem;
      // li:last-child{margin-top: auto;}
      li {
        @include flex($x: center, $y: center);
        width: $sidebar-menuIcon-width + rem;

        position: relative;
        a.icon-link {
          @include flex($x: center, $y: center);

          // font-size: $sidebar-menuIcon-width / 3 + rem;
          // font-size: calc(#{$sidebar-menuIcon-width}rem - 1.5rem) ;
          border-radius: 0.5rem;
          transition: all .2s ease;
          -webkit-transition: all 0.2s ease;
          -moz-transition: all 0.2s ease;
          -o-transition: all 0.2s ease;
          -ms-transition: all 0.2s ease;
          // position: absolute;
          // left: 50%;
          // transform: translateX(-47%);
        }
        // &::before {
        //   content: "";
        //   height: 0;
        //   transition: height 400ms ease-in-out;
        // }
        // &.active::before {
        //   content: "";
        //   position: absolute;
        //   left: 0rem;
        //   top: 50%;
        //   transform: translateY(-50%);
        //   border: none;
        //   border-right: .25rem solid $accent-02-active;
        //   height: 1.563rem;
        //   border-top-right-radius: .3125rem;
        //   border-bottom-right-radius: .3125rem;
        // }
      }
      // .icon-popover {
      //   left: 95%;
      //   visibility: none;
      //   opacity: 0;
      //   // display: none;
      //   pointer-events: none;
      //   transform: translateY(-50%) translateX(15%);
      //   transition: transform 250ms ease-in, opacity 200ms ease-in;
      // }
      li:hover .icon-popover {
        visibility: visible;
        opacity: 1;
        // display: ;
        transform: translateY(-50%) translateX(0%);
      }
    }
    .user-profile {
      @include flex($x: center, $y: center);

      transition: background-color 400ms ease-in-out;
      margin-top: auto;
      z-index: 10;
      &>* {
        @include sq(1.938rem);
      }
      & img {
        object-fit: cover;
      }
      .no-image {
        @include flex($x: center, $y: center);
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 500;
      }
      li a {
        display: flex;
        position: relative;
        display: inline-block;
        width: 100%;
        font-size: 0.875rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: background-color 200ms ease-in-out;
      }
      li.hasSubmenu>a {
        padding-right: 1.875rem;
      }
      li.hasSubmenu li a {
        padding-left: 1.75rem;
        padding-right: 0.75rem;
        font-size: 0.75rem;
        font-weight: 500;
      }
      li.hasSubmenu>a::after {
        content: "❯";
        font-size: 0.813rem;
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%) rotate(0deg);
        transition: transform 200ms ease-in-out;
      }
      li.hasSubmenu.showMenu>a::after {
        transform: translateY(-50%) rotate(90deg);
      }
      .hasSubmenu li {
        display: flex;
      }
      //  .hasSubmenu li a{
      //     padding-top:0.75rem;
      //     padding-bottom:0.75rem;
      //  }
      li.hasSubmenu>ul {
        overflow: hidden;
        height: 0;
        transform: translateY(-50%) scaleY(0);
        transition: transform 300ms ease-in-out;
        //  transition: height 400ms ease-out;
      }
      li.hasSubmenu.showMenu>ul {
        transform: translateY(0%) scaleY(1);
        height: 100%;
      }
    }
    .menu-list:not(.user-detail)>li>a {
      padding-left: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
      padding-right: 1.563rem;
    }
    .sidebar--collapse-btn {
      position: absolute;
      @include sq(1rem);
      border-radius: 50%;
      left: $sidebar-menuIcon-width + rem;
      top: 3.313rem;
      transform: translateX(-50%);
      z-index: 9999;
      font-size: 0.5rem;
      @include flex(center, center);
      cursor: pointer;
      transition: all 400ms ease-in-out;
      i {
        position: absolute;
        top: 50%;
        left: 52%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &--menu-list {
    @include flex;
    z-index: 999;
    position: relative;
    // overflow: hidden;
    width: 0;
    transition: width 200ms ease-in-out, background-color 400ms ease-in-out;
    box-shadow: inset 0rem 0rem .25rem rgba(0, 0, 0, 0.15);

    ul {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0;
    }
  }
  .menu-list {
    padding-top: 1.25rem;
    padding-bottom: 4.063rem;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    position: absolute;
    opacity: 0;
    left: -100%;
    &.user-detail {
      opacity: 1;
    }
    &.active {
      left: 0;
      opacity: 1;
      transition: left 200ms ease-in-out, opacity 200ms 200ms;
    }
    // &:not(.user-detail) > :last-child {
    //   margin-bottom: 4.5rem;
    //   // opacity: 0;
    // }
    li {
      display: flex;
      flex-direction: column;
    }
    li a {
      display: flex;
      position: relative;
      display: inline-block;
      width: 100%;
      font-size: 0.875rem;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      // transition: background-color 200ms ease-in-out;
      transition: all 200ms ease-in;
    }
    li.hasSubmenu>a {
      padding-right: 1.875rem;
    }
    li.hasSubmenu li a {
      padding-left: 1.5rem;
      padding-right: 0.625rem;
      font-size: 0.875rem;
      font-weight: 500;
    }
    li.hasSubmenu>a::after {
      content: "❯";
      font-size: 0.625rem;
      position: absolute;
      right: 0.938rem;
      top: 50%;
      transform: translateY(-50%) rotate(0deg);
      transition: transform 200ms ease-in-out;
    }
    li.hasSubmenu.showMenu>a::after {
      transform: translateY(-50%) rotate(90deg);
    }
    .hasSubmenu li {
      display: flex;
    }
    // .hasSubmenu li a {
    //   padding-top: 0.75rem;
    //   padding-bottom: 0.75rem;
    // }
    li.hasSubmenu>ul {
      overflow: hidden;
      height: 0;
      transform: translateY(-50%) scaleY(0);
      transition: transform 300ms ease-in-out;
      //  transition: height 400ms ease-out;
    }
    li.hasSubmenu.showMenu>ul {
      transform: translateY(0%) scaleY(1);
      height: 100%;
    }
    .hasSubmenu li a.subChild.active,
    &>li a.active:not(.subParent) {
      &::before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.188rem;
        height: 0.938rem;
        border-radius: 0 1rem 1rem 0;
      }
    }
    // .hasSubmenu.active > li a{
    //   // color: var(--sidebar-list-active);
    //   background: linear-gradient(90deg, var(--sidebar-list-active) -300%, var(--sidebar-list-hover) 102.07%);
    // }
  }
  .menu-list:not(.user-detail)>li>a {
    padding: 0rem 1rem;
  }
  .menu-list:not(.user-detail)>li>a.subParent {
    padding-right: 1.688rem;
  }
  .menu-list.user-detail>li>a {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .menu-list.user-detail {
    position: relative;
    inset: 0;
    list-style-type: none;
    overflow: hidden;
    pointer-events: none;
    li {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4.063rem;
      @include flex(space-between, center);
      padding: 0.813rem 0.75rem;
      pointer-events: all;
      a {
        line-height: unset;
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0;
        &:hover {
          background: unset !important;
        }
      }
      i {
        // background-color: $low-contrast-light;
        @include flex(center, center);
        @include sq(2.5rem);
        border-radius: .5rem;
        cursor: pointer;
        font-size: 1.375rem;
      }
    }
    .user-name {
      font-size: 0.875;
    }
    .user-desg {
      font-size: 0.75rem;
    }
    .user-name,
    .user-desg {
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
      text-transform: capitalize;
      // width: 5.625rem;
    }
  }
}
// .menu-icon,
// .menu-list {
//   scrollbar-width: none;
// }
// .menu-icon:hover {
//   scrollbar-color: #262c38 #141a26;
//   scrollbar-width: thin;
// }
// .menu-list:hover {
//   scrollbar-color: #295d82 #1c2336;
//   scrollbar-width: thin;
// }
// .menu-icon::-webkit-scrollbar {
//   width: .3125rem;
//   height: .5rem;
//   background-color: #1c2336;
// }
// .menu-list::-webkit-scrollbar {
//   width: .3125rem;
//   height: .5rem;
//   background-color: #1c2336;
// }
// .menu-icon::-webkit-scrollbar-thumb {
//   background: #262c38;
// }
// .menu-list::-webkit-scrollbar-thumb {
//   background: #295d82;
// }
.expand .sidebar--collapse-btn {
  left: $sidebar-expanded-width + rem !important;
  transform: translateX(-50%) rotate(180deg) !important;
}
.hasSubmenu.showMenu {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    bottom: 0.5rem;
    width: calc(100% - 2rem);
    height: .0625rem;
    left: 50%;
    transform: translateX(-50%);
  }
  ul {
    padding-bottom: 1rem;
  }
}
// .subChild.active{
//   background: linear-gradient(90deg, rgba(0, 120, 206, 0.25) 0%, rgba(49, 170, 183, 0) 102.07%);
// }
// .menu-list:not(.user-detail) > :last-child{
//     margin-bottom: 4.5rem;
// }
//*******************************
// ======Container-sidebar======
//*******************************
// .sidebar-wrap {
//   --sidebar-width: 23.9375rem;
//   display: flex;
//   .container-sidebar {
//     width: var(--sidebar-width);
//     height: calc(100vh - 3rem);
//     background: #ffffff;
//     border: .0625rem solid #e4e8ea;
//     display: flex;
//     flex-direction: column;
//     overflow-y: auto;
//     overflow-x: hidden;
//     position: fixed;
//     right: 0;
//   }
//   & > .container-fluid {
//     width: calc(100% - var(--sidebar-width));
//     margin-left: 0;
//     margin-right: var(--sidebar-width);
//   }
// }
//==============================
// ============Sizing===========
//==============================
$IconOuterBoxGap :1.25;
.sidebar {
  &--menu-icon {
    width: $sidebar-menuIcon-width + rem;
    .brand-logo {
      img {
        width: 1.48rem;
      }
    }
    .menu-icon {
      li {
        min-height: calc(#{$sidebar-menuIcon-width}rem - 0.75rem);
        a.icon-link {
          // @include rect(
          //   $w: calc(#{$sidebar-menuIcon-width}rem - #{$IconOuterBoxGap}rem),
          //   $h: calc(#{$sidebar-menuIcon-width}rem - #{$IconOuterBoxGap}rem)
          // );
          @include sq(calc(#{$sidebar-menuIcon-width}rem - #{$IconOuterBoxGap}rem));
          font-size: 1.25rem;
        }
      }
    }
    .user-profile {
      // @include sq($sidebar-menuIcon-width + rem);
      min-height: 4.063rem;
      &>* {
        @include sq(2.375rem);
      }
    }
  }
  &--menu-list {
    &.active {
      width: $sidebar-menuList-width + rem;
    }
    .menu-list {
      li a {
        line-height: 2.188rem;
        height: 2.188rem;
      }
    }
  }
}


//==============================
// ============Theme============
//==============================
.sidebar {
  .sidebar--menu-icon {
    background-color: var(--sidebar-icon-bg-main);
    .menu-icon {
      @include scrollbars(.3125rem,
        var(--sidebar-icon-bg-hover),
        var(--sidebar-icon-bg-main));
      li {
        a.icon-link {
          color: var(--sidebar-icon-color);
        }
        &.active a.icon-link {
          color: var(--sidebar-icon-color-active);
          background-color: var(--sidebar-icon-bg-active);

        }
        a.icon-link:hover {
          color: var(--sidebar-icon-color-hover);
          background-color: var(--sidebar-icon-bg-hover);
          font-size: 1.5rem;
        }
      }
    }
    .user-profile {
      // background-color: var(--sidebar-icon-bg);
      &.active {
        background-color: #2e3840;
        &>* {
          border: .125rem solid var(--sidebar-list-bg-active);
          &:hover {
            border: .125rem solid var(--sidebar-icon-color);
          }
        }
      }
      &>*:hover {
        border: .125rem solid var(--sidebar-icon-color);
      }
      .no-image {
        color: #324552;
        background-color: #daedfb;
      }
      li a {
        color: $high-contrast-dark;
        &:hover {
          background-color: #1c2731;
        }
      }
      li.active:not(.hasSubmenu)>a {
        color: $accent-02-active;
      }
      li.hasSubmenu.active>a {
        color: $accent-02-active;
      }
      .hasSubmenu li a.active,
      .hasSubmenu.active>li a,
      &>li a.active {
        color: $accent-02-active;
      }
    }
    .sidebar--collapse-btn {
      color: #fff;
      background: #566976;
      &:hover {
        background-color: #31aab7;
      }
    }
  }
  .sidebar--menu-list {
    background-color: var(--sidebar-list-bg-main);
    .menu-list {
      @include scrollbars(.3125rem,
        var(--sidebar-list-color),
        var(--sidebar-list-bg-main));
      li a {
        color: var(--sidebar-list-color);
        &:hover {
          color: var(--sidebar-list-color-hover);
          background: linear-gradient(90deg,
              var(--sidebar-list-bg-hover) 0%,
              var(--sidebar-list-bg-main) 100%);
        }
      }
      li.active:not(.hasSubmenu)>a {
        color: $accent-02-active;
      }
      li.hasSubmenu.active>a {
        color: $accent-02-active;
      }
      .hasSubmenu li a.subChild.active,
      &>li a.active:not(.subParent) {
        color: var(--sidebar-list-color-active);
        // background: linear-gradient(
        //   90deg,
        //   var(--sidebar-list-bg-active) 0%,
        //   var(--sidebar-list-bg-main) 100%
        // );
        &::before {
          background-color: var(--sidebar-list-color-active);
        }
      }
      // .subChild.active{
      //   position: relative;
      //   &:before{
      //     content: '';
      //     position: absolute;
      //     left: 0;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     width:0.188rem;
      //     height:0.938rem ;
      //     border-radius: 0 1rem 1rem 0;
      //     background-color: var(--sidebar-list-color-active);
      //   }
      // }
    }
  }
  .menu-list.user-detail {
    li {
      background-color: #2e3840;
      i {
        color: $low-contrast-dark;
        &:hover {
          background-color: #141f28;
        }
      }
    }
    .user-name {
      color: #fff;
    }
    .user-desg {
      color: #acbac3;
    }
  }
  .hasSubmenu.showMenu {
    &:after {
      background-color: #566976;
    }
  }
}
.sidebar {
  &.skizzle-default {
    --sidebar-icon-bg-main: #111b22;
    --sidebar-icon-color: #C3CBD1;
    --sidebar-icon-color-hover: #ffffff;
    --sidebar-icon-color-active: #ffffff;
    --sidebar-icon-bg-hover: #353D43;
    --sidebar-icon-bg-active: #0078CE;
    --sidebar-list-bg-main: #17222b;
    --sidebar-list-color: #ACBAC3;
    --sidebar-list-color-hover: #ffffff;
    --sidebar-list-color-active: #0078ce;
    --sidebar-list-bg-hover: #17222b;
    --sidebar-list-bg-active: #152b3c;
    --sidebar-popover-color: #ffffff;
    --sidebar-popover-bg: #324552;
  }
  &.skizzle-bright-white {
    //****** Bright white ******
    --sidebar-icon-bg-main: #ffffff;
    --sidebar-icon-color: #324552;
    --sidebar-icon-color-hover: #7d92a1;
    --sidebar-icon-color-active: #0078ce;
    --sidebar-icon-bg-hover: #353d43;
    --sidebar-icon-bg-active: #f2f5f6;
    --sidebar-list-bg-main: #ffffff;
    --sidebar-list-color: #acbac3;
    --sidebar-list-color-hover: #bdcbd4;
    --sidebar-list-color-active: #0078ce;
    --sidebar-list-bg-hover: #f2f5f6;
    --sidebar-list-bg-active: #152b3c;
    --sidebar-popover-color: #ffffff;
    --sidebar-popover-bg: #324552;
  }
  &.skizzle-space-blue {
    // *******Space blue*******
    --sidebar-icon-bg-main: #033b4f;
    --sidebar-icon-color: #acbac3;
    --sidebar-icon-color-active: #ffffff;
    --sidebar-icon-bg-active: #012e3e;
    --sidebar-list-bg-main: #00455f;
    --sidebar-list-color: #acbac3;
    --sidebar-list-bg-active: #0078ce;
    --sidebar-list-bg-hover: #1e6079;
  }
  &.skizzle-barn-red {
    // *******Barn red*******
    --sidebar-icon-bg-main: #370e06;
    --sidebar-icon-color: #566976;
    --sidebar-icon-color-active: #ffffff;
    --sidebar-icon-bg-active: #4d291f;
    --sidebar-list-bg-main: #4e1c1b;
    --sidebar-list-color: #acbac3;
    --sidebar-list-bg-active: #0078ce;
    --sidebar-list-bg-hover: #622d2c;
  }
  &.skizzle-eminence {
    // *******Eminence*******
    --sidebar-icon-bg-main: #1a102a;
    --sidebar-icon-color: #566976;
    --sidebar-icon-color-active: #ffffff;
    --sidebar-icon-bg-active: #5e389a;
    --sidebar-list-bg-main: #3a2855;
    --sidebar-list-color: #acbac3;
    --sidebar-list-bg-active: #0078ce;
    --sidebar-list-bg-hover: #49316c;
  }
}
$circleWidth: 4rem;
.theme-box {
  // overflow: hidden;
  position: relative;
  width: $circleWidth;
  height: $circleWidth;
  outline: .125rem solid #e4e8ea;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in 400ms;
  .theme-primary,
  .theme-secondary {
    width: calc(#{$circleWidth}/ 2);
    height: $circleWidth;
  }
  .theme-primary {
    border-radius: $circleWidth 0 0 $circleWidth;
  }
  .theme-secondary {
    border-radius: 0 $circleWidth $circleWidth 0;
  }
  &.active {
    outline: .25rem solid rgba(54, 157, 232, 0.87);
    &:after {
      content: "\f633";
      font-family: bootstrap-icons !important;
      background-color: #0078ce;
      @include sq(1.625rem);
      // @extend .flex-center;
      // width: 1.625rem;
      // height: 1.625rem;
      border-radius: 50%;
      position: absolute;
      right: -0.3125rem;
      top: -0.3125rem;
      color: #fff;
      padding-top: .0625rem;
    }
  }
}
.theme-default {
  .theme-primary {
    background-color: #566976;
  }
  .theme-secondary {
    background-color: #111b22;
  }
}
.theme-bright-white {
  .theme-primary {
    background-color: #ffffff;
  }
  .theme-secondary {
    background-color: #f2f5f6;
  }
}
.theme-space-blue {
  .theme-primary {
    background-color: #00455f;
  }
  .theme-secondary {
    background-color: #012e3e;
  }
}
.theme-barn-red {
  .theme-primary {
    background-color: #4e1c1b;
  }
  .theme-secondary {
    background-color: #360e06;
  }
}
.theme-eminence {
  .theme-primary {
    background-color: #3a2855;
  }
  .theme-secondary {
    background-color: #1a102a;
  }
}