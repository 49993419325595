// @import '/src/styles/3-helpers/mixin';
.ngu-touch-container {
  width: calc(100% - 4rem);
  margin: 0 auto;
  overflow: hidden;
  .item {
    padding-bottom: 0.75rem;
  }
  .tile {
    box-shadow: none !important;
    height: 100%;
  }
}
.leftRs,
.rightRs {
  background: #eaedef;
  border-radius: 50%;
  @include sq(2rem);
  @extend .flex-center;
  border: none;
  box-shadow: none;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  img {
    width: 0.875rem;
  }
}
.rightRs {
  right: 0;
}
.custom-carousel {
  .ngu-touch-container {
    width: 100%;
    padding: 1rem 0;
    // overflow-y: auto !important;
  }
  // ngu-touch-container
  .ngucarousel-items {
    .item {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
    }
    // .item:first-child {
    //   // padding-right: 1rem;
    // }
    .item:last-child {
      padding-right: 0rem;
    }
  }
  .leftRs,
  .rightRs {
    transition: opacity 100ms ease-in;
    z-index: 1;
    border: 1px solid #e4e8ea;
    background-color: #ffffff;
    color: #324552;
    font-size: 0.8125rem;
    box-shadow: 0px 2px 4px rgba(105, 112, 117, 0.2);
    @include media(">=figma") {
      border: 0.0625rem solid #e4e8ea;
    }
  }
}
.carousel-c1 {
  position: relative;

  .carousel-items {
    display: flex;
    gap: 1rem;
    // padding-left: 2.5rem;
    // padding-right: 2.5rem;
    overflow: auto;
    & {
      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none; /* Firefox */
    }
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
  button {
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    font-size: 0.75rem;
    &:hover,
    &:active {
      background-color: #ffffff !important;
    }
  }
  .btnLeft {
    left: 0;
  }
  .btnRight {
    right: 0;
  }
}
