@charset "UTF-8";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~ngx-toastr/toastr.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */
:root {
  --c-national: #0066dd;
  --bg-national: #e1ebf6;
  --c-floating: #009722;
  --bg-floating: #bef2ca;
  --c-regular: #7252a4;
  --bg-regular: #dfd0f6;
  --c-weekoff: #7f5539;
  --bg-weekoff: #f2c7ab;
  --c-leave: #d8a123;
  --bg-leave: #fff4ba;
  --c-restricted-holiday: #566976;
  --bg-restricted-holiday: #fafafa;
  --c-holiday: #013a63;
  --bg-holiday: #93c1e2;
  --c-absent: #dc3545;
  --bg-absent: #fdd3d3;
  --c-present: #28a745;
  --bg-present: #dff0d8;
  --c-unassigned: #324552;
  --bg-unassigned: #e4e8ea;
  --bg-unknown: transparent;
  --bg-regularized: #009722;
  --bg-deduction: #448fe5;
  --bg-override: #8AD7E0;
  --bg-punch: #ee9090;
  --bg-onduty: #bdae5e;
  --bg-late: #ffc107;
  --bg--WFH: #9207FF;
  --bg--currentday: #ecf2f5;
  --bg--overdue: #DC3545;
  --bg--on-behalf-request: #31AAB7;
  --bg--early-checkout:#7F5539;
  --c-improvement:#622776;
  --bg-improvement:#E8BCF7;
  --c-fixation:#F38300;
  --bg-fixation:#FCF8E3;
  --c-feature:#0066DD;
  --bg-feature:#DCEAFB;
}

.text-dark-700 {
  color: #2e3438;
}

.link-primary {
  cursor: pointer;
  color: #31aab7 !important;
}
.link-primary:hover {
  color: #129aa6 !important;
}

.link-primary1 {
  cursor: pointer;
  color: #0078ce;
}
.link-primary1:hover {
  color: #0069b4;
}

.link-danger {
  cursor: pointer;
  color: #dc3545;
}
.link-danger:hover {
  color: #bd2130;
}

.text-light-400 {
  color: #7d92a1 !important;
}

.text-light-500 {
  color: #566976 !important;
}

.text-light-600 {
  color: #324552 !important;
}

.bg-darkgrey {
  background: #e4e8ea !important;
}

.text-success {
  color: #28a745 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-accent1 {
  color: #31aab7 !important;
}

.text-accent2 {
  color: #0078ce !important;
}

.text-accent3 {
  color: #5654ab !important;
}

.text-accent4 {
  color: #566976 !important;
}

.text5 {
  color: #cb786c !important;
}

.text6 {
  color: #2b31ba !important;
}

.text7 {
  color: #6562cf !important;
}

.text8 {
  color: #c36f0b !important;
}

.text9 {
  color: #c7645d !important;
}

.text10 {
  color: #f1921b !important;
}

.text11 {
  color: #e91053 !important;
}

.text12 {
  color: #110580 !important;
}

.text13 {
  color: #e27575 !important;
}

.text14 {
  color: #625fd7 !important;
}

.text15 {
  color: #d18176 !important;
}

.text16 {
  color: #af64cc !important;
}

.text17 {
  color: #d1639a !important;
}

.text18 {
  color: #129aa6 !important;
}

.text19 {
  color: #6166db !important;
}

.text20 {
  color: #009722 !important;
}

.text21 {
  color: #ffb000 !important;
}

.text22 {
  color: #a8d257 !important;
}

.text23 {
  color: #622776 !important;
}

.text24 {
  color: #7D92A1 !important;
}

.text25 {
  color: #E29F24 !important;
}

.bg-accent1 {
  background-color: #31aab7 !important;
}

.bg-accent2 {
  background-color: #0078ce !important;
}

.bg-dark-500 {
  background-color: #f8f8f8;
}

.bg-dark-400 {
  background-color: #f6f6f6;
}

.bg-dark-300 {
  background-color: #fcfcfc;
}

.bg-info {
  background-color: rgba(109, 205, 216, 0.8) !important;
}

.bg-warning {
  background-color: #fcf8e3 !important;
}

.bg-secondary {
  background-color: #f8fafb !important;
}

.bg0 {
  background-color: #80e0b4 !important;
}

.bg1 {
  background-color: rgba(109, 205, 216, 0.8) !important;
}

.bg2 {
  background-color: #96b2e0 !important;
}

.bg3 {
  background-color: #e0aaa2 !important;
}

.bg4 {
  background-color: #e0bd75 !important;
}

.bg5 {
  background-color: #f8e0e0 !important;
}

.bg6 {
  background-color: #dff0d8 !important;
}

.bg7 {
  background-color: #fff2cf !important;
}

.bg8 {
  background-color: #dbecf8 !important;
}

.bg9 {
  background-color: #dcdbf2 !important;
}

.bg10 {
  background-color: #f8e0e0 !important;
}

.bg11 {
  background-color: #d9d5fe !important;
}

.bg12 {
  background-color: #eddde2 !important;
}

.bg13 {
  background-color: #dbebdf !important;
}

.bg14 {
  background-color: #e2f5f7 !important;
}

.bg15 {
  background-color: #d1e1ec !important;
}

.bg16 {
  background-color: #fdf9d3 !important;
}

.bg17 {
  background-color: #d0f5d6 !important;
}

.bg18 {
  background-color: #ffe4e7 !important;
}

.bg19 {
  background-color: #d1d3ed !important;
}

.bg20 {
  background-color: #ebd8ff !important;
}

.bg21 {
  background-color: #cbe4f6 !important;
}

.bg22 {
  background-color: #e07ea1 !important;
}

.bg23 {
  background-color: #bb80d7 !important;
}

.bg24 {
  background-color: #6dcdd833 !important;
}

.bg25 {
  background-color: #f6e3fc !important;
}

.bg26 {
  background-color: #f8e0ec !important;
}

.bg27 {
  background-color: #fa8687 !important;
}

.bg28 {
  background-color: #0078ce1a;
}

.bg29 {
  background-color: #eff4f6;
}

.bg30 {
  background-color: #6166db33;
}

.bg31 {
  background-color: #6dcdd880 !important;
}

.bg32 {
  background-color: #ecac7e !important;
}

.bg33 {
  background-color: #8880e0 !important;
}

.bg34 {
  background-color: #bce080 !important;
}

.bg35 {
  background-color: #0078ce !important;
}

.bg36 {
  background-color: #009722 !important;
}

.bg37 {
  background-color: #ffc107 !important;
}

.bg38 {
  background-color: #dc3545 !important;
}

.bg39 {
  background-color: #9207ff !important;
}

.bg40 {
  background-color: #ffc107cc !important;
}

.bg41 {
  background-color: #c3e1f5cc !important;
}

.bg42 {
  background-color: #dff0d8cc !important;
}

.bg43 {
  background-color: #e2e2efcc !important;
}

.bg44 {
  background-color: #cceba766 !important;
}

.bg45 {
  background-color: #fef7d1cc !important;
}

.bg46 {
  background-color: #d6456814 !important;
}

.bg47 {
  background-color: #cce4f5 !important;
}

.bg48 {
  background-color: #ebf7d5 !important;
}

.bg49 {
  background-color: #324552 !important;
}

.bg50 {
  background-color: #daedfb;
}

.bg51 {
  background-color: #31AAB733 !important;
}

.bg52 {
  background-color: #31AAB7 !important;
}

.bg53 {
  background-color: #999DA0 !important;
}

.bg54 {
  background-color: #00C2CE !important;
}

.bg55 {
  background-color: #CE00AD !important;
}

.bg56 {
  background-color: #0400CE !important;
}

.bg57 {
  background-color: #622776 !important;
}

.bg58 {
  background-color: #53A71F !important;
}

.bg59 {
  background-color: #10A833 !important;
}

.bg60 {
  background-color: #CB6619 !important;
}

body {
  color: #324552;
}

:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg1 {
  background-color: #8ad7e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg1 .card-body {
  border-color: #8ad7e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg2 {
  background-color: #e0bd75 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg2 .card-body {
  border-color: #e0bd75 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg3 {
  background-color: #e0aaa2 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg3 .card-body {
  border-color: #e0aaa2 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg4 {
  background-color: #c0afdb !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg4 .card-body {
  border-color: #c0afdb !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg5 {
  background-color: #96b2e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg5 .card-body {
  border-color: #96b2e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg6 {
  background-color: #8be0b0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg6 .card-body {
  border-color: #8be0b0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg7 {
  background-color: #d2a1e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg7 .card-body {
  border-color: #d2a1e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg8 {
  background-color: #bce080 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg8 .card-body {
  border-color: #bce080 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg9 {
  background-color: #8880e0 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg10 {
  background-color: #31aab7 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg11 {
  background-color: #ecac7e !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg12 {
  background-color: #e07ea1 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg13 {
  background-color: #009722 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg14 {
  background-color: #ffc107 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg15 {
  background-color: #dc3545 !important;
}
:not(.profile-card, .org-card, .indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1, .td-today).uniquebg16 {
  background-color: #9207ff !important;
}

.shift-bg1 {
  background-color: #6dcdd8cc !important;
}

.shift-bg2 {
  background-color: #e0aaa2 !important;
}

.shift-bg3 {
  background-color: #96b2e0 !important;
}

.shift-bg4 {
  background-color: #80e0b4 !important;
}

.shift-bg5 {
  background-color: #e0bd75 !important;
}

.shift-bg6 {
  background-color: #d2a1e0 !important;
}

.shift-bg7 {
  background-color: #bce080 !important;
}

.shift-bg8 {
  background-color: #ecac7e !important;
}

.shift-bg9 {
  background-color: #e08080 !important;
}

.shift-bg10 {
  background-color: #8880e0 !important;
}

.cbg-accent1 {
  color: #31aab7 !important;
  background-color: rgba(49, 170, 183, 0.1) !important;
}

.cbg-accent2 {
  color: #0078ce !important;
  background-color: #dbecf8 !important;
}

.cbg-warning {
  color: #ffc107 !important;
  background-color: #f9edb8 !important;
}

.cbg-secondary {
  color: #2b31ba !important;
  background-color: #d1d3ed !important;
}

.cbg-success {
  color: #009722 !important;
  background-color: #dbf8e3 !important;
}

.cbg-danger {
  color: #ff162c !important;
  background-color: #ffdede !important;
}

.cbg-1 {
  color: #4a1998;
  background-color: #e0d9ea;
}

.cbg-2 {
  color: #ff7e07;
  background-color: #f7e1c0;
}

.cbg-3 {
  color: #6562cf;
  background-color: #6562cf4d;
}

.cbg-4 {
  color: #c08c3c;
  background-color: #fbefdd;
}

.cbg-5 {
  color: #2b31ba;
  background-color: #2b31ba4d;
}

.cbg-6 {
  color: #c7645d;
  background-color: #fbe1e0;
}

.cbg-7 {
  color: #622776 !important;
  background-color: #E8BCF766 !important;
}

.cbg-8 {
  color: #7d92a1 !important;
  background-color: #7d92a11a !important;
}

.unique-cbg1 {
  background-color: #8ad7e0a6 !important;
  color: #1d7f8a !important;
}

.unique-cbg2 {
  background-color: #e0bd75a6 !important;
  color: #a17316 !important;
}

.unique-cbg3 {
  background-color: #e0aaa2a6 !important;
  color: #aa3b2a !important;
}

.unique-cbg4 {
  background-color: #c0afdba6 !important;
  color: #7344bd !important;
}

.unique-cbg5 {
  background-color: #96b2e0a6 !important;
  color: #0078ce !important;
}

.unique-cbg6 {
  background-color: #8be0b0a6 !important;
  color: #139c4f !important;
}

.unique-cbg7 {
  background-color: #d2a1e0a6 !important;
  color: #9b46b3 !important;
}

.unique-cbg8 {
  background-color: #bce080a6 !important;
  color: #5d8619 !important;
}

.bgwhite {
  background-color: #ffffff !important;
}

.bg-grad1 {
  background: linear-gradient(0deg, #31AAB7 0%, #0FA0B0 100%);
}

.sq {
  --ratio:1.875rem;
  width: var(--ratio) !important;
  height: var(--ratio) !important;
  aspect-ratio: 1;
  flex-shrink: 0 !important;
  min-height: unset !important;
}

.sq {
  --ratio:1.875rem;
  width: var(--ratio) !important;
  height: var(--ratio) !important;
  aspect-ratio: 1;
  flex-shrink: 0 !important;
  min-height: unset !important;
}

hr {
  height: 0.125rem !important;
  margin: 0;
  border-width: 0;
  color: #eaedef;
  background-color: #eaedef;
}

.flex-center, .timeline-c2 li:before, .ol-type-1 li::before, .avatar-second,
.avatar-first,
.avatar-third, .custom-chart .chart-upperlevel, .btn-tags, .btn-operator, .custom-accordion3 .accordion-button::after, .custom-accordion .accordion-button::after, .leftRs,
.rightRs, .cell-status-2 .status-span, .cdk-drag-preview, .fullcalendar-c2 .fc-daygrid-day-number, .fullcalendar-fulloption .first-cell-div .tag-cell, .fullcalendar-reset .fc-col-header-cell .fc-scrollgrid-sync-inner, .fullcalendar-reset .fc-header-toolbar .fc-prev-button,
.fullcalendar-reset .fc-header-toolbar .fc-next-button, .calendar-control .calendar-button, .holiday-calender .national-holiday .mat-calendar-body-cell-content:after,
.holiday-calender .floating-holiday .mat-calendar-body-cell-content:after, .holiday-calender .mat-calendar-body-selected:before, .badge4, .nav-c2 li a::before, .wave-loader, .card-c2.success::after, .card-c6::after, .card-hover:hover .img-hover, .card-holiday1 .holiday-day, .card-holiday1 .holiday-type i, .card-holiday .holiday-day, .card-c3 .status, .card-c3 .date, .form-check-input:checked + .input-uniquebg::after, .slider-input-container .slider-btn-left,
.slider-input-container .slider-btn-right, .check-btn-grp .form-check-btn, .radio-group .radio-check:checked + .radio-content::after, .custom-checklist li.success::before, .empty-container, .mat-paginator .mat-focus-indicator,
.mat-paginator .mat-custom-page {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
}

.avatar {
  object-fit: contain;
  text-transform: uppercase;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
}

.bt {
  border-top: 0.0625rem solid #eaedef !important;
}

.bb {
  border-bottom: 0.0625rem solid #eaedef !important;
}

.h-40 {
  height: 2.5rem;
}

.h-36.form-control {
  height: 2.25rem !important;
}
.h-36.form-ngselect .ng-select-container {
  height: 2.25rem !important;
}

.border_rad_4 {
  border-radius: 0.25rem;
}

.outline_none {
  outline: none;
}

.border_none {
  border: none;
}

.h-fullscreen {
  height: 100vh !important;
}

.cropContainer {
  border-radius: 1.5rem !important;
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a !important;
}

.track-i {
  background: #0066dd !important;
  height: 0.5rem !important;
  border-radius: 0.25rem 0rem 0rem 0.25rem !important;
}

.y-bg-j {
  background: #dae2e8 !important;
  height: 0.5rem !important;
  box-shadow: inset 0rem 0.125rem 0.125rem -0.0625rem rgba(74, 74, 104, 0.1) !important;
  border-radius: 0.5rem !important;
}

.thumb-m {
  background: #ffffff !important;
  border: 0.0625rem solid #dae2e8 !important;
  width: 1.5rem !important;
  height: 1.5rem !important;
  top: -0.6875rem !important;
  box-shadow: none !important;
  border-radius: 2.75rem !important;
}
.thumb-m:hover {
  box-shadow: 0rem 0rem 0rem 0.375rem rgba(105, 112, 117, 0.1) !important;
}
.thumb-m:hover:before {
  box-shadow: 0rem 0rem 0rem 0.375rem rgba(105, 112, 117, 0.1) !important;
}
.thumb-m:focus:before {
  box-shadow: 0rem 0rem 0rem 0.375rem rgba(105, 112, 117, 0.1) !important;
}

.crop-container {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
  overflow: hidden;
  min-height: 15rem;
}

.crop-container h4 {
  padding: 1.5rem;
  margin-bottom: 0;
}

.y-bg-j {
  opacity: 1 !important;
}

.text-trim, .tips, .form-ngselect .ng-select-container .ng-value .ng-value-label,
app-master-select .ng-select-container .ng-value .ng-value-label,
.form-ngselect1 .ng-select-container .ng-value .ng-value-label {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.w-15ch {
  max-width: 15ch !important;
}

.w-17ch {
  max-width: 17ch !important;
}

.w-18ch {
  max-width: 18ch !important;
}

.statistic {
  text-transform: uppercase;
  display: inline-block;
  font-size: 0.6563rem;
  font-weight: 500;
  margin-right: auto;
  display: flex;
  align-items: center;
}
.statistic span {
  display: flex;
  align-items: center;
}
.statistic span::before {
  content: "";
  width: 0.25rem;
  height: 1rem;
  border-radius: 0.25rem;
  margin-right: 0.25rem;
  background: #eaedef;
  position: relative;
  display: inline-block;
}
.statistic.bg-primary, .statistic.bg-success, .statistic.bg-accent1, .statistic.bg-warning, .statistic.bg-info, .statistic.bg-danger, .statistic.bg-47, .statistic.bg-48, .statistic.bg-accent1-hover {
  background-color: transparent !important;
}
.statistic.bg-primary {
  --color: #0078ce;
}
.statistic.bg-primary span {
  color: var(--color);
}
.statistic.bg-primary span:before {
  background: var(--color);
}
.statistic.bg-success {
  --color: #28a745;
}
.statistic.bg-success span {
  color: var(--color);
}
.statistic.bg-success span:before {
  background: var(--color);
}
.statistic.bg-accent1 {
  --color: #31aab7;
}
.statistic.bg-accent1 span {
  color: var(--color);
}
.statistic.bg-accent1 span:before {
  background: var(--color);
}
.statistic.bg-warning {
  --color: #e29f24;
}
.statistic.bg-warning span {
  color: var(--color);
}
.statistic.bg-warning span:before {
  background: var(--color);
}
.statistic.bg-info {
  --color: #6562cf;
}
.statistic.bg-info span {
  color: var(--color);
}
.statistic.bg-info span:before {
  background: var(--color);
}
.statistic.bg-danger {
  --color: #dc3545;
}
.statistic.bg-danger span {
  color: var(--color);
}
.statistic.bg-danger span:before {
  background: var(--color);
}
.statistic.bg-accent1-hover {
  --color: #129aa6;
}
.statistic.bg-accent1-hover span {
  color: var(--color);
}
.statistic.bg-accent1-hover span:before {
  background: var(--color);
}
.statistic.bg-47 {
  --color: #622776;
}
.statistic.bg-47 span {
  color: var(--color);
}
.statistic.bg-47 span:before {
  background: var(--color);
}
.statistic.bg-48 {
  --color: #7d92a1;
}
.statistic.bg-48 span {
  color: var(--color);
}
.statistic.bg-48 span:before {
  background: var(--color);
}

.user-card {
  --color: #8ad7e0;
  background: linear-gradient(var(--color) 2.75rem, #ffffff 2.75rem, #ffffff 100%);
}
.user-card.bg0 {
  --color: #8ad7e0;
}
.user-card.bg1 {
  --color: #d7b09a;
}
.user-card.bg2 {
  --color: #c0afdb;
}
.user-card.bg3 {
  --color: #e0bd75;
}
.user-card.uniquebg1 {
  --color: #8ad7e0 !important;
}
.user-card.uniquebg2 {
  --color: #e0bd75 !important;
}
.user-card.uniquebg3 {
  --color: #eac2ac !important;
}
.user-card.uniquebg4 {
  --color: #c0afdb !important;
}
.user-card.uniquebg5 {
  --color: #96b2e0 !important;
}
.user-card.uniquebg6 {
  --color: #8be0b0 !important;
}
.user-card.uniquebg7 {
  --color: #d2a1e0 !important;
}
.user-card.uniquebg8 {
  --color: #bce080 !important;
}

.list-type-none {
  list-style-type: none;
  padding-left: 0;
}

.pagination {
  margin-bottom: 0;
  --bs-pagination-border-radius: 0.125rem;
}
.pagination .page-item + .page-item {
  padding-left: 0.5rem;
}
.pagination .page-link {
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.125rem;
  background: #ffffff;
  color: #7d92a1;
  font-size: 0.875rem;
  width: 2rem;
  height: 2rem;
  aspect-ratio: 1;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
}
.pagination .page-link:active, .pagination .page-link:focus {
  box-shadow: none;
}
.pagination .page-item.active .page-link span {
  display: none;
}
.pagination .page-item.active .page-link {
  background-color: #fff;
  border-color: #31aab7;
  color: #31aab7;
  z-index: 0;
}

.title2 {
  font-weight: 500;
  text-transform: uppercase;
}

.graph-increase,
.graph-decrease {
  position: relative;
  padding-left: 1.0625rem;
}
.graph-increase::before,
.graph-decrease::before {
  font-family: bootstrap-icons !important;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.graph-increase::before {
  color: #28a745;
  content: "";
}

.graph-decrease::before {
  color: #dc3545;
  content: "";
}

.chart-legend .legend-labels {
  background-color: transparent !important;
  text-align: right !important;
  float: right !important;
}

.legend-label-text {
  color: #324552;
}

.active .legend-label-text {
  color: #566976 !important;
}

.list-steps {
  display: flex;
  align-items: flex-start;
}
.list-steps .step {
  white-space: nowrap;
  background: #0078ce;
  color: #fff;
  border-radius: 1.5rem;
  padding: 0.375rem 1rem;
  margin-right: 1rem;
}
.list-steps .details {
  padding-top: 0.375rem;
}

.tooltip-icon:hover::before {
  content: "" !important;
}

.dot {
  position: relative;
  padding-left: 0.75rem;
}
.dot:before {
  position: absolute;
  content: "";
  width: 0.5rem !important;
  height: 0.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #e4e8ea;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.text-success .dot:before {
  background-color: #28a745;
}

.text-accent2 .dot:before {
  background-color: #0078ce;
}

.pdf-container {
  height: calc(80vh - 4.375rem) !important;
  display: block;
}

.pdfViewer .canvasWrapper {
  width: 100% !important;
}

.pdfViewer.removePageBorders .page {
  width: 100% !important;
}

.pdfViewer .page canvas {
  width: 100% !important;
}

.modal-pdfviewer .modal-dialog:not(.modal-lg) {
  max-width: 43.1875rem !important;
}
.modal-pdfviewer .modal-content {
  border-radius: 0.5rem !important;
}
.modal-pdfviewer .modal-body {
  background: #f8fafb !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
}

::ng-deep .ng2-pdf-viewer-container {
  inset: 0;
  width: 80% !important;
  height: calc(100% - 1.375rem) !important;
  margin: 1.375rem auto;
}

pdf-viewer {
  height: calc(100% - 1.375rem) !important;
}

.loader.progress {
  margin-bottom: 0rem !important;
}

.flag_div {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: #f8fafb;
  display: flex;
  align-items: center;
  left: 1px;
  top: 1px;
  height: calc(100% - 2px);
  width: 4.0625rem;
}

.sticky-footer {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  z-index: 2;
}

.search-dropdowns .drop-down-item .imgitem {
  height: 1rem !important;
  width: 1rem !important;
  margin-right: 0.25rem;
}

.text-trim-2,
.text-trim-3,
.text-trim-5,
.text-trim-4 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.text-trim-2 {
  -webkit-line-clamp: 2;
}

.text-trim-3 {
  -webkit-line-clamp: 3;
}

.text-trim-4 {
  -webkit-line-clamp: 4;
}

.text-trim-5 {
  -webkit-line-clamp: 5;
}

.mat-paginator {
  background: none;
}
.mat-paginator .mat-paginator-range-label,
.mat-paginator .mat-paginator-page-size,
.mat-paginator .mat-button-wrapper {
  display: none;
}
.mat-paginator .mat-paginator-container {
  padding: 0 !important;
  min-height: auto;
}
.mat-paginator .mat-custom-page:active {
  box-shadow: none !important;
}
.mat-paginator .mat-paginator-range-actions {
  gap: 0.5rem;
}
.mat-paginator .mat-focus-indicator,
.mat-paginator .mat-custom-page {
  width: 2rem;
  height: 2rem;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.125rem;
  background-color: #ffffff !important;
  padding: 0;
  font-size: 0.875rem;
  box-shadow: none;
  color: #7d92a1 !important;
  margin: 0rem !important;
  font-weight: 400;
}
.mat-paginator .mat-focus-indicator:disabled {
  cursor: default;
  opacity: 0.7;
}
.mat-paginator .mat-focus-indicator:disabled::before {
  color: #7d92a1 !important;
}
.mat-paginator .mat-focus-indicator:hover:enabled,
.mat-paginator .mat-custom-page:hover:not([disabled=disabled]) {
  border: 0.0625rem solid #31aab7;
  color: #31aab7 !important;
}
.mat-paginator .mat-custom-page[disabled=disabled] {
  border: 0.0625rem solid #31aab7;
  color: #31aab7 !important;
}
.mat-paginator .mat-focus-indicator::before {
  font-family: bootstrap-icons !important;
  inset: 0;
  font-size: 0.75rem;
  color: #31aab7;
}
.mat-paginator .mat-paginator-navigation-first::before {
  content: "";
}
.mat-paginator .mat-paginator-navigation-previous::before {
  content: "";
}
.mat-paginator .mat-paginator-navigation-next::before {
  content: "";
}
.mat-paginator .mat-paginator-navigation-last::before {
  content: "";
}

.empty-container {
  flex-direction: column;
}

.chart-legend .legend-labels {
  margin-top: 0.5rem !important;
}

.w-30ch {
  max-width: 30ch !important;
}

.w-50ch {
  max-width: 50ch !important;
}

.pointer-event {
  pointer-events: none;
}

.box-1 {
  background-color: rgba(0, 120, 206, 0.1);
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  display: flex;
  width: 100%;
}

.title {
  color: #324552;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  position: relative;
  display: block;
}
.title::after {
  content: "";
  width: 2.9375rem;
  position: absolute;
  height: 0.125rem;
  bottom: -0.5rem;
  background-color: black;
  left: 0;
}

.y-overlay-a,
ly-overlay-container {
  z-index: 7000 !important;
}

.custom-checklist {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.custom-checklist li + li {
  margin-top: 0.625rem;
}
.custom-checklist li {
  border-radius: 0.25rem;
  padding: 0.75rem 1.88rem 0.75rem 3.188rem;
  font-size: 0.875rem;
  position: relative;
}
.custom-checklist li::before {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 1.688rem;
  height: 1.688rem;
  border-radius: 50%;
  border: 0.0625rem solid #dee3e7;
  background-color: #fff;
}
.custom-checklist li::after {
  content: "";
  font-family: bootstrap-icons !important;
  position: absolute;
  right: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
  color: #acbac3;
  font-size: 0.813rem;
}
.custom-checklist li:hover {
  background-color: #eff4f6;
  cursor: pointer;
}
.custom-checklist li.success {
  color: #7d92a1;
  text-decoration: line-through;
  pointer-events: none;
}
.custom-checklist li.success::before {
  content: "";
  font-family: "icomoon";
  background-color: #28a745;
  color: #fff;
  border: none;
  font-size: 0.813rem;
}

.timer-display {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.25rem;
  min-width: 77px;
  font-weight: 600;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.pac-container {
  z-index: 2000 !important;
  font-family: "Work Sans", sans-serif;
}
.pac-container::after {
  content: unset !important;
}

.cluster {
  --size: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size) !important;
  height: var(--size) !important;
}
.cluster > img {
  width: 100%;
  clip: rect(0px, var(--size), var(--size), 0px) !important;
}
.cluster > div {
  font-family: "Work Sans", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.bg-birthday {
  background-image: url(/assets/images/bg/bg-birthday.png);
}

.bg-anniversary {
  background-image: url(/assets/images/bg/bg-anniversary.png);
}

.bg-pattern {
  background-image: url(/assets/images/bg/bg-pattern.png);
  background-color: #eff4f6;
  background-blend-mode: color-dodge;
}

.bg-pattern1 {
  background: url(/assets/images/bg/bg-pattern2.png) 95% 50% no-repeat, url(/assets/images/bg/bg-wave.png) bottom left no-repeat;
  background-size: contain;
}

.main-height {
  height: calc(100vh - 3rem);
}

.card-chat {
  border-radius: 8px;
  background: #e3eef4;
  min-height: calc(100vh - 5rem);
}

.border-accent1 {
  border: 2px solid #31aab7 !important;
}

ng-autocomplete .autocomplete-overlay {
  pointer-events: none;
}

.nodata-sm {
  width: 3.875rem;
  aspect-ratio: 1;
  object-fit: contain;
}

.agm-info-window-content {
  font-family: "Work Sans", sans-serif;
}

.gm-style-mtc-bbw {
  margin: 1rem !important;
}

.gm-style-mtc button,
.gm-style-mtc li {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  font-family: Work Sans !important;
  color: #324552 !important;
}
.gm-style-mtc button {
  height: 2.5rem !important;
}
.gm-style-mtc ul {
  top: 2.5rem !important;
}

button, .gm-style-iw-d {
  font-family: Work Sans !important;
}

.btn-map {
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  color: #324552 !important;
  height: 2.5rem !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px -1px;
  border: 1px solid #EAEDEF;
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.btn-breadcrumb:hover {
  background-color: #f4f4f4;
}

.btn-zoom {
  color: #31aab7 !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}

.gm-style-iw-c {
  padding-right: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.gm-style-iw-chr {
  position: relative;
}
.gm-style-iw-chr .gm-style-iw-ch {
  padding-top: 0.75rem !important;
}
.gm-style-iw-chr .gm-ui-hover-effect {
  position: absolute !important;
  right: -10px;
  z-index: 1;
  width: 36px !important;
  height: 36px !important;
  outline: none !important;
}
.gm-style-iw-chr .gm-ui-hover-effect span {
  width: 16px !important;
  height: 16px !important;
}

.statistic1 {
  position: relative;
  background-color: transparent !important;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-left: 1.25rem;
}
.statistic1::before {
  position: absolute;
  content: "";
  height: 2.5rem;
  width: 0.25rem;
  border-radius: 0.25rem;
  left: 0;
  background-color: #0066dd;
}

.statistic1.bg-accent1::before {
  background-color: #31AAB7 !important;
}
.statistic1.bg-danger::before {
  background-color: #DC3545 !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.shadow-0 {
  box-shadow: none !important;
}

.shadow-sm, .holiday-calender .national-holiday .mat-calendar-body-cell-content:after,
.holiday-calender .floating-holiday .mat-calendar-body-cell-content:after {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a !important;
}

.shadow-md {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a !important;
}

.shadow-lg {
  box-shadow: 0rem 1.5rem 8rem 0rem #121b2166 !important;
}

.radius-0 {
  border-radius: 0rem !important;
}

.radius-l-0 {
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.radius-r-0 {
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.radius-t-0 {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.radius-b-0 {
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}

.radius-tl-0 {
  border-top-left-radius: 0rem !important;
}

.radius-tr-0 {
  border-top-right-radius: 0rem !important;
}

.radius-bl-0 {
  border-bottom-left-radius: 0rem !important;
}

.radius-br-0 {
  border-bottom-right-radius: 0rem !important;
}

.radius-2 {
  border-radius: 0.125rem !important;
}

.radius-l-2 {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.radius-r-2 {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.radius-t-2 {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.radius-b-2 {
  border-bottom-left-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.radius-tl-2 {
  border-top-left-radius: 0.125rem !important;
}

.radius-tr-2 {
  border-top-right-radius: 0.125rem !important;
}

.radius-bl-2 {
  border-bottom-left-radius: 0.125rem !important;
}

.radius-br-2 {
  border-bottom-right-radius: 0.125rem !important;
}

.radius-4 {
  border-radius: 0.25rem !important;
}

.radius-l-4 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.radius-r-4 {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.radius-t-4 {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.radius-b-4 {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.radius-tl-4 {
  border-top-left-radius: 0.25rem !important;
}

.radius-tr-4 {
  border-top-right-radius: 0.25rem !important;
}

.radius-bl-4 {
  border-bottom-left-radius: 0.25rem !important;
}

.radius-br-4 {
  border-bottom-right-radius: 0.25rem !important;
}

.radius-6 {
  border-radius: 0.375rem !important;
}

.radius-l-6 {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.radius-r-6 {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.radius-t-6 {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.radius-b-6 {
  border-bottom-left-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.radius-tl-6 {
  border-top-left-radius: 0.375rem !important;
}

.radius-tr-6 {
  border-top-right-radius: 0.375rem !important;
}

.radius-bl-6 {
  border-bottom-left-radius: 0.375rem !important;
}

.radius-br-6 {
  border-bottom-right-radius: 0.375rem !important;
}

.radius-8 {
  border-radius: 0.5rem !important;
}

.radius-l-8 {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.radius-r-8 {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.radius-t-8 {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.radius-b-8 {
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.radius-tl-8 {
  border-top-left-radius: 0.5rem !important;
}

.radius-tr-8 {
  border-top-right-radius: 0.5rem !important;
}

.radius-bl-8 {
  border-bottom-left-radius: 0.5rem !important;
}

.radius-br-8 {
  border-bottom-right-radius: 0.5rem !important;
}

.radius-16 {
  border-radius: 1rem !important;
}

.radius-l-16 {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.radius-r-16 {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.radius-t-16 {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.radius-b-16 {
  border-bottom-left-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.radius-tl-16 {
  border-top-left-radius: 1rem !important;
}

.radius-tr-16 {
  border-top-right-radius: 1rem !important;
}

.radius-bl-16 {
  border-bottom-left-radius: 1rem !important;
}

.radius-br-16 {
  border-bottom-right-radius: 1rem !important;
}

.radius-24 {
  border-radius: 1.5rem !important;
}

.radius-l-24 {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.radius-r-24 {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.radius-t-24 {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.radius-b-24 {
  border-bottom-left-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.radius-tl-24 {
  border-top-left-radius: 1.5rem !important;
}

.radius-tr-24 {
  border-top-right-radius: 1.5rem !important;
}

.radius-bl-24 {
  border-bottom-left-radius: 1.5rem !important;
}

.radius-br-24 {
  border-bottom-right-radius: 1.5rem !important;
}

.radius-40 {
  border-radius: 2.5rem !important;
}

.radius-l-40 {
  border-top-left-radius: 2.5rem !important;
  border-bottom-left-radius: 2.5rem !important;
}

.radius-r-40 {
  border-top-right-radius: 2.5rem !important;
  border-bottom-right-radius: 2.5rem !important;
}

.radius-t-40 {
  border-top-left-radius: 2.5rem !important;
  border-top-right-radius: 2.5rem !important;
}

.radius-b-40 {
  border-bottom-left-radius: 2.5rem !important;
  border-bottom-right-radius: 2.5rem !important;
}

.radius-tl-40 {
  border-top-left-radius: 2.5rem !important;
}

.radius-tr-40 {
  border-top-right-radius: 2.5rem !important;
}

.radius-bl-40 {
  border-bottom-left-radius: 2.5rem !important;
}

.radius-br-40 {
  border-bottom-right-radius: 2.5rem !important;
}

.border {
  border: 0.0625rem solid #e4e8ea !important;
}

@media only screen and (min-width: 768px) {
  .w-md-50 {
    width: 50%;
  }
}
.lh-28 {
  line-height: 1.75rem;
}

.pointer {
  cursor: pointer;
}

.scrollbar-10::-webkit-scrollbar, .table-responsive::-webkit-scrollbar {
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 5.5rem;
}
.scrollbar-10::-webkit-scrollbar-thumb, .table-responsive::-webkit-scrollbar-thumb {
  background: rgba(50, 69, 82, 0.15);
  border-radius: 5.5rem;
}
.scrollbar-10::-webkit-scrollbar-track, .table-responsive::-webkit-scrollbar-track {
  background: #f8fafb;
  border-radius: 5.5rem;
}
.scrollbar-10, .table-responsive {
  scrollbar-face-color: rgba(50, 69, 82, 0.15);
  scrollbar-track-color: #f8fafb;
}
.scrollbar-10, .table-responsive {
  scrollbar-color: rgba(50, 69, 82, 0.15) #f8fafb !important;
  scrollbar-width: thin;
}

.w-35 {
  width: 35% !important;
}

.fs-1 {
  font-size: 0.063rem !important;
}

.gap-1 {
  gap: 0.063rem !important;
}

.row-1 {
  --bs-gutter-x: 0.063rem;
  --bs-gutter-y: 0.063rem;
}

.li-1 li + li {
  padding-top: 0.063rem;
}

.sq-1 {
  width: 0.063rem !important;
  height: 0.063rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-2 {
  font-size: 0.125rem !important;
}

.gap-2 {
  gap: 0.125rem !important;
}

.row-2 {
  --bs-gutter-x: 0.125rem;
  --bs-gutter-y: 0.125rem;
}

.li-2 li + li {
  padding-top: 0.125rem;
}

.sq-2 {
  width: 0.125rem !important;
  height: 0.125rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-3 {
  font-size: 0.188rem !important;
}

.gap-3 {
  gap: 0.188rem !important;
}

.row-3 {
  --bs-gutter-x: 0.188rem;
  --bs-gutter-y: 0.188rem;
}

.li-3 li + li {
  padding-top: 0.188rem;
}

.sq-3 {
  width: 0.188rem !important;
  height: 0.188rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-4 {
  font-size: 0.25rem !important;
}

.gap-4 {
  gap: 0.25rem !important;
}

.row-4 {
  --bs-gutter-x: 0.25rem;
  --bs-gutter-y: 0.25rem;
}

.li-4 li + li {
  padding-top: 0.25rem;
}

.sq-4 {
  width: 0.25rem !important;
  height: 0.25rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-5 {
  font-size: 0.313rem !important;
}

.gap-5 {
  gap: 0.313rem !important;
}

.row-5 {
  --bs-gutter-x: 0.313rem;
  --bs-gutter-y: 0.313rem;
}

.li-5 li + li {
  padding-top: 0.313rem;
}

.sq-5 {
  width: 0.313rem !important;
  height: 0.313rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-6 {
  font-size: 0.375rem !important;
}

.gap-6 {
  gap: 0.375rem !important;
}

.row-6 {
  --bs-gutter-x: 0.375rem;
  --bs-gutter-y: 0.375rem;
}

.li-6 li + li {
  padding-top: 0.375rem;
}

.sq-6 {
  width: 0.375rem !important;
  height: 0.375rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-7 {
  font-size: 0.438rem !important;
}

.gap-7 {
  gap: 0.438rem !important;
}

.row-7 {
  --bs-gutter-x: 0.438rem;
  --bs-gutter-y: 0.438rem;
}

.li-7 li + li {
  padding-top: 0.438rem;
}

.sq-7 {
  width: 0.438rem !important;
  height: 0.438rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-8 {
  font-size: 0.5rem !important;
}

.gap-8 {
  gap: 0.5rem !important;
}

.row-8 {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0.5rem;
}

.li-8 li + li {
  padding-top: 0.5rem;
}

.sq-8 {
  width: 0.5rem !important;
  height: 0.5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-9 {
  font-size: 0.563rem !important;
}

.gap-9 {
  gap: 0.563rem !important;
}

.row-9 {
  --bs-gutter-x: 0.563rem;
  --bs-gutter-y: 0.563rem;
}

.li-9 li + li {
  padding-top: 0.563rem;
}

.sq-9 {
  width: 0.563rem !important;
  height: 0.563rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-10 {
  font-size: 0.625rem !important;
}

.gap-10 {
  gap: 0.625rem !important;
}

.row-10 {
  --bs-gutter-x: 0.625rem;
  --bs-gutter-y: 0.625rem;
}

.li-10 li + li {
  padding-top: 0.625rem;
}

.sq-10, .fullcalendar-fulloption .first-cell-div .type-indicator {
  width: 0.625rem !important;
  height: 0.625rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-11 {
  font-size: 0.688rem !important;
}

.gap-11 {
  gap: 0.688rem !important;
}

.row-11 {
  --bs-gutter-x: 0.688rem;
  --bs-gutter-y: 0.688rem;
}

.li-11 li + li {
  padding-top: 0.688rem;
}

.sq-11 {
  width: 0.688rem !important;
  height: 0.688rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-12 {
  font-size: 0.75rem !important;
}

.gap-12 {
  gap: 0.75rem !important;
}

.row-12 {
  --bs-gutter-x: 0.75rem;
  --bs-gutter-y: 0.75rem;
}

.li-12 li + li {
  padding-top: 0.75rem;
}

.sq-12 {
  width: 0.75rem !important;
  height: 0.75rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-13 {
  font-size: 0.813rem !important;
}

.gap-13 {
  gap: 0.813rem !important;
}

.row-13 {
  --bs-gutter-x: 0.813rem;
  --bs-gutter-y: 0.813rem;
}

.li-13 li + li {
  padding-top: 0.813rem;
}

.sq-13 {
  width: 0.813rem !important;
  height: 0.813rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-14 {
  font-size: 0.875rem !important;
}

.gap-14 {
  gap: 0.875rem !important;
}

.row-14 {
  --bs-gutter-x: 0.875rem;
  --bs-gutter-y: 0.875rem;
}

.li-14 li + li {
  padding-top: 0.875rem;
}

.sq-14 {
  width: 0.875rem !important;
  height: 0.875rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-15 {
  font-size: 0.938rem !important;
}

.gap-15 {
  gap: 0.938rem !important;
}

.row-15 {
  --bs-gutter-x: 0.938rem;
  --bs-gutter-y: 0.938rem;
}

.li-15 li + li {
  padding-top: 0.938rem;
}

.sq-15 {
  width: 0.938rem !important;
  height: 0.938rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.gap-16 {
  gap: 1rem !important;
}

.row-16 {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
}

.li-16 li + li {
  padding-top: 1rem;
}

.sq-16 {
  width: 1rem !important;
  height: 1rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-17 {
  font-size: 1.063rem !important;
}

.gap-17 {
  gap: 1.063rem !important;
}

.row-17 {
  --bs-gutter-x: 1.063rem;
  --bs-gutter-y: 1.063rem;
}

.li-17 li + li {
  padding-top: 1.063rem;
}

.sq-17 {
  width: 1.063rem !important;
  height: 1.063rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-18 {
  font-size: 1.125rem !important;
}

.gap-18 {
  gap: 1.125rem !important;
}

.row-18 {
  --bs-gutter-x: 1.125rem;
  --bs-gutter-y: 1.125rem;
}

.li-18 li + li {
  padding-top: 1.125rem;
}

.sq-18 {
  width: 1.125rem !important;
  height: 1.125rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-20 {
  font-size: 1.25rem !important;
}

.gap-20 {
  gap: 1.25rem !important;
}

.row-20 {
  --bs-gutter-x: 1.25rem;
  --bs-gutter-y: 1.25rem;
}

.li-20 li + li {
  padding-top: 1.25rem;
}

.sq-20 {
  width: 1.25rem !important;
  height: 1.25rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-22 {
  font-size: 1.375rem !important;
}

.gap-22 {
  gap: 1.375rem !important;
}

.row-22 {
  --bs-gutter-x: 1.375rem;
  --bs-gutter-y: 1.375rem;
}

.li-22 li + li {
  padding-top: 1.375rem;
}

.sq-22 {
  width: 1.375rem !important;
  height: 1.375rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-23 {
  font-size: 1.438rem !important;
}

.gap-23 {
  gap: 1.438rem !important;
}

.row-23 {
  --bs-gutter-x: 1.438rem;
  --bs-gutter-y: 1.438rem;
}

.li-23 li + li {
  padding-top: 1.438rem;
}

.sq-23 {
  width: 1.438rem !important;
  height: 1.438rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-24 {
  font-size: 1.5rem !important;
}

.gap-24 {
  gap: 1.5rem !important;
}

.row-24 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}

.li-24 li + li {
  padding-top: 1.5rem;
}

.sq-24 {
  width: 1.5rem !important;
  height: 1.5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-25 {
  font-size: 1.563rem !important;
}

.gap-25 {
  gap: 1.563rem !important;
}

.row-25 {
  --bs-gutter-x: 1.563rem;
  --bs-gutter-y: 1.563rem;
}

.li-25 li + li {
  padding-top: 1.563rem;
}

.sq-25 {
  width: 1.563rem !important;
  height: 1.563rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-26 {
  font-size: 1.625rem !important;
}

.gap-26 {
  gap: 1.625rem !important;
}

.row-26 {
  --bs-gutter-x: 1.625rem;
  --bs-gutter-y: 1.625rem;
}

.li-26 li + li {
  padding-top: 1.625rem;
}

.sq-26 {
  width: 1.625rem !important;
  height: 1.625rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-28 {
  font-size: 1.75rem !important;
}

.gap-28 {
  gap: 1.75rem !important;
}

.row-28 {
  --bs-gutter-x: 1.75rem;
  --bs-gutter-y: 1.75rem;
}

.li-28 li + li {
  padding-top: 1.75rem;
}

.sq-28 {
  width: 1.75rem !important;
  height: 1.75rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-30 {
  font-size: 1.875rem !important;
}

.gap-30 {
  gap: 1.875rem !important;
}

.row-30 {
  --bs-gutter-x: 1.875rem;
  --bs-gutter-y: 1.875rem;
}

.li-30 li + li {
  padding-top: 1.875rem;
}

.sq-30 {
  width: 1.875rem !important;
  height: 1.875rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-31 {
  font-size: 1.938rem !important;
}

.gap-31 {
  gap: 1.938rem !important;
}

.row-31 {
  --bs-gutter-x: 1.938rem;
  --bs-gutter-y: 1.938rem;
}

.li-31 li + li {
  padding-top: 1.938rem;
}

.sq-31 {
  width: 1.938rem !important;
  height: 1.938rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-32 {
  font-size: 2rem !important;
}

.gap-32 {
  gap: 2rem !important;
}

.row-32 {
  --bs-gutter-x: 2rem;
  --bs-gutter-y: 2rem;
}

.li-32 li + li {
  padding-top: 2rem;
}

.sq-32 {
  width: 2rem !important;
  height: 2rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-35 {
  font-size: 2.188rem !important;
}

.gap-35 {
  gap: 2.188rem !important;
}

.row-35 {
  --bs-gutter-x: 2.188rem;
  --bs-gutter-y: 2.188rem;
}

.li-35 li + li {
  padding-top: 2.188rem;
}

.sq-35 {
  width: 2.188rem !important;
  height: 2.188rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-36 {
  font-size: 2.25rem !important;
}

.gap-36 {
  gap: 2.25rem !important;
}

.row-36 {
  --bs-gutter-x: 2.25rem;
  --bs-gutter-y: 2.25rem;
}

.li-36 li + li {
  padding-top: 2.25rem;
}

.sq-36 {
  width: 2.25rem !important;
  height: 2.25rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-40 {
  font-size: 2.5rem !important;
}

.gap-40 {
  gap: 2.5rem !important;
}

.row-40 {
  --bs-gutter-x: 2.5rem;
  --bs-gutter-y: 2.5rem;
}

.li-40 li + li {
  padding-top: 2.5rem;
}

.sq-40 {
  width: 2.5rem !important;
  height: 2.5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-42 {
  font-size: 2.625rem !important;
}

.gap-42 {
  gap: 2.625rem !important;
}

.row-42 {
  --bs-gutter-x: 2.625rem;
  --bs-gutter-y: 2.625rem;
}

.li-42 li + li {
  padding-top: 2.625rem;
}

.sq-42 {
  width: 2.625rem !important;
  height: 2.625rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-44 {
  font-size: 2.75rem !important;
}

.gap-44 {
  gap: 2.75rem !important;
}

.row-44 {
  --bs-gutter-x: 2.75rem;
  --bs-gutter-y: 2.75rem;
}

.li-44 li + li {
  padding-top: 2.75rem;
}

.sq-44 {
  width: 2.75rem !important;
  height: 2.75rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-45 {
  font-size: 2.813rem !important;
}

.gap-45 {
  gap: 2.813rem !important;
}

.row-45 {
  --bs-gutter-x: 2.813rem;
  --bs-gutter-y: 2.813rem;
}

.li-45 li + li {
  padding-top: 2.813rem;
}

.sq-45 {
  width: 2.813rem !important;
  height: 2.813rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-46 {
  font-size: 2.875rem !important;
}

.gap-46 {
  gap: 2.875rem !important;
}

.row-46 {
  --bs-gutter-x: 2.875rem;
  --bs-gutter-y: 2.875rem;
}

.li-46 li + li {
  padding-top: 2.875rem;
}

.sq-46 {
  width: 2.875rem !important;
  height: 2.875rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-48 {
  font-size: 3rem !important;
}

.gap-48 {
  gap: 3rem !important;
}

.row-48 {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 3rem;
}

.li-48 li + li {
  padding-top: 3rem;
}

.sq-48 {
  width: 3rem !important;
  height: 3rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-50 {
  font-size: 3.125rem !important;
}

.gap-50 {
  gap: 3.125rem !important;
}

.row-50 {
  --bs-gutter-x: 3.125rem;
  --bs-gutter-y: 3.125rem;
}

.li-50 li + li {
  padding-top: 3.125rem;
}

.sq-50 {
  width: 3.125rem !important;
  height: 3.125rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-56 {
  font-size: 3.5rem !important;
}

.gap-56 {
  gap: 3.5rem !important;
}

.row-56 {
  --bs-gutter-x: 3.5rem;
  --bs-gutter-y: 3.5rem;
}

.li-56 li + li {
  padding-top: 3.5rem;
}

.sq-56 {
  width: 3.5rem !important;
  height: 3.5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-57 {
  font-size: 3.563rem !important;
}

.gap-57 {
  gap: 3.563rem !important;
}

.row-57 {
  --bs-gutter-x: 3.563rem;
  --bs-gutter-y: 3.563rem;
}

.li-57 li + li {
  padding-top: 3.563rem;
}

.sq-57 {
  width: 3.563rem !important;
  height: 3.563rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-64 {
  font-size: 4rem !important;
}

.gap-64 {
  gap: 4rem !important;
}

.row-64 {
  --bs-gutter-x: 4rem;
  --bs-gutter-y: 4rem;
}

.li-64 li + li {
  padding-top: 4rem;
}

.sq-64 {
  width: 4rem !important;
  height: 4rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-68 {
  font-size: 4.25rem !important;
}

.gap-68 {
  gap: 4.25rem !important;
}

.row-68 {
  --bs-gutter-x: 4.25rem;
  --bs-gutter-y: 4.25rem;
}

.li-68 li + li {
  padding-top: 4.25rem;
}

.sq-68 {
  width: 4.25rem !important;
  height: 4.25rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-72 {
  font-size: 4.5rem !important;
}

.gap-72 {
  gap: 4.5rem !important;
}

.row-72 {
  --bs-gutter-x: 4.5rem;
  --bs-gutter-y: 4.5rem;
}

.li-72 li + li {
  padding-top: 4.5rem;
}

.sq-72 {
  width: 4.5rem !important;
  height: 4.5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-75 {
  font-size: 4.688rem !important;
}

.gap-75 {
  gap: 4.688rem !important;
}

.row-75 {
  --bs-gutter-x: 4.688rem;
  --bs-gutter-y: 4.688rem;
}

.li-75 li + li {
  padding-top: 4.688rem;
}

.sq-75 {
  width: 4.688rem !important;
  height: 4.688rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-80 {
  font-size: 5rem !important;
}

.gap-80 {
  gap: 5rem !important;
}

.row-80 {
  --bs-gutter-x: 5rem;
  --bs-gutter-y: 5rem;
}

.li-80 li + li {
  padding-top: 5rem;
}

.sq-80 {
  width: 5rem !important;
  height: 5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-88 {
  font-size: 5.5rem !important;
}

.gap-88 {
  gap: 5.5rem !important;
}

.row-88 {
  --bs-gutter-x: 5.5rem;
  --bs-gutter-y: 5.5rem;
}

.li-88 li + li {
  padding-top: 5.5rem;
}

.sq-88 {
  width: 5.5rem !important;
  height: 5.5rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-90 {
  font-size: 5.625rem !important;
}

.gap-90 {
  gap: 5.625rem !important;
}

.row-90 {
  --bs-gutter-x: 5.625rem;
  --bs-gutter-y: 5.625rem;
}

.li-90 li + li {
  padding-top: 5.625rem;
}

.sq-90 {
  width: 5.625rem !important;
  height: 5.625rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-96 {
  font-size: 6rem !important;
}

.gap-96 {
  gap: 6rem !important;
}

.row-96 {
  --bs-gutter-x: 6rem;
  --bs-gutter-y: 6rem;
}

.li-96 li + li {
  padding-top: 6rem;
}

.sq-96 {
  width: 6rem !important;
  height: 6rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.fs-100 {
  font-size: 6.25rem !important;
}

.gap-100 {
  gap: 6.25rem !important;
}

.row-100 {
  --bs-gutter-x: 6.25rem;
  --bs-gutter-y: 6.25rem;
}

.li-100 li + li {
  padding-top: 6.25rem;
}

.sq-100 {
  width: 6.25rem !important;
  height: 6.25rem !important;
  min-height: unset !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: contain !important;
}

.m-6,
.my-6,
.mt-6 {
  margin-top: 0.375rem !important;
}

.m-6,
.my-6,
.mb-6 {
  margin-bottom: 0.375rem !important;
}

.m-6,
.mx-6,
.ms-6 {
  margin-left: 0.375rem !important;
}

.m-6,
.mx-6,
.me-6 {
  margin-right: 0.375rem !important;
}

.p-6,
.py-6,
.pt-6 {
  padding-top: 0.375rem !important;
}

.p-6,
.py-6,
.pb-6 {
  padding-bottom: 0.375rem !important;
}

.p-6,
.px-6,
.ps-6 {
  padding-left: 0.375rem !important;
}

.p-6,
.px-6,
.pe-6 {
  padding-right: 0.375rem !important;
}

.m-7,
.my-7,
.mt-7 {
  margin-top: 0.438rem !important;
}

.m-7,
.my-7,
.mb-7 {
  margin-bottom: 0.438rem !important;
}

.m-7,
.mx-7,
.ms-7 {
  margin-left: 0.438rem !important;
}

.m-7,
.mx-7,
.me-7 {
  margin-right: 0.438rem !important;
}

.p-7,
.py-7,
.pt-7 {
  padding-top: 0.438rem !important;
}

.p-7,
.py-7,
.pb-7 {
  padding-bottom: 0.438rem !important;
}

.p-7,
.px-7,
.ps-7 {
  padding-left: 0.438rem !important;
}

.p-7,
.px-7,
.pe-7 {
  padding-right: 0.438rem !important;
}

.m-8,
.my-8,
.mt-8 {
  margin-top: 0.5rem !important;
}

.m-8,
.my-8,
.mb-8 {
  margin-bottom: 0.5rem !important;
}

.m-8,
.mx-8,
.ms-8 {
  margin-left: 0.5rem !important;
}

.m-8,
.mx-8,
.me-8 {
  margin-right: 0.5rem !important;
}

.p-8,
.py-8,
.pt-8 {
  padding-top: 0.5rem !important;
}

.p-8,
.py-8,
.pb-8 {
  padding-bottom: 0.5rem !important;
}

.p-8,
.px-8,
.ps-8 {
  padding-left: 0.5rem !important;
}

.p-8,
.px-8,
.pe-8 {
  padding-right: 0.5rem !important;
}

.m-9,
.my-9,
.mt-9 {
  margin-top: 0.563rem !important;
}

.m-9,
.my-9,
.mb-9 {
  margin-bottom: 0.563rem !important;
}

.m-9,
.mx-9,
.ms-9 {
  margin-left: 0.563rem !important;
}

.m-9,
.mx-9,
.me-9 {
  margin-right: 0.563rem !important;
}

.p-9,
.py-9,
.pt-9 {
  padding-top: 0.563rem !important;
}

.p-9,
.py-9,
.pb-9 {
  padding-bottom: 0.563rem !important;
}

.p-9,
.px-9,
.ps-9 {
  padding-left: 0.563rem !important;
}

.p-9,
.px-9,
.pe-9 {
  padding-right: 0.563rem !important;
}

.m-10,
.my-10,
.mt-10 {
  margin-top: 0.625rem !important;
}

.m-10,
.my-10,
.mb-10 {
  margin-bottom: 0.625rem !important;
}

.m-10,
.mx-10,
.ms-10 {
  margin-left: 0.625rem !important;
}

.m-10,
.mx-10,
.me-10 {
  margin-right: 0.625rem !important;
}

.p-10,
.py-10,
.pt-10 {
  padding-top: 0.625rem !important;
}

.p-10,
.py-10,
.pb-10 {
  padding-bottom: 0.625rem !important;
}

.p-10,
.px-10,
.ps-10 {
  padding-left: 0.625rem !important;
}

.p-10,
.px-10,
.pe-10 {
  padding-right: 0.625rem !important;
}

.m-11,
.my-11,
.mt-11 {
  margin-top: 0.688rem !important;
}

.m-11,
.my-11,
.mb-11 {
  margin-bottom: 0.688rem !important;
}

.m-11,
.mx-11,
.ms-11 {
  margin-left: 0.688rem !important;
}

.m-11,
.mx-11,
.me-11 {
  margin-right: 0.688rem !important;
}

.p-11,
.py-11,
.pt-11 {
  padding-top: 0.688rem !important;
}

.p-11,
.py-11,
.pb-11 {
  padding-bottom: 0.688rem !important;
}

.p-11,
.px-11,
.ps-11 {
  padding-left: 0.688rem !important;
}

.p-11,
.px-11,
.pe-11 {
  padding-right: 0.688rem !important;
}

.m-12,
.my-12,
.mt-12 {
  margin-top: 0.75rem !important;
}

.m-12,
.my-12,
.mb-12 {
  margin-bottom: 0.75rem !important;
}

.m-12,
.mx-12,
.ms-12 {
  margin-left: 0.75rem !important;
}

.m-12,
.mx-12,
.me-12 {
  margin-right: 0.75rem !important;
}

.p-12,
.py-12,
.pt-12 {
  padding-top: 0.75rem !important;
}

.p-12,
.py-12,
.pb-12 {
  padding-bottom: 0.75rem !important;
}

.p-12,
.px-12,
.ps-12 {
  padding-left: 0.75rem !important;
}

.p-12,
.px-12,
.pe-12 {
  padding-right: 0.75rem !important;
}

.m-13,
.my-13,
.mt-13 {
  margin-top: 0.813rem !important;
}

.m-13,
.my-13,
.mb-13 {
  margin-bottom: 0.813rem !important;
}

.m-13,
.mx-13,
.ms-13 {
  margin-left: 0.813rem !important;
}

.m-13,
.mx-13,
.me-13 {
  margin-right: 0.813rem !important;
}

.p-13,
.py-13,
.pt-13 {
  padding-top: 0.813rem !important;
}

.p-13,
.py-13,
.pb-13 {
  padding-bottom: 0.813rem !important;
}

.p-13,
.px-13,
.ps-13 {
  padding-left: 0.813rem !important;
}

.p-13,
.px-13,
.pe-13 {
  padding-right: 0.813rem !important;
}

.m-14,
.my-14,
.mt-14 {
  margin-top: 0.875rem !important;
}

.m-14,
.my-14,
.mb-14 {
  margin-bottom: 0.875rem !important;
}

.m-14,
.mx-14,
.ms-14 {
  margin-left: 0.875rem !important;
}

.m-14,
.mx-14,
.me-14 {
  margin-right: 0.875rem !important;
}

.p-14,
.py-14,
.pt-14 {
  padding-top: 0.875rem !important;
}

.p-14,
.py-14,
.pb-14 {
  padding-bottom: 0.875rem !important;
}

.p-14,
.px-14,
.ps-14 {
  padding-left: 0.875rem !important;
}

.p-14,
.px-14,
.pe-14 {
  padding-right: 0.875rem !important;
}

.m-15,
.my-15,
.mt-15 {
  margin-top: 0.938rem !important;
}

.m-15,
.my-15,
.mb-15 {
  margin-bottom: 0.938rem !important;
}

.m-15,
.mx-15,
.ms-15 {
  margin-left: 0.938rem !important;
}

.m-15,
.mx-15,
.me-15 {
  margin-right: 0.938rem !important;
}

.p-15,
.py-15,
.pt-15 {
  padding-top: 0.938rem !important;
}

.p-15,
.py-15,
.pb-15 {
  padding-bottom: 0.938rem !important;
}

.p-15,
.px-15,
.ps-15 {
  padding-left: 0.938rem !important;
}

.p-15,
.px-15,
.pe-15 {
  padding-right: 0.938rem !important;
}

.m-16,
.my-16,
.mt-16 {
  margin-top: 1rem !important;
}

.m-16,
.my-16,
.mb-16 {
  margin-bottom: 1rem !important;
}

.m-16,
.mx-16,
.ms-16 {
  margin-left: 1rem !important;
}

.m-16,
.mx-16,
.me-16 {
  margin-right: 1rem !important;
}

.p-16,
.py-16,
.pt-16 {
  padding-top: 1rem !important;
}

.p-16,
.py-16,
.pb-16 {
  padding-bottom: 1rem !important;
}

.p-16,
.px-16,
.ps-16 {
  padding-left: 1rem !important;
}

.p-16,
.px-16,
.pe-16 {
  padding-right: 1rem !important;
}

.m-17,
.my-17,
.mt-17 {
  margin-top: 1.063rem !important;
}

.m-17,
.my-17,
.mb-17 {
  margin-bottom: 1.063rem !important;
}

.m-17,
.mx-17,
.ms-17 {
  margin-left: 1.063rem !important;
}

.m-17,
.mx-17,
.me-17 {
  margin-right: 1.063rem !important;
}

.p-17,
.py-17,
.pt-17 {
  padding-top: 1.063rem !important;
}

.p-17,
.py-17,
.pb-17 {
  padding-bottom: 1.063rem !important;
}

.p-17,
.px-17,
.ps-17 {
  padding-left: 1.063rem !important;
}

.p-17,
.px-17,
.pe-17 {
  padding-right: 1.063rem !important;
}

.m-18,
.my-18,
.mt-18 {
  margin-top: 1.125rem !important;
}

.m-18,
.my-18,
.mb-18 {
  margin-bottom: 1.125rem !important;
}

.m-18,
.mx-18,
.ms-18 {
  margin-left: 1.125rem !important;
}

.m-18,
.mx-18,
.me-18 {
  margin-right: 1.125rem !important;
}

.p-18,
.py-18,
.pt-18 {
  padding-top: 1.125rem !important;
}

.p-18,
.py-18,
.pb-18 {
  padding-bottom: 1.125rem !important;
}

.p-18,
.px-18,
.ps-18 {
  padding-left: 1.125rem !important;
}

.p-18,
.px-18,
.pe-18 {
  padding-right: 1.125rem !important;
}

.m-20,
.my-20,
.mt-20 {
  margin-top: 1.25rem !important;
}

.m-20,
.my-20,
.mb-20 {
  margin-bottom: 1.25rem !important;
}

.m-20,
.mx-20,
.ms-20 {
  margin-left: 1.25rem !important;
}

.m-20,
.mx-20,
.me-20 {
  margin-right: 1.25rem !important;
}

.p-20,
.py-20,
.pt-20 {
  padding-top: 1.25rem !important;
}

.p-20,
.py-20,
.pb-20 {
  padding-bottom: 1.25rem !important;
}

.p-20,
.px-20,
.ps-20 {
  padding-left: 1.25rem !important;
}

.p-20,
.px-20,
.pe-20 {
  padding-right: 1.25rem !important;
}

.m-22,
.my-22,
.mt-22 {
  margin-top: 1.375rem !important;
}

.m-22,
.my-22,
.mb-22 {
  margin-bottom: 1.375rem !important;
}

.m-22,
.mx-22,
.ms-22 {
  margin-left: 1.375rem !important;
}

.m-22,
.mx-22,
.me-22 {
  margin-right: 1.375rem !important;
}

.p-22,
.py-22,
.pt-22 {
  padding-top: 1.375rem !important;
}

.p-22,
.py-22,
.pb-22 {
  padding-bottom: 1.375rem !important;
}

.p-22,
.px-22,
.ps-22 {
  padding-left: 1.375rem !important;
}

.p-22,
.px-22,
.pe-22 {
  padding-right: 1.375rem !important;
}

.m-23,
.my-23,
.mt-23 {
  margin-top: 1.438rem !important;
}

.m-23,
.my-23,
.mb-23 {
  margin-bottom: 1.438rem !important;
}

.m-23,
.mx-23,
.ms-23 {
  margin-left: 1.438rem !important;
}

.m-23,
.mx-23,
.me-23 {
  margin-right: 1.438rem !important;
}

.p-23,
.py-23,
.pt-23 {
  padding-top: 1.438rem !important;
}

.p-23,
.py-23,
.pb-23 {
  padding-bottom: 1.438rem !important;
}

.p-23,
.px-23,
.ps-23 {
  padding-left: 1.438rem !important;
}

.p-23,
.px-23,
.pe-23 {
  padding-right: 1.438rem !important;
}

.m-24,
.my-24,
.mt-24 {
  margin-top: 1.5rem !important;
}

.m-24,
.my-24,
.mb-24 {
  margin-bottom: 1.5rem !important;
}

.m-24,
.mx-24,
.ms-24 {
  margin-left: 1.5rem !important;
}

.m-24,
.mx-24,
.me-24 {
  margin-right: 1.5rem !important;
}

.p-24,
.py-24,
.pt-24 {
  padding-top: 1.5rem !important;
}

.p-24,
.py-24,
.pb-24 {
  padding-bottom: 1.5rem !important;
}

.p-24,
.px-24,
.ps-24 {
  padding-left: 1.5rem !important;
}

.p-24,
.px-24,
.pe-24 {
  padding-right: 1.5rem !important;
}

.m-25,
.my-25,
.mt-25 {
  margin-top: 1.563rem !important;
}

.m-25,
.my-25,
.mb-25 {
  margin-bottom: 1.563rem !important;
}

.m-25,
.mx-25,
.ms-25 {
  margin-left: 1.563rem !important;
}

.m-25,
.mx-25,
.me-25 {
  margin-right: 1.563rem !important;
}

.p-25,
.py-25,
.pt-25 {
  padding-top: 1.563rem !important;
}

.p-25,
.py-25,
.pb-25 {
  padding-bottom: 1.563rem !important;
}

.p-25,
.px-25,
.ps-25 {
  padding-left: 1.563rem !important;
}

.p-25,
.px-25,
.pe-25 {
  padding-right: 1.563rem !important;
}

.m-26,
.my-26,
.mt-26 {
  margin-top: 1.625rem !important;
}

.m-26,
.my-26,
.mb-26 {
  margin-bottom: 1.625rem !important;
}

.m-26,
.mx-26,
.ms-26 {
  margin-left: 1.625rem !important;
}

.m-26,
.mx-26,
.me-26 {
  margin-right: 1.625rem !important;
}

.p-26,
.py-26,
.pt-26 {
  padding-top: 1.625rem !important;
}

.p-26,
.py-26,
.pb-26 {
  padding-bottom: 1.625rem !important;
}

.p-26,
.px-26,
.ps-26 {
  padding-left: 1.625rem !important;
}

.p-26,
.px-26,
.pe-26 {
  padding-right: 1.625rem !important;
}

.m-28,
.my-28,
.mt-28 {
  margin-top: 1.75rem !important;
}

.m-28,
.my-28,
.mb-28 {
  margin-bottom: 1.75rem !important;
}

.m-28,
.mx-28,
.ms-28 {
  margin-left: 1.75rem !important;
}

.m-28,
.mx-28,
.me-28 {
  margin-right: 1.75rem !important;
}

.p-28,
.py-28,
.pt-28 {
  padding-top: 1.75rem !important;
}

.p-28,
.py-28,
.pb-28 {
  padding-bottom: 1.75rem !important;
}

.p-28,
.px-28,
.ps-28 {
  padding-left: 1.75rem !important;
}

.p-28,
.px-28,
.pe-28 {
  padding-right: 1.75rem !important;
}

.m-30,
.my-30,
.mt-30 {
  margin-top: 1.875rem !important;
}

.m-30,
.my-30,
.mb-30 {
  margin-bottom: 1.875rem !important;
}

.m-30,
.mx-30,
.ms-30 {
  margin-left: 1.875rem !important;
}

.m-30,
.mx-30,
.me-30 {
  margin-right: 1.875rem !important;
}

.p-30,
.py-30,
.pt-30 {
  padding-top: 1.875rem !important;
}

.p-30,
.py-30,
.pb-30 {
  padding-bottom: 1.875rem !important;
}

.p-30,
.px-30,
.ps-30 {
  padding-left: 1.875rem !important;
}

.p-30,
.px-30,
.pe-30 {
  padding-right: 1.875rem !important;
}

.m-31,
.my-31,
.mt-31 {
  margin-top: 1.938rem !important;
}

.m-31,
.my-31,
.mb-31 {
  margin-bottom: 1.938rem !important;
}

.m-31,
.mx-31,
.ms-31 {
  margin-left: 1.938rem !important;
}

.m-31,
.mx-31,
.me-31 {
  margin-right: 1.938rem !important;
}

.p-31,
.py-31,
.pt-31 {
  padding-top: 1.938rem !important;
}

.p-31,
.py-31,
.pb-31 {
  padding-bottom: 1.938rem !important;
}

.p-31,
.px-31,
.ps-31 {
  padding-left: 1.938rem !important;
}

.p-31,
.px-31,
.pe-31 {
  padding-right: 1.938rem !important;
}

.m-32,
.my-32,
.mt-32 {
  margin-top: 2rem !important;
}

.m-32,
.my-32,
.mb-32 {
  margin-bottom: 2rem !important;
}

.m-32,
.mx-32,
.ms-32 {
  margin-left: 2rem !important;
}

.m-32,
.mx-32,
.me-32 {
  margin-right: 2rem !important;
}

.p-32,
.py-32,
.pt-32 {
  padding-top: 2rem !important;
}

.p-32,
.py-32,
.pb-32 {
  padding-bottom: 2rem !important;
}

.p-32,
.px-32,
.ps-32 {
  padding-left: 2rem !important;
}

.p-32,
.px-32,
.pe-32 {
  padding-right: 2rem !important;
}

.m-35,
.my-35,
.mt-35 {
  margin-top: 2.188rem !important;
}

.m-35,
.my-35,
.mb-35 {
  margin-bottom: 2.188rem !important;
}

.m-35,
.mx-35,
.ms-35 {
  margin-left: 2.188rem !important;
}

.m-35,
.mx-35,
.me-35 {
  margin-right: 2.188rem !important;
}

.p-35,
.py-35,
.pt-35 {
  padding-top: 2.188rem !important;
}

.p-35,
.py-35,
.pb-35 {
  padding-bottom: 2.188rem !important;
}

.p-35,
.px-35,
.ps-35 {
  padding-left: 2.188rem !important;
}

.p-35,
.px-35,
.pe-35 {
  padding-right: 2.188rem !important;
}

.m-36,
.my-36,
.mt-36 {
  margin-top: 2.25rem !important;
}

.m-36,
.my-36,
.mb-36 {
  margin-bottom: 2.25rem !important;
}

.m-36,
.mx-36,
.ms-36 {
  margin-left: 2.25rem !important;
}

.m-36,
.mx-36,
.me-36 {
  margin-right: 2.25rem !important;
}

.p-36,
.py-36,
.pt-36 {
  padding-top: 2.25rem !important;
}

.p-36,
.py-36,
.pb-36 {
  padding-bottom: 2.25rem !important;
}

.p-36,
.px-36,
.ps-36 {
  padding-left: 2.25rem !important;
}

.p-36,
.px-36,
.pe-36 {
  padding-right: 2.25rem !important;
}

.m-40,
.my-40,
.mt-40 {
  margin-top: 2.5rem !important;
}

.m-40,
.my-40,
.mb-40 {
  margin-bottom: 2.5rem !important;
}

.m-40,
.mx-40,
.ms-40 {
  margin-left: 2.5rem !important;
}

.m-40,
.mx-40,
.me-40 {
  margin-right: 2.5rem !important;
}

.p-40,
.py-40,
.pt-40 {
  padding-top: 2.5rem !important;
}

.p-40,
.py-40,
.pb-40 {
  padding-bottom: 2.5rem !important;
}

.p-40,
.px-40,
.ps-40 {
  padding-left: 2.5rem !important;
}

.p-40,
.px-40,
.pe-40 {
  padding-right: 2.5rem !important;
}

.m-42,
.my-42,
.mt-42 {
  margin-top: 2.625rem !important;
}

.m-42,
.my-42,
.mb-42 {
  margin-bottom: 2.625rem !important;
}

.m-42,
.mx-42,
.ms-42 {
  margin-left: 2.625rem !important;
}

.m-42,
.mx-42,
.me-42 {
  margin-right: 2.625rem !important;
}

.p-42,
.py-42,
.pt-42 {
  padding-top: 2.625rem !important;
}

.p-42,
.py-42,
.pb-42 {
  padding-bottom: 2.625rem !important;
}

.p-42,
.px-42,
.ps-42 {
  padding-left: 2.625rem !important;
}

.p-42,
.px-42,
.pe-42 {
  padding-right: 2.625rem !important;
}

.m-44,
.my-44,
.mt-44 {
  margin-top: 2.75rem !important;
}

.m-44,
.my-44,
.mb-44 {
  margin-bottom: 2.75rem !important;
}

.m-44,
.mx-44,
.ms-44 {
  margin-left: 2.75rem !important;
}

.m-44,
.mx-44,
.me-44 {
  margin-right: 2.75rem !important;
}

.p-44,
.py-44,
.pt-44 {
  padding-top: 2.75rem !important;
}

.p-44,
.py-44,
.pb-44 {
  padding-bottom: 2.75rem !important;
}

.p-44,
.px-44,
.ps-44 {
  padding-left: 2.75rem !important;
}

.p-44,
.px-44,
.pe-44 {
  padding-right: 2.75rem !important;
}

.m-45,
.my-45,
.mt-45 {
  margin-top: 2.813rem !important;
}

.m-45,
.my-45,
.mb-45 {
  margin-bottom: 2.813rem !important;
}

.m-45,
.mx-45,
.ms-45 {
  margin-left: 2.813rem !important;
}

.m-45,
.mx-45,
.me-45 {
  margin-right: 2.813rem !important;
}

.p-45,
.py-45,
.pt-45 {
  padding-top: 2.813rem !important;
}

.p-45,
.py-45,
.pb-45 {
  padding-bottom: 2.813rem !important;
}

.p-45,
.px-45,
.ps-45 {
  padding-left: 2.813rem !important;
}

.p-45,
.px-45,
.pe-45 {
  padding-right: 2.813rem !important;
}

.m-46,
.my-46,
.mt-46 {
  margin-top: 2.875rem !important;
}

.m-46,
.my-46,
.mb-46 {
  margin-bottom: 2.875rem !important;
}

.m-46,
.mx-46,
.ms-46 {
  margin-left: 2.875rem !important;
}

.m-46,
.mx-46,
.me-46 {
  margin-right: 2.875rem !important;
}

.p-46,
.py-46,
.pt-46 {
  padding-top: 2.875rem !important;
}

.p-46,
.py-46,
.pb-46 {
  padding-bottom: 2.875rem !important;
}

.p-46,
.px-46,
.ps-46 {
  padding-left: 2.875rem !important;
}

.p-46,
.px-46,
.pe-46 {
  padding-right: 2.875rem !important;
}

.m-48,
.my-48,
.mt-48 {
  margin-top: 3rem !important;
}

.m-48,
.my-48,
.mb-48 {
  margin-bottom: 3rem !important;
}

.m-48,
.mx-48,
.ms-48 {
  margin-left: 3rem !important;
}

.m-48,
.mx-48,
.me-48 {
  margin-right: 3rem !important;
}

.p-48,
.py-48,
.pt-48 {
  padding-top: 3rem !important;
}

.p-48,
.py-48,
.pb-48 {
  padding-bottom: 3rem !important;
}

.p-48,
.px-48,
.ps-48 {
  padding-left: 3rem !important;
}

.p-48,
.px-48,
.pe-48 {
  padding-right: 3rem !important;
}

.m-50,
.my-50,
.mt-50 {
  margin-top: 3.125rem !important;
}

.m-50,
.my-50,
.mb-50 {
  margin-bottom: 3.125rem !important;
}

.m-50,
.mx-50,
.ms-50 {
  margin-left: 3.125rem !important;
}

.m-50,
.mx-50,
.me-50 {
  margin-right: 3.125rem !important;
}

.p-50,
.py-50,
.pt-50 {
  padding-top: 3.125rem !important;
}

.p-50,
.py-50,
.pb-50 {
  padding-bottom: 3.125rem !important;
}

.p-50,
.px-50,
.ps-50 {
  padding-left: 3.125rem !important;
}

.p-50,
.px-50,
.pe-50 {
  padding-right: 3.125rem !important;
}

.m-56,
.my-56,
.mt-56 {
  margin-top: 3.5rem !important;
}

.m-56,
.my-56,
.mb-56 {
  margin-bottom: 3.5rem !important;
}

.m-56,
.mx-56,
.ms-56 {
  margin-left: 3.5rem !important;
}

.m-56,
.mx-56,
.me-56 {
  margin-right: 3.5rem !important;
}

.p-56,
.py-56,
.pt-56 {
  padding-top: 3.5rem !important;
}

.p-56,
.py-56,
.pb-56 {
  padding-bottom: 3.5rem !important;
}

.p-56,
.px-56,
.ps-56 {
  padding-left: 3.5rem !important;
}

.p-56,
.px-56,
.pe-56 {
  padding-right: 3.5rem !important;
}

.m-57,
.my-57,
.mt-57 {
  margin-top: 3.563rem !important;
}

.m-57,
.my-57,
.mb-57 {
  margin-bottom: 3.563rem !important;
}

.m-57,
.mx-57,
.ms-57 {
  margin-left: 3.563rem !important;
}

.m-57,
.mx-57,
.me-57 {
  margin-right: 3.563rem !important;
}

.p-57,
.py-57,
.pt-57 {
  padding-top: 3.563rem !important;
}

.p-57,
.py-57,
.pb-57 {
  padding-bottom: 3.563rem !important;
}

.p-57,
.px-57,
.ps-57 {
  padding-left: 3.563rem !important;
}

.p-57,
.px-57,
.pe-57 {
  padding-right: 3.563rem !important;
}

.m-64,
.my-64,
.mt-64 {
  margin-top: 4rem !important;
}

.m-64,
.my-64,
.mb-64 {
  margin-bottom: 4rem !important;
}

.m-64,
.mx-64,
.ms-64 {
  margin-left: 4rem !important;
}

.m-64,
.mx-64,
.me-64 {
  margin-right: 4rem !important;
}

.p-64,
.py-64,
.pt-64 {
  padding-top: 4rem !important;
}

.p-64,
.py-64,
.pb-64 {
  padding-bottom: 4rem !important;
}

.p-64,
.px-64,
.ps-64 {
  padding-left: 4rem !important;
}

.p-64,
.px-64,
.pe-64 {
  padding-right: 4rem !important;
}

.m-68,
.my-68,
.mt-68 {
  margin-top: 4.25rem !important;
}

.m-68,
.my-68,
.mb-68 {
  margin-bottom: 4.25rem !important;
}

.m-68,
.mx-68,
.ms-68 {
  margin-left: 4.25rem !important;
}

.m-68,
.mx-68,
.me-68 {
  margin-right: 4.25rem !important;
}

.p-68,
.py-68,
.pt-68 {
  padding-top: 4.25rem !important;
}

.p-68,
.py-68,
.pb-68 {
  padding-bottom: 4.25rem !important;
}

.p-68,
.px-68,
.ps-68 {
  padding-left: 4.25rem !important;
}

.p-68,
.px-68,
.pe-68 {
  padding-right: 4.25rem !important;
}

.m-72,
.my-72,
.mt-72 {
  margin-top: 4.5rem !important;
}

.m-72,
.my-72,
.mb-72 {
  margin-bottom: 4.5rem !important;
}

.m-72,
.mx-72,
.ms-72 {
  margin-left: 4.5rem !important;
}

.m-72,
.mx-72,
.me-72 {
  margin-right: 4.5rem !important;
}

.p-72,
.py-72,
.pt-72 {
  padding-top: 4.5rem !important;
}

.p-72,
.py-72,
.pb-72 {
  padding-bottom: 4.5rem !important;
}

.p-72,
.px-72,
.ps-72 {
  padding-left: 4.5rem !important;
}

.p-72,
.px-72,
.pe-72 {
  padding-right: 4.5rem !important;
}

.m-75,
.my-75,
.mt-75 {
  margin-top: 4.688rem !important;
}

.m-75,
.my-75,
.mb-75 {
  margin-bottom: 4.688rem !important;
}

.m-75,
.mx-75,
.ms-75 {
  margin-left: 4.688rem !important;
}

.m-75,
.mx-75,
.me-75 {
  margin-right: 4.688rem !important;
}

.p-75,
.py-75,
.pt-75 {
  padding-top: 4.688rem !important;
}

.p-75,
.py-75,
.pb-75 {
  padding-bottom: 4.688rem !important;
}

.p-75,
.px-75,
.ps-75 {
  padding-left: 4.688rem !important;
}

.p-75,
.px-75,
.pe-75 {
  padding-right: 4.688rem !important;
}

.m-80,
.my-80,
.mt-80 {
  margin-top: 5rem !important;
}

.m-80,
.my-80,
.mb-80 {
  margin-bottom: 5rem !important;
}

.m-80,
.mx-80,
.ms-80 {
  margin-left: 5rem !important;
}

.m-80,
.mx-80,
.me-80 {
  margin-right: 5rem !important;
}

.p-80,
.py-80,
.pt-80 {
  padding-top: 5rem !important;
}

.p-80,
.py-80,
.pb-80 {
  padding-bottom: 5rem !important;
}

.p-80,
.px-80,
.ps-80 {
  padding-left: 5rem !important;
}

.p-80,
.px-80,
.pe-80 {
  padding-right: 5rem !important;
}

.m-88,
.my-88,
.mt-88 {
  margin-top: 5.5rem !important;
}

.m-88,
.my-88,
.mb-88 {
  margin-bottom: 5.5rem !important;
}

.m-88,
.mx-88,
.ms-88 {
  margin-left: 5.5rem !important;
}

.m-88,
.mx-88,
.me-88 {
  margin-right: 5.5rem !important;
}

.p-88,
.py-88,
.pt-88 {
  padding-top: 5.5rem !important;
}

.p-88,
.py-88,
.pb-88 {
  padding-bottom: 5.5rem !important;
}

.p-88,
.px-88,
.ps-88 {
  padding-left: 5.5rem !important;
}

.p-88,
.px-88,
.pe-88 {
  padding-right: 5.5rem !important;
}

.m-90,
.my-90,
.mt-90 {
  margin-top: 5.625rem !important;
}

.m-90,
.my-90,
.mb-90 {
  margin-bottom: 5.625rem !important;
}

.m-90,
.mx-90,
.ms-90 {
  margin-left: 5.625rem !important;
}

.m-90,
.mx-90,
.me-90 {
  margin-right: 5.625rem !important;
}

.p-90,
.py-90,
.pt-90 {
  padding-top: 5.625rem !important;
}

.p-90,
.py-90,
.pb-90 {
  padding-bottom: 5.625rem !important;
}

.p-90,
.px-90,
.ps-90 {
  padding-left: 5.625rem !important;
}

.p-90,
.px-90,
.pe-90 {
  padding-right: 5.625rem !important;
}

.m-96,
.my-96,
.mt-96 {
  margin-top: 6rem !important;
}

.m-96,
.my-96,
.mb-96 {
  margin-bottom: 6rem !important;
}

.m-96,
.mx-96,
.ms-96 {
  margin-left: 6rem !important;
}

.m-96,
.mx-96,
.me-96 {
  margin-right: 6rem !important;
}

.p-96,
.py-96,
.pt-96 {
  padding-top: 6rem !important;
}

.p-96,
.py-96,
.pb-96 {
  padding-bottom: 6rem !important;
}

.p-96,
.px-96,
.ps-96 {
  padding-left: 6rem !important;
}

.p-96,
.px-96,
.pe-96 {
  padding-right: 6rem !important;
}

.m-100,
.my-100,
.mt-100 {
  margin-top: 6.25rem !important;
}

.m-100,
.my-100,
.mb-100 {
  margin-bottom: 6.25rem !important;
}

.m-100,
.mx-100,
.ms-100 {
  margin-left: 6.25rem !important;
}

.m-100,
.mx-100,
.me-100 {
  margin-right: 6.25rem !important;
}

.p-100,
.py-100,
.pt-100 {
  padding-top: 6.25rem !important;
}

.p-100,
.py-100,
.pb-100 {
  padding-bottom: 6.25rem !important;
}

.p-100,
.px-100,
.ps-100 {
  padding-left: 6.25rem !important;
}

.p-100,
.px-100,
.pe-100 {
  padding-right: 6.25rem !important;
}

.td-fs-4 td {
  font-size: 0.25rem !important;
}

.th-fs-4 th {
  font-size: 0.25rem !important;
}

.td-m-4 td,
.td-my-4 td,
.td-mt-4 td {
  margin-top: 0.25rem !important;
}

.th-m-4 th,
.th-my-4 th,
.th-mt-4 th {
  margin-top: 0.25rem !important;
}

.td-m-4 td,
.td-my-4 td,
.td-mb-4 td {
  margin-bottom: 0.25rem !important;
}

.th-m-4 th,
.th-my-4 th,
.th-mb-4 th {
  margin-bottom: 0.25rem !important;
}

.td-m-4 td,
.td-mx-4 td,
.td-ms-4 td {
  margin-left: 0.25rem !important;
}

.th-m-4 th,
.th-mx-4 th,
.th-ms-4 th {
  margin-left: 0.25rem !important;
}

.td-m-4 td,
.td-mx-4 td,
.td-me-4 td {
  margin-right: 0.25rem !important;
}

.th-m-4 th,
.th-mx-4 th,
.th-me-4 th {
  margin-right: 0.25rem !important;
}

.td-p-4 td,
.td-py-4 td,
.td-pt-4 td {
  padding-top: 0.25rem !important;
}

.th-p-4 th,
.th-py-4 th,
.th-pt-4 th {
  padding-top: 0.25rem !important;
}

.td-p-4 td,
.td-py-4 td,
.td-pb-4 td {
  padding-bottom: 0.25rem !important;
}

.th-p-4 th,
.th-py-4 th,
.th-pb-4 th {
  padding-bottom: 0.25rem !important;
}

.td-p-4 td,
.td-px-4 td,
.td-ps-4 td {
  padding-left: 0.25rem !important;
}

.th-p-4 th,
.th-px-4 th,
.th-ps-4 th {
  padding-left: 0.25rem !important;
}

.td-p-4 td,
.td-px-4 td,
.td-pe-4 td {
  padding-right: 0.25rem !important;
}

.th-p-4 th,
.th-px-4 th,
.th-pe-4 th {
  padding-right: 0.25rem !important;
}

.td-fs-6 td {
  font-size: 0.375rem !important;
}

.th-fs-6 th {
  font-size: 0.375rem !important;
}

.td-m-6 td,
.td-my-6 td,
.td-mt-6 td {
  margin-top: 0.375rem !important;
}

.th-m-6 th,
.th-my-6 th,
.th-mt-6 th {
  margin-top: 0.375rem !important;
}

.td-m-6 td,
.td-my-6 td,
.td-mb-6 td {
  margin-bottom: 0.375rem !important;
}

.th-m-6 th,
.th-my-6 th,
.th-mb-6 th {
  margin-bottom: 0.375rem !important;
}

.td-m-6 td,
.td-mx-6 td,
.td-ms-6 td {
  margin-left: 0.375rem !important;
}

.th-m-6 th,
.th-mx-6 th,
.th-ms-6 th {
  margin-left: 0.375rem !important;
}

.td-m-6 td,
.td-mx-6 td,
.td-me-6 td {
  margin-right: 0.375rem !important;
}

.th-m-6 th,
.th-mx-6 th,
.th-me-6 th {
  margin-right: 0.375rem !important;
}

.td-p-6 td,
.td-py-6 td,
.td-pt-6 td {
  padding-top: 0.375rem !important;
}

.th-p-6 th,
.th-py-6 th,
.th-pt-6 th {
  padding-top: 0.375rem !important;
}

.td-p-6 td,
.td-py-6 td,
.td-pb-6 td {
  padding-bottom: 0.375rem !important;
}

.th-p-6 th,
.th-py-6 th,
.th-pb-6 th {
  padding-bottom: 0.375rem !important;
}

.td-p-6 td,
.td-px-6 td,
.td-ps-6 td {
  padding-left: 0.375rem !important;
}

.th-p-6 th,
.th-px-6 th,
.th-ps-6 th {
  padding-left: 0.375rem !important;
}

.td-p-6 td,
.td-px-6 td,
.td-pe-6 td {
  padding-right: 0.375rem !important;
}

.th-p-6 th,
.th-px-6 th,
.th-pe-6 th {
  padding-right: 0.375rem !important;
}

.td-fs-8 td {
  font-size: 0.5rem !important;
}

.th-fs-8 th {
  font-size: 0.5rem !important;
}

.td-m-8 td,
.td-my-8 td,
.td-mt-8 td {
  margin-top: 0.5rem !important;
}

.th-m-8 th,
.th-my-8 th,
.th-mt-8 th {
  margin-top: 0.5rem !important;
}

.td-m-8 td,
.td-my-8 td,
.td-mb-8 td {
  margin-bottom: 0.5rem !important;
}

.th-m-8 th,
.th-my-8 th,
.th-mb-8 th {
  margin-bottom: 0.5rem !important;
}

.td-m-8 td,
.td-mx-8 td,
.td-ms-8 td {
  margin-left: 0.5rem !important;
}

.th-m-8 th,
.th-mx-8 th,
.th-ms-8 th {
  margin-left: 0.5rem !important;
}

.td-m-8 td,
.td-mx-8 td,
.td-me-8 td {
  margin-right: 0.5rem !important;
}

.th-m-8 th,
.th-mx-8 th,
.th-me-8 th {
  margin-right: 0.5rem !important;
}

.td-p-8 td,
.td-py-8 td,
.td-pt-8 td {
  padding-top: 0.5rem !important;
}

.th-p-8 th,
.th-py-8 th,
.th-pt-8 th {
  padding-top: 0.5rem !important;
}

.td-p-8 td,
.td-py-8 td,
.td-pb-8 td {
  padding-bottom: 0.5rem !important;
}

.th-p-8 th,
.th-py-8 th,
.th-pb-8 th {
  padding-bottom: 0.5rem !important;
}

.td-p-8 td,
.td-px-8 td,
.td-ps-8 td {
  padding-left: 0.5rem !important;
}

.th-p-8 th,
.th-px-8 th,
.th-ps-8 th {
  padding-left: 0.5rem !important;
}

.td-p-8 td,
.td-px-8 td,
.td-pe-8 td {
  padding-right: 0.5rem !important;
}

.th-p-8 th,
.th-px-8 th,
.th-pe-8 th {
  padding-right: 0.5rem !important;
}

.td-fs-10 td {
  font-size: 0.625rem !important;
}

.th-fs-10 th {
  font-size: 0.625rem !important;
}

.td-m-10 td,
.td-my-10 td,
.td-mt-10 td {
  margin-top: 0.625rem !important;
}

.th-m-10 th,
.th-my-10 th,
.th-mt-10 th {
  margin-top: 0.625rem !important;
}

.td-m-10 td,
.td-my-10 td,
.td-mb-10 td {
  margin-bottom: 0.625rem !important;
}

.th-m-10 th,
.th-my-10 th,
.th-mb-10 th {
  margin-bottom: 0.625rem !important;
}

.td-m-10 td,
.td-mx-10 td,
.td-ms-10 td {
  margin-left: 0.625rem !important;
}

.th-m-10 th,
.th-mx-10 th,
.th-ms-10 th {
  margin-left: 0.625rem !important;
}

.td-m-10 td,
.td-mx-10 td,
.td-me-10 td {
  margin-right: 0.625rem !important;
}

.th-m-10 th,
.th-mx-10 th,
.th-me-10 th {
  margin-right: 0.625rem !important;
}

.td-p-10 td,
.td-py-10 td,
.td-pt-10 td {
  padding-top: 0.625rem !important;
}

.th-p-10 th,
.th-py-10 th,
.th-pt-10 th {
  padding-top: 0.625rem !important;
}

.td-p-10 td,
.td-py-10 td,
.td-pb-10 td {
  padding-bottom: 0.625rem !important;
}

.th-p-10 th,
.th-py-10 th,
.th-pb-10 th {
  padding-bottom: 0.625rem !important;
}

.td-p-10 td,
.td-px-10 td,
.td-ps-10 td {
  padding-left: 0.625rem !important;
}

.th-p-10 th,
.th-px-10 th,
.th-ps-10 th {
  padding-left: 0.625rem !important;
}

.td-p-10 td,
.td-px-10 td,
.td-pe-10 td {
  padding-right: 0.625rem !important;
}

.th-p-10 th,
.th-px-10 th,
.th-pe-10 th {
  padding-right: 0.625rem !important;
}

.td-fs-12 td {
  font-size: 0.75rem !important;
}

.th-fs-12 th {
  font-size: 0.75rem !important;
}

.td-m-12 td,
.td-my-12 td,
.td-mt-12 td {
  margin-top: 0.75rem !important;
}

.th-m-12 th,
.th-my-12 th,
.th-mt-12 th {
  margin-top: 0.75rem !important;
}

.td-m-12 td,
.td-my-12 td,
.td-mb-12 td {
  margin-bottom: 0.75rem !important;
}

.th-m-12 th,
.th-my-12 th,
.th-mb-12 th {
  margin-bottom: 0.75rem !important;
}

.td-m-12 td,
.td-mx-12 td,
.td-ms-12 td {
  margin-left: 0.75rem !important;
}

.th-m-12 th,
.th-mx-12 th,
.th-ms-12 th {
  margin-left: 0.75rem !important;
}

.td-m-12 td,
.td-mx-12 td,
.td-me-12 td {
  margin-right: 0.75rem !important;
}

.th-m-12 th,
.th-mx-12 th,
.th-me-12 th {
  margin-right: 0.75rem !important;
}

.td-p-12 td,
.td-py-12 td,
.td-pt-12 td {
  padding-top: 0.75rem !important;
}

.th-p-12 th,
.th-py-12 th,
.th-pt-12 th {
  padding-top: 0.75rem !important;
}

.td-p-12 td,
.td-py-12 td,
.td-pb-12 td {
  padding-bottom: 0.75rem !important;
}

.th-p-12 th,
.th-py-12 th,
.th-pb-12 th {
  padding-bottom: 0.75rem !important;
}

.td-p-12 td,
.td-px-12 td,
.td-ps-12 td {
  padding-left: 0.75rem !important;
}

.th-p-12 th,
.th-px-12 th,
.th-ps-12 th {
  padding-left: 0.75rem !important;
}

.td-p-12 td,
.td-px-12 td,
.td-pe-12 td {
  padding-right: 0.75rem !important;
}

.th-p-12 th,
.th-px-12 th,
.th-pe-12 th {
  padding-right: 0.75rem !important;
}

.td-fs-14 td {
  font-size: 0.875rem !important;
}

.th-fs-14 th {
  font-size: 0.875rem !important;
}

.td-m-14 td,
.td-my-14 td,
.td-mt-14 td {
  margin-top: 0.875rem !important;
}

.th-m-14 th,
.th-my-14 th,
.th-mt-14 th {
  margin-top: 0.875rem !important;
}

.td-m-14 td,
.td-my-14 td,
.td-mb-14 td {
  margin-bottom: 0.875rem !important;
}

.th-m-14 th,
.th-my-14 th,
.th-mb-14 th {
  margin-bottom: 0.875rem !important;
}

.td-m-14 td,
.td-mx-14 td,
.td-ms-14 td {
  margin-left: 0.875rem !important;
}

.th-m-14 th,
.th-mx-14 th,
.th-ms-14 th {
  margin-left: 0.875rem !important;
}

.td-m-14 td,
.td-mx-14 td,
.td-me-14 td {
  margin-right: 0.875rem !important;
}

.th-m-14 th,
.th-mx-14 th,
.th-me-14 th {
  margin-right: 0.875rem !important;
}

.td-p-14 td,
.td-py-14 td,
.td-pt-14 td {
  padding-top: 0.875rem !important;
}

.th-p-14 th,
.th-py-14 th,
.th-pt-14 th {
  padding-top: 0.875rem !important;
}

.td-p-14 td,
.td-py-14 td,
.td-pb-14 td {
  padding-bottom: 0.875rem !important;
}

.th-p-14 th,
.th-py-14 th,
.th-pb-14 th {
  padding-bottom: 0.875rem !important;
}

.td-p-14 td,
.td-px-14 td,
.td-ps-14 td {
  padding-left: 0.875rem !important;
}

.th-p-14 th,
.th-px-14 th,
.th-ps-14 th {
  padding-left: 0.875rem !important;
}

.td-p-14 td,
.td-px-14 td,
.td-pe-14 td {
  padding-right: 0.875rem !important;
}

.th-p-14 th,
.th-px-14 th,
.th-pe-14 th {
  padding-right: 0.875rem !important;
}

.td-fs-16 td {
  font-size: 1rem !important;
}

.th-fs-16 th {
  font-size: 1rem !important;
}

.td-m-16 td,
.td-my-16 td,
.td-mt-16 td {
  margin-top: 1rem !important;
}

.th-m-16 th,
.th-my-16 th,
.th-mt-16 th {
  margin-top: 1rem !important;
}

.td-m-16 td,
.td-my-16 td,
.td-mb-16 td {
  margin-bottom: 1rem !important;
}

.th-m-16 th,
.th-my-16 th,
.th-mb-16 th {
  margin-bottom: 1rem !important;
}

.td-m-16 td,
.td-mx-16 td,
.td-ms-16 td {
  margin-left: 1rem !important;
}

.th-m-16 th,
.th-mx-16 th,
.th-ms-16 th {
  margin-left: 1rem !important;
}

.td-m-16 td,
.td-mx-16 td,
.td-me-16 td {
  margin-right: 1rem !important;
}

.th-m-16 th,
.th-mx-16 th,
.th-me-16 th {
  margin-right: 1rem !important;
}

.td-p-16 td,
.td-py-16 td,
.td-pt-16 td {
  padding-top: 1rem !important;
}

.th-p-16 th,
.th-py-16 th,
.th-pt-16 th {
  padding-top: 1rem !important;
}

.td-p-16 td,
.td-py-16 td,
.td-pb-16 td {
  padding-bottom: 1rem !important;
}

.th-p-16 th,
.th-py-16 th,
.th-pb-16 th {
  padding-bottom: 1rem !important;
}

.td-p-16 td,
.td-px-16 td,
.td-ps-16 td {
  padding-left: 1rem !important;
}

.th-p-16 th,
.th-px-16 th,
.th-ps-16 th {
  padding-left: 1rem !important;
}

.td-p-16 td,
.td-px-16 td,
.td-pe-16 td {
  padding-right: 1rem !important;
}

.th-p-16 th,
.th-px-16 th,
.th-pe-16 th {
  padding-right: 1rem !important;
}

.td-fs-18 td {
  font-size: 1.125rem !important;
}

.th-fs-18 th {
  font-size: 1.125rem !important;
}

.td-m-18 td,
.td-my-18 td,
.td-mt-18 td {
  margin-top: 1.125rem !important;
}

.th-m-18 th,
.th-my-18 th,
.th-mt-18 th {
  margin-top: 1.125rem !important;
}

.td-m-18 td,
.td-my-18 td,
.td-mb-18 td {
  margin-bottom: 1.125rem !important;
}

.th-m-18 th,
.th-my-18 th,
.th-mb-18 th {
  margin-bottom: 1.125rem !important;
}

.td-m-18 td,
.td-mx-18 td,
.td-ms-18 td {
  margin-left: 1.125rem !important;
}

.th-m-18 th,
.th-mx-18 th,
.th-ms-18 th {
  margin-left: 1.125rem !important;
}

.td-m-18 td,
.td-mx-18 td,
.td-me-18 td {
  margin-right: 1.125rem !important;
}

.th-m-18 th,
.th-mx-18 th,
.th-me-18 th {
  margin-right: 1.125rem !important;
}

.td-p-18 td,
.td-py-18 td,
.td-pt-18 td {
  padding-top: 1.125rem !important;
}

.th-p-18 th,
.th-py-18 th,
.th-pt-18 th {
  padding-top: 1.125rem !important;
}

.td-p-18 td,
.td-py-18 td,
.td-pb-18 td {
  padding-bottom: 1.125rem !important;
}

.th-p-18 th,
.th-py-18 th,
.th-pb-18 th {
  padding-bottom: 1.125rem !important;
}

.td-p-18 td,
.td-px-18 td,
.td-ps-18 td {
  padding-left: 1.125rem !important;
}

.th-p-18 th,
.th-px-18 th,
.th-ps-18 th {
  padding-left: 1.125rem !important;
}

.td-p-18 td,
.td-px-18 td,
.td-pe-18 td {
  padding-right: 1.125rem !important;
}

.th-p-18 th,
.th-px-18 th,
.th-pe-18 th {
  padding-right: 1.125rem !important;
}

.td-fs-20 td {
  font-size: 1.25rem !important;
}

.th-fs-20 th {
  font-size: 1.25rem !important;
}

.td-m-20 td,
.td-my-20 td,
.td-mt-20 td {
  margin-top: 1.25rem !important;
}

.th-m-20 th,
.th-my-20 th,
.th-mt-20 th {
  margin-top: 1.25rem !important;
}

.td-m-20 td,
.td-my-20 td,
.td-mb-20 td {
  margin-bottom: 1.25rem !important;
}

.th-m-20 th,
.th-my-20 th,
.th-mb-20 th {
  margin-bottom: 1.25rem !important;
}

.td-m-20 td,
.td-mx-20 td,
.td-ms-20 td {
  margin-left: 1.25rem !important;
}

.th-m-20 th,
.th-mx-20 th,
.th-ms-20 th {
  margin-left: 1.25rem !important;
}

.td-m-20 td,
.td-mx-20 td,
.td-me-20 td {
  margin-right: 1.25rem !important;
}

.th-m-20 th,
.th-mx-20 th,
.th-me-20 th {
  margin-right: 1.25rem !important;
}

.td-p-20 td,
.td-py-20 td,
.td-pt-20 td {
  padding-top: 1.25rem !important;
}

.th-p-20 th,
.th-py-20 th,
.th-pt-20 th {
  padding-top: 1.25rem !important;
}

.td-p-20 td,
.td-py-20 td,
.td-pb-20 td {
  padding-bottom: 1.25rem !important;
}

.th-p-20 th,
.th-py-20 th,
.th-pb-20 th {
  padding-bottom: 1.25rem !important;
}

.td-p-20 td,
.td-px-20 td,
.td-ps-20 td {
  padding-left: 1.25rem !important;
}

.th-p-20 th,
.th-px-20 th,
.th-ps-20 th {
  padding-left: 1.25rem !important;
}

.td-p-20 td,
.td-px-20 td,
.td-pe-20 td {
  padding-right: 1.25rem !important;
}

.th-p-20 th,
.th-px-20 th,
.th-pe-20 th {
  padding-right: 1.25rem !important;
}

.td-fs-22 td {
  font-size: 1.375rem !important;
}

.th-fs-22 th {
  font-size: 1.375rem !important;
}

.td-m-22 td,
.td-my-22 td,
.td-mt-22 td {
  margin-top: 1.375rem !important;
}

.th-m-22 th,
.th-my-22 th,
.th-mt-22 th {
  margin-top: 1.375rem !important;
}

.td-m-22 td,
.td-my-22 td,
.td-mb-22 td {
  margin-bottom: 1.375rem !important;
}

.th-m-22 th,
.th-my-22 th,
.th-mb-22 th {
  margin-bottom: 1.375rem !important;
}

.td-m-22 td,
.td-mx-22 td,
.td-ms-22 td {
  margin-left: 1.375rem !important;
}

.th-m-22 th,
.th-mx-22 th,
.th-ms-22 th {
  margin-left: 1.375rem !important;
}

.td-m-22 td,
.td-mx-22 td,
.td-me-22 td {
  margin-right: 1.375rem !important;
}

.th-m-22 th,
.th-mx-22 th,
.th-me-22 th {
  margin-right: 1.375rem !important;
}

.td-p-22 td,
.td-py-22 td,
.td-pt-22 td {
  padding-top: 1.375rem !important;
}

.th-p-22 th,
.th-py-22 th,
.th-pt-22 th {
  padding-top: 1.375rem !important;
}

.td-p-22 td,
.td-py-22 td,
.td-pb-22 td {
  padding-bottom: 1.375rem !important;
}

.th-p-22 th,
.th-py-22 th,
.th-pb-22 th {
  padding-bottom: 1.375rem !important;
}

.td-p-22 td,
.td-px-22 td,
.td-ps-22 td {
  padding-left: 1.375rem !important;
}

.th-p-22 th,
.th-px-22 th,
.th-ps-22 th {
  padding-left: 1.375rem !important;
}

.td-p-22 td,
.td-px-22 td,
.td-pe-22 td {
  padding-right: 1.375rem !important;
}

.th-p-22 th,
.th-px-22 th,
.th-pe-22 th {
  padding-right: 1.375rem !important;
}

.td-fs-24 td {
  font-size: 1.5rem !important;
}

.th-fs-24 th {
  font-size: 1.5rem !important;
}

.td-m-24 td,
.td-my-24 td,
.td-mt-24 td {
  margin-top: 1.5rem !important;
}

.th-m-24 th,
.th-my-24 th,
.th-mt-24 th {
  margin-top: 1.5rem !important;
}

.td-m-24 td,
.td-my-24 td,
.td-mb-24 td {
  margin-bottom: 1.5rem !important;
}

.th-m-24 th,
.th-my-24 th,
.th-mb-24 th {
  margin-bottom: 1.5rem !important;
}

.td-m-24 td,
.td-mx-24 td,
.td-ms-24 td {
  margin-left: 1.5rem !important;
}

.th-m-24 th,
.th-mx-24 th,
.th-ms-24 th {
  margin-left: 1.5rem !important;
}

.td-m-24 td,
.td-mx-24 td,
.td-me-24 td {
  margin-right: 1.5rem !important;
}

.th-m-24 th,
.th-mx-24 th,
.th-me-24 th {
  margin-right: 1.5rem !important;
}

.td-p-24 td,
.td-py-24 td,
.td-pt-24 td {
  padding-top: 1.5rem !important;
}

.th-p-24 th,
.th-py-24 th,
.th-pt-24 th {
  padding-top: 1.5rem !important;
}

.td-p-24 td,
.td-py-24 td,
.td-pb-24 td {
  padding-bottom: 1.5rem !important;
}

.th-p-24 th,
.th-py-24 th,
.th-pb-24 th {
  padding-bottom: 1.5rem !important;
}

.td-p-24 td,
.td-px-24 td,
.td-ps-24 td {
  padding-left: 1.5rem !important;
}

.th-p-24 th,
.th-px-24 th,
.th-ps-24 th {
  padding-left: 1.5rem !important;
}

.td-p-24 td,
.td-px-24 td,
.td-pe-24 td {
  padding-right: 1.5rem !important;
}

.th-p-24 th,
.th-px-24 th,
.th-pe-24 th {
  padding-right: 1.5rem !important;
}

.td-fs-28 td {
  font-size: 1.75rem !important;
}

.th-fs-28 th {
  font-size: 1.75rem !important;
}

.td-m-28 td,
.td-my-28 td,
.td-mt-28 td {
  margin-top: 1.75rem !important;
}

.th-m-28 th,
.th-my-28 th,
.th-mt-28 th {
  margin-top: 1.75rem !important;
}

.td-m-28 td,
.td-my-28 td,
.td-mb-28 td {
  margin-bottom: 1.75rem !important;
}

.th-m-28 th,
.th-my-28 th,
.th-mb-28 th {
  margin-bottom: 1.75rem !important;
}

.td-m-28 td,
.td-mx-28 td,
.td-ms-28 td {
  margin-left: 1.75rem !important;
}

.th-m-28 th,
.th-mx-28 th,
.th-ms-28 th {
  margin-left: 1.75rem !important;
}

.td-m-28 td,
.td-mx-28 td,
.td-me-28 td {
  margin-right: 1.75rem !important;
}

.th-m-28 th,
.th-mx-28 th,
.th-me-28 th {
  margin-right: 1.75rem !important;
}

.td-p-28 td,
.td-py-28 td,
.td-pt-28 td {
  padding-top: 1.75rem !important;
}

.th-p-28 th,
.th-py-28 th,
.th-pt-28 th {
  padding-top: 1.75rem !important;
}

.td-p-28 td,
.td-py-28 td,
.td-pb-28 td {
  padding-bottom: 1.75rem !important;
}

.th-p-28 th,
.th-py-28 th,
.th-pb-28 th {
  padding-bottom: 1.75rem !important;
}

.td-p-28 td,
.td-px-28 td,
.td-ps-28 td {
  padding-left: 1.75rem !important;
}

.th-p-28 th,
.th-px-28 th,
.th-ps-28 th {
  padding-left: 1.75rem !important;
}

.td-p-28 td,
.td-px-28 td,
.td-pe-28 td {
  padding-right: 1.75rem !important;
}

.th-p-28 th,
.th-px-28 th,
.th-pe-28 th {
  padding-right: 1.75rem !important;
}

.td-fs-32 td {
  font-size: 2rem !important;
}

.th-fs-32 th {
  font-size: 2rem !important;
}

.td-m-32 td,
.td-my-32 td,
.td-mt-32 td {
  margin-top: 2rem !important;
}

.th-m-32 th,
.th-my-32 th,
.th-mt-32 th {
  margin-top: 2rem !important;
}

.td-m-32 td,
.td-my-32 td,
.td-mb-32 td {
  margin-bottom: 2rem !important;
}

.th-m-32 th,
.th-my-32 th,
.th-mb-32 th {
  margin-bottom: 2rem !important;
}

.td-m-32 td,
.td-mx-32 td,
.td-ms-32 td {
  margin-left: 2rem !important;
}

.th-m-32 th,
.th-mx-32 th,
.th-ms-32 th {
  margin-left: 2rem !important;
}

.td-m-32 td,
.td-mx-32 td,
.td-me-32 td {
  margin-right: 2rem !important;
}

.th-m-32 th,
.th-mx-32 th,
.th-me-32 th {
  margin-right: 2rem !important;
}

.td-p-32 td,
.td-py-32 td,
.td-pt-32 td {
  padding-top: 2rem !important;
}

.th-p-32 th,
.th-py-32 th,
.th-pt-32 th {
  padding-top: 2rem !important;
}

.td-p-32 td,
.td-py-32 td,
.td-pb-32 td {
  padding-bottom: 2rem !important;
}

.th-p-32 th,
.th-py-32 th,
.th-pb-32 th {
  padding-bottom: 2rem !important;
}

.td-p-32 td,
.td-px-32 td,
.td-ps-32 td {
  padding-left: 2rem !important;
}

.th-p-32 th,
.th-px-32 th,
.th-ps-32 th {
  padding-left: 2rem !important;
}

.td-p-32 td,
.td-px-32 td,
.td-pe-32 td {
  padding-right: 2rem !important;
}

.th-p-32 th,
.th-px-32 th,
.th-pe-32 th {
  padding-right: 2rem !important;
}

@font-face {
  font-family: "icomoon";
  src: url("../../assets/fonts/icomoon.eot?5g8mim");
  src: url("../../assets/fonts/icomoon.eot?5g8mim#iefix") format("embedded-opentype"), url("../../assets/fonts/icomoon.ttf?5g8mim") format("truetype"), url("../../assets/fonts/icomoon.woff?5g8mim") format("woff"), url("../../assets/fonts/icomoon.svg?5g8mim#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
i[class^=icon-],
i[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
i[class^=icon-]::before,
i[class*=" icon-"]::before {
  text-transform: uppercase;
}

.icon-clock-repeat:before {
  content: "";
}

.icon-briefcase-clock-fill:before {
  content: "";
}

.icon-clipboard-clock:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-stopwatch1-fill:before {
  content: "";
}

.icon-calendar-fill-clock:before {
  content: "";
}

.icon-calendar-fill-x:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-stopwatch:before {
  content: "";
}

.icon-calendar-clock:before {
  content: "";
}

.icon-clock1:before {
  content: "";
}

.icon-clock-checked:before {
  content: "";
}

.icon-clock-fill:before {
  content: "";
}

.icon-stopwatch-fill:before {
  content: "";
}

.icon-crown:before, .alert-premium:before {
  content: "";
}

.icon-map-route:before {
  content: "";
}

.icon-geo-alt-fill:before {
  content: "";
}

.icon-geo-alt:before {
  content: "";
}

.icon-geo1-alt-fill:before {
  content: "";
}

.icon-megaphone3:before {
  content: "";
}

.icon-megaphone2:before {
  content: "";
}

.icon-note-2:before {
  content: "";
}

.icon-megaphone:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-list-ul:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-shield-lock:before {
  content: "";
}

.icon-message-system:before {
  content: "";
}

.icon-ai:before {
  content: "";
}

.icon-slip:before {
  content: "";
}

.icon-papers-earmark-fill:before {
  content: "";
}

.icon-graph-square:before {
  content: "";
}

.icon-chat-new:before {
  content: "";
}

.icon-note:before {
  content: "";
}

.icon-cash-stack:before {
  content: "";
}

.icon-cash1:before {
  content: "";
}

.icon-stack:before {
  content: "";
}

.icon-loader:before {
  content: "";
}

.icon-gallery:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-file-earmark-arrow-left:before {
  content: "";
}

.icon-files-arrow-in-right:before {
  content: "";
}

.icon-file-grid-fill:before {
  content: "";
}

.icon-window-arrow-in-up:before {
  content: "";
}

.icon-tick-circle:before {
  content: "";
}

.icon-info-circle2:before {
  content: "";
}

.icon-question-circle:before {
  content: "";
}

.icon-check-bold:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-check-circle-bold:before {
  content: "";
}

.icon-check2-circle:before {
  content: "";
}

.icon-double-check-circle-fill:before {
  content: "";
}

.icon-check-square-fill:before {
  content: "";
}

.icon-info-circle:before {
  content: "";
}

.icon-info-circle-fill:before {
  content: "";
}

.icon-exclamation-circle-fill:before {
  content: "";
}

.icon-exclamation-triangle:before {
  content: "";
}

.icon-exclamation-triangle-fill:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-plus-circle:before {
  content: "";
}

.icon-plus-square:before {
  content: "";
}

.icon-plus-square-fill:before {
  content: "";
}

.icon-minus-square:before {
  content: "";
}

.icon-close:before {
  content: "";
}

.icon-close-lg:before {
  content: "";
}

.icon-close-circle:before {
  content: "";
}

.icon-question-circle-fill:before {
  content: "";
}

.icon-cloud-check:before {
  content: "";
}

.icon-cloud-upload:before {
  content: "";
}

.icon-file-earmark-arrow-up:before {
  content: "";
}

.icon-graph-up:before {
  content: "";
}

.icon-file-earmark-text-fill:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-eye-slash:before {
  content: "";
}

.icon-filter-circle:before {
  content: "";
}

.icon-filter-left:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-pencil-square:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-three-dots:before {
  content: "";
}

.icon-three-dots-vertical:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-min-fullscreen:before {
  content: "";
}

.icon-fullscreen:before {
  content: "";
}

.icon-arrows-minimize:before {
  content: "";
}

.icon-arrows-fullscreen:before {
  content: "";
}

.icon-graph-upwards:before {
  content: "";
}

.icon-arrow-left-circle:before {
  content: "";
}

.icon-arrow-repeat-counterclockwise:before {
  content: "";
}

.icon-arrow-right-circle:before {
  content: "";
}

.icon-return-left-fill:before {
  content: "";
}

.icon-arrow-repeat:before {
  content: "";
}

.icon-arrow-return-left-square:before {
  content: "";
}

.icon-arrow-anti-clockwise:before {
  content: "";
}

.icon-arrow-right1:before {
  content: "";
}

.icon-send:before {
  content: "";
}

.icon-chevron-right-circle:before {
  content: "";
}

.icon-chevron-left-circle:before {
  content: "";
}

.icon-arrow-left-circle-fill:before {
  content: "";
}

.icon-arrow-right-circle-fill:before {
  content: "";
}

.icon-caret-down-fill:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-chevron-double-left:before {
  content: "";
}

.icon-chevron-double-right:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-down-lg:before {
  content: "";
}

.icon-chevron-left-lg:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-right-lg:before {
  content: "";
}

.icon-chevron-down-lg1:before {
  content: "";
}

.icon-chevron-up-lg:before {
  content: "";
}

.icon-arrow2-up-circle:before {
  content: "";
}

.icon-bank:before {
  content: "";
}

.icon-briefcase-grid:before {
  content: "";
}

.icon-family:before {
  content: "";
}

.icon-graduation-cap:before {
  content: "";
}

.icon-person2:before {
  content: "";
}

.icon-person-badge-settings:before {
  content: "";
}

.icon-person-lines-fill:before {
  content: "";
}

.icon-shield-plus:before {
  content: "";
}

.icon-calendar-fill:before {
  content: "";
}

.icon-cash-stack-fill:before {
  content: "";
}

.icon-people-fill:before {
  content: "";
}

.icon-card-add-fill:before {
  content: "";
}

.icon-buildings-fill:before {
  content: "";
}

.icon-cash-fill:before {
  content: "";
}

.icon-My-team-hierarchy-icon-svg:before {
  content: "";
}

.icon-person1:before {
  content: "";
}

.icon-bank2:before {
  content: "";
}

.icon-calendar-check-fill:before {
  content: "";
}

.icon-calendar-event-fill:before {
  content: "";
}

.icon-clipboard-data-fill:before {
  content: "";
}

.icon-square:before {
  content: "";
}

.icon-hourglass:before {
  content: "";
}

.icon-qr-code:before {
  content: "";
}

.icon-newspaper:before {
  content: "";
}

.icon-home-building:before {
  content: "";
}

.icon-person-helmet:before {
  content: "";
}

.icon-calendar-edit:before {
  content: "";
}

.icon-bell-2-fill:before {
  content: "";
}

.icon-cake:before {
  content: "";
}

.icon-calendar-close-circle:before {
  content: "";
}

.icon-file-invalid:before {
  content: "";
}

.icon-inbox-fill:before {
  content: "";
}

.icon-gift-fill:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-person-hexagon:before {
  content: "";
}

.icon-grid-fill:before {
  content: "";
}

.icon-coffee:before {
  content: "";
}

.icon-handwave:before {
  content: "";
}

.icon-snooze-fill:before {
  content: "";
}

.icon-star2:before {
  content: "";
}

.icon-stop-fill:before {
  content: "";
}

.icon-share2:before {
  content: "";
}

.icon-paper:before {
  content: "";
}

.icon-clipboard2-check:before {
  content: "";
}

.icon-chat-square-star:before {
  content: "";
}

.icon-briefcase-clock:before {
  content: "";
}

.icon-app-indicator:before {
  content: "";
}

.icon-house-door:before {
  content: "";
}

.icon-envelope:before {
  content: "";
}

.icon-list-check:before {
  content: "";
}

.icon-bar-chart-line:before {
  content: "";
}

.icon-person-gear:before {
  content: "";
}

.icon-person-directory:before {
  content: "";
}

.icon-person-group:before {
  content: "";
}

.icon-diagram-3:before {
  content: "";
}

.icon-gear:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-box-arrow-in-fill:before {
  content: "";
}

.icon-gear2:before {
  content: "";
}

.icon-gear-fill:before {
  content: "";
}

.icon-palette:before {
  content: "";
}

.icon-manage-system:before {
  content: "";
}

.icon-bell-2:before {
  content: "";
}

.icon-wallet-plus:before {
  content: "";
}

.icon-resignation:before {
  content: "";
}

.icon-dollar-wallet:before {
  content: "";
}

.icon-calendar-fill-dollar:before {
  content: "";
}

.icon-calendar-fill1:before {
  content: "";
}

.icon-calendar-fill-user:before {
  content: "";
}

.icon-briefcase-timer-fill:before {
  content: "";
}

.icon-coins:before {
  content: "";
}

.icon-gold-bars:before {
  content: "";
}

.icon-align-bottom:before {
  content: "";
}

.icon-briefcase-fill:before {
  content: "";
}

.icon-bullseye:before {
  content: "";
}

.icon-bus-fill:before {
  content: "";
}

.icon-cash:before {
  content: "";
}

.icon-dollor:before {
  content: "";
}

.icon-expense-fill:before {
  content: "";
}

.icon-file-pencil:before {
  content: "";
}

.icon-holiday:before {
  content: "";
}

.icon-house-fill:before {
  content: "";
}

.icon-ticket-fill:before {
  content: "";
}

html {
  height: 100%;
}

:root {
  font-size: 14px;
}
@media (min-width: 1440px) {
  :root {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  :root {
    font-size: 18px;
  }
}

body {
  min-height: 100%;
  overflow-x: hidden;
  background-color: #eff4f6;
  position: relative;
  scroll-behavior: smooth;
  --bs-body-color: #324552;
  --bs-font-sans-serif: Work Sans;
}

p {
  word-wrap: break-word;
  word-break: break-word;
}

a {
  text-decoration: none;
  cursor: pointer;
}

*::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5.5rem;
}
*::-webkit-scrollbar-thumb {
  background: rgba(50, 69, 82, 0.35);
  border-radius: 5.5rem;
}
*::-webkit-scrollbar-track {
  background: #f8fafb;
  border-radius: 5.5rem;
}
* {
  scrollbar-face-color: rgba(50, 69, 82, 0.35);
  scrollbar-track-color: #f8fafb;
}
* {
  scrollbar-color: rgba(50, 69, 82, 0.35) #f8fafb !important;
  scrollbar-width: thin;
}

body {
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes fontfix {
  from {
    opacity: 1;
  }
  to {
    opacity: 1;
  }
}
body {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.display-1 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.display-2 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.display-3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.display-4 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
}
.display-1 {
  font-size: 6rem;
  line-height: 6.875rem;
}
.display-2 {
  font-size: 5.5rem;
  line-height: 6.313rem;
}
.display-3 {
  font-size: 4.5rem;
  line-height: 5.188rem;
}
.display-4 {
  font-size: 3.5rem;
  line-height: 4.125rem;
}

h1,
.h1 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 3rem;
}

h2,
.h2 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.438rem;
}

h3,
.h3 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-bottom: 0.2rem;
}

h4,
.h4 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1.875rem;
}

h5,
.h5 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.625rem;
}

h6,
.h6 {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.3rem;
}

.lead {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.875rem;
}

p {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.375rem;
}

.text-helper {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.85rem;
  line-height: 1.4rem;
  letter-spacing: 0rem;
  color: #566976;
}

.label-xl {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: 0rem;
}

.label-lg {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0rem;
}

label .label-md {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}

.label-sm {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}

small {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.3125rem;
  letter-spacing: 0rem;
}

.note {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: var(--bs-gray-300);
  text-align: center;
  font-size: 0.75rem;
  margin-bottom: 0;
}
.note::before {
  content: "<-------";
}
.note::after {
  content: "-------->";
}

.form-label {
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-bottom: 0.375rem;
}

.required::after {
  content: " *";
  color: #dc3545;
  font-size: 0.875rem;
}

.form-text {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.form-control,
.form-select,
.form-control.mat-input-element {
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.25rem;
  caret-color: #0078ce;
  color: #324552;
  font-size: 1rem;
  padding-left: 0.25rem;
}
.form-control:focus,
.form-select:focus,
.form-control.mat-input-element:focus {
  border: 0.0625rem solid #0078ce;
  box-shadow: none !important;
  outline: none;
}
.form-control:disabled, .form-control:read-only,
.form-select:disabled,
.form-select:read-only,
.form-control.mat-input-element:disabled,
.form-control.mat-input-element:read-only {
  background: #f8fafb;
  border: 0.0625rem solid #dae2e8;
  color: #7d92a1;
}
.form-control::placeholder,
.form-select::placeholder,
.form-control.mat-input-element::placeholder {
  color: #7d92a1;
  font-weight: 400;
}
.form-control:not(textarea),
.form-select:not(textarea),
.form-control.mat-input-element:not(textarea) {
  height: 2.5rem;
}

.ng-select .ng-select-container {
  border: 0.0625rem solid #dae2e8;
}

.form-select.is-invalid,
.form-select.is-valid {
  background-image: url("../../assets/icons/Light/Large/ico-tab dropdown.svg") !important;
  background-size: 1.25rem !important;
}

.form-select {
  background-image: url("../../assets/icons/Light/Large/ico-tab dropdown.svg");
  background-size: 1.25rem;
}
.form-select option:disabled {
  color: #7d92a1;
}

.form-icon {
  position: relative;
}
.form-icon i, .form-icon > span {
  position: absolute;
  color: #324552;
  z-index: 1000;
}
.form-icon.icon-left input, .form-icon.icon-left igx-time-picker {
  padding-left: calc(1rem * 3);
}
.form-icon.icon-left i:not(.form-ngselect *), .form-icon.icon-left span:not(.form-ngselect *) {
  top: 50%;
  left: 1rem;
  transform: translateY(-51%);
}
.form-icon.icon-left > span {
  left: calc(1rem/2);
  color: #7d92a1;
}
.form-icon.icon-right span:not(.form-ngselect *) {
  right: calc(1rem/2);
}
.form-icon.icon-right input, .form-icon.icon-right igx-time-picker {
  padding-right: calc(1rem * 3);
}
.form-icon.icon-right i:not(.form-ngselect *), .form-icon.icon-right span:not(.form-ngselect *) {
  top: 50%;
  right: 0.5rem;
  transform: translate(-3px, -51%);
}
.form-icon.icon-right-2 .icon-end-2 {
  right: 2rem !important;
}
.form-icon.icon-right-2 .ng-value-container {
  padding-right: 1.625rem;
}

.form-cell .form-icon {
  width: fit-content;
}

.sticky-header .form-icon i {
  z-index: 0;
}

.form-icon2 {
  position: relative;
}
.form-icon2 .icon-left,
.form-icon2 .icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #7d92a1;
}
.form-icon2 .icon-left {
  left: 1rem;
}
.form-icon2 .icon-left ~ .form-control {
  padding-left: calc(1rem * 3);
}
.form-icon2 .icon-right {
  right: 1rem;
}
.form-icon2 .icon-right ~ .form-control {
  padding-right: calc(1rem * 3);
}

.form-check-input[type=checkbox] {
  margin-top: 0.25rem;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.form-check-input[type=radio] {
  width: 1.125rem !important;
  height: 1.125rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  margin-top: 0.188rem;
  border: 0.125rem solid #7d92a1;
  margin-right: 0.5rem;
}

.upload-btn {
  cursor: pointer;
  width: 100%;
  height: 8.125rem;
  background: #ffffff;
  border: 0.125rem dashed #dae2e8;
  box-sizing: border-box;
  border-radius: 1rem;
  background-image: url("/assets/images/ico-image.png");
  background-repeat: no-repeat;
  background-position: center 1.688rem;
  position: relative;
  background-size: 2rem;
}
.upload-btn.uploaded {
  background-image: none;
}
.upload-btn .upload-text {
  font-size: 0.875rem;
  position: absolute;
  left: 50%;
  bottom: 1rem;
  transform: translateX(-50%);
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
  text-align: center;
}
.upload-btn .upload-img {
  position: absolute;
  left: 50%;
  top: 1.5rem;
  width: 3.875rem !important;
  height: 3.875rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 0.5rem;
  transform: translateX(-50%);
  pointer-events: none;
  object-fit: contain;
}
.upload-btn .upload-delete {
  position: absolute;
  right: 0;
  top: 0.75rem;
  color: #324552;
  border-radius: 0.5rem;
  pointer-events: none;
  text-align: right;
  background-color: transparent;
}
.upload-btn .upload-delete i {
  pointer-events: all;
  padding: 0.938rem 1.063rem;
}

.form-control.is-valid, .form-control.is-invalid {
  background-image: none;
  padding-right: 0.25rem;
}
.form-control.is-valid .valid-feedback,
.form-control.is-valid .invalid-feedback, .form-control.is-invalid .valid-feedback,
.form-control.is-invalid .invalid-feedback {
  font-size: 0.875rem;
}
.form-control.is-valid {
  border-color: #28a745;
}
.form-control.is-valid ~ .valid-feedback {
  color: #28a745;
}
.form-control.is-invalid {
  border-color: #dc3545;
}
.form-control.is-invalid ~ .invalid-feedback {
  color: #dc3545;
}

.multiselect {
  height: 2.5rem;
}
.multiselect.ng-select-focused .ng-select-container, .multiselect.ng-select-opened .ng-select-container {
  border: 0.0625rem solid #0078ce !important;
  box-shadow: none !important;
  outline: none !important;
}
.multiselect .ng-select-container {
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.25rem;
  caret-color: #0078ce;
  color: #324552;
  font-size: 1rem;
  height: 2.5rem;
}
.multiselect .ng-select-container .ng-value-container {
  flex-wrap: initial !important;
  width: 3.125rem;
  overflow: hidden;
}
.multiselect .ng-select-container .ng-clear-wrapper {
  width: 1.875rem;
  text-align: center;
  z-index: 3;
}
.multiselect .ng-select-container .ng-arrow {
  border-color: #324552 transparent transparent;
}
.multiselect .ng-select-container .ng-placeholder {
  top: 50% !important;
  padding: 0rem !important;
  transform: translateY(-50%);
  color: #7d92a1;
  font-weight: 400;
}
.multiselect .ng-option {
  font-size: 0.875rem !important;
  padding: 0.5rem 0.625rem;
}
.multiselect .ng-option img {
  width: 1.5rem !important;
  height: 1.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 12.5rem;
  margin-right: 0.5rem;
  object-fit: contain;
}

.selected-values {
  padding-left: 0;
  float: left;
  margin-bottom: 0;
  list-style-type: none;
  width: inherit;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.25rem;
  width: 100% !important;
}
.selected-values .ng-value {
  float: left;
}
.selected-values .ng-value {
  background: #e4e8ea;
  border-radius: 1rem;
  padding: 0.25rem 0.5rem;
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}
.selected-values .ng-value img {
  background-color: #fff;
}
.selected-values .ng-value .ng-value-label {
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}
.selected-values .ng-value .ng-value-label img,
.selected-values .ng-value .ng-value-label span.avatar-circle {
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.3125rem;
  margin-left: -0.25rem;
}
.selected-values .ng-value .ng-value-label .d-inline-flex span {
  word-wrap: break-word !important;
  word-break: break-word !important;
}
.selected-values .ng-value .ng-value-icon {
  font-size: 0.875rem;
  text-align: end;
  width: 0.938rem !important;
  height: 0.938rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: initial;
  justify-content: end;
  align-items: center;
  margin-left: 0.375rem;
  cursor: pointer;
}

.multiselect .ng-value {
  background: #e4e8ea !important;
  border-radius: 12.5rem !important;
  padding: 0.25rem 0.5rem !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}
.multiselect .ng-value .ng-value-label {
  display: flex !important;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
  padding: 0 !important;
}
.multiselect .ng-value .ng-value-label img {
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}
.multiselect .ng-value .ng-value-icon {
  font-size: 1.25rem;
  text-align: end;
  width: 0.938rem !important;
  height: 0.938rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  width: 1.25rem !important;
  display: flex !important;
  padding: 0 !important;
  display: flex;
  flex-direction: initial;
  justify-content: end;
  align-items: center;
  margin-left: 0.375rem;
  cursor: pointer;
}

::not(.form-switch) .form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.66699L3.66667 8.33366L9.66667 1.66699' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-size: 0.688rem;
}

.form-check-input[type=checkbox] {
  border-radius: 0.25rem;
  border-color: #324552;
}

.form-check-input:disabled {
  border-color: #566976 !important;
  border-width: 0.0625rem;
}

.form-check-input:disabled:checked {
  background-color: #566976 !important;
}

.form-check-input:checked {
  background-color: #31aab7;
  border-color: #31aab7;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #31aab7;
}

.form-check-input:not(:disabled),
.form-check-input:not(:disabled) ~ .form-check-label {
  cursor: pointer;
}

.form-ngselect, .form-ngselect *,
app-master-select,
app-master-select * {
  cursor: pointer !important;
}
.form-ngselect .ng-select-container,
app-master-select .ng-select-container {
  height: 2.5rem !important;
  min-height: unset;
}
.form-ngselect input,
app-master-select input {
  caret-color: transparent;
}

.form-ngselect .ng-dropdown-panel {
  box-shadow: none !important;
}

.form-ngselect .ng-select-container,
app-master-select .ng-select-container,
.form-ngselect1 .ng-select-container {
  box-shadow: none !important;
}
.form-ngselect .ng-select-container:hover,
app-master-select .ng-select-container:hover,
.form-ngselect1 .ng-select-container:hover {
  box-shadow: none;
}
.form-ngselect .ng-select-container .ng-input,
app-master-select .ng-select-container .ng-input,
.form-ngselect1 .ng-select-container .ng-input {
  padding-left: 0.25rem !important;
}
.form-ngselect .ng-select-container .ng-value-container,
app-master-select .ng-select-container .ng-value-container,
.form-ngselect1 .ng-select-container .ng-value-container {
  padding-left: 0.25rem;
  position: relative;
  height: inherit;
}
.form-ngselect .ng-select-container .ng-value,
app-master-select .ng-select-container .ng-value,
.form-ngselect1 .ng-select-container .ng-value {
  width: 100%;
  width: -moz-available;
  /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available;
  /* Mozilla-based browsers will ignore this. */
  width: fill-available;
}
.form-ngselect :not(td) .form-ngselect .ng-input,
.form-ngselect :not(td) app-master-select .ng-input,
.form-ngselect :not(td) .form-ngselect1 .ng-input,
app-master-select :not(td) .form-ngselect .ng-input,
app-master-select :not(td) app-master-select .ng-input,
app-master-select :not(td) .form-ngselect1 .ng-input,
.form-ngselect1 :not(td) .form-ngselect .ng-input,
.form-ngselect1 :not(td) app-master-select .ng-input,
.form-ngselect1 :not(td) .form-ngselect1 .ng-input {
  padding-left: 0.25rem !important;
}
.form-ngselect .ng-placeholder,
app-master-select .ng-placeholder,
.form-ngselect1 .ng-placeholder {
  color: #7d92a1 !important;
}
.form-ngselect.is-invalid .ng-select-container,
app-master-select.is-invalid .ng-select-container,
.form-ngselect1.is-invalid .ng-select-container {
  border-color: #dc3545 !important;
}
.form-ngselect.ng-select-opened .ng-select-container, .form-ngselect:not(.is-invalid).ng-select-focused .ng-select-container,
app-master-select.ng-select-opened .ng-select-container,
app-master-select:not(.is-invalid).ng-select-focused .ng-select-container,
.form-ngselect1.ng-select-opened .ng-select-container,
.form-ngselect1:not(.is-invalid).ng-select-focused .ng-select-container {
  border-color: #0078ce !important;
  box-shadow: none !important;
}
.form-ngselect .ng-arrow-wrapper,
app-master-select .ng-arrow-wrapper,
.form-ngselect1 .ng-arrow-wrapper {
  padding-right: 0.5rem;
  width: 20px;
}
.form-ngselect .ng-arrow-wrapper .ng-arrow,
app-master-select .ng-arrow-wrapper .ng-arrow,
.form-ngselect1 .ng-arrow-wrapper .ng-arrow {
  border-color: #324552 transparent transparent;
  border-style: solid;
  border-width: 0.3125rem 0.3125rem 0.1563rem;
}
.form-ngselect.ng-select-opened > .ng-select-container .ng-arrow,
app-master-select.ng-select-opened > .ng-select-container .ng-arrow,
.form-ngselect1.ng-select-opened > .ng-select-container .ng-arrow {
  top: -0.125rem;
  border-color: transparent transparent #324552;
  border-width: 0 0.3125rem 0.3125rem;
}
.form-ngselect .ng-clear-wrapper,
app-master-select .ng-clear-wrapper,
.form-ngselect1 .ng-clear-wrapper {
  display: none;
}

.form-ngselect.ng-select-disabled .ng-select-container {
  background-color: transparent;
  border: 0.0625rem solid #dae2e8;
  color: #7d92a1;
}
.form-ngselect.ng-select-disabled .ng-arrow {
  border-color: #7d92a1 transparent transparent;
}

.form-ngdatepicker {
  display: block;
  width: 100%;
}
.form-ngdatepicker .dp-picker-input {
  width: 100% !important;
  height: 2.5rem !important;
  border: 0.0625rem solid #dae2e8;
  padding-left: 0.25rem;
  border-radius: 0.25rem;
  caret-color: #0078ce;
  color: #324552;
  font-size: 1rem !important;
}
.form-ngdatepicker .dp-open .dp-picker-input:focus-visible,
.form-ngdatepicker .dp-open .dp-picker-input:focus {
  border: 0.0625rem solid #0078ce;
}

.form-ngdatepicker.is-invalid .dp-picker-input {
  border: 0.0625rem solid #dc3545;
}

.form-ngselect-inline > *,
.form-ngselect-inline2 > * {
  box-shadow: none !important;
}
.form-ngselect-inline .ng-select-container,
.form-ngselect-inline2 .ng-select-container {
  border: none;
  background-color: transparent;
  min-height: fit-content;
  height: auto !important;
  border-radius: 0;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  top: 50%;
  transform: translateY(-50%);
  color: #324552;
}
.form-ngselect-inline .ng-clear-wrapper,
.form-ngselect-inline2 .ng-clear-wrapper {
  display: none !important;
}
.form-ngselect-inline .ng-input,
.form-ngselect-inline2 .ng-input {
  top: 0 !important;
}
.form-ngselect-inline.ng-select-opened .ng-select-container,
.form-ngselect-inline2.ng-select-opened .ng-select-container {
  border: none;
  background-color: transparent;
}
.form-ngselect-inline input,
.form-ngselect-inline2 input {
  caret-color: transparent;
}
.form-ngselect-inline .ng-arrow,
.form-ngselect-inline2 .ng-arrow {
  border: none !important;
  background-repeat: no-repeat;
  width: 0.625rem !important;
  height: 0.375rem !important;
  background-size: 0.625rem;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23324552' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  top: -0.0625rem;
}
.form-ngselect-inline.ng-select-opened > .ng-select-container .ng-arrow,
.form-ngselect-inline2.ng-select-opened > .ng-select-container .ng-arrow {
  top: -0.0625rem;
}

.form-ngselect-inline2 .ng-select-container {
  transform: none !important;
}

.input-group-combine .ng-select-container {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-right: none;
}

.input-group-combine .form-control {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-left: none;
}

.ngb-dp-day,
.ngb-dp-week-number,
.ngb-dp-weekday,
[ngbDatepickerDayView] {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

[ngbDatepickerDayView] {
  line-height: 2.5rem !important;
  border-radius: 50% !important;
  font-size: 0.75rem !important;
}

.ngb-dp-week-number,
.ngb-dp-weekday {
  line-height: 2.5rem !important;
  font-style: normal !important;
  font-size: 0.75rem !important;
}

ngb-datepicker-navigation-select > .custom-select {
  background-color: transparent !important;
  border: 0.0625rem solid #dae2e8 !important;
  border-radius: 0.25rem !important;
}

.ngb-dp-navigation-select {
  gap: 0.625rem !important;
}

.ngb-dp-navigation-chevron {
  border-width: 0.1em 0.1em 0 0 !important;
  width: 0.5em !important;
  height: 0.5em !important;
}

.ngb-dp-arrow .btn {
  border: 0.0625rem solid #e4e8ea;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.ngb-dp-arrow.btn-link {
  color: #31aab7 !important;
}

ngb-datepicker.dropdown-menu {
  padding: 1rem !important;
  border-color: #e4e8ea !important;
}

.ngb-dp-header {
  padding-top: 0 !important;
  padding-bottom: 1rem !important;
}

.ngb-dp-day .bg-primary {
  background: #0078ce !important;
}
.ngb-dp-day .btn-light:hover {
  background: rgba(0, 120, 206, 0.3) !important;
}

.ngb-dp-today .btn-light:not(.bg-primary) {
  color: #0078ce !important;
  font-weight: 500 !important;
}

.upload-doc {
  color: #0078ce;
  cursor: pointer;
}

.upload-doc:hover {
  color: #0078cecc;
}

.dropdown-toggle::after {
  content: none !important;
}

.upload-border {
  border: 0.125rem dashed #dae2e8;
  background: #ffffff;
  border-radius: 1rem;
  cursor: pointer;
}

.ng-dropdown-panel {
  z-index: 5000 !important;
}

.error-list {
  font-size: 0.875rem;
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 1.5rem;
  color: #324552;
}
.error-list li {
  position: relative;
  padding-top: 0.5rem;
}
.error-list li::before {
  content: "";
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 0.875rem;
  left: -1.5rem;
  background-repeat: no-repeat;
  background-size: 0.875rem;
}
.error-list li.valid::before {
  background-image: url("/assets/icons/svg/ico-tick-green.svg");
}
.error-list li.invalid::before {
  background-image: url("/assets/icons/svg/ico-warning-danger.svg");
}

.form-cell .form-ngselect1 .ng-value {
  background: #e4e8ea !important;
  border-radius: 12.5rem !important;
  padding: 0.25rem 0.5rem !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}
.form-cell .form-ngselect1 .ng-value .ng-value-label {
  display: flex !important;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
  padding: 0 !important;
}
.form-cell .form-ngselect1 .ng-value .ng-value-label img {
  object-fit: cover;
  border-radius: 50%;
  margin-right: 0.5rem;
  margin-left: -0.25rem;
}
.form-cell .form-ngselect1 .ng-value .ng-value-icon {
  font-size: 1.25rem;
  text-align: end;
  width: 0.938rem !important;
  height: 0.938rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  width: 1.25rem !important;
  display: flex !important;
  padding: 0 !important;
  display: flex;
  flex-direction: initial;
  justify-content: end;
  align-items: center;
  margin-left: 0.375rem;
  cursor: pointer;
}

.textarea-comment {
  background: #f8fafb;
  color: #7d92a1;
  padding: 1rem 1.5rem;
  font-size: 0.75rem;
}

.form-check-input.checkbox-16 {
  width: 1rem;
  height: 1rem;
}

.form-check label {
  cursor: pointer;
  word-break: break-word;
}

.form-check-input:disabled {
  cursor: not-allowed;
  pointer-events: revert;
  opacity: 0.5;
}

input:autofill {
  background: #fff;
  /* or any other */
}

.timepicker-backdrop-overlay {
  z-index: 1001 !important;
}

.timepicker-overlay {
  z-index: 1002 !important;
}

.cdk-overlay-container {
  z-index: 7000;
}

.form-timepicker {
  --focused-secondary-color: none !important;
  --focused-bottom-line-color: none !important;
  padding: 0;
}
.form-timepicker igx-input-group {
  border-bottom: none !important;
}
.form-timepicker igx-prefix,
.form-timepicker igx-suffix,
.form-timepicker .igx-input-group__border {
  display: none !important;
}
.form-timepicker .igx-input-group__bundle {
  padding-top: 0rem;
  box-shadow: none;
}
.form-timepicker .igx-input-group__bundle:hover, .form-timepicker .igx-input-group__bundle:focus, .form-timepicker .igx-input-group__bundle:active {
  box-shadow: none !important;
}
.form-timepicker .igx-input-group__input {
  height: 2.5rem;
  padding: 0.375rem 0.75rem 0.375rem 0.25rem;
  border-radius: 0.125rem !important;
  border-top: none !important;
}

.igx-overlay__content {
  min-width: 18.75rem !important;
}

.igx-input-group--invalid .igx-input-group__bundle {
  box-shadow: none !important;
}

.igx-time-picker {
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  width: 100% !important;
}
.igx-time-picker .igx-time-picker__main {
  padding: 1.5rem;
}
.igx-time-picker .igx-time-picker__body,
.igx-time-picker .igx-time-picker__buttons {
  padding: 0rem;
}
.igx-time-picker .igx-time-picker__item {
  font-size: 1rem;
  color: #7d92a1;
  height: 1rem;
}
.igx-time-picker .igx-time-picker__item:hover {
  color: #0078ce;
}
.igx-time-picker .igx-time-picker__item--selected {
  font-size: 1.625rem;
  color: #0078ce;
  border-radius: 0.25rem;
  width: 3.25rem;
  height: 2.75rem;
}
.igx-time-picker .igx-time-picker__item--selected:hover {
  background: #eff4f6;
}
.igx-time-picker .igx-time-picker__body {
  position: relative;
}
.igx-time-picker .igx-time-picker__body::after {
  content: "";
  position: absolute;
  height: 3.625rem;
  width: 100%;
  border-top: 0.0625rem dashed #acbac3;
  border-bottom: 0.0625rem dashed #acbac3;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}
.igx-time-picker .igx-time-picker__column {
  height: 15.875rem;
}
.igx-time-picker .igx-time-picker__buttons {
  height: unset;
  margin-top: 0.75rem;
  gap: 1rem;
}
.igx-time-picker .igx-time-picker__buttons .igx-button {
  width: 100%;
  height: 2.5rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  border-radius: 0.125rem;
  margin: 0rem !important;
}
.igx-time-picker .igx-time-picker__buttons :first-child {
  color: #7d92a1;
  border: 0.0625rem solid #7d92a1;
}
.igx-time-picker .igx-time-picker__buttons :first-child:hover {
  background-color: #acbac3;
  border-color: #acbac3;
  color: #fff;
}
.igx-time-picker .igx-time-picker__buttons :last-child {
  color: #fff;
  background-color: #31aab7;
  border-color: #31aab7;
}
.igx-time-picker .igx-time-picker__buttons :last-child:hover {
  background-color: #129aa6;
  border-color: #129aa6;
}

.btn .ng-select-container {
  border: none;
  background-color: transparent !important;
  min-width: 4.0625rem;
}
.btn .ng-arrow-wrapper {
  padding-right: 0rem;
  width: 1.25rem;
  height: 1.25rem;
}
.btn .ng-arrow-wrapper .ng-arrow {
  border: none;
  position: relative;
  width: 1.25rem !important;
  height: 1.25rem !important;
  top: 0rem !important;
}
.btn .ng-arrow-wrapper .ng-arrow::after {
  font-family: "icomoon" !important;
  content: "";
  left: 50%;
  top: 50% !important;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #324552;
  font-size: 0.625rem;
}
.btn .ng-value {
  line-height: 0.75rem;
  top: -0.0625rem;
  position: relative;
}
.btn .ng-value-label {
  font-size: 0.75rem;
  font-weight: 600;
}
.btn .ng-select-opened .ng-arrow::after {
  content: "";
}
.btn input {
  caret-color: transparent;
}
.btn .ng-option-label {
  font-size: 0.875rem;
}

.radio-group {
  display: block;
}
.radio-group .radio-check {
  display: none;
}
.radio-group .radio-content {
  border-width: 0.0625rem;
  transition: all ease-in 400ms;
}
.radio-group .radio-check:checked + .radio-content {
  position: relative;
  border: 0.0625rem solid #0078ce;
}
.radio-group .radio-check:checked + .radio-content::after {
  position: absolute;
  content: "";
  font-family: "icomoon";
  color: #fff;
  background-color: #0078ce;
  border-radius: 1.3125rem;
  right: 1rem;
  top: 1rem;
  width: 1.313rem !important;
  height: 1.313rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  font-size: 0.625rem;
  padding-top: 0.0625rem;
  transition: right 400ms ease-in-out;
}

.checkbox-22 .form-check-input {
  width: 1.375rem;
  height: 1.375rem;
  aspect-ratio: 1;
}

.checkbox-24 .form-check-input {
  width: 1.5rem;
  height: 1.5rem;
  margin-top: 0;
  background-size: 0.75rem;
  margin-right: 0.75rem;
}

.label-disabled {
  pointer-events: none;
  opacity: 0.6;
}

.check-btn-grp .form-check-btn {
  border: 0.0625rem solid #31aab7;
  border-radius: 3.125rem;
  min-width: 7rem;
  min-height: 2.25rem;
  cursor: pointer;
}
.check-btn-grp .form-check-input:checked + .form-check-btn {
  background: #31aab7;
  color: #fff;
}

.form-check {
  margin-bottom: 0;
}

.multiselect.is-invalid .ng-select-container {
  border-color: #dc3545 !important;
}

.form-ngselect .ng-value {
  display: flex;
}
.form-ngselect .ng-value .d-inline-flex {
  height: 2.5rem;
}
.form-ngselect .ng-value .d-inline-flex img.avatar-sm {
  background-color: unset;
  margin-left: 0.25rem;
}
.form-ngselect .ng-value .d-inline-flex span.avatar-sm {
  margin-left: 0.25rem;
}

.form-ngselect .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0.5rem 0.625rem;
}

.config-app-master .ng-select-opened {
  z-index: 1000;
  position: relative;
}

.config-container .ng-select-opened {
  z-index: 2000;
  position: relative;
}

.form-ngselect .ng-dropdown-panel-items .ng-option-label, .form-ngselect .ng-dropdown-panel-items .ng-option {
  font-size: 0.875rem;
}

.search-c2 input {
  border-bottom-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0rem;
  padding-top: 0;
  padding-bottom: 0rem;
  height: 1.875rem;
  font-size: 0.875rem;
}
.search-c2 input:focus {
  border-top: none;
  border-left: none;
  border-right: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.slider-input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 0.0625rem solid #dee3e7;
  border-radius: 0.5rem;
  min-height: 2.5rem;
  width: 100%;
  gap: 0.25rem;
  padding: 0 0.594rem;
}
.slider-input-container .slider-btn-left,
.slider-input-container .slider-btn-right {
  background-color: #eaedef;
  border-radius: 4.5625rem;
  width: 1.563rem !important;
  height: 1.563rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border: none;
  color: #324552;
  font-size: 0.625rem;
}
.slider-input-container .slider-input {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.slider-input-container .slider-input-value {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.563rem 0 !important;
}

.input-group1 {
  display: flex;
}
.input-group1 .divider {
  position: relative;
}
.input-group1 .divider:after {
  position: absolute;
  content: "";
  height: 1.25rem;
  width: 0.0625rem;
  background-color: #dae2e8;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
}
.input-group1 .ng-select:not(:last-child):not(.ng-select-opened) .ng-select-container,
.input-group1 .form-control:not(:last-child):not(:focus, .is-invalid) {
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-right: none;
  position: relative;
}
.input-group1 .ng-select:not(:last-child):not(.ng-select-opened) .ng-select-container::after,
.input-group1 .form-control:not(:last-child):not(:focus, .is-invalid)::after {
  position: absolute;
  content: "";
  width: 0.0625rem;
  height: 65%;
  right: 0;
  background-color: #dae2e8;
}
.input-group1 .ng-select:last-child:not(.ng-select-opened) .ng-select-container,
.input-group1 .form-control:last-child:not(:focus, .is-invalid) {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-left: none;
}

.ngselect-outline-primary.form-ngselect .ng-select-container {
  border-color: #31aab7 !important;
  background-color: transparent;
  color: #31aab7;
  font-size: 0.75rem;
  font-weight: 600;
}
.ngselect-outline-primary.form-ngselect .ng-arrow {
  border-color: #31aab7 transparent transparent;
}
.ngselect-outline-primary.ng-select-opened.ng-select-bottom .ng-arrow {
  border-color: transparent transparent #31aab7;
}

.form-ngselect1 .ng-value-container .ng-placeholder {
  top: unset !important;
}

.form-ngselect2 .ng-select-container {
  padding-left: 0.5rem;
}

.form-switch .form-check-input[type=checkbox] {
  border-radius: 2.5rem;
  height: 1.5rem;
  width: 2.875rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  background-size: 1.25rem;
}
.form-switch .form-check-input:not(:checked) {
  border-color: #7d92a1;
  background-color: #7d92a1;
}
.form-switch .form-check-input:checked {
  background-color: #31aab7;
  border-color: #31aab7;
}

.form-check-input:checked + .input-uniquebg {
  outline: 0.25rem solid rgba(54, 157, 232, 0.87);
  position: relative;
}
.form-check-input:checked + .input-uniquebg::after {
  content: "";
  font-size: 0.5rem;
  font-family: bootstrap-icons !important;
  background-color: #0078ce;
  width: 1rem !important;
  height: 1rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  right: -0.3125rem;
  top: -0.3125rem;
  color: #fff;
  padding-top: 0.0625rem;
}

.h-36 .ng-autocomplete,
.h-36 .autocomplete-container,
.h-36 input {
  height: 2.25rem !important;
}

.ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
  top: auto !important;
  display: flex;
}

.flag_div.is-invalid {
  border-color: #dc3545;
}

.form-control-2 {
  height: 38px;
  border-radius: 8px;
  background-color: #EFF4F6;
  border: 1px solid #DAE2E8;
}

.chat-input {
  position: relative;
}
.chat-input .form-control {
  border-radius: 0.25rem;
  border: 1px solid #c3cbd1;
  box-shadow: 0px 0.5rem 1rem 0px rgba(18, 27, 33, 0.1);
  font-size: 0.875rem;
  padding-left: 1.5rem;
  padding-right: 3.5rem;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  padding-top: 1rem;
  height: 3.4rem;
  overflow: auto !important;
}
.chat-input .form-control:focus {
  border: 0.0625rem solid #0078ce;
}
.chat-input .form-control::placeholder {
  color: #7d92a1;
}
.chat-input .form-control::-webkit-input-placeholder, .chat-input .form-control::-moz-input-placeholder, .chat-input .form-control:-moz-input-placeholder, .chat-input .form-control:-ms-input-placeholder {
  padding-top: 1.5rem;
}
.chat-input button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 1.5rem;
}

td ng-select:not(.form-ngselect1) .ng-value-container {
  padding-left: 0px !important;
}
td ng-select:not(.form-ngselect1) .ng-value-container .ng-placeholder,
td ng-select:not(.form-ngselect1) .ng-value-container .ng-value,
td ng-select:not(.form-ngselect1) .ng-value-container .ng-input {
  position: absolute;
  left: 0.5rem;
  padding-left: 0px !important;
}

.selected-values .ng-value-danger {
  background-color: #FAE9E9;
  border: 1px solid #DC3545;
}

.form-rangepicker {
  color: #324552;
  font-size: 0.938em;
  font-family: "Work Sans", sans-serif;
  position: relative;
}
.form-rangepicker input {
  caret-color: #0078ce;
}
.form-rangepicker .mat-form-field-wrapper {
  padding-bottom: 0;
}
.form-rangepicker .mat-form-field-underline {
  display: none;
}
.form-rangepicker .mat-form-field-infix {
  border-top: none;
  width: 100%;
  padding: 0px;
}
.form-rangepicker .mat-date-range-input {
  height: 2.5rem;
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.25rem;
  background-color: #fff;
  padding: 0.375rem 3rem 0.375rem 0.25rem;
}
.form-rangepicker .mat-date-range-input-container {
  height: 100%;
}
.form-rangepicker.mat-focused .mat-date-range-input {
  border: 0.0625rem solid #0078ce;
}
.form-rangepicker .mat-date-range-input-separator {
  color: #7d92a1;
}
.form-rangepicker i {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.form-rangepicker.is-invalid .mat-date-range-input {
  border-color: #dc3545;
}

.mat-input-element {
  background-color: #fff !important;
}

.form-check-inline {
  margin-right: 0;
}
.form-check-inline + .form-check-inline {
  margin-left: 1rem;
}

.form-ngselect-input {
  transition: all 1s ease-in;
}
.form-ngselect-input .form-ngselect {
  width: 7rem;
}
.form-ngselect-input .form-ngselect.ng-select-opened {
  width: 12rem;
}
.form-ngselect-input .form-ngselect:not(.ng-select-opened, :focus) .ng-select-container {
  border-right: transparent;
  border-radius: 4px 0 0 4px;
}
.form-ngselect-input .form-control:not(:focus) {
  border-left: transparent;
  border-radius: 0 4px 4px 0;
}

.card {
  border: none;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
}
.card .card-body {
  padding: 1rem;
}

.card-body + .card-body {
  border-top: 0.0625rem solid #e6ecee;
}

.card-hover .view-section {
  max-width: calc(100% - 1.625rem);
  width: 100%;
  padding: 1rem;
}
.card-hover .dropdown-section {
  width: 1.625rem;
  padding: 1rem 1rem 1rem 0;
  visibility: hidden;
}
.card-hover .dropdown-section .dropdown-toggle {
  font-size: 1rem;
  color: #7d92a1;
}
.card-hover .dropdown-section .dropdown-menu {
  font-size: 0.875rem;
}

.card-hover:hover .dropdown-section {
  visibility: visible;
}

.card-custom1 {
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  border: 0.0625rem solid #dee3e7;
  transition: transform 100ms ease-in-out;
}
.card-custom1 .card-body {
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}
.card-custom1 .card-body .logo-img {
  width: 3rem !important;
  height: 3rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 0.5rem;
  object-fit: contain;
}
.card-custom1 .card-body div.logo-img {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 500;
  background-color: #dff0d8;
  color: #324552;
}
.card-custom1 .card-body .user-img {
  width: 1.5rem !important;
  height: 1.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 0.125rem solid #fff;
  box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
  object-fit: cover;
}
.card-custom1 .card-body div.user-img {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  font-size: 0.688rem;
  font-weight: 500;
  background-color: #dff0d8;
  position: relative;
}
.card-custom1 .card-body div.user-img span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-custom1 .card-body .logo-img + div {
  margin-left: 1rem;
}
.card-custom1 .card-body .user-img + div {
  margin-left: 0.5rem;
}
.card-custom1 .card-body + .card-body {
  border-top: 0.0625rem solid #e6ecee;
}

.details-card {
  box-shadow: none;
  border: 0.0625rem solid #dee3e7;
}
.details-card .title {
  color: #324552;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  position: relative;
  display: block;
}
.details-card .title::after {
  content: "";
  width: 2.9375rem;
  position: absolute;
  height: 0.125rem;
  bottom: -0.5rem;
  background-color: black;
  left: 0;
}
.details-card .detail-title {
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #7d92a1;
}
.details-card .detail-title img {
  padding-left: 0.5rem;
}
.details-card .detail-desc {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #324552;
  display: flex;
  align-items: center;
  word-break: break-word;
}

.card-custom2 {
  box-shadow: none;
  border-radius: 0.25rem;
  border: 0.0625rem solid #e4e8ea;
  padding: 0;
  box-shadow: none;
  cursor: pointer;
  transition: transform 100ms ease-in-out;
}
.card-custom2 .card-body {
  padding: 1rem;
}
.card-custom2 .card-body.status {
  padding-right: 4.188rem;
  position: relative;
}
.card-custom2 .status-define, .card-custom2 .status.status-success:after, .card-custom2 .status.status-pending:after {
  content: "";
  width: 1.625rem !important;
  height: 1.625rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  position: absolute;
  top: 1rem;
  right: 1rem;
  z-index: 100;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.25rem;
}
.card-custom2 .status.status-pending:after {
  background-color: #ffc107;
}
.card-custom2 .status.status-success:after {
  background-size: 1.25rem;
  background-color: #28a745;
}

.status-icon {
  width: 1.25rem !important;
  height: 1.25rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.25rem;
  border-radius: 0.25rem;
  display: inline-block;
}
.status-icon.status-success {
  background-size: 1.25rem;
  background-color: #28a745;
}
.status-icon.status-pending {
  background-size: 0.875rem;
  background-color: #ffc107;
}

.clock-bg {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-clock-history' viewBox='0 0 16 16'%3E%3Cpath d='M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z'/%3E%3Cpath d='M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z'/%3E%3Cpath d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z'/%3E%3C/svg%3E");
}

.check-bg, .card-custom2 .status.status-success:after, .status-icon.status-success {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23fff' class='bi bi-check-lg' viewBox='0 0 16 16'%3E%3Cpath d='M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z'/%3E%3C/svg%3E");
}

.hourglass-bg, .card-custom2 .status.status-pending:after, .status-icon.status-pending {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23ffffff' class='bi bi-hourglass' viewBox='0 0 16 16'%3E%3Cpath d='M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z'/%3E%3C/svg%3E");
}

.card-hover:hover {
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  cursor: pointer;
}

.card-hover1:hover {
  box-shadow: 0rem 0.1rem 1rem rgba(18, 27, 33, 0.1);
  cursor: pointer;
}

.card-c2 {
  box-shadow: none;
  border: 0.0625rem solid #e4e8ea;
}
.card-c2 .card-body + .card-body {
  border-top: 0.0625rem solid #eaedef;
}

.card-c2.active {
  border: 0.125rem solid #0078ce;
}

.hr-1 {
  min-height: 0.083rem !important;
  height: 0.063rem !important;
  margin: 0;
  color: #eaedef;
  background-color: #eaedef;
  opacity: 1;
}

.card-c1 {
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.25rem;
  box-shadow: none;
}

.card-c3 {
  cursor: pointer;
  box-shadow: none;
  border: 0.0625rem solid #eaedef;
  border-radius: 0.25rem;
  text-align: center;
}
.card-c3 .date {
  flex-direction: column;
  max-height: 3.563rem;
}
.card-c3 .status {
  background: #eaedef;
  height: 1.375rem;
  color: #7d92a1;
}
.card-c3.current .status {
  color: #0066dd;
}
.card-c3.active {
  border: 0.0625rem solid #31aab7;
  position: relative;
}
.card-c3.active .status {
  background: #31aab7;
  color: #fff !important;
}
.card-c3.active::after {
  content: "";
  position: absolute;
  border-color: transparent transparent #31aab7 transparent;
  border-width: 0.6563rem;
  border-style: solid;
  top: 91%;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
}
.card-c3.success .status {
  color: #28a745;
}

.card-highlight {
  border-left-color: #e4e8ea;
  border-left-width: 0.25rem;
}

.card-highlight-top {
  border-top-color: #0078ce;
  border-top-width: 0.25rem;
}

.highlight-primary {
  border-left-color: #0078ce !important;
}

.highlight1 {
  border-left-color: #e0aaa2 !important;
}

.highlight2 {
  border-left-color: #e0bd75 !important;
}

.highlight3 {
  border-left-color: #96b2e0 !important;
}

.highlight4 {
  border-left-color: #88d5de !important;
}

.highlight5 {
  border-left-color: #80e0b4 !important;
}

.card-c4 {
  box-shadow: none;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.5rem;
}

.card-hide {
  display: none;
}
.card-hide.show {
  display: block;
}

.notification-new .notification-icon {
  position: relative;
}
.notification-new .notification-icon::before {
  content: "";
  position: absolute;
  background: #0078ce;
  width: 0.75rem !important;
  height: 0.75rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  right: 0rem;
  top: 0;
  border-radius: 50%;
}

.indicater, .indicater6, .indicater5, .indicater4, .indicater3, .indicater2, .indicater1 {
  position: relative;
}
.indicater::before, .indicater6::before, .indicater5::before, .indicater4::before, .indicater3::before, .indicater2::before, .indicater1::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0078ce;
  width: 0.25rem;
  height: 1.5rem;
}
.indicater:not(.card, .card-body)::before, .indicater6:not(.card, .card-body)::before, .indicater5:not(.card, .card-body)::before, .indicater4:not(.card, .card-body)::before, .indicater3:not(.card, .card-body)::before, .indicater2:not(.card, .card-body)::before, .indicater1:not(.card, .card-body)::before {
  left: -0.25rem;
}

.indicater.uniquebg1::before, .uniquebg1.indicater6::before, .uniquebg1.indicater5::before, .uniquebg1.indicater4::before, .uniquebg1.indicater3::before, .uniquebg1.indicater2::before, .uniquebg1.indicater1::before {
  background-color: #8ad7e0 !important;
}
.indicater.uniquebg2::before, .uniquebg2.indicater6::before, .uniquebg2.indicater5::before, .uniquebg2.indicater4::before, .uniquebg2.indicater3::before, .uniquebg2.indicater2::before, .uniquebg2.indicater1::before {
  background-color: #e0bd75 !important;
}
.indicater.uniquebg3::before, .uniquebg3.indicater6::before, .uniquebg3.indicater5::before, .uniquebg3.indicater4::before, .uniquebg3.indicater3::before, .uniquebg3.indicater2::before, .uniquebg3.indicater1::before {
  background-color: #e0aaa2 !important;
}
.indicater.uniquebg4::before, .uniquebg4.indicater6::before, .uniquebg4.indicater5::before, .uniquebg4.indicater4::before, .uniquebg4.indicater3::before, .uniquebg4.indicater2::before, .uniquebg4.indicater1::before {
  background-color: #c0afdb !important;
}
.indicater.uniquebg5::before, .uniquebg5.indicater6::before, .uniquebg5.indicater5::before, .uniquebg5.indicater4::before, .uniquebg5.indicater3::before, .uniquebg5.indicater2::before, .uniquebg5.indicater1::before {
  background-color: #96b2e0 !important;
}
.indicater.uniquebg6::before, .uniquebg6.indicater6::before, .uniquebg6.indicater5::before, .uniquebg6.indicater4::before, .uniquebg6.indicater3::before, .uniquebg6.indicater2::before, .uniquebg6.indicater1::before {
  background-color: #8be0b0 !important;
}
.indicater.uniquebg7::before, .uniquebg7.indicater6::before, .uniquebg7.indicater5::before, .uniquebg7.indicater4::before, .uniquebg7.indicater3::before, .uniquebg7.indicater2::before, .uniquebg7.indicater1::before {
  background-color: #d2a1e0 !important;
}
.indicater.uniquebg8::before, .uniquebg8.indicater6::before, .uniquebg8.indicater5::before, .uniquebg8.indicater4::before, .uniquebg8.indicater3::before, .uniquebg8.indicater2::before, .uniquebg8.indicater1::before {
  background-color: #bce080 !important;
}

.indicater1::before {
  top: 1rem;
  transform: none;
  height: 1.438rem;
}

.indicater2::before {
  height: 2.5rem;
}

.indicater3::before {
  height: 2.813rem;
}

.indicater4::before {
  height: 100%;
  border-radius: 1.25rem;
}

.indicater4::before {
  height: 100%;
  border-radius: 1.25rem 0 0 1.25rem;
  width: 0.3125rem;
}

.indicater5::before {
  height: 100%;
  width: 2px;
}

.indicater6 {
  padding-left: 10px !important;
}
.indicater6::before {
  width: 6px;
  height: 33px;
  border-radius: 6px;
}

.indicater-bg1::before {
  background-color: #0078ce !important;
}

.indicater-bg2::before {
  background-color: #c99b36 !important;
}

.indicater-bg3::before {
  background-color: #28a745 !important;
}

.indicater-bg4::before {
  background-color: #6562cf !important;
}

.indicater-bg5::before {
  background-color: #e0aaa2 !important;
}

.indicater-bg6::before {
  background-color: #e0bd75 !important;
}

.indicater-bg7::before {
  background-color: #80e0b4 !important;
}

.indicater-bg8::before {
  background-color: #96b2e0 !important;
}

.indicater-bg9::before {
  background-color: #88d5de !important;
}

.indicater-bg10::before {
  background-color: #31aab7 !important;
}

.indicater-warning::before {
  background-color: #ffc107 !important;
}

.indicater-success::before {
  background-color: #009722 !important;
}

.indicater-bg11::before {
  background-color: #566976 !important;
}

.indicater-bg12::before {
  background-color: #0066DD !important;
}

.card-c5 {
  flex-direction: row;
  overflow: hidden;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.25rem;
  box-shadow: none;
}
.card-c5 > .card-body:first-child {
  width: 5.625rem;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  min-height: 5.625rem;
}
.card-c5 > .card-body + .card-body {
  border-top: none;
}

.card-holiday {
  box-shadow: none;
  background: #fcfcfc;
  border: 0.0625rem solid #dee3e7;
  border-radius: 0.25rem;
  padding: 1rem 1rem;
  flex-direction: row;
}
.card-holiday .holiday-day {
  background: #fbefdd;
  border-radius: 0.25rem;
  width: 3.25rem !important;
  height: 3.25rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  margin-right: 1rem;
  flex-direction: column;
  line-height: 1.125rem;
  color: #000;
}
.card-holiday .card-date {
  width: 4.5rem !important;
  height: 4.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
}
.card-holiday .holiday-desc {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.card-holiday.active {
  border-color: #0066dd;
}

.card-holiday1 {
  display: flex;
  padding: 1rem;
  border: 0.0625rem solid #e4e8ea;
  box-shadow: none;
  border-radius: 0.25rem;
  gap: 0.5rem;
}
.card-holiday1 .holiday-type {
  display: flex;
  position: relative;
  padding-left: 1.75rem;
}
.card-holiday1 .holiday-type i {
  width: 1.25rem !important;
  height: 1.25rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  color: #fff;
  position: absolute;
  left: 0rem;
  background: #0078ce;
  border-radius: 4.3125rem;
}
.card-holiday1 .holiday-day {
  width: 100%;
  height: 2rem;
  border-radius: 0.625rem;
}

.national-holiday .holiday-day {
  background-color: var(--bg-national);
}

.floating-holiday .holiday-day {
  background-color: var(--bg-floating);
}

.regular-holiday .holiday-day {
  background-color: var(--bg-regular);
}

.card-holiday1.national-holiday i {
  background-color: var(--c-national);
}
.card-holiday1.national-holiday .holiday-day {
  background-color: var(--bg-national);
  color: var(--c-national);
}

.card-holiday1.floating-holiday i {
  background: var(--c-floating);
}
.card-holiday1.floating-holiday .holiday-day {
  background: var(--c-floating);
  color: var(--c-floating);
}

.card-img {
  border-radius: 0.25rem;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: top;
}

.card-hover:hover .img-hover {
  color: #fff;
  position: relative;
}
.card-hover:hover .img-hover::before {
  border-radius: 0.25rem;
  position: absolute;
  inset: 0;
  content: "";
  background: #7d92a1;
  opacity: 0.75;
  z-index: 1;
}
.card-hover:hover .img-hover .img-desc {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 0.75rem;
  z-index: 2;
}

.img-hover {
  width: 100%;
  height: 8.875rem;
  overflow: hidden;
  border-radius: 0.5rem;
  position: relative;
}

.card-hover .img-desc {
  display: none;
}

.card-img {
  position: absolute;
  top: 0;
  z-index: 0;
}

.card-c6 {
  position: relative;
}
.card-c6::after {
  position: absolute;
  content: "";
  font-family: "icomoon";
  color: #fff;
  background-color: #7d92a1;
  border-radius: 1.3125rem;
  right: 1rem;
  top: 1rem;
  width: 1.3125rem !important;
  height: 1.3125rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  font-size: 0.625rem;
  padding-top: 0.0625rem;
}

.horizontal-body {
  flex-direction: row;
}
.horizontal-body .card-body + .card-body {
  border-left: 0.0625rem solid #dee3e7;
  border-top: none;
}

.card-c7 {
  box-shadow: none;
  background: #f8fafb;
  /* Stroke */
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.25rem;
}
.card-c7.active {
  background-color: #eff4f6;
  position: relative;
}
.card-c7.active:before {
  position: absolute;
  content: "";
  background: #0078ce;
  border-radius: 0 0.188rem 0.188rem 0;
  width: 0.188rem;
  height: 0.938rem;
  top: 0.5rem;
  left: 0.063rem;
}

.highlight-top {
  border-top: 0.3125rem solid #0078ce;
}

.card-c2.success {
  position: relative;
}
.card-c2.success::after {
  position: absolute;
  content: "";
  font-family: "icomoon";
  color: #fff;
  background-color: #28a745;
  border-radius: 1.3125rem;
  right: 1rem;
  top: 1rem;
  width: 1.313rem !important;
  height: 1.313rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  font-size: 0.625rem;
  padding-top: 0.0625rem;
}

.form-card {
  max-width: 28.125rem;
  width: 100%;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 0.5rem;
}
.form-card input {
  border-radius: 0.5rem;
  height: 2.875rem;
}
.form-card .org-logo {
  width: 12.5rem !important;
  max-height: 3.125rem;
  object-fit: contain;
}

.card-cell {
  position: relative;
  height: 4.675rem;
}
.card-cell .card-body:first-child {
  border-bottom: 1px solid #96b2e0;
}
@media (min-width: 1440px) {
  .card-cell .card-body:first-child {
    border-bottom: 0.0625rem solid #96b2e0;
  }
}
.card-cell .card-body:nth-child(2) {
  border-width: 0px;
}
.card-cell.shift-night {
  background-color: #96b2e066;
}
.card-cell.shift-general {
  background-color: #6dcdd899;
}
.card-cell.shift-weekoff::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  border-color: transparent #dc3545 #dc3545 transparent;
  border-width: 0.4063rem;
  border-style: solid;
  z-index: 10;
}
.card-cell .card-body {
  border-color: #96b2e069 !important;
}

.card-cell-2 {
  position: relative;
  height: 4.5rem;
}
.card-cell-2 .time-event {
  white-space: normal !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.card-cell-2 .time-event .start-time,
.card-cell-2 .time-event .end-time,
.card-cell-2 .time-event .duration,
.card-cell-2 .time-event .overtime {
  padding-left: 1.125rem;
  color: #566976;
  white-space: nowrap;
  display: block;
  font-size: 0.688rem;
  line-height: 0.625rem;
  position: relative;
}
.card-cell-2 .time-event .start-time::before,
.card-cell-2 .time-event .end-time::before,
.card-cell-2 .time-event .duration::before,
.card-cell-2 .time-event .overtime::before {
  font-family: "icomoon" !important;
  position: absolute;
  color: #0078ce;
  font-size: 0.525rem;
}
.card-cell-2 .time-event .overtime {
  color: #e59e00;
}
.card-cell-2 .time-event .overtime::before {
  content: "";
  font-size: 0.625rem;
  color: #e59e00;
}
.card-cell-2 .time-event .start-time::before {
  content: "";
  left: 0;
}
.card-cell-2 .time-event .end-time::before {
  content: "";
  left: 0;
}
.card-cell-2 .time-event .duration::before {
  content: "";
  left: 0.25rem;
}

.card-lb .card-body {
  width: 4rem;
}

.bg-welcome-card {
  background: url(/assets/images/bg/semicircle.svg) bottom left, url(/assets/images/bg/semicircle1.svg) bottom right, url(/assets/images/bg/star.svg) 26% 20%, url(/assets/images/bg/star.svg) 61% 90%, url(/assets/images/bg/star.svg) 99.5% -25%, #0078ce;
  background-repeat: no-repeat;
  background-size: contain, auto, 1.25rem, 1.625rem, 3.25rem;
}

.bg-glass {
  background: rgba(243, 245, 248, 0.1);
  border: 1px solid rgba(231, 231, 231, 0.4);
  backdrop-filter: blur(3px);
}

.card-template {
  box-shadow: none;
  --bs-card-border-color: #dee3e7;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  height: 17rem;
  position: relative;
  transition: all 300ms ease-in-out;
  overflow: hidden;
}
.card-template img {
  object-fit: contain;
}
.card-template.active {
  --bs-card-border-color: #0078ce;
}
.card-template:hover {
  --bs-card-border-color: #0078ce;
}

.card-disable {
  background-color: rgba(255, 255, 255, 0.5);
  color: #566976;
}

.card-c8 {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 1px solid #E4E8EA;
  border-radius: 0.25rem;
}

.divider-c1 {
  position: relative;
  font-size: 0.625rem;
  color: #0078CE;
  text-align: center;
}
.divider-c1 > span {
  border: 1px solid #C3CBD1;
  border-radius: 4px;
  width: max-content;
  padding: 2px 4px;
  background-color: #fff;
  display: inline-flex;
  gap: 5px;
  align-items: center;
}
.divider-c1::before {
  content: "";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: #E4E8EA;
  top: 50%;
  left: 0;
  z-index: -1;
}

.btn {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  padding: 0 1rem;
  min-height: 2.5rem;
}
.btn:active, .btn:focus {
  box-shadow: none;
  outline: none;
}
.btn-primary {
  color: #fff;
  background-color: #31aab7 !important;
  border-color: #31aab7 !important;
}
.btn-primary:disabled {
  background-color: #6dcdd8cc !important;
  border-color: #6dcdd8cc !important;
}
.btn-primary:hover {
  background-color: #129aa6 !important;
  border-color: #129aa6 !important;
}
.btn-primary:active, .btn-primary:focus {
  background-color: #21717a !important;
  border-color: #21717a !important;
}
.btn-accent2 {
  color: #fff;
  background-color: #0078ce !important;
  border-color: #0078ce !important;
}
.btn-accent2:disabled {
  background-color: #0078cecc !important;
  border-color: #0078cecc !important;
}
.btn-accent2:hover {
  background-color: #0069b4 !important;
  border-color: #0069b4 !important;
}
.btn-accent2:active, .btn-accent2:focus {
  background-color: #005a9b !important;
  border-color: #005a9b !important;
}
.btn-outline-accent2 {
  color: #0078ce !important;
  border-color: #0078ce !important;
}
.btn-outline-accent2:disabled {
  border-color: #0078cecc !important;
  color: #0078cecc !important;
}
.btn-outline-accent2:hover {
  background-color: #0069b4 !important;
  border-color: #0069b4 !important;
  color: #fff !important;
}
.btn-outline-accent2:active, .btn-outline-accent2:focus {
  background-color: #005a9b !important;
  border-color: #005a9b !important;
  color: #fff !important;
}
.btn-secondary {
  color: #324552 !important;
  background-color: #eaedef !important;
  border-color: #eaedef !important;
}
.btn-secondary:hover {
  background-color: #acbac3 !important;
  border-color: #acbac3 !important;
}
.btn-secondary:active, .btn-secondary:focus {
  background-color: #acbac3 !important;
  border-color: #acbac3 !important;
}
.btn-outline-primary {
  color: #31aab7 !important;
  border-color: #31aab7 !important;
}
.btn-outline-primary:disabled {
  border-color: #6dcdd8cc !important;
  color: #6dcdd8cc !important;
}
.btn-outline-primary:hover {
  background-color: #129aa6 !important;
  border-color: #129aa6 !important;
  color: #ffffff !important;
}
.btn-outline-primary:focus {
  background-color: #31aab7 !important;
  border-color: #21717a !important;
  color: #fff !important;
}
.btn-outline-secondary {
  color: #566976 !important;
  border-color: #7d92a1 !important;
}
.btn-outline-secondary:hover {
  background-color: #acbac3 !important;
  border-color: #acbac3 !important;
}
.btn-outline-secondary:active, .btn-outline-secondary:focus {
  background-color: #acbac3 !important;
  border-color: #acbac3 !important;
  color: #fff !important;
}

.btn-warning-2 {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f38300;
  --bs-btn-border-color: #f38300;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #f38300d6;
  --bs-btn-hover-border-color: #f38300d6;
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #e2aa03;
  --bs-btn-active-border-color: #e2aa03;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #f38300;
  --bs-btn-disabled-border-color: #f38300;
}

.btn-white-accent1 {
  --bs-btn-color: #0078ce;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #479edc;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #1b8edf;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(255, 255, 255, 0.125);
  --bs-btn-disabled-color: #0078ce;
  --bs-btn-disabled-bg: #ffffffd9;
  --bs-btn-disabled-border-color: #ffffffd9;
}

.btn-square {
  width: 2.5rem !important;
  height: 2.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
}

.btn-icon i {
  margin-right: 0.5rem;
  font-size: 0.75rem;
}

.btn-add {
  font-size: 0.625rem;
  font-weight: 700;
  text-transform: uppercase;
}

.btn-sm {
  border-radius: 0.125rem;
  height: 2.5rem;
  font-size: 0.875rem;
  min-width: 8.75rem;
  font-weight: 600;
}

.btn-reset, .table-navigation .btn-left,
.table-navigation .btn-right {
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.btn-group .btn {
  white-space: nowrap;
}

.btn-check:checked + .btn-outline-accent2 {
  color: #fff !important;
  background-color: #0078ce;
  border-color: #0078ce;
}

.btn-check:focus + .btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn-outline-primary.disabled {
  background-color: #6dcdd8cc;
  border-color: none;
  color: #fff;
}

.search-btn {
  cursor: text !important;
}

.btn-c1 {
  border: 0.063rem solid #e4e8ea;
  border-radius: 0.25rem;
  cursor: pointer;
  height: 2.5rem;
  min-width: 3rem;
  background-color: #ffffff;
  padding: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
}
.btn-c1.active {
  background-color: #0078ce;
  border-color: #0078ce;
  color: #ffffff;
}
.btn-c1:hover {
  background-color: #eff4f6;
  border-color: #e4e8ea;
  color: #324552;
}

.btn-white {
  --bs-btn-color: #324552;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #dee3e7;
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-hover-border-color: #dee3e7;
  --bs-btn-hover-color: #324552;
}

.popover {
  z-index: 9999;
}

.tips {
  position: relative;
}

.tips-helper {
  transform: scale(0);
  transform-origin: top left;
  position: absolute;
  background: #ffffff;
  border-radius: 0.25rem;
  max-width: 12.5rem;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  filter: drop-shadow(0rem 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
  transition: 300ms transform ease-in;
  transition-delay: 0.1s;
  z-index: 999;
}

.tips:hover + .tips-helper {
  transform: scale(1);
}

.profile-popover {
  padding: 0;
  color: unset;
  width: 13.75rem;
  background-color: transparent;
  border: none;
  top: 1rem !important;
  left: -4.5rem !important;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
}
.profile-popover .popover-body {
  padding: 0;
}
.profile-popover .profile-link {
  cursor: pointer;
  border-radius: 0.5rem;
}
.profile-popover .profile-link:hover {
  background: #eaedef80;
}

.error-popover {
  border: none;
  padding: 0.5rem 1.5rem 1rem;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
  left: 0.625rem !important;
}
.error-popover .popover-body {
  padding: 0;
}
.error-popover .arrow {
  width: 0.625rem;
  height: 0.625rem;
  border-color: transparent #fff transparent transparent;
  border-style: solid;
  border-width: 0.625rem;
  left: -1.125rem;
  top: 1.5rem;
  position: absolute;
  top: 53% !important;
  transform: translateY(-50%);
}

.popover-input {
  padding: 0.5rem 0.75rem;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 0.125rem;
  font-size: 0.875rem;
  background-color: #fff;
  border-color: #566976;
}
.popover-input .popover-body {
  padding: 0;
}
.popover-input .popover-body {
  color: #566976;
}

.popover-md {
  --bs-popover-max-width: 21.875rem;
}

.popover-default {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
  border-radius: 0.25rem;
  border: none;
}
.popover-default .popover-body {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  color: #324552;
}
.popover-default.popover-c2 {
  background-color: #566976;
}
.popover-default.popover-c2 .arrow {
  background-color: #566976;
}
.popover-default.popover-c2 .popover-body {
  color: #fff;
}
.popover-default.popover-alert {
  background-color: #dc3545;
}
.popover-default.popover-alert .arrow {
  background-color: #dc3545;
}
.popover-default.popover-alert .popover-body {
  color: #fff;
}

.popover-type1 {
  z-index: 2002;
  border: none;
  box-shadow: 0rem 1.5rem 8rem 0rem rgba(18, 27, 33, 0.4);
}
.popover-type1 .arrow {
  position: absolute;
  top: 50%;
  margin-top: unset !important;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #566976;
  transform: translateY(-50%) rotateZ(45deg);
}
.popover-type1.bs-popover-right {
  left: 0.438rem !important;
}
.popover-type1.bs-popover-right .arrow {
  left: -0.25rem;
}
.popover-type1.bs-popover-left .arrow {
  left: calc(100% - 0.25rem);
}
.popover-type1 .popover-body {
  padding: 0 1rem;
  color: #ffffff;
  background-color: #566976;
  height: 2.125rem;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  min-width: 6.5rem;
  justify-content: center;
  border-radius: 0.25rem;
}

.popover {
  z-index: 9999;
}

.user-list {
  border-radius: 0.25rem;
}
.user-list.single-user .popover-body {
  width: 11.563rem;
}
.user-list.multi-user .popover-body {
  width: 15.813rem;
  max-height: 14.688rem;
}
.user-list .popover-body {
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  overflow-x: hidden;
  overflow-y: auto;
}
.user-list .popover-body::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 5.5rem;
}
.user-list .popover-body::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 5.5rem;
}
.user-list .popover-body::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5.5rem;
}
.user-list .popover-body {
  scrollbar-face-color: #fff;
  scrollbar-track-color: #fff;
}
.user-list .popover-body {
  scrollbar-color: #fff #fff !important;
  scrollbar-width: thin;
}
.user-list .popover-body:hover::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 5.5rem;
}
.user-list .popover-body:hover::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 5.5rem;
}
.user-list .popover-body:hover::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 5.5rem;
}
.user-list .popover-body:hover {
  scrollbar-face-color: #dfdfdf;
  scrollbar-track-color: #fff;
}
.user-list .popover-body:hover {
  scrollbar-color: #dfdfdf #fff !important;
  scrollbar-width: thin;
}
.user-list .popover-body ul {
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
}
.user-list .popover-body ul li {
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
}
.user-list .popover-body ul li + li {
  border-top: 0.0625rem solid #eaedef;
}
.user-list .popover-body .user-details {
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}
.user-list .popover-body .user-avatar {
  width: 2.125rem !important;
  height: 2.125rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  margin-right: 0.625rem;
  box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
}
.user-list .popover-body span.user-avatar {
  color: #fff;
  color: #324552;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.user-list .popover-body .user-label {
  font-size: 0.875rem;
  font-weight: 500;
  word-break: break-word;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.user-list .popover-body .user-code {
  font-size: 0.625rem;
  color: #7d92a1;
  text-transform: uppercase;
}

.draggable-popup {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 19.063rem;
  border-radius: 0.5rem;
  z-index: 9999;
  cursor: move;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
}
.draggable-popup.popup-primary {
  background-color: #e3ecf7;
  height: 6.313rem;
}
.draggable-popup.popup-success {
  background-color: #e6f5e0;
  height: 7.75rem;
}
.draggable-popup.popup-danger {
  background-color: #fae9e9;
  height: 7.75rem;
}

.popover-c2 {
  --bs-popover-max-width: 21.75rem;
}
.popover-c2 .popover-body {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.popover .arrow {
  width: 0.5rem;
  height: 0.5rem;
  position: absolute;
  margin: unset !important;
  transform: translate(-50%, -50%) rotate(45deg);
}
.popover.bs-popover-bottom {
  top: 0.313rem !important;
}
.popover.bs-popover-bottom .arrow {
  left: 50%;
  top: 0%;
}
.popover.bs-popover-top {
  top: -0.313rem !important;
}
.popover.bs-popover-top .arrow {
  left: 50%;
  top: 100%;
}
.popover.bs-popover-start, .popover.bs-popover-right {
  left: 0.438rem !important;
}
.popover.bs-popover-start .arrow, .popover.bs-popover-right .arrow {
  top: 50%;
  left: 0;
}
.popover.bs-popover-end, .popover.bs-popover-left {
  left: -0.438rem !important;
}
.popover.bs-popover-end .arrow, .popover.bs-popover-left .arrow {
  top: 50%;
  left: 100%;
}

.popover-info {
  background-color: #566976;
}
.popover-info .arrow {
  background-color: #566976;
}
.popover-info .popover-body {
  color: #fff;
}

.side-panel {
  position: fixed;
  top: 0;
  right: -1000vw;
  bottom: 0;
  z-index: 1600;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: initial;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
  transition: right 900ms cubic-bezier(0.73, 0.96, 0, 1.01);
  margin-left: 3.75rem;
}
.side-panel.side-pane-active {
  right: 0;
}
.side-panel-container {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  flex: 1;
  width: var(--sidepanel-width);
  background-color: #f8fafb;
  background-color: #eff4f6;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  z-index: 1002;
}
.side-panel-head, .side-panel-footer {
  background-color: #ffffff;
  box-shadow: 0rem 0.0625rem 0.0625rem 0rem #6970751a;
}
.side-panel-head {
  padding: 1rem 2rem;
  height: 3.625rem;
  border-top-left-radius: 0.5rem;
  border-bottom: 0.0625rem solid #eaedef;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
}
.side-panel-head h5 {
  margin-bottom: 0;
  font-weight: 400;
  white-space: nowrap;
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.side-panel-head .toggle-panel {
  color: #324552;
}
.side-panel-head .toggle-panel:hover {
  opacity: 0.8;
}
.side-panel-body {
  height: calc(100vh - 3.625rem);
}
.side-panel-footer {
  border-bottom-left-radius: 0.5rem;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .side-panel-container {
    max-width: 48rem;
    width: 100%;
  }
}
@media only screen and (max-width: 945px) {
  .side-panel-container {
    max-width: 59.0625rem;
    width: 100%;
  }
}
.notification-panel {
  max-height: 100vh;
}

.side-panel.side-pane-active::after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  pointer-events: auto;
  background-color: #000000aa;
  z-index: 1000;
}

.change-theme-sidepanel {
  z-index: 9000;
}

.nesteded-config {
  width: 100% !important;
}
.nesteded-config .config-layout {
  margin-left: auto;
}
.nesteded-config .config-container {
  top: 3rem;
  min-height: calc(100vh - 3rem);
}

.main-sidebar.expand ~ .main-container .nesteded-config .config-header,
.main-sidebar.expand ~ .main-container .nesteded-config .config-layout {
  width: calc(100% - 15rem);
}

.main-sidebar:not(.expand) ~ .main-container .nesteded-config .config-header,
.main-sidebar:not(.expand) ~ .main-container .nesteded-config .config-layout {
  width: calc(100% - 3.75rem);
}

.drop-down {
  position: relative;
  z-index: 100;
}
.drop-down-menu {
  position: absolute;
  margin-top: 0.313rem;
  background-color: #ffffff;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  padding: 0rem;
  border-radius: 0.25rem;
  min-width: 10rem;
}
.drop-down-menu-start {
  left: 0;
}
.drop-down-menu-end {
  right: 0;
}
.drop-down-item {
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
.drop-down-item:hover {
  background-color: #f9f9f9;
}
.drop-down-item .item-image {
  width: 1.5rem !important;
  height: 1.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  object-fit: cover;
  border-radius: 0.25rem;
}
.drop-down-item div.item-image {
  background-color: #b8d0ec;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}
.drop-down-item .item-text {
  font-size: 0.875rem;
  color: #324552;
}
.drop-down-item .item-image + .item-text {
  padding-left: 0.5rem;
}
.drop-down-menu {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transform: translateY(15%);
  transition: transform 250ms ease-in, opacity 200ms ease-in;
}
.drop-down-menu.search-result {
  left: 0;
  right: 0;
  max-height: 13.5rem;
  overflow-y: hidden;
}
.drop-down-menu.search-result:hover {
  overflow-y: auto;
}
.drop-down .search-result.active {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  pointer-events: auto;
}

.drop-down:hover .drop-down-toggle + .drop-down-menu:not(.search-result),
.drop-down-toggle:focus + .drop-down-menu:not(.search-result),
.drop-down-toggle:hover + .drop-down-menu:not(.search-result) {
  visibility: visible;
  opacity: 1;
  transform: translateY(0%);
  pointer-events: auto;
}

.dropdown-menu {
  padding: 0rem;
  border: none;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 0.25rem;
}
.dropdown-menu .dropdown-item {
  padding: 0.75rem;
  border-radius: 0rem;
  color: #324552;
  display: flex;
  align-items: center;
  position: relative;
}
.dropdown-menu .dropdown-item.active {
  padding-right: 2.125rem !important;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #eff4f6;
}
.dropdown-menu .dropdown-item.active:not(:hover) {
  background: rgba(0, 120, 206, 0.1);
  color: #0078ce;
}
.dropdown-menu .dropdown-item.active::after {
  content: "";
  font-family: "icomoon" !important;
  color: #0078ce;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  right: 0.75rem;
  font-size: 0.75rem;
}
.dropdown-menu .dropdown-item:first-child {
  border-radius: 0.25rem 0.25rem 0 0;
}
.dropdown-menu .dropdown-item:last-child {
  border-radius: 0 0 0.25rem 0.25rem;
}
.dropdown-menu.bottom-left {
  left: unset !important;
  right: 0 !important;
}

.dropdown-menu-end {
  right: 0;
  left: unset !important;
}

.filter-dropdown {
  left: -20rem !important;
  position: absolute !important;
}

.dropdown-item .form-check-input {
  margin-left: 0rem;
}

.no-item-image .item-image {
  display: none !important;
}

.input-dropdown {
  position: relative;
}
.input-dropdown.dropdown:not(.show) .dropdown-menu, .input-dropdown.dropup:not(.show) .dropdown-menu {
  display: none;
}
.input-dropdown::after {
  content: "";
  position: absolute;
  right: 0.875rem;
  top: 55%;
  transform: translateY(-50%);
  border-color: #324552 transparent transparent;
  border-style: solid;
  border-width: 0.3125rem 0.3125rem 0.1563rem;
}
.input-dropdown .dropdown-toggle {
  height: 2.5rem;
  padding-right: 1.75rem;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  color: #324552;
}
.input-dropdown .dropdown-toggle.is-invalid {
  border-color: #dc3545;
}
.input-dropdown .dropdown-toggle .btn-placeholder {
  color: #7d92a1;
}
.input-dropdown .btn-placeholder {
  color: #7d92a1;
}
.input-dropdown .dropdown-menu {
  box-shadow: none;
  border: 0.0625rem solid #dae2e8;
}

.input-dropdown:focus-visible, .input-dropdown:focus,
.dropdown-toggle:focus-visible,
.dropdown-toggle:focus {
  outline: none;
  border: 0.0625rem solid #0078ce;
}

.four-dropdown .ng-dropdown-panel-items {
  max-height: calc(2.1875rem * 4) !important;
}

.dropdown-scroll {
  max-height: 300px;
  overflow: auto;
}

.input-dropdown .dropdown-toggle:disabled {
  background-color: transparent;
  border: 0.0625rem solid #dae2e8;
  color: #7d92a1;
}

.dropdown-item.disabled {
  background-color: #f5f5f5;
  color: #7d92a1;
}

@-webkit-keyframes animate-rotate /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes animate-rotate {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.animate-rotate {
  -webkit-animation: animate-rotate 1s linear infinite;
  -moz-animation: animate-rotate 1s linear infinite;
  -ms-animation: animate-rotate 1s linear infinite;
  -o-animation: animate-rotate 1s linear infinite;
  animation: animate-rotate 1s linear infinite;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dot-pulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #31aab7;
  color: #31aab7;
  box-shadow: 9999px 0 0 -5px #31aab7;
  animation: dotPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #31aab7;
  color: #31aab7;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px #31aab7;
  animation: dotPulseBefore 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px #31aab7;
  animation: dotPulseAfter 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotPulseBefore {
  0% {
    box-shadow: 9984px 0 0 -5px #31aab7;
  }
  30% {
    box-shadow: 9984px 0 0 2px #31aab7;
  }
  60%, 100% {
    box-shadow: 9984px 0 0 -5px #31aab7;
  }
}
@keyframes dotPulse {
  0% {
    box-shadow: 9999px 0 0 -5px #31aab7;
  }
  30% {
    box-shadow: 9999px 0 0 2px #31aab7;
  }
  60%, 100% {
    box-shadow: 9999px 0 0 -5px #31aab7;
  }
}
@keyframes dotPulseAfter {
  0% {
    box-shadow: 10014px 0 0 -5px #31aab7;
  }
  30% {
    box-shadow: 10014px 0 0 2px #31aab7;
  }
  60%, 100% {
    box-shadow: 10014px 0 0 -5px #31aab7;
  }
}
.wave-loader {
  gap: 0.625rem;
}
.wave-loader .wave {
  width: 8px;
  height: 56px;
  animation: wave 1s linear infinite;
  border-radius: 1.25rem;
}
.wave-loader .wave:nth-child(odd) {
  background-color: #31aab7;
}
.wave-loader .wave:nth-child(even) {
  background-color: #0078ce;
}
.wave-loader .wave:nth-child(2) {
  animation-delay: 0.1s;
}
.wave-loader .wave:nth-child(3) {
  animation-delay: 0.2s;
}
.wave-loader .wave:nth-child(4) {
  animation-delay: 0.3s;
}
.wave-loader .wave:nth-child(5) {
  animation-delay: 0.4s;
}
.wave-loader .wave:nth-child(6) {
  animation-delay: 0.5s;
}

@keyframes wave {
  0% {
    transform: scaleY(0.5);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.5);
  }
}
lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  width: 0.8125rem;
  height: 0.8125rem;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
.vetical-tab {
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  display: block !important;
}
.vetical-tab .tab-link {
  position: relative;
  height: 2.5rem;
  display: block;
  line-height: 2.5rem;
  padding: 0 1.5rem;
  font-size: 0.875rem;
  color: #566976;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.vetical-tab .tab-link.success {
  color: #28a745;
  padding-right: 1.875rem !important;
}
.vetical-tab .tab-link.success::after {
  content: "";
  font-family: "icomoon" !important;
  color: #28a745;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  position: absolute;
  right: 0.75rem;
  font-size: 0.75rem;
  top: 50%;
  transform: translateY(-50%);
}
.vetical-tab .tab-link.active {
  font-weight: 500;
}
.vetical-tab .tab-link.active::before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 1.25rem;
  left: 0rem;
  background-color: #0078ce;
  border-radius: 0 1rem 1.5rem 0rem;
  top: 50%;
  transform: translateY(-50%);
}
.vetical-tab .tab-link:hover {
  background-color: #c0ced733;
}

.vetical-tab .tab-link.active {
  color: #0078ce;
  background: #0078ce1c;
}
.vetical-tab .tab-link:hover {
  background-color: #c0ced733;
}

.vetical-tab-inverted .tab-link.active {
  color: #0078ce;
  background-color: #fff;
}
.vetical-tab-inverted .tab-link:hover {
  background-color: #ffffff21;
}

.tabs {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.tabs li {
  float: left;
}

.tab-pill .pill {
  background: #f8fafb;
  border: 0.0625rem solid #c7d0d4;
  border-radius: 0.25rem;
  height: 1.5rem;
  padding: 0.188rem 0.5rem;
  font-size: 0.75rem;
  color: #324552;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
}
.tab-pill .pill:hover:not(.success, .active) {
  border-color: #0078ce;
  color: #0078ce;
}
.tab-pill .pill.active {
  background: #0078ce;
  border-color: #0078ce;
  color: white;
  font-weight: 600;
}
.tab-pill .pill.active:hover {
  border-color: #0078ce;
  background: transparent;
  color: #0078ce;
}
.tab-pill .pill.success:not(.active) {
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='16' height='16' rx='8' fill='%2328A745'/%3E%3Cpath d='M5.6001 8.4L7.2001 10L10.8001 6' stroke='white' stroke-width='0.8' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 1rem;
  padding-left: 2rem;
  background-position-y: center;
  background-position-x: 0.5rem;
}
.tab-pill .pill.success:not(.active):hover {
  border-color: #28a745;
  color: #28a745;
}

.nav-custom {
  background-color: #ffffff;
  min-height: 2.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  box-shadow: 0.25rem 0 0.3125rem rgba(0, 0, 0, 0.08);
}
.nav-custom.sticky {
  position: sticky;
  top: 0rem;
  z-index: 888;
}
.nav-custom .nav-link {
  font-size: 0.785rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  color: #566976;
  border-top: 0.125rem solid transparent;
  border-bottom: 0.125rem solid transparent;
  transition: border-bottom 200ms ease-in-out;
  height: 2.5rem;
  min-width: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50ch;
  overflow: hidden;
  display: block;
  text-align: center;
}
.nav-custom .nav-link + .nav-link {
  margin-left: 1.5rem;
}
.nav-custom .nav-link:hover {
  background-color: #f8fafb;
}
.nav-custom .nav-link.active {
  font-weight: 500;
  color: #0078ce;
  border-bottom-color: #0078ce;
}
.nav-custom.nav-inline {
  flex-wrap: unset;
}
.nav-custom.nav-inline .nav-link {
  flex: 1;
  text-align: center;
}

.top-48 {
  top: 3rem !important;
}

.nav-c2 {
  counter-reset: item;
  justify-content: space-between;
  width: 100%;
  flex-wrap: nowrap;
}
.nav-c2 li {
  width: 100%;
}
.nav-c2 li:last-child {
  width: 1.5rem;
  aspect-ratio: 1;
}
.nav-c2 li:not(:last-child) a::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e4e8ea;
  top: 50%;
  transform: translateY(-50%);
  left: calc(1.5rem / 2);
  z-index: 9;
}
@media (min-width: 1440px) {
  .nav-c2 li:not(:last-child) a::after {
    height: 0.0625rem;
  }
}
.nav-c2 li a {
  height: 1.5rem;
  width: 100%;
  padding: 0;
  position: relative;
}
.nav-c2 li a::before {
  content: counter(item) "";
  counter-increment: item;
  position: absolute;
  width: 1.5rem;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 50%;
  aspect-ratio: 1;
  font-size: 0.875rem;
  font-weight: 500;
  color: #e4e8ea;
  transition: all ease-in 400ms;
  left: 0;
  z-index: 10;
  background: #fff;
  pointer-events: none;
}
.nav-c2 li a.success::before {
  content: "";
  font-family: "icomoon" !important;
  color: #fff;
  background-color: #009722;
  border-color: #009722;
  font-size: 0.625rem;
}
.nav-c2 li a.success::after {
  background-color: #009722 !important;
}
.nav-c2 li a.active::before {
  background-color: #0078ce;
  border-color: #0078ce;
  color: #fff;
}

.tab-pane {
  height: 100%;
}

.multilevel-tab,
.multilevel-tab ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.multilevel-tab a {
  font-size: 0.75rem;
  padding: 0 1.5rem;
  white-space: nowrap;
  display: flex;
  position: relative;
}
.multilevel-tab a:hover {
  background: #0078ce1a;
}
.multilevel-tab a :last-child:not(:first-child) {
  width: 4ch;
  text-align: right;
  white-space: nowrap;
  margin-left: 0.5rem;
}
.multilevel-tab a :first-child {
  width: calc(100% - 5ch);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multilevel-tab a.active {
  background: #0078ce1a;
  color: #0078ce !important;
  font-weight: 600;
  position: relative;
}
.multilevel-tab a.active::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translateY(-50%);
  border-right: 0.25rem solid #0078ce;
  height: 50%;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  font-weight: 400;
}
.multilevel-tab > li a {
  font-weight: 600;
  line-height: 2.5rem;
  height: 2.5rem;
}
.multilevel-tab li.hasSubmenu > a {
  padding-right: 2.5rem;
}
.multilevel-tab li.hasSubmenu > a::after {
  content: "";
  font-family: "icomoon";
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.5rem;
  font-weight: 400;
}
.multilevel-tab .sub-tab {
  border-bottom: 1px solid #ACBAC3;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.multilevel-tab .sub-tab > li a {
  font-weight: 500;
  line-height: 2rem;
  height: 2rem;
  color: #566976;
  padding-left: 2rem;
}

.modal, .note-modal {
  z-index: 7000 !important;
}
.modal.show, .note-modal.show {
  display: block;
}
.modal::after, .note-modal::after {
  content: "";
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  pointer-events: none;
  background-color: #000000aa;
  z-index: -1;
}

.modal-custom .modal-dialog:not(.modal-lg),
.modal-alert .modal-dialog:not(.modal-lg) {
  --bs-modal-width: 31rem;
}
.modal-custom .modal-content,
.modal-alert .modal-content {
  border: none;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 1.5rem;
}
.modal-custom .modal-title,
.modal-alert .modal-title {
  line-height: 1.875rem;
}
.modal-custom .modal-header,
.modal-alert .modal-header {
  padding: 1.5rem 1.5rem 1rem 2rem;
  justify-content: start;
  border: none;
  gap: 1rem;
}
.modal-custom .modal-header i,
.modal-alert .modal-header i {
  font-size: 1.25rem;
}
.modal-custom .modal-body,
.modal-alert .modal-body {
  padding: 1rem 2rem;
}
.modal-custom .modal-footer,
.modal-alert .modal-footer {
  border: none;
  padding: 1.5rem 2rem;
  justify-content: center;
  gap: 1rem;
}
.modal-custom .modal-footer > *,
.modal-alert .modal-footer > * {
  margin: 0;
}

.modal-custom1 .modal-dialog {
  max-width: 43.1875rem !important;
}
.modal-custom1 .modal-content {
  border-radius: 0.5rem !important;
}
.modal-custom1 .modal-body {
  background: #f8fafb !important;
  border-radius: 0 0 0.5rem 0.5rem !important;
}

.qr-modal .modal-dialog {
  max-width: 24.6875rem !important;
}
.qr-modal .modal-header {
  border-bottom: 0.0625rem solid #e4e8ea;
}

.modal-c1 {
  --bs-modal-header-padding: 1rem 1.5rem;
  --bs-modal-padding: 1rem 1.5rem;
  --bs-modal-border-radius: 0.25rem;
  --bs-modal-border-width: 0;
  --bs-modal-header-border-width: 0;
}
.modal-c1 .modal-content {
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
}
.modal-c1 .modal-header {
  box-shadow: 0px 1px 1px rgba(105, 112, 117, 0.1);
}

.modal {
  --bs-modal-header-border-color: #e4e8ea;
}

.modal-dialog-lg {
  height: 500px;
  min-height: unset;
}

.alert {
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: relative;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  border: none;
  font-size: 0.875rem;
  color: #324552;
  display: none;
  opacity: 0;
  height: 0;
  visibility: hidden;
  transition: opacity 500ms ease-in, height 1000ms ease-in;
}
.alert > p {
  font-size: 0.875rem;
}
.alert-info {
  background-color: #cde2ff;
}
.alert-success {
  background-color: #dff0d8;
}
.alert-warning {
  background-color: #fcf8e3;
}
.alert-danger {
  background-color: #f8e0e0;
}
.alert-premium {
  background-color: #FCF8E3;
  border: 1px solid #E29F24;
  border-width: 1px 1px 1px 8px;
}
.alert-success:before, .alert-warning:before, .alert-info:before, .alert-danger:before, .alert-premium::before {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
  background-size: 1.25rem;
}
.alert-success:before {
  content: url(/assets/icons/svg/ico-tick-green.svg);
}
.alert-warning:before {
  content: url(/assets/icons/svg/ico-warning-warning.svg);
}
.alert-danger:before {
  content: url(/assets/icons/svg/ico-warning-danger.svg);
}
.alert-info:before {
  content: "";
  font-family: bootstrap-icons !important;
  color: #3d84e5;
  font-size: 1.125rem;
  left: 1.25rem !important;
  font-weight: 500;
}
.alert-premium:before {
  font-family: "icomoon" !important;
  color: #E29F24;
  font-size: 22px;
  left: 1rem !important;
  font-weight: 500;
}
.alert .btn-close {
  font-size: 0.55rem;
  box-shadow: none;
  position: absolute;
  top: 50%;
  right: 1.5rem;
  transform: translateY(-50%);
}
.alert.show {
  display: flex;
  opacity: 1;
  visibility: visible;
  height: auto;
}

.alert-bg-white {
  padding-left: 1.75rem;
  background-color: white !important;
}
.alert-bg-white::before {
  left: 0 !important;
}

.alert-icon-top::before {
  left: 0 !important;
}

.badge, .badge3, .badge2 {
  font-size: 0.75rem;
  font-weight: 500;
  padding: 0.5rem;
  background: #dde1e3;
  border-radius: 12.5rem;
  color: #324552;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.badge2 {
  padding: 0.25rem 0.5rem 0.25rem 0.25rem;
  display: flex;
  align-items: center;
}
.badge2 .avatar-circle {
  margin-right: 0.25rem;
}

.badge-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.badge-float {
  position: absolute;
  background: #0078ce;
  width: 0.625rem !important;
  height: 0.625rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  right: -0.125rem;
  top: -0.125rem;
  border-radius: 1.875rem;
}

.badge3 {
  line-height: 1;
  text-overflow: ellipsis;
  white-space: break-spaces;
  text-align: start;
  border-radius: 1.125rem;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding: 0.25rem;
}

.badge-custom {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.125rem;
  font-size: 0.75rem;
  min-height: 1.313rem;
  color: #ffffff;
  padding: 0rem 0.5rem;
  background-color: #e0aaa2;
}

.badge4 {
  height: 1.125rem;
  padding: 0 0.375rem;
  font-size: 0.625rem;
  font-weight: 500;
}

.badge-primary {
  color: #0078ce;
  background-color: rgba(0, 120, 206, 0.1);
}

.badge-success {
  color: #28a745;
  background-color: #dff0d8;
}

.badge-success1 {
  color: #fff;
  background-color: #28a745;
}

.badge-warning {
  color: #eab004;
  background-color: #f9f0c4;
}

.badge-danger {
  color: #dc3545;
  background-color: #f8e0e0;
}

.badge-danger1 {
  color: #fff;
  background-color: #dc3545;
}

.badge-secondary {
  color: #324552;
  background-color: #e4e8ea;
}

.badge-weekoff {
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}

.badge-holiday {
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}

.badge-shift-not-assigned {
  color: var(--c-unknown);
  background-color: var(--bg-unknown);
}

.badge-employee {
  border-radius: 1rem;
  padding: 0.125rem 0.625rem;
  text-transform: uppercase;
  font-size: 0.625rem;
}
.badge-employee.badge-float-2 {
  position: absolute;
  top: 2.75rem;
  right: 0.5rem;
}

.badge-c1 {
  color: #31aab7;
  background-color: #31aab71a;
}

.badge-c2 {
  color: #e29f24;
  background-color: #e29f241a;
}

.badge-c3 {
  color: #6562cf;
  background-color: #6562cf1a;
}

.badge-c4 {
  color: #129aa6;
  background-color: #129aa61a;
}

.status1 {
  position: relative;
  padding-left: 0.875rem;
}
.status1::before {
  position: absolute;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 0.125rem;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.status1.status-success::before {
  background-color: #28a745;
}
.status1.status-warning::before {
  background-color: #ffc107;
}
.status1.status-danger::before {
  background-color: #dc3545;
}
.status1.status-primary::before {
  background-color: #31aab7;
}
.status1.status-secondary::before {
  background-color: #dee3e7;
}
.status1.status-cancel::before {
  background-color: #7d92a1;
}

.badge-hover {
  position: relative;
  padding: 0 0.25rem;
  transition: all ease-in-out 400ms;
}
.badge-hover .icon-close {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
.badge-hover:hover {
  background-color: #e4e8ea;
  padding-right: 0.875rem;
  line-height: 1.313rem;
  font-size: 0.875rem;
  border-radius: 0.625rem 0 0.625rem 0.625rem;
}
.badge-hover:hover .icon-close {
  background-color: #e4e8ea;
  border-radius: 0.625rem 0.625rem 0 0;
  z-index: 1;
  top: -0.625rem;
  right: 0;
  font-size: 0.75rem;
  padding: 0.125rem;
  opacity: 1;
  pointer-events: all;
}

.badge-c5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  background-color: #eff4f6;
  height: 2rem;
}
.badge-c5:hover {
  background-color: #acbac3;
  cursor: pointer;
}

.draggable {
  position: relative;
  padding-left: 24px;
}
.draggable::before {
  position: absolute;
  content: url("data:image/svg+xml,%3Csvg width='6' height='14' viewBox='0 0 6 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='2' height='2' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(4)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(0 6)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(4 6)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(0 12)' fill='%23324552'/%3E%3Crect width='2' height='2' transform='translate(4 12)' fill='%23324552'/%3E%3C/svg%3E%0A");
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  line-height: 0;
}

.badge-circle {
  display: inline-block !important;
  background-color: var(--badge-bg);
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
}

.badge-yellow {
  --badge-bg: #ffc107;
}

.badge-orange {
  --badge-bg: #ff8d07;
}

.badge-red {
  --badge-bg: #dc3545;
}

.badge-green {
  --badge-bg: #009722;
}

.badge-improvement {
  color: var(--c-improvement) !important;
  background-color: var(--bg-improvement) !important;
}

.badge-fixation {
  color: var(--c-fixation) !important;
  background-color: var(--bg-fixation) !important;
}

.badge-feature {
  color: var(--c-feature) !important;
  background-color: var(--bg-feature) !important;
}

.badge-c6 {
  color: #622776 !important;
  background-color: #E8BCF7 !important;
}

.holiday-calender {
  font-family: "Work Sans", sans-serif;
}
.holiday-calender .mat-calendar-body-label {
  visibility: hidden;
}
.holiday-calender .mat-calendar-body-label[colspan="7"] {
  display: none;
}
.holiday-calender mat-calendar-header {
  display: none;
}
.holiday-calender .mat-calendar-table-header-divider::after {
  display: none;
}
.holiday-calender .mat-calendar-table-header th {
  font-weight: 600;
  font-size: 0.813rem;
}
.holiday-calender .mat-calendar-body-cell-content {
  font-size: 0.813rem;
}
.holiday-calender .mat-calendar-body-cell-content {
  width: 85% !important;
  height: 60% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.holiday-calender .mat-calendar-body-today {
  border-color: #0078ce !important;
}
.holiday-calender .mat-calendar-body-selected {
  background-color: initial;
  color: initial;
  border: 0.0625rem solid rgba(54, 157, 232, 0.87);
}
.holiday-calender .mat-calendar-body-selected:before {
  width: 1.188rem !important;
  height: 1.188rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  background: #0078ce;
  content: "";
  font-family: bootstrap-icons !important;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
}
.holiday-calender .week-off .mat-calendar-body-cell-content {
  background-color: #b1e3da;
}
.holiday-calender .national-holiday .mat-calendar-body-cell-content {
  background-color: #fbefdd;
}
.holiday-calender .floating-holiday .mat-calendar-body-cell-content {
  background-color: #dde8f5;
}
.holiday-calender .national-holiday .mat-calendar-body-cell-content:after,
.holiday-calender .floating-holiday .mat-calendar-body-cell-content:after {
  content: "Republic Day";
  position: absolute;
  top: 100%;
  padding: 0.5rem 1.5rem;
  background-color: #fff;
  box-shadow: 0rem 0rem 0.0625rem #eaeaea;
  min-height: 2rem;
  width: 8.75rem;
  border-radius: 0.25rem;
  line-height: 1.125rem;
  font-size: 0.875rem;
  z-index: 2;
  opacity: 0;
  transition: all 600ms;
  color: #324552;
}
.holiday-calender .national-holiday:hover .mat-calendar-body-cell-content:after,
.holiday-calender .floating-holiday:hover .mat-calendar-body-cell-content:after {
  opacity: 1;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover .mat-calendar-body-cell-content {
  background-color: #dceafb !important;
}

.calendar-control {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 2rem;
}
.calendar-control .calendar-title {
  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;
}
.calendar-control .calendar-button {
  position: absolute;
  top: 0;
  width: 2rem !important;
  height: 2rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border: 0.0625rem solid #e4e8ea;
  color: #31aab7;
  border-radius: 50%;
  cursor: pointer;
}
.calendar-control .calendar-prev-button {
  left: 0.5rem;
}
.calendar-control .calendar-next-button {
  right: 0.5rem;
}

.calender-highlights {
  display: flex;
  flex-wrap: wrap;
  gap: 3.125rem;
  border-top: 0.0625rem solid #eaedef;
}
.calender-highlights .special-day {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.calender-highlights .special-day .indicator {
  width: 1.125rem;
  height: 0.625rem;
  border-radius: 7rem;
}
.calender-highlights .special-day .name {
  font-weight: 600;
  font-size: 0.75rem;
}
.calender-highlights .week-off .indicator {
  background-color: #b1e3da;
}
.calender-highlights .national-holiday .indicator {
  background-color: #fbefdd;
}
.calender-highlights .floating-holiday .indicator {
  background-color: #dde8f5;
}

.fullcalendar-reset {
  font-family: "Work Sans", sans-serif;
  --fc-border-color: #eaedef;
  --fc-today-bg-color: #ecf2f5;
}
.fullcalendar-reset .fc-header-toolbar {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
.fullcalendar-reset .fc-header-toolbar .fc-toolbar-title {
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
}
.fullcalendar-reset .fc-header-toolbar .fc-prev-button,
.fullcalendar-reset .fc-header-toolbar .fc-next-button {
  width: 2rem !important;
  height: 2rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  color: #31aab7;
  background-color: #fff;
  border-radius: 50% !important;
  border: 0.0625rem solid #e4e8ea;
}
.fullcalendar-reset .fc-header-toolbar .fc-prev-button .fc-icon,
.fullcalendar-reset .fc-header-toolbar .fc-next-button .fc-icon {
  font-size: 1.2em;
}
.fullcalendar-reset .fc-header-toolbar .fc-prev-button:hover, .fullcalendar-reset .fc-header-toolbar .fc-prev-button:active,
.fullcalendar-reset .fc-header-toolbar .fc-next-button:hover,
.fullcalendar-reset .fc-header-toolbar .fc-next-button:active {
  background-color: #31aab7 !important;
  color: #fff;
  border-color: #31aab7 !important;
}
.fullcalendar-reset .fc-header-toolbar .fc-prev-button:focus, .fullcalendar-reset .fc-header-toolbar .fc-prev-button:active,
.fullcalendar-reset .fc-header-toolbar .fc-next-button:focus,
.fullcalendar-reset .fc-header-toolbar .fc-next-button:active {
  outline: none;
  box-shadow: none !important;
}
.fullcalendar-reset .fc-col-header-cell {
  background-color: #f8fafb;
  border-bottom: 0.125rem solid #eaedef;
}
.fullcalendar-reset .fc-col-header-cell .fc-scrollgrid-sync-inner {
  height: 2rem;
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: capitalize;
}
.fullcalendar-reset .fc-col-header-cell .fc-scrollgrid-sync-inner a {
  color: #222730;
}
.fullcalendar-reset .fc-daygrid-day-frame .fc-daygrid-day-number {
  color: #222730;
}

.fullcalender-border-0 {
  --fc-border-color: #fff0 !important;
}
.fullcalender-border-0 .fc-col-header-cell {
  background-color: unset !important;
  border: none !important;
}

.fullcalendar-fulloption .fc-header-toolbar {
  max-width: 22.875rem;
}
.fullcalendar-fulloption .fc-toolbar-title {
  font-size: 0.875rem !important;
}
.fullcalendar-fulloption .fc-daygrid-day-frame {
  min-height: 6rem !important;
}
.fullcalendar-fulloption .fc-daygrid-day-top {
  float: right;
  position: absolute;
  top: 0;
  left: 0;
}
.fullcalendar-fulloption .fc-daygrid-day-top .fc-daygrid-day-number {
  padding: 0 0 0 0.5rem;
  line-height: 2rem;
}
.fullcalendar-fulloption .fc-daygrid-day-frame {
  overflow: hidden;
}
.fullcalendar-fulloption .fc-daygrid-day-events {
  float: left;
  width: calc(100% - 2.5rem);
  padding-left: 0.25rem;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.fullcalendar-fulloption .fc-daygrid-event {
  background-color: transparent !important;
  color: #566976 !important;
  border: none !important;
  margin: 0 !important;
  pointer-events: none;
}
.fullcalendar-fulloption .time-event {
  white-space: normal !important;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.fullcalendar-fulloption .time-event .start-time,
.fullcalendar-fulloption .time-event .end-time,
.fullcalendar-fulloption .time-event .duration,
.fullcalendar-fulloption .time-event .overtime {
  padding-left: 1.125rem;
  color: #566976;
  white-space: nowrap;
  display: block;
  font-size: 0.688rem;
  line-height: 0.625rem;
  position: relative;
  font-weight: 500;
}
.fullcalendar-fulloption .time-event .start-time::before,
.fullcalendar-fulloption .time-event .end-time::before,
.fullcalendar-fulloption .time-event .duration::before,
.fullcalendar-fulloption .time-event .overtime::before {
  font-family: "icomoon" !important;
  position: absolute;
  color: #0078ce;
  font-size: 0.625rem;
}
.fullcalendar-fulloption .time-event .overtime {
  color: #e59e00;
}
.fullcalendar-fulloption .time-event .overtime::before {
  content: "";
  left: 0.125rem;
  color: #e59e00;
}
.fullcalendar-fulloption .time-event .start-time::before {
  content: "";
  left: 0.1875rem;
}
.fullcalendar-fulloption .time-event .end-time::before {
  content: "";
  left: 0.1875rem;
}
.fullcalendar-fulloption .time-event .duration::before {
  content: "";
  left: 0.1875rem;
}
.fullcalendar-fulloption .fc-day-other .time-event,
.fullcalendar-fulloption .fc-day-other .first-cell-div {
  display: none;
}
.fullcalendar-fulloption .selected-day::after, .fullcalendar-fulloption .selected-day::before {
  height: calc(1.5rem + 1px);
}
.fullcalendar-fulloption .selected-day:not(.status-leave-present,
.status-absent-present,
.status-present-leave,
.status-present-absent)::after, .fullcalendar-fulloption .selected-day:not(.status-leave-present,
.status-absent-present,
.status-present-leave,
.status-present-absent)::before {
  width: calc(100% + 1px);
}
.fullcalendar-fulloption .selected-day.status-leave-present::after, .fullcalendar-fulloption .selected-day.status-absent-present::after, .fullcalendar-fulloption .selected-day.status-present-leave::after, .fullcalendar-fulloption .selected-day.status-present-absent::after {
  width: calc(50% + 1px) !important;
}
.fullcalendar-fulloption .selected-day .tag-cell {
  right: -0.125rem !important;
}
.fullcalendar-fulloption .first-cell-div {
  position: absolute;
  height: 2rem;
  width: calc(100% - 1.75rem);
  right: 0;
  display: flex;
}
.fullcalendar-fulloption .first-cell-div .tag-cell {
  position: absolute;
  background-color: #ffc107;
  color: #324552;
  font-weight: 500;
  font-size: 0.5rem;
  right: 0;
  height: 1rem;
  padding: 0 0.25rem 0 0.625rem;
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 20% 50%, 0% 0%);
}
.fullcalendar-fulloption .first-cell-div .type-indicator {
  margin-top: 0.75rem;
  margin-right: 4px;
}

.fullcalendar-c1 .selected-day .checkbox-icon:before {
  content: "" !important;
  color: #31aab7;
}
.fullcalendar-c1 .checkbox-icon {
  position: absolute;
  top: 65%;
  left: calc(100% + 0.875rem);
  font-size: 1rem;
  color: #acbac3;
  background-color: #fff;
  line-height: 1;
}
.fullcalendar-c1 .fc-day .checkbox-icon:before {
  content: "";
}
.fullcalendar-c1 .fc-header-toolbar {
  margin-bottom: 0.5rem !important;
}

.fullcalendar-c3:not(.fullcalendar-c2) .fc-day-today {
  background-color: var(--bg--currentday) !important;
  outline: 1px solid #0078ce;
  outline-offset: -1px;
  z-index: 99;
}
.fullcalendar-c3:not(.fullcalendar-c2) .selected-day {
  background-color: #96b2e0 !important;
}
.fullcalendar-c3:not(.fullcalendar-c2) .selected-day:not(first-cell-div) *:not(.tag-cell),
.fullcalendar-c3:not(.fullcalendar-c2) .selected-day:not(first-cell-div) *::before,
.fullcalendar-c3:not(.fullcalendar-c2) .selected-day:not(first-cell-div) *::after {
  color: #ffffff !important;
}
.fullcalendar-c3:not(.fullcalendar-c2) .fc-daygrid-day .fc-daygrid-day-frame:first-child {
  padding-top: 1.5625rem;
}

.fullcalendar-c2 .fc-scrollgrid-section-body .fc-daygrid-day {
  padding: 2px;
  position: relative;
}
@media (min-width: 1440px) {
  .fullcalendar-c2 .fc-scrollgrid-section-body .fc-daygrid-day {
    padding: 0.125rem;
  }
}
.fullcalendar-c2 .fc-scrollgrid-section-body .fc-daygrid-day .fc-daygrid-day-frame {
  height: 4.375rem;
}
.fullcalendar-c2 .fc-scrollgrid-section-body .fc-daygrid-day .fc-daygrid-day-number {
  font-size: 0.75rem;
  color: #222730;
}
.fullcalendar-c2 .fc-scrollgrid-section-body .fc-daygrid-day.fc-day-other .fc-daygrid-day-number {
  color: #7d92a1;
}
.fullcalendar-c2 .fc-scrollgrid-sync-inner {
  padding-top: 0 !important;
}
.fullcalendar-c2 .fc-h-event {
  border: none;
  background-color: unset;
}
.fullcalendar-c2 .fc-scrollgrid-sync-table tr .fc-daygrid-day:first-child,
.fullcalendar-c2 .fc-scrollgrid-sync-table tr .fc-daygrid-day:last-child {
  padding-left: 3px;
}
@media (min-width: 1440px) {
  .fullcalendar-c2 .fc-scrollgrid-sync-table tr .fc-daygrid-day:first-child,
.fullcalendar-c2 .fc-scrollgrid-sync-table tr .fc-daygrid-day:last-child {
    padding-left: 0.1875rem;
  }
}
.fullcalendar-c2 .holiday-status.fc-day-today {
  background-color: inherit !important;
}
.fullcalendar-c2 .holiday-status:not(.fc-day-other) .fc-daygrid-day-frame .fc-daygrid-day-top {
  border-radius: 0.5rem;
  width: 100%;
  position: relative;
  border: 1px solid transparent;
}
.fullcalendar-c2 .holiday-status:not(.fc-day-other) .fc-daygrid-day-frame::after {
  content: attr(data-text);
  position: absolute;
  font-weight: 500;
  font-size: 0.625rem;
  inset: auto auto 0.625rem 0.625rem;
  width: calc(100% - (1.25rem));
  word-break: break-word;
}
.fullcalendar-c2 .holiday-status .fc-daygrid-day-top {
  height: 100%;
}
.fullcalendar-c2 .holiday-status.cell-status .fc-daygrid-day-top {
  height: calc(100% - (1.5rem + 0.125rem));
}
.fullcalendar-c2 .holiday-status.cell-status .fc-daygrid-day-frame::after {
  bottom: 2rem;
}
.fullcalendar-c2 .national-holiday:not(.fc-day-other) .fc-daygrid-day-frame::after {
  color: var(--c-national);
}
.fullcalendar-c2 .national-holiday:not(.fc-day-other) .fc-daygrid-day-frame .fc-daygrid-day-top {
  background-color: var(--bg-national);
}
.fullcalendar-c2 .floating-holiday:not(.fc-day-other) .fc-daygrid-day-frame::after {
  color: var(--c-floating);
}
.fullcalendar-c2 .floating-holiday:not(.fc-day-other) .fc-daygrid-day-frame .fc-daygrid-day-top {
  background-color: var(--bg-floating);
}
.fullcalendar-c2 .regular-holiday:not(.fc-day-other) .fc-daygrid-day-frame::after {
  color: var(--c-regular);
}
.fullcalendar-c2 .regular-holiday:not(.fc-day-other) .fc-daygrid-day-frame .fc-daygrid-day-top {
  background-color: var(--bg-regular);
}
.fullcalendar-c2 .fc-day-today .fc-daygrid-day-number {
  border: 1px solid #0078ce;
  background-color: #ffffff;
}
@media (min-width: 1440px) {
  .fullcalendar-c2 .fc-day-today .fc-daygrid-day-number {
    border: 0.0625rem solid #0078ce;
  }
}
.fullcalendar-c2 .selected-day {
  background-color: inherit !important;
}
.fullcalendar-c2 .selected-day .fc-daygrid-day-top {
  border-color: #0078ce !important;
  animation: selected 1s normal forwards ease-in-out;
  animation-iteration-count: 1;
  animation-delay: 3s;
}
@media (min-width: 1440px) {
  .fullcalendar-c2 .selected-day .fc-daygrid-day-top {
    outline: 0.0625rem solid #0078ce;
  }
}
@keyframes selected {
  0% {
    border-color: #0078ce;
  }
  100% {
    border-color: transparent;
  }
}
.fullcalendar-c2 .fc-daygrid-day-number {
  width: 1.375rem !important;
  height: 1.375rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  margin-top: 0.25rem;
  margin-left: 0.25rem;
  border-radius: 50%;
  padding: 4px !important;
  position: absolute !important;
  left: 0;
}

.md-drppicker.shown {
  font-family: "Work Sans", sans-serif !important;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1) !important;
  border-radius: 0.25rem !important;
  padding: 0rem !important;
  max-width: 60.375rem !important;
  max-height: 22.875rem !important;
  min-height: 19.5rem !important;
  display: flex;
  transform: translateY(0.813rem) !important;
}
.md-drppicker > * {
  flex-shrink: 0;
}

.md-drppicker .ranges {
  width: 18.625rem !important;
}
.md-drppicker .ranges + .calendar {
  border-left: 0.0625rem solid #e4e8ea;
}
.md-drppicker .ranges ul {
  padding: 1rem 0 !important;
}
.md-drppicker .ranges ul li button {
  font-size: 0.875rem;
  padding: 0 1.5rem;
  color: #566976;
  height: 2.5rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.md-drppicker .ranges ul li button.active {
  color: #0078ce !important;
  background: #0078ce1c !important;
  font-weight: 500;
}
.md-drppicker .ranges ul li button.active::before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 1.25rem;
  left: 0.0625rem;
  background-color: #0078ce !important;
  border-radius: 0rem 1rem 1.5rem 0rem;
}
.md-drppicker .ranges ul li button:hover {
  background-color: #c0ced733 !important;
}

.md-drppicker .calendar {
  height: 22.375rem;
  width: 20.125rem !important;
  width: 100%;
  padding: 1.375rem 1.625rem;
  margin: 0 !important;
}
.md-drppicker .calendar.left, .md-drppicker .calendar.right {
  position: relative;
}
.md-drppicker .calendar.left::before, .md-drppicker .calendar.right::before {
  content: "";
  height: 0.0625rem;
  width: 91%;
  position: absolute;
  background-color: #e4e8ea;
  right: 0;
  bottom: 4.5rem;
}
.md-drppicker .calendar.right:before {
  left: 0;
}
.md-drppicker .calendar.left:after {
  content: "";
  height: 16rem;
  width: 0.0625rem;
  position: absolute;
  top: 1.375rem;
  background-color: #e4e8ea;
  right: 0;
}
.md-drppicker .calendar .calendar-table {
  padding: 0 !important;
}
.md-drppicker .calendar .calendar-table .next.available,
.md-drppicker .calendar .calendar-table .prev.available {
  border: 0.0625rem solid #e4e8ea;
  border-radius: 5.125rem;
  color: #31aab7;
  width: 2rem;
  height: 2rem;
  min-width: 2rem;
  background-image: url(../../assets/icons/svg/ico-arrow-left.svg);
}
.md-drppicker .calendar .calendar-table .month {
  color: #324552;
  font-weight: 600;
  font-size: 0.875rem;
}
.md-drppicker .calendar .calendar-table .week-days {
  display: none;
}
.md-drppicker .calendar td {
  line-height: 1.9375rem;
  font-size: 0.75rem;
  color: #222730;
}
.md-drppicker .calendar td.active {
  background-color: #0078ce;
}
.md-drppicker .calendar td.active:hover {
  background-color: #0078ce;
}
.md-drppicker .calendar td.in-range:not(.end-date) {
  background: rgba(0, 120, 206, 0.3);
}
.md-drppicker .calendar td.today {
  font-weight: 700;
}
.md-drppicker .calendar td.today:not(.in-range, .active) {
  background: #dee3e7;
}

.md-drppicker .buttons {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}
.md-drppicker .buttons_input {
  display: flex;
  width: 33.75rem;
  padding: 1rem 1.5rem;
  justify-content: end;
  gap: 1rem;
}
.md-drppicker .buttons_input .btn.btn-default.clear svg {
  display: none;
}
.md-drppicker .buttons_input .btn {
  width: 8.75rem;
  height: 2.5rem;
  border-radius: 0.125rem;
  text-transform: uppercase;
  box-shadow: none;
  font-size: 0.875rem;
}
.md-drppicker .buttons_input .btn.btn-default {
  color: #7d92a1;
  border: 0.0625rem solid #acbac3;
}
.md-drppicker .buttons_input .btn:not(.btn-default) {
  background: #31aab7;
  color: #ffffff;
}

.vertical-datepicker .md-drppicker {
  flex-direction: column;
  max-height: unset !important;
}
.vertical-datepicker .md-drppicker .ranges {
  width: auto !important;
}
.vertical-datepicker .md-drppicker .calendar.left,
.vertical-datepicker .md-drppicker .calendar.right {
  width: 100% !important;
  max-width: unset;
  height: unset;
}
.vertical-datepicker .md-drppicker .calendar.left::before,
.vertical-datepicker .md-drppicker .calendar.right::before {
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  left: unset;
}
.vertical-datepicker .md-drppicker .calendar.left {
  border: none;
}
.vertical-datepicker .md-drppicker .calendar.left::after {
  content: none;
}
.vertical-datepicker .md-drppicker .buttons {
  position: relative;
}
.vertical-datepicker .md-drppicker .buttons .buttons_input {
  width: auto;
}

.mat-datepicker-content * {
  font-family: "Work Sans", sans-serif !important;
}

.mat-datepicker-content .mat-calendar {
  height: auto !important;
}
.mat-datepicker-content .mat-calendar-spacer {
  display: none;
}
.mat-datepicker-content .mat-calendar-content {
  padding: 0 1rem 1rem 1rem;
}
.mat-datepicker-content .mat-calendar-header {
  padding: 1rem;
}
.mat-datepicker-content .mat-calendar-header .mat-calendar-controls {
  height: 2rem;
  margin: 0rem !important;
}
.mat-datepicker-content .mat-calendar-header .mat-focus-indicator {
  position: absolute;
}
.mat-datepicker-content .mat-calendar-header .mat-icon-button {
  position: absolute;
  width: 2rem;
  height: 2rem;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 5.125rem;
  line-height: 0;
}
.mat-datepicker-content .mat-calendar-header .mat-icon-button:after {
  border: none;
}
.mat-datepicker-content .mat-calendar-header .mat-icon-button .mat-button-wrapper::after {
  font-family: "icomoon" !important;
  content: "";
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  color: #31aab7;
  font-size: 0.75rem;
}
.mat-datepicker-content .mat-calendar-header .mat-calendar-next-button {
  right: 1rem;
  left: unset;
}
.mat-datepicker-content .mat-calendar-header .mat-calendar-next-button .mat-button-wrapper::after {
  transform: translate(-50%, -50%) rotateZ(180deg) !important;
}
.mat-datepicker-content .mat-calendar-header .mat-calendar-period-button {
  line-height: 2rem;
  font-weight: 600;
  font-size: 0.875rem;
  left: 50%;
  transform: translate(-50%);
}
.mat-datepicker-content .mat-calendar-body-label {
  visibility: hidden;
}
.mat-datepicker-content .mat-calendar-body-label[colspan="7"] {
  display: none;
}
.mat-datepicker-content mat-year-view .mat-calendar-body-label[colspan="4"] {
  display: none;
}
.mat-datepicker-content .mat-calendar-table-header th {
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  color: #7d92a1;
  text-transform: capitalize;
}
.mat-datepicker-content .mat-calendar-table-header-divider {
  display: none;
}
.mat-datepicker-content .mat-calendar-body-cell-content {
  font-size: 0.813rem;
}
.mat-datepicker-content .mat-calendar-body-cell-content {
  width: 100% !important;
  height: 80% !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.mat-datepicker-content .mat-calendar-body-today {
  background-color: #dee3e7;
  border: none;
}
.mat-datepicker-content .mat-calendar-body-selected {
  background-color: #0078ce;
  color: #fff;
}

.status-week-off .mat-calendar-body-cell-content {
  background-color: var(--bg-weekoff);
}

.status-holiday .mat-calendar-body-cell-content {
  background-color: var(--bg-holiday);
}

.status-first-week-off .mat-calendar-body-cell-content, .status-second-week-off .mat-calendar-body-cell-content {
  border: none;
  position: relative;
}
.status-first-week-off .mat-calendar-body-cell-content::before, .status-second-week-off .mat-calendar-body-cell-content::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 51%;
  border: 1px solid #E4E8EA;
}

.status-second-week-off .mat-calendar-body-cell-content {
  background-image: linear-gradient(to right, transparent 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
}
.status-second-week-off .mat-calendar-body-cell-content::before {
  left: 0;
  border-radius: 999px 0 0 999px;
}

.status-first-week-off .mat-calendar-body-cell-content {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, transparent 51%, transparent 100%);
}
.status-first-week-off .mat-calendar-body-cell-content::before {
  right: 0;
  border-radius: 0 999px 999px 0;
}

.fc-col-header,
.fc-timegrid-body {
  width: 100% !important;
}

.fc-timegrid-body table {
  width: 100% !important;
}

::ng-deep table.fc-col-header,
::ng-deep .fc-scroller > div,
::ng-deep .fc-scroller > div > table {
  width: 100% !important;
}

.td-today {
  background-color: #ecf2f5 !important;
}

.sq {
  --ratio:1.875rem;
  width: var(--ratio) !important;
  height: var(--ratio) !important;
  aspect-ratio: 1;
  flex-shrink: 0 !important;
  min-height: unset !important;
}

.mail-wrap {
  display: flex;
  width: 100%;
}
.mail-wrap > * {
  overflow-y: auto;
  height: calc(100vh - 3rem);
  flex-shrink: 0;
}

.mail-category {
  background-color: #fff;
  width: 14.25rem;
  box-shadow: 0rem 0rem 0.25rem rgba(105, 112, 117, 0.2) inset;
}
.mail-category .title {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
  padding: 1.5rem 1.5rem 0.75rem 1.5rem;
  color: #7d92a1;
}
.mail-category ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.mail-category ul a {
  position: relative;
  display: flex;
  padding: 0 1.5rem;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 0.75rem;
  height: 2.5rem;
  color: #566976;
  transition: 400ms ease-in;
}
.mail-category ul a :first-child {
  width: calc(100%- 5ch);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mail-category ul a :last-child {
  width: 4ch;
  text-align: right;
  white-space: nowrap;
  margin-left: 0.5rem;
}
.mail-category ul a.active {
  background: #0078ce1a;
  color: #0078ce;
  font-weight: 600;
}
.mail-category ul a.active::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translateY(-50%);
  border-right: 0.25rem solid #0078ce;
  height: 50%;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}

.mail-list {
  width: 26.125rem;
  padding-bottom: 2rem;
}
.mail-list .mail-list--container {
  overflow-y: auto;
}
.mail-list .mail-list--container > * {
  margin-top: 0.5rem;
}
.mail-list .mail--date {
  font-size: 0.75rem;
  font-weight: 400;
  color: #566976;
  text-transform: uppercase;
}

.mail-card {
  background-color: #fff;
  border: 0.0625rem solid #eaedef;
  padding: 0.75rem 1rem;
  border-radius: 0.25rem;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.mail-card:hover {
  border-color: #0069b4;
  cursor: pointer;
}
.mail-card .mail-card-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.mail-card.mail-active {
  border-color: #0078ce;
}
.mail-card.mail-active::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translateY(-50%);
  border-right: 0.25rem solid #0078ce;
  height: 50%;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
}
.mail-card.mail-new:not(.mail-active)::before {
  content: "";
  position: absolute;
  right: 0rem;
  top: 0;
  border-width: 0.4375rem;
  border-style: solid;
  border-color: #31aab7 #31aab7 transparent transparent;
  border-top-right-radius: 0.25rem;
}

.mail-view {
  background-color: #fff;
  width: 100%;
  flex: 1;
  box-shadow: 0rem 0rem 0.25rem rgba(105, 112, 117, 0.2) inset;
}

.bg-grad {
  background-image: linear-gradient(#f8fafb 80%, #f8fafba3);
}

.toggle-star::before {
  content: "";
}

.btn-star:hover .toggle-star::before {
  color: #ffb000;
}

.mail-stared .toggle-star::before {
  content: "";
  color: #ffb000;
}

.star-button {
  position: absolute;
  right: 0;
}

.custom-doughnut .advanced-pie-legend {
  display: flex;
  flex-direction: column-reverse;
  width: 100% !important;
  padding-bottom: 2.5rem;
}
.custom-doughnut .advanced-pie.chart,
.custom-doughnut .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.custom-doughnut .total-value,
.custom-doughnut .total-label {
  font-size: 1rem !important;
  position: absolute !important;
  margin: 0 !important;
  bottom: 0;
}
.custom-doughnut .total-label {
  position: relative;
  padding-left: 1rem;
}
.custom-doughnut .total-label::before {
  position: absolute;
  content: "";
  left: 0rem;
  width: 0.5rem !important;
  height: 1.5rem !important;
  background-color: #eee;
}
.custom-doughnut .total-value {
  right: 0;
}
.custom-doughnut .legend-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-height: 6.875rem;
  overflow: auto;
}
.custom-doughnut .legend-item {
  display: flex !important;
  align-items: center;
  position: relative;
  margin: 0 !important;
}
.custom-doughnut .legend-item .item-percent {
  display: none;
}
.custom-doughnut .legend-item .item-color {
  border-width: 0.5rem !important;
  height: 1.5rem !important;
  margin-right: 0.5rem !important;
}
.custom-doughnut .legend-item .item-value,
.custom-doughnut .legend-item .item-label {
  font-size: 1rem !important;
  opacity: 1 !important;
}
.custom-doughnut .legend-item .item-value {
  position: absolute;
  right: 0;
}
.custom-doughnut .legend-item .item-label {
  margin: 0 !important;
}
.custom-doughnut .advanced-pie {
  height: auto !important;
  position: relative;
}
.custom-doughnut .advanced-pie .total-count {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.875rem;
  color: #0078ce;
}
.custom-doughnut .advanced-pie.chart .pie.chart stop {
  stop-opacity: 1 !important;
}

.custom-doughnut-type2 ngx-charts-advanced-pie-chart > div {
  display: flex;
}
.custom-doughnut-type2 .advanced-pie.chart {
  width: 60% !important;
  height: 100% !important;
  position: relative;
}
.custom-doughnut-type2 .advanced-pie.chart .pie.chart stop {
  stop-opacity: 1 !important;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper {
  width: 40% !important;
  overflow-x: hidden;
  overflow-y: auto;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .total-label,
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .item-label {
  font-weight: 400;
  font-size: 0.75rem !important;
  line-height: 1.5rem;
  color: #324552;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .total-value,
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .item-value {
  font-weight: 500;
  font-size: 1rem !important;
  line-height: 1.5rem;
  color: #324552;
  margin-top: 0 !important;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .total-value {
  position: absolute;
  left: 1rem;
  top: 1.125rem;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .total-label {
  margin-bottom: 0;
  position: relative;
  top: 0;
  padding-left: 1rem;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .total-label::before {
  position: absolute;
  content: "";
  width: 0.25rem;
  height: 2.0625rem;
  background-color: #e4e8ea;
  left: 0;
  top: 0.5rem;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container {
  padding-top: 1.625rem;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container .legend-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container .legend-items .legend-item {
  margin-right: 0;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-percent {
  display: none;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-color {
  border-left-width: 0.25rem;
  width: 0.25rem;
  height: 2.0625rem;
  margin-right: 0;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-value {
  margin-left: 1rem;
}
.custom-doughnut-type2 .advanced-pie-legend-wrapper .advanced-pie-legend .legend-items-container .legend-items .legend-item .item-label {
  margin-left: 1rem;
  margin-top: 0;
  opacity: 1;
  line-height: 1;
}
.custom-doughnut-type2 .dateDiv {
  position: absolute;
  font-weight: 500;
  text-align: center;
  display: flex;
  height: initial;
  bottom: 0;
  top: 0;
  align-items: center;
  left: 17px;
}

.chart-doughnut1 .advanced-pie.chart,
.chart-doughnut1 .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.chart-doughnut1 .advanced-pie.chart {
  width: 50% !important;
}
.chart-doughnut1 .advanced-pie.chart .pie.chart {
  width: 100%;
}
@media (min-width: 1000px) {
  .chart-doughnut1 .advanced-pie.chart .pie.chart {
    transform: scale(170%, 170%) translate(8.5%, 30%);
  }
}
@media (min-width: 769px) and (max-width: 1366px) {
  .chart-doughnut1 .advanced-pie.chart .pie.chart {
    transform: scale(250%, 250%) translate(8.5%, 20%);
  }
}
.chart-doughnut1 .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.chart-doughnut1 .total-label,
.chart-doughnut1 .legend-item .item-label {
  font-size: 0.875rem !important;
}

.chart-doughnut2 .advanced-pie.chart,
.chart-doughnut2 .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.chart-doughnut2 .advanced-pie.chart {
  width: 50% !important;
}
.chart-doughnut2 .advanced-pie.chart .pie.chart {
  width: 100%;
}
@media (min-width: 1000px) {
  .chart-doughnut2 .advanced-pie.chart .pie.chart {
    transform: scale(170%, 170%) translate(8.5%, 30%);
  }
}
@media (min-width: 769px) and (max-width: 1366px) {
  .chart-doughnut2 .advanced-pie.chart .pie.chart {
    transform: scale(230%, 230%) translate(9.5%, 22%);
  }
}
.chart-doughnut2 .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.chart-doughnut2 .total-label,
.chart-doughnut2 .legend-item .item-label {
  font-size: 0.875rem !important;
}

.chart-doughnut3 .advanced-pie.chart {
  width: 50% !important;
}
.chart-doughnut3 .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.chart-doughnut3 .advanced-pie.chart .pie.chart {
  width: 100%;
}
@media (min-width: 1000px) {
  .chart-doughnut3 .advanced-pie.chart .pie.chart {
    transform: scale(170%, 170%) translate(8.5%, 30%);
  }
}
@media (min-width: 769px) and (max-width: 1366px) {
  .chart-doughnut3 .advanced-pie.chart .pie.chart {
    transform: scale(200%, 200%) translate(9.5%, 25%);
  }
}
.chart-doughnut3 .advanced-pie-legend-wrapper {
  width: 50% !important;
}
.chart-doughnut3 .total-label,
.chart-doughnut3 .legend-item .item-label {
  font-size: 0.875rem !important;
}

.custom-doughnut.hide-total .advanced-pie-legend {
  padding-bottom: 0 !important;
}
.custom-doughnut.hide-total .advanced-pie-legend .total-value,
.custom-doughnut.hide-total .advanced-pie-legend .total-label {
  display: none;
}

.custom-linearea-chart {
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.custom-linearea-chart .x.axis .tick text {
  font-size: 0.625rem !important;
  fill: #566976;
}
.custom-linearea-chart .y.axis .tick text {
  font-size: 0.75rem !important;
  fill: #566976;
}
.custom-linearea-chart.no-x-axis .x.axis > g > g .gridline-path {
  stroke: transparent;
}
.custom-linearea-chart.no-x-axis .tooltip-anchor {
  fill: #80808085;
}
.custom-linearea-chart .y.axis .gridline-path {
  stroke: #eaedef !important;
}

.ngx-charts-tooltip-content {
  background-color: white !important;
  padding: 0.375rem 0.5rem !important;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
}
.ngx-charts-tooltip-content .tooltip-caret.position-right {
  border-right-color: white !important;
}
.ngx-charts-tooltip-content .tooltip-caret.position-top {
  border-top-color: white !important;
}
.ngx-charts-tooltip-content .tooltip-caret.position-bottom {
  border-bottom-color: white !important;
}
.ngx-charts-tooltip-content .tooltip-caret.position-left {
  border-left-color: white !important;
}
.ngx-charts-tooltip-content .tooltip-content {
  background-color: white;
  color: #324552;
}

.chart-stamp {
  width: 0.75rem;
  margin-right: 0.5rem;
  background-color: #0078ce;
  aspect-ratio: 1;
  display: inline-block;
  border-radius: 0.25rem;
}

.line-chart-1 .line-chart.chart {
  transform: translate(20%, 5%);
}

.chart-line-reset .chart-legend .legend-label-color {
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.3125rem;
  border-radius: 0;
}
.chart-line-reset .chart-legend .legend-label-text {
  width: calc(100% - 0.8125rem);
}
.chart-line-reset .x.axis text,
.chart-line-reset .y.axis text {
  color: #566976;
  font-size: 0.75rem !important;
}
.chart-line-reset .chart-legend .legend-labels {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.chart-line-reset .chart-legend .legend-label {
  margin: 0;
}
.chart-line-reset .line-chart.chart {
  transform: translateX(62px);
}

ngx-charts-bar-vertical-stacked .gridline-path,
.ngx-charts .gridline-path {
  stroke: #eaedef !important;
}

/**********************************************/
/**********************************************/
.chart-container {
  --h: 11.5rem;
  max-height: var(--h, 11.5rem);
  position: relative;
}
.chart-container .total-legend {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #0078ce;
  font-size: 1.125rem;
}

.label-wrap {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.label-wrap .label-items {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  max-height: calc(var(--h, 11.5rem) - 2rem);
}
.label-wrap .label-item {
  display: flex;
}
.label-wrap .label-item .item-color {
  background-color: #eee;
}
.label-wrap .label-item .item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.c1-label-wrap {
  width: 100%;
}
.c1-label-wrap .label-item {
  padding-right: 0.5rem;
  align-items: center;
  min-height: 1.5rem;
}
.c1-label-wrap .label-item .item-color {
  width: 0.5rem;
  height: 1.5rem;
  background-color: #eee;
  margin-right: 0.5rem;
}
.c1-label-wrap .label-item .item-name {
  font-size: var(--fs, 0.75rem);
}
.c1-label-wrap .label-item .item-value {
  font-size: var(--fs, 0.75rem);
  margin-left: auto;
  font-weight: 500;
}

.c2-label-wrap .label-item {
  flex-direction: column;
  align-items: start;
  position: relative;
  padding-left: calc(0.1875rem + 1rem);
}
.c2-label-wrap .label-item .item-color {
  position: absolute;
  width: 0.1875rem;
  height: 2.0625rem;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.c2-label-wrap .label-item .item-name {
  font-size: 0.75rem;
}
.c2-label-wrap .label-item .item-value {
  font-size: 1.125rem;
  font-weight: 500;
}

.dropdown_progres {
  display: block;
  z-index: 201;
  min-width: 98%;
  width: 100%;
  min-height: 100%;
  font-size: 0.813rem;
  font-weight: 400;
  color: #989393;
  overflow: hidden;
}

.progress_drp {
  align-items: center;
  padding: 0rem;
  justify-content: center;
  list-style-type: none;
}

.progress__item {
  position: relative;
  min-height: 7.188rem;
  counter-increment: list;
}

.progress__item:before {
  content: "";
  position: absolute;
  left: calc(0.75rem - 0.094rem);
  height: 100%;
  width: 0.0625rem;
  border-left: 0.125rem solid #adb6b8;
}

.progress__item:after {
  content: "";
  position: absolute;
  top: 0%;
  left: 0rem;
  width: 1.5rem;
  height: 1.5rem;
  color: #ffffff;
  border-radius: 0.125rem;
  font-family: "icomoon" !important;
  text-align: center;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress__item.progress__item--pending:after,
.progress__item.progress__item--pending:before {
  background-color: #e29f24 !important;
  border: 0.0625rem solid #e29f24 !important;
}

.progress__item.progress__item--accepted:after,
.progress__item.progress__item--accepted:before {
  background-color: #28a745 !important;
  border: 0.0625rem solid #28a745 !important;
}

.progress__item.progress__item--accepted:after {
  content: "" !important;
  font-size: 0.75rem;
}

.progress__item.progress__item--rejected:after,
.progress__item.progress__item--rejected:before {
  background-color: #dc3545 !important;
  border: 0.0625rem solid #dc3545 !important;
}

.progress__item.progress__item--rejected:after {
  content: "" !important;
}

.progress__item.progress__item--intimation:after,
.progress__item.progress__item--intimation:before {
  background-color: #e4e8ea !important;
  border: 0.0625rem solid #e4e8ea !important;
}

.progress__item.progress__item--multiple:after,
.progress__item.progress__item--multiple:before {
  background-color: #0078ce !important;
  border: 0.0625rem solid #0078ce !important;
  content: "";
}

.progress__title {
  font-size: 0.875rem;
  font-weight: 400;
  color: #324552;
  margin-bottom: 0.5rem;
}

.progress__item:last-child:before {
  display: none;
}

.custom-progress {
  position: relative;
  --radius: 1.313rem;
  background: #eaedef;
  border-radius: var(--radius);
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  height: 1rem;
}
.custom-progress .progress-bar {
  width: var(--percentage);
  height: inherit;
}
.custom-progress .progress-bar.progressbar-shift {
  background-color: #b8d0ec;
}
.custom-progress .progress-bar.progressbar-break {
  background-color: #c7acff;
}
.custom-progress .progress-bar:hover {
  filter: brightness(95%);
}
.custom-progress .progress-bar:last-child {
  border-radius: 0 0.25rem 0.25rem 0;
}

.progress_vertical {
  margin-left: -1rem;
}
.progress_vertical .progress__item {
  float: left;
  min-height: unset;
}
.progress_vertical .progress__item::before {
  height: 0.188rem;
  width: 100%;
  top: calc(0.5rem - 0.094rem);
  left: calc(50% - 0.4rem);
}
.progress_vertical .progress__item::after {
  left: 50%;
  width: 1rem;
  height: 1rem;
  border-radius: 0.125rem;
  transform: translate(-50%);
  font-size: 0.563rem !important;
}
.progress_vertical .progress__tile {
  margin: 1.5rem 1rem 0.5rem;
  max-width: 10rem;
}
.progress_vertical .progress__tile > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Shine */
.progress-type1 {
  height: 2.125rem;
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.5rem;
  background-color: #fff;
  position: relative;
}
.progress-type1 .progress-bar,
.progress-type1 .progress-details {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.progress-type1 .progress-details {
  width: 100%;
  align-items: center;
  padding: 0 0.5rem;
}
.progress-type1 .progress-bar {
  background-color: #dff0d8;
}

.progress-type3 {
  height: 1rem;
  border-radius: 0.75rem;
  background-color: #dee3e7 !important;
  display: flex;
  align-items: center;
  padding: 0.167rem;
}
.progress-type3 .progress-bar {
  height: 0.667rem;
  border-radius: 0.75rem;
}
.progress-type3 .bg-success {
  background-color: #31aab7 !important;
}

.progress-type4 {
  height: 0.5rem;
  border-radius: 0.5rem;
  background-color: #eff4f6 !important;
  display: flex;
  align-items: center;
  padding: 0rem;
}
.progress-type4 .progress-bar {
  height: 100%;
  border-radius: 0.5rem;
  background-color: #0078ce;
}
.progress-type4 .progress-bar.bg-info {
  background-color: #6562cf !important;
}
.progress-type4 .progress-bar.bg-danger {
  background-color: #dc3545 !important;
}
.progress-type4 .progress-bar.bg-warning {
  background-color: #ffc107 !important;
}
.progress-type4.h-13 {
  height: 0.8125rem;
}

.timeline {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.timeline.timeline-vetical {
  display: flex;
  flex-wrap: wrap;
  gap: 0.625rem;
  overflow: hidden;
}
.timeline.timeline-vetical .timeline-item:not(:last-child) {
  position: relative;
  padding-right: 3.125rem;
}
.timeline.timeline-vetical .timeline-item:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 0.0625rem;
  border-bottom: 0.0625rem dashed #acbac3;
  top: 0.813rem;
  left: 2.875rem;
}
.timeline .timeline-title {
  color: white;
  border-radius: 0.125rem;
  display: inline-flex;
  align-items: center;
  padding: 0 0.563rem;
  height: 1.375rem;
  font-size: 0.75rem;
  font-weight: 500;
  z-index: 9;
  position: relative;
  margin-bottom: 0.375rem;
}
.timeline .timeline-content {
  font-weight: 400;
  font-size: 0.875rem;
}

.progress-type2 {
  width: 100%;
  background-color: #fff;
  border-radius: 0.25rem;
}
.progress-type2 .progress-bar {
  border-radius: 0.25rem;
}
.progress-type2 .progress-bar.bg-primary {
  background-color: #31aab7 !important;
}

.vetification-progress {
  position: relative;
  width: 60%;
}
.vetification-progress div {
  z-index: 2;
}
.vetification-progress.progress-success::before, .vetification-progress.progress-success::after, .vetification-progress.progress-danger::before, .vetification-progress.progress-danger::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.vetification-progress.progress-success::before, .vetification-progress.progress-danger::before {
  content: "";
  background-color: var(--v-color);
  height: 2px;
  width: 100%;
}
.vetification-progress.progress-success::after, .vetification-progress.progress-danger::after {
  font-family: "icomoon";
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 2px solid var(--v-color);
  color: var(--v-color);
  outline: 3px solid #fff;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
}
.vetification-progress.progress-success {
  --v-color: #009722;
}
.vetification-progress.progress-success::after {
  content: "";
}
.vetification-progress.progress-danger {
  --v-color: #dc3545;
}
.vetification-progress.progress-danger::after {
  content: "";
  font-size: 1rem;
}
.vetification-progress:not(.progress-pending) .lds-ellipsis {
  display: none;
}
.vetification-progress.progress-pending .lds-ellipsis {
  width: 82px;
  height: 13px;
  position: relative;
  top: 0;
  left: 0;
}
.vetification-progress.progress-pending .lds-ellipsis div {
  background-color: #c3cbd1 !important;
}

.progress-vertical {
  --c-success: #28a745;
  --c-warning: #e29f24;
  --c-default: #e4e8ea;
  --c-primary: #0078ce;
  --c-danger: #dc3545;
  padding-left: 0;
  margin-bottom: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  color: #566976;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.progress-vertical li {
  font-size: 0.75rem;
  text-align: center;
  position: relative;
  padding: 1.5rem 0.75rem 0;
}
.progress-vertical li:not(:first-child)::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 0.125rem;
  top: calc(1rem/2);
  left: 0%;
  transform: translateY(-50%);
}
.progress-vertical li:not(:last-child)::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 0.125rem;
  top: calc(1rem/2);
  left: 50%;
  transform: translateY(-50%);
}
.progress-vertical li > *::before {
  position: absolute;
  content: var(--icon);
  font-family: bootstrap-icons !important;
  font-size: 1rem;
  top: calc(-.5rem/2);
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  background-color: #fff;
  z-index: 10;
}
.progress-vertical .progress-status-success {
  --icon: "";
}
.progress-vertical .progress-status-success::after {
  background-color: var(--c-success);
}
.progress-vertical .progress-status-success ~ li::before {
  background-color: var(--c-success);
}
.progress-vertical .progress-status-success > *::before {
  color: var(--c-success);
}
.progress-vertical .progress-status-warning {
  --icon: "";
}
.progress-vertical .progress-status-warning::after {
  background-color: var(--c-warning);
}
.progress-vertical .progress-status-warning ~ li::before {
  background-color: var(--c-warning);
}
.progress-vertical .progress-status-warning > *::before {
  color: var(--c-warning);
}
.progress-vertical .progress-status-default {
  --icon: "";
}
.progress-vertical .progress-status-default::after {
  background-color: var(--c-default);
}
.progress-vertical .progress-status-default ~ li::before {
  background-color: var(--c-default);
}
.progress-vertical .progress-status-default > *::before {
  color: var(--c-default);
}
.progress-vertical .progress-status-primary {
  --icon: "";
}
.progress-vertical .progress-status-primary::after {
  background-color: var(--c-primary);
}
.progress-vertical .progress-status-primary ~ li::before {
  background-color: var(--c-primary);
}
.progress-vertical .progress-status-primary > *::before {
  color: var(--c-primary);
}
.progress-vertical .progress-status-danger {
  --icon: "";
}
.progress-vertical .progress-status-danger::after {
  background-color: var(--c-danger);
}
.progress-vertical .progress-status-danger ~ li::before {
  background-color: var(--c-danger);
}
.progress-vertical .progress-status-danger > *::before {
  color: var(--c-danger);
}

.toast-c1 {
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a !important;
  background-color: #ffffff !important;
  width: 31rem !important;
  border-radius: 0.25rem !important;
  padding: 1rem 1.5rem 1rem 3rem !important;
  background-position: 1.125rem 1.25rem !important;
  background-size: 1.125rem !important;
  color: #324552;
}
.toast-c1::hover {
  cursor: pointer;
}
.toast-c1 .toast-title {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.25rem;
  color: #324552;
}
.toast-c1 .toast-message {
  font-size: 0.875rem;
  color: #324552;
}
.toast-c1 .toast-close-button {
  font-weight: normal;
  color: #324552;
}

.toast-success {
  background-image: url(/assets/icons/svg/ico-tick-green.svg) !important;
  background-color: white !important;
}
.toast-success .toast-title {
  color: #28a745 !important;
}

.toast-error {
  background-image: url(/assets/icons/svg/ico-warning-danger.svg);
  background-color: white !important;
}
.toast-error .toast-title {
  color: #dc3545 !important;
}

.toast-warning {
  background-image: url(/assets/icons/svg/ico-warning-warning.svg);
  background-color: white !important;
}
.toast-warning .toast-title {
  color: #ffc107 !important;
}

.toast-custom {
  background-image: none;
  background-color: transparent !important;
  width: 25rem;
}

.toast-custom .toast-close-button {
  position: relative;
  right: 0.2em;
  top: 0.2em;
  font-weight: 500;
  color: #191818;
  pointer-events: auto;
}

.notification-new {
  border-radius: 0.5rem;
  overflow: hidden;
}
.notification-new .toast-close-button {
  top: 0.625rem;
  font-weight: 400;
}

.notification-icon {
  /* Time and attendance */
  /* Payroll */
  /* HR */
  /* Letter */
}
.notification-icon i {
  font-family: "icomoon" !important;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: inherit;
}
.notification-icon.type-1 {
  background-color: #dbecf8;
  color: #0078ce;
  font-size: 1.25rem;
}
.notification-icon.type-1 i::before {
  content: "";
}
.notification-icon.type-2 {
  background-color: #f6cece;
  color: #cb786c;
  font-size: 1.25rem;
}
.notification-icon.type-2 i::before {
  content: "";
}
.notification-icon.type-3 {
  background-color: #fdf4c4;
  color: #f6ad05;
  font-size: 1.1875rem;
}
.notification-icon.type-3 i::before {
  content: "";
}
.notification-icon.type-4 {
  background-color: #CBECBE;
  color: #129AA6;
  font-size: 1.25rem;
}
.notification-icon.type-4 i::before {
  content: "";
}

.toast-top-right {
  overflow: hidden;
  border-radius: 0.5rem;
}

.toast-top-right.toast-container .toast-close-button {
  z-index: 100;
  font-weight: 400;
  top: 1rem;
  right: 1rem;
  position: absolute;
}

.toast-container > :not(:last-child) {
  margin-bottom: 0.5rem;
}

.mail-container {
  background-color: white;
  margin: auto;
}
.mail-container .mail-body {
  border: 0.0625rem solid #6970751A;
}
.mail-container .mail-body .mail-header {
  display: flex;
  background-color: #F8FAFB;
}
.mail-container .mail-body .mail-header .header-img {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.mail-container .mail-body .mail-header .mail-title {
  padding-top: 5.1875rem;
  max-width: 21.125rem;
}
.mail-container .mail-body .mail-content {
  padding: 2rem 5rem;
}
.mail-container .mail-body .mail-content p {
  font-size: 0.875rem;
}
.mail-container .mail-footer {
  display: flex;
  margin-top: 1rem;
}
.mail-container .mail-footer p {
  font-size: 0.75rem;
  color: #7d92a1;
  margin-bottom: 0;
  line-height: 1rem;
}

.config-layout {
  margin-top: -3rem;
  animation: 500ms ease-in 0s 1 slideInFromLeft;
  z-index: 1000;
  position: relative;
}

.config-header {
  background-color: #ffffff;
  padding: 1rem 2rem;
  height: 3.625rem;
  box-shadow: 0rem 0.0625rem 0.0625rem 0rem #6970751a;
  position: sticky;
  top: 0;
  z-index: 1500;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
  position: fixed;
}

.main-sidebar.expand ~ .main-container .config-header {
  width: calc(100% - 15rem);
}

.main-sidebar:not(.expand) ~ .main-container .config-header {
  width: calc(100% - 3.75rem);
}

.config-topbar {
  background-color: #fff;
  z-index: 999;
}
.config-topbar .nav-item {
  flex-grow: 1;
}

.config-topbar ~ .config-container .form-container {
  min-height: calc(100vh - (3.6875rem + 5.5rem + 6rem));
}

.config-header ~ .config-container .form-container {
  min-height: calc(100vh - (3.625rem + 4.5rem));
}

.config-header ~ .config-container.tabs-container {
  min-height: calc(100vh - (3.625rem + 4.5rem + 2.5rem));
}
.config-header ~ .config-container.tabs-container .form-container {
  min-height: calc(100vh - (3.625rem + 4.5rem + 2.5rem));
}

.config-sidebar {
  background: #ffffff;
  width: 15.75rem;
  position: fixed;
  top: 3.625rem;
  height: calc(100vh - 3.625rem);
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
}

.card-details {
  background: #f8fafb;
  border: 0.0625rem solid #e4e8ea;
  padding: 1rem;
  text-transform: uppercase;
  box-shadow: none;
  border-radius: 0.25rem;
  font-weight: 400;
}
.card-details .highlight {
  color: #0078ce;
  font-weight: 700;
}

.config-sidebar ~ .config-container {
  width: calc(100% - 15.75rem);
  margin-left: 15.75rem;
}
.config-sidebar ~ .config-container .form-container {
  min-height: calc(100vh - (3.625rem + 4.5rem));
}

.config-container {
  top: 3.625rem;
  min-height: calc(100vh - 3.625rem);
  position: relative;
}
.config-container .form-container {
  height: 100%;
}
.config-container .submit-footer {
  position: sticky;
  bottom: 0;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: center;
  height: 4.5rem;
  width: 100%;
  margin-top: auto;
  background: #ffffff;
  padding: 1rem 2rem;
  box-shadow: 0.0625rem 0rem 0.25rem rgba(105, 112, 17, 0.2);
  z-index: 1500;
  border-left: 0.0625rem solid #eaeaea;
  justify-content: end;
}

.sidebar-2 {
  width: 11.25rem;
}
.sidebar-2.config-sidebar ~ .config-container {
  width: calc(100% - 11.25rem);
  margin-left: 11.25rem;
}

.sidebar-collapse {
  overflow-y: auto;
}
.sidebar-collapse:not(.active) > :not(.sidebar-collapse-btn) {
  display: none;
}
.sidebar-collapse {
  width: 3rem;
}
.sidebar-collapse.config-sidebar ~ .config-container {
  width: calc(100% - 3rem);
  margin-left: 3rem;
}
.sidebar-collapse.active {
  width: 17.375rem;
}
.sidebar-collapse.active.config-sidebar ~ .config-container {
  width: calc(100% - 17.375rem);
  margin-left: 17.375rem;
}

.steps-2 {
  overflow-y: auto;
  overflow-x: hidden;
  list-style-type: none;
  padding-left: 0;
}
.steps-2 .nav-link {
  padding: 1rem 1.5rem;
  position: relative;
  padding-left: calc(1.5rem + 1.125rem);
}
.steps-2 .nav-link::before {
  font-family: "icomoon" !important;
  content: "";
  color: #0078ce;
  font-size: 0.875rem;
  position: absolute;
  left: 1.35rem;
}
.steps-2 .nav-item .nav-link.active {
  background-color: #dceafb;
}
.steps-2 li + li {
  border-top: 1px solid #e4e8ea;
}

.steps {
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 2rem;
  display: flex;
}
.steps li {
  width: 100%;
}
.steps .step {
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: initial;
  padding: 0;
}
.steps .step .count {
  width: 2.5rem !important;
  height: 2.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 50%;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  color: #7d92a1;
  flex-shrink: 0;
}
.steps .step .content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: initial;
  width: 100%;
  flex-wrap: nowrap;
}
.steps .step .content span {
  font-size: 0.75rem;
  text-transform: uppercase;
  color: #7d92a1;
}
.steps .step .content h6 {
  margin-bottom: 0;
  color: #7d92a1;
}
.steps .step.active .count {
  border: 0.0625rem solid #0078ce;
}
.steps .step.active .count,
.steps .step.active .content span,
.steps .step.active .content h6 {
  color: #0078ce;
}
.steps .step.success:not(.active) .count,
.steps .step.success:not(.active) .content span {
  color: #28a745;
}
.steps .step.success:not(.active) .content h6 {
  color: #28a745;
}
.steps .step.success:not(.active) .count {
  background: #28a745;
  border: none;
  position: relative;
}
.steps .step.success:not(.active) .count::before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  inset: 0;
  color: #fff;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
}

.config-container .sticky-profile-card {
  top: calc(1.5rem + 3.625rem) !important;
}
.config-container .tab-scroll {
  max-height: calc(100vh - (3.625rem + 15.1875rem)) !important;
}

.config-toolbar {
  background-color: white;
  flex-shrink: 0;
  height: calc(100vh - (3.625rem + 4.5rem));
  overflow: auto;
}

.table,
table {
  width: 100%;
  margin-bottom: 0;
  font-family: "Work Sans", sans-serif !important;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.02);
}
.table thead th,
table thead th {
  padding: 1rem;
  font-weight: 600;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: #324552;
  background-color: #fff;
  white-space: nowrap;
}
.table tbody td,
table tbody td {
  padding: 0.5rem 1rem;
  vertical-align: middle;
}
.table > :not(:last-child) > :last-child > *,
table > :not(:last-child) > :last-child > * {
  border-color: #e4e8ea;
}

.mat-sort th,
.mat-sort td {
  padding: 1rem 1rem 1rem 1.5rem !important;
}

.table > :not(:first-child) {
  border-top: none;
}

.form-table .form-cell {
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  text-align: left;
  vertical-align: middle;
}
.form-table .form-cell .form-select {
  border-color: transparent;
  background-color: transparent;
  padding-left: 0;
  padding-right: 1.563rem;
  background-position: right 0.188rem center;
  font-size: 1rem;
  min-width: 9.125rem;
}
.form-table .form-cell .form-select:focus {
  border-color: #31aab7;
}
.form-table .form-cell label {
  width: 100%;
  min-width: 2.5rem;
  height: 2.5rem;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-table .form-cell .form-check-input {
  background-size: 0.813rem;
  width: 1.25rem;
  height: 1.25rem;
}
.form-table .form-cell .form-control {
  background-color: transparent;
  min-width: 5rem;
}
.form-table input[type=checkbox] {
  margin: 0;
  border-color: #acbac3;
}

.cell-16 td {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.table-sm {
  font-size: 0.8rem;
}
.table-sm th {
  font-weight: 600;
  padding: 1rem;
  white-space: nowrap;
}
.table-sm td {
  font-weight: 400;
  padding: 0.75rem 1rem;
  color: #324552 !important;
}
.table-sm tr:last-child td {
  border-bottom: none;
}

[class*=highlight] {
  position: relative;
}
[class*=highlight]:before {
  content: "";
  position: absolute;
  width: 0.25rem;
  height: 1.5rem;
  left: 0;
}

.highlight-success:before {
  background: #28a745;
}

.highlight-danger:before {
  background: #dc3545;
}

.vertical-align-top tbody td {
  vertical-align: top !important;
}

.form-cell .form-ngselect.is-invalid .ng-select-container {
  background-repeat: no-repeat;
  background-position: right 1.875rem center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.form-cell .form-ngselect.is-invalid .ng-value-label {
  padding-right: 1.5rem;
}

.form-cell ng-select, .form-cell input:not([type=checkbox]):not([type=radio]), .form-cell app-master-select ng-select {
  min-width: 12.5rem !important;
  max-width: 15.625rem;
}

.form-cell-sm ng-select, .form-cell-sm input:not([type=checkbox]):not([type=radio]), .form-cell-sm app-master-select ng-select {
  width: 9rem !important;
}

.sticky-header thead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky-header thead tr th,
.sticky-header thead {
  box-shadow: 0rem 0rem 0.125rem #e4e8ea;
  z-index: 11;
}

.sticky-first-col tr th:first-child,
.sticky-first-col tr td:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
}

.sticky-last-col tr th:last-child,
.sticky-last-col tr td:last-child {
  position: sticky;
  right: 0;
  z-index: 1;
}

.td-white-space td {
  white-space: nowrap !important;
}

.th-vertical-middele th {
  vertical-align: middle;
}

.table-responsive {
  scroll-behavior: smooth;
}

.cdk-drag-preview {
  background: #fafafa;
  border: 0.0625rem solid #e4e8ea;
  opacity: 1;
  border-radius: 0.25rem;
  min-height: 3.5rem;
  padding: 1rem !important;
  font-size: 0.875rem;
  vertical-align: middle;
  text-transform: uppercase;
  white-space: nowrap;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: relative;
}
.cdk-drag-preview .mat-sort-header-container {
  padding-left: 1.5rem;
}
.cdk-drag-preview::before {
  position: absolute;
  content: "";
  font-family: "icomoon";
  left: 1rem;
  color: #7d92a1;
  font-size: 0.625rem;
  transform: translateX(-50%);
}

.cdk-drag-dragging {
  background-color: #000;
  width: 0.3125rem;
  height: 100%;
  border: 0.0625rem solid #000;
}

.card-c2 .table tr:last-child td {
  border-bottom: 0rem;
}

.example-custom-placeholder {
  border: dotted 0.1875rem #999 !important;
  min-height: 3.5rem;
  width: 100%;
  height: 100%;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  position: relative;
}

.cdk-drop-list:not(.cdk-drop-list-dragging) th.cdk-drag:hover {
  position: relative;
}
.cdk-drop-list:not(.cdk-drop-list-dragging) th.cdk-drag:hover::before {
  position: absolute;
  content: "";
  font-family: "icomoon";
  left: 10px;
  color: #E4E8EA;
  font-size: 10px;
  transform: translateX(-50%);
}

.table-navigation {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
  width: calc(100% - 1.375rem);
  display: flex;
  justify-content: space-between;
}
.table-navigation .btn-left,
.table-navigation .btn-right {
  width: 2.375rem;
  height: 4.125rem;
  background: #acbac3;
  opacity: 0.5;
  border-radius: 0.25rem;
  color: #566976;
  font-size: 0.875rem;
}
.table-navigation .btn-left i,
.table-navigation .btn-right i {
  pointer-events: none;
}
.table-navigation .btn-left:hover,
.table-navigation .btn-right:hover {
  opacity: 1;
}
.table-navigation .btn-right {
  transform: rotate(180deg);
}

.add-column {
  position: absolute;
  top: 0.75rem;
  right: 0.438rem;
  z-index: 50;
}
.add-column .dropdown-toggle {
  aspect-ratio: 1;
  z-index: 3;
  position: relative;
}
.add-column .dropdown-toggle:not(:hover) {
  background-color: #fff;
  color: #31aab7;
}

.table-filter {
  max-height: 12.5rem;
  overflow-y: auto;
}

.cell-error {
  background-color: #f8e0e0 !important;
  padding: 0.5rem;
  border-radius: 0.25rem;
  min-height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.313rem;
}

.td-dropdown .dropdown:not(.show) .dropdown-menu {
  display: none;
}
.td-dropdown .dropdown-toggle {
  height: 2.5rem;
  padding-right: 1.75rem;
  width: 100%;
  display: flex;
  align-items: start;
  justify-content: center;
  color: #324552;
}

body > .dropdown {
  z-index: 2000 !important;
}

.table-sm tr.mat-header-row,
.table-sm thead tr {
  height: 2.75rem;
  vertical-align: middle;
}
.table-sm tr.mat-row,
.table-sm tbody tr {
  height: 2.5rem;
}
.table-sm .mat-cell,
.table-sm .mat-footer-cell {
  font-size: 0.875rem;
}

.td-text-break td {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

td .form-control,
td .form-select {
  font-size: 0.875rem;
}

.table-striped {
  --bs-table-striped-color: #324552;
  --bs-table-striped-bg: #f3f3f3;
}

.table-striped > tbody > tr.td-alert > * {
  background-color: #f8e0e0 !important;
  --bs-table-accent-bg: #f8e0e0 !important;
  --bs-table-bg-type: #f8e0e0 !important;
}

.td-width-15ch td {
  max-width: 15ch;
  text-overflow: ellipsis;
  overflow: hidden;
}

.td-checkbox-16 {
  width: 3rem;
}

.cell-status {
  position: relative;
}
.cell-status::before, .cell-status::after {
  position: absolute;
  width: calc(100% + 1px);
  font-size: 0.625rem;
  height: 1.5rem;
  top: calc(100% - 1.5rem);
  left: 0;
  white-space: nowrap;
  display: block;
  text-align: center;
  padding: 0 0.3125rem;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.5rem;
  z-index: 10;
}
.cell-status.fc-day-today::before, .cell-status.fc-day-today::after {
  width: calc(100% - 2px) !important;
  left: 1px;
  top: calc(100% - 1.5rem - 1px);
}
.cell-status.status-unknown::before {
  content: "  ";
  color: var( );
  background-color: var(--bg-unknown);
}
.cell-status.status-shift-unassigned::before {
  content: " Shift not assigned";
  color: var(--c-unassigned);
  background-color: var(--bg-unassigned);
}
.cell-status.status-leave::before {
  content: " Leave";
  color: var(--c-leave);
  background-color: var(--bg-leave);
}
.cell-status.status-present::before {
  content: " Present";
  color: var(--c-present);
  background-color: var(--bg-present);
}
.cell-status.status-absent::before {
  content: " Absent";
  color: var(--c-absent);
  background-color: var(--bg-absent);
}
.cell-status.status-weekoff::before {
  content: " Weekoff";
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.cell-status.status-holiday::before {
  content: " Holiday";
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.cell-status.status-onduty::before {
  content: " ";
  color: var();
  background-color: var(--bg-onduty);
}
.cell-status.status-wfh::before {
  content: "  ";
  color: var( );
  background-color: var(--bg--WFH);
}
.cell-status.status-unknown-leave::before {
  width: 50% !important;
  left: 0rem;
  content: " ";
  color: var( );
  background-color: var(--bg-unknown);
}
.cell-status.status-unknown-leave::after {
  width: 50% !important;
  left: 50%;
  content: "Leave";
  background-color: var(--bg-leave);
  color: var(--c-leave);
}
.cell-status.status-unknown-leave.fc-day-today::before {
  left: 1px;
}
.cell-status.status-unknown-leave.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-unknown-present::before {
  width: 50% !important;
  left: 0rem;
  content: " ";
  color: var( );
  background-color: var(--bg-unknown);
}
.cell-status.status-unknown-present::after {
  width: 50% !important;
  left: 50%;
  content: "Present";
  background-color: var(--bg-present);
  color: var(--c-present);
}
.cell-status.status-unknown-present.fc-day-today::before {
  left: 1px;
}
.cell-status.status-unknown-present.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-unknown-absent::before {
  width: 50% !important;
  left: 0rem;
  content: " ";
  color: var( );
  background-color: var(--bg-unknown);
}
.cell-status.status-unknown-absent::after {
  width: 50% !important;
  left: 50%;
  content: "Absent";
  background-color: var(--bg-absent);
  color: var(--c-absent);
}
.cell-status.status-unknown-absent.fc-day-today::before {
  left: 1px;
}
.cell-status.status-unknown-absent.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-unknown-weekoff::before {
  width: 50% !important;
  left: 0rem;
  content: " ";
  color: var( );
  background-color: var(--bg-unknown);
}
.cell-status.status-unknown-weekoff::after {
  width: 50% !important;
  left: 50%;
  content: "Weekoff";
  background-color: var(--bg-weekoff);
  color: var(--c-weekoff);
}
.cell-status.status-unknown-weekoff.fc-day-today::before {
  left: 1px;
}
.cell-status.status-unknown-weekoff.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-unknown-holiday::before {
  width: 50% !important;
  left: 0rem;
  content: " ";
  color: var( );
  background-color: var(--bg-unknown);
}
.cell-status.status-unknown-holiday::after {
  width: 50% !important;
  left: 50%;
  content: "Holiday";
  background-color: var(--bg-holiday);
  color: var(--c-holiday);
}
.cell-status.status-unknown-holiday.fc-day-today::before {
  left: 1px;
}
.cell-status.status-unknown-holiday.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-leave-unknown::before {
  width: 50% !important;
  left: 0rem;
  content: "Leave";
  color: var(--c-leave);
  background-color: var(--bg-leave);
}
.cell-status.status-leave-unknown::after {
  width: 50% !important;
  left: 50%;
  content: " ";
  background-color: var(--bg-unknown);
  color: var( );
}
.cell-status.status-leave-unknown.fc-day-today::before {
  left: 1px;
}
.cell-status.status-leave-unknown.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-leave-present::before {
  width: 50% !important;
  left: 0rem;
  content: "Leave";
  color: var(--c-leave);
  background-color: var(--bg-leave);
}
.cell-status.status-leave-present::after {
  width: 50% !important;
  left: 50%;
  content: "Present";
  background-color: var(--bg-present);
  color: var(--c-present);
}
.cell-status.status-leave-present.fc-day-today::before {
  left: 1px;
}
.cell-status.status-leave-present.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-leave-absent::before {
  width: 50% !important;
  left: 0rem;
  content: "Leave";
  color: var(--c-leave);
  background-color: var(--bg-leave);
}
.cell-status.status-leave-absent::after {
  width: 50% !important;
  left: 50%;
  content: "Absent";
  background-color: var(--bg-absent);
  color: var(--c-absent);
}
.cell-status.status-leave-absent.fc-day-today::before {
  left: 1px;
}
.cell-status.status-leave-absent.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-leave-weekoff::before {
  width: 50% !important;
  left: 0rem;
  content: "Leave";
  color: var(--c-leave);
  background-color: var(--bg-leave);
}
.cell-status.status-leave-weekoff::after {
  width: 50% !important;
  left: 50%;
  content: "Weekoff";
  background-color: var(--bg-weekoff);
  color: var(--c-weekoff);
}
.cell-status.status-leave-weekoff.fc-day-today::before {
  left: 1px;
}
.cell-status.status-leave-weekoff.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-leave-holiday::before {
  width: 50% !important;
  left: 0rem;
  content: "Leave";
  color: var(--c-leave);
  background-color: var(--bg-leave);
}
.cell-status.status-leave-holiday::after {
  width: 50% !important;
  left: 50%;
  content: "Holiday";
  background-color: var(--bg-holiday);
  color: var(--c-holiday);
}
.cell-status.status-leave-holiday.fc-day-today::before {
  left: 1px;
}
.cell-status.status-leave-holiday.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-present-unknown::before {
  width: 50% !important;
  left: 0rem;
  content: "Present";
  color: var(--c-present);
  background-color: var(--bg-present);
}
.cell-status.status-present-unknown::after {
  width: 50% !important;
  left: 50%;
  content: " ";
  background-color: var(--bg-unknown);
  color: var( );
}
.cell-status.status-present-unknown.fc-day-today::before {
  left: 1px;
}
.cell-status.status-present-unknown.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-present-leave::before {
  width: 50% !important;
  left: 0rem;
  content: "Present";
  color: var(--c-present);
  background-color: var(--bg-present);
}
.cell-status.status-present-leave::after {
  width: 50% !important;
  left: 50%;
  content: "Leave";
  background-color: var(--bg-leave);
  color: var(--c-leave);
}
.cell-status.status-present-leave.fc-day-today::before {
  left: 1px;
}
.cell-status.status-present-leave.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-present-absent::before {
  width: 50% !important;
  left: 0rem;
  content: "Present";
  color: var(--c-present);
  background-color: var(--bg-present);
}
.cell-status.status-present-absent::after {
  width: 50% !important;
  left: 50%;
  content: "Absent";
  background-color: var(--bg-absent);
  color: var(--c-absent);
}
.cell-status.status-present-absent.fc-day-today::before {
  left: 1px;
}
.cell-status.status-present-absent.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-present-weekoff::before {
  width: 50% !important;
  left: 0rem;
  content: "Present";
  color: var(--c-present);
  background-color: var(--bg-present);
}
.cell-status.status-present-weekoff::after {
  width: 50% !important;
  left: 50%;
  content: "Weekoff";
  background-color: var(--bg-weekoff);
  color: var(--c-weekoff);
}
.cell-status.status-present-weekoff.fc-day-today::before {
  left: 1px;
}
.cell-status.status-present-weekoff.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-present-holiday::before {
  width: 50% !important;
  left: 0rem;
  content: "Present";
  color: var(--c-present);
  background-color: var(--bg-present);
}
.cell-status.status-present-holiday::after {
  width: 50% !important;
  left: 50%;
  content: "Holiday";
  background-color: var(--bg-holiday);
  color: var(--c-holiday);
}
.cell-status.status-present-holiday.fc-day-today::before {
  left: 1px;
}
.cell-status.status-present-holiday.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-absent-unknown::before {
  width: 50% !important;
  left: 0rem;
  content: "Absent";
  color: var(--c-absent);
  background-color: var(--bg-absent);
}
.cell-status.status-absent-unknown::after {
  width: 50% !important;
  left: 50%;
  content: " ";
  background-color: var(--bg-unknown);
  color: var( );
}
.cell-status.status-absent-unknown.fc-day-today::before {
  left: 1px;
}
.cell-status.status-absent-unknown.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-absent-leave::before {
  width: 50% !important;
  left: 0rem;
  content: "Absent";
  color: var(--c-absent);
  background-color: var(--bg-absent);
}
.cell-status.status-absent-leave::after {
  width: 50% !important;
  left: 50%;
  content: "Leave";
  background-color: var(--bg-leave);
  color: var(--c-leave);
}
.cell-status.status-absent-leave.fc-day-today::before {
  left: 1px;
}
.cell-status.status-absent-leave.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-absent-present::before {
  width: 50% !important;
  left: 0rem;
  content: "Absent";
  color: var(--c-absent);
  background-color: var(--bg-absent);
}
.cell-status.status-absent-present::after {
  width: 50% !important;
  left: 50%;
  content: "Present";
  background-color: var(--bg-present);
  color: var(--c-present);
}
.cell-status.status-absent-present.fc-day-today::before {
  left: 1px;
}
.cell-status.status-absent-present.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-absent-weekoff::before {
  width: 50% !important;
  left: 0rem;
  content: "Absent";
  color: var(--c-absent);
  background-color: var(--bg-absent);
}
.cell-status.status-absent-weekoff::after {
  width: 50% !important;
  left: 50%;
  content: "Weekoff";
  background-color: var(--bg-weekoff);
  color: var(--c-weekoff);
}
.cell-status.status-absent-weekoff.fc-day-today::before {
  left: 1px;
}
.cell-status.status-absent-weekoff.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-absent-holiday::before {
  width: 50% !important;
  left: 0rem;
  content: "Absent";
  color: var(--c-absent);
  background-color: var(--bg-absent);
}
.cell-status.status-absent-holiday::after {
  width: 50% !important;
  left: 50%;
  content: "Holiday";
  background-color: var(--bg-holiday);
  color: var(--c-holiday);
}
.cell-status.status-absent-holiday.fc-day-today::before {
  left: 1px;
}
.cell-status.status-absent-holiday.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-weekoff-unknown::before {
  width: 50% !important;
  left: 0rem;
  content: "Weekoff";
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.cell-status.status-weekoff-unknown::after {
  width: 50% !important;
  left: 50%;
  content: " ";
  background-color: var(--bg-unknown);
  color: var( );
}
.cell-status.status-weekoff-unknown.fc-day-today::before {
  left: 1px;
}
.cell-status.status-weekoff-unknown.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-weekoff-leave::before {
  width: 50% !important;
  left: 0rem;
  content: "Weekoff";
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.cell-status.status-weekoff-leave::after {
  width: 50% !important;
  left: 50%;
  content: "Leave";
  background-color: var(--bg-leave);
  color: var(--c-leave);
}
.cell-status.status-weekoff-leave.fc-day-today::before {
  left: 1px;
}
.cell-status.status-weekoff-leave.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-weekoff-present::before {
  width: 50% !important;
  left: 0rem;
  content: "Weekoff";
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.cell-status.status-weekoff-present::after {
  width: 50% !important;
  left: 50%;
  content: "Present";
  background-color: var(--bg-present);
  color: var(--c-present);
}
.cell-status.status-weekoff-present.fc-day-today::before {
  left: 1px;
}
.cell-status.status-weekoff-present.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-weekoff-absent::before {
  width: 50% !important;
  left: 0rem;
  content: "Weekoff";
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.cell-status.status-weekoff-absent::after {
  width: 50% !important;
  left: 50%;
  content: "Absent";
  background-color: var(--bg-absent);
  color: var(--c-absent);
}
.cell-status.status-weekoff-absent.fc-day-today::before {
  left: 1px;
}
.cell-status.status-weekoff-absent.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-weekoff-holiday::before {
  width: 50% !important;
  left: 0rem;
  content: "Weekoff";
  color: var(--c-weekoff);
  background-color: var(--bg-weekoff);
}
.cell-status.status-weekoff-holiday::after {
  width: 50% !important;
  left: 50%;
  content: "Holiday";
  background-color: var(--bg-holiday);
  color: var(--c-holiday);
}
.cell-status.status-weekoff-holiday.fc-day-today::before {
  left: 1px;
}
.cell-status.status-weekoff-holiday.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-holiday-unknown::before {
  width: 50% !important;
  left: 0rem;
  content: "Holiday";
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.cell-status.status-holiday-unknown::after {
  width: 50% !important;
  left: 50%;
  content: " ";
  background-color: var(--bg-unknown);
  color: var( );
}
.cell-status.status-holiday-unknown.fc-day-today::before {
  left: 1px;
}
.cell-status.status-holiday-unknown.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-holiday-leave::before {
  width: 50% !important;
  left: 0rem;
  content: "Holiday";
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.cell-status.status-holiday-leave::after {
  width: 50% !important;
  left: 50%;
  content: "Leave";
  background-color: var(--bg-leave);
  color: var(--c-leave);
}
.cell-status.status-holiday-leave.fc-day-today::before {
  left: 1px;
}
.cell-status.status-holiday-leave.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-holiday-present::before {
  width: 50% !important;
  left: 0rem;
  content: "Holiday";
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.cell-status.status-holiday-present::after {
  width: 50% !important;
  left: 50%;
  content: "Present";
  background-color: var(--bg-present);
  color: var(--c-present);
}
.cell-status.status-holiday-present.fc-day-today::before {
  left: 1px;
}
.cell-status.status-holiday-present.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-holiday-absent::before {
  width: 50% !important;
  left: 0rem;
  content: "Holiday";
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.cell-status.status-holiday-absent::after {
  width: 50% !important;
  left: 50%;
  content: "Absent";
  background-color: var(--bg-absent);
  color: var(--c-absent);
}
.cell-status.status-holiday-absent.fc-day-today::before {
  left: 1px;
}
.cell-status.status-holiday-absent.fc-day-today::after {
  left: calc(50% - 1px);
}
.cell-status.status-holiday-weekoff::before {
  width: 50% !important;
  left: 0rem;
  content: "Holiday";
  color: var(--c-holiday);
  background-color: var(--bg-holiday);
}
.cell-status.status-holiday-weekoff::after {
  width: 50% !important;
  left: 50%;
  content: "Weekoff";
  background-color: var(--bg-weekoff);
  color: var(--c-weekoff);
}
.cell-status.status-holiday-weekoff.fc-day-today::before {
  left: 1px;
}
.cell-status.status-holiday-weekoff.fc-day-today::after {
  left: calc(50% - 1px);
}

.sticky-first-col .cell-status::before,
.sticky-first-col .cell-status::after {
  z-index: 0;
}

.cell-status-2 {
  position: relative;
}
.cell-status-2 .status-span {
  width: 80%;
  height: 55%;
  border-radius: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
}
.cell-status-2.status-unknown .status-span {
  background-color: var(--bg-unknown);
}
.cell-status-2.status-unknown + .cell-status-2.status-unknown .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-unknown + .cell-status-2.status-unknown .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-shift-unassigned .status-span {
  background-color: var(--bg-unassigned);
}
.cell-status-2.status-shift-unassigned + .cell-status-2.status-shift-unassigned .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-shift-unassigned + .cell-status-2.status-shift-unassigned .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-leave .status-span {
  background-color: var(--bg-leave);
}
.cell-status-2.status-leave + .cell-status-2.status-leave .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-leave + .cell-status-2.status-leave .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-present .status-span {
  background-color: var(--bg-present);
}
.cell-status-2.status-present + .cell-status-2.status-present .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-present + .cell-status-2.status-present .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-absent .status-span {
  background-color: var(--bg-absent);
}
.cell-status-2.status-absent + .cell-status-2.status-absent .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-absent + .cell-status-2.status-absent .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-weekoff .status-span {
  background-color: var(--bg-weekoff);
}
.cell-status-2.status-weekoff + .cell-status-2.status-weekoff .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-weekoff + .cell-status-2.status-weekoff .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-holiday .status-span {
  background-color: var(--bg-holiday);
}
.cell-status-2.status-holiday + .cell-status-2.status-holiday .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-holiday + .cell-status-2.status-holiday .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-onduty .status-span {
  background-color: var(--bg-onduty);
}
.cell-status-2.status-onduty + .cell-status-2.status-onduty .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-onduty + .cell-status-2.status-onduty .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-wfh .status-span {
  background-color: var(--bg--WFH);
}
.cell-status-2.status-wfh + .cell-status-2.status-wfh .status-span::before {
  content: "";
  width: calc(20% + 2.25rem);
  height: 100%;
  background-color: inherit;
  position: absolute;
}
.cell-status-2.status-wfh + .cell-status-2.status-wfh .status-span::before {
  right: calc(100% - 16px);
}
.cell-status-2.status-unknown-leave .status-span {
  background-image: linear-gradient(to right, var(--bg-unknown) 50%, var(--bg-leave) 51%, var(--bg-leave) 100%);
}
.cell-status-2.status-unknown-present .status-span {
  background-image: linear-gradient(to right, var(--bg-unknown) 50%, var(--bg-present) 51%, var(--bg-present) 100%);
}
.cell-status-2.status-unknown-absent .status-span {
  background-image: linear-gradient(to right, var(--bg-unknown) 50%, var(--bg-absent) 51%, var(--bg-absent) 100%);
}
.cell-status-2.status-unknown-weekoff .status-span {
  background-image: linear-gradient(to right, var(--bg-unknown) 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
}
.cell-status-2.status-unknown-holiday .status-span {
  background-image: linear-gradient(to right, var(--bg-unknown) 50%, var(--bg-holiday) 51%, var(--bg-holiday) 100%);
}
.cell-status-2.status-leave-unknown .status-span {
  background-image: linear-gradient(to right, var(--bg-leave) 50%, var(--bg-unknown) 51%, var(--bg-unknown) 100%);
}
.cell-status-2.status-leave-present .status-span {
  background-image: linear-gradient(to right, var(--bg-leave) 50%, var(--bg-present) 51%, var(--bg-present) 100%);
}
.cell-status-2.status-leave-absent .status-span {
  background-image: linear-gradient(to right, var(--bg-leave) 50%, var(--bg-absent) 51%, var(--bg-absent) 100%);
}
.cell-status-2.status-leave-weekoff .status-span {
  background-image: linear-gradient(to right, var(--bg-leave) 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
}
.cell-status-2.status-leave-holiday .status-span {
  background-image: linear-gradient(to right, var(--bg-leave) 50%, var(--bg-holiday) 51%, var(--bg-holiday) 100%);
}
.cell-status-2.status-present-unknown .status-span {
  background-image: linear-gradient(to right, var(--bg-present) 50%, var(--bg-unknown) 51%, var(--bg-unknown) 100%);
}
.cell-status-2.status-present-leave .status-span {
  background-image: linear-gradient(to right, var(--bg-present) 50%, var(--bg-leave) 51%, var(--bg-leave) 100%);
}
.cell-status-2.status-present-absent .status-span {
  background-image: linear-gradient(to right, var(--bg-present) 50%, var(--bg-absent) 51%, var(--bg-absent) 100%);
}
.cell-status-2.status-present-weekoff .status-span {
  background-image: linear-gradient(to right, var(--bg-present) 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
}
.cell-status-2.status-present-holiday .status-span {
  background-image: linear-gradient(to right, var(--bg-present) 50%, var(--bg-holiday) 51%, var(--bg-holiday) 100%);
}
.cell-status-2.status-absent-unknown .status-span {
  background-image: linear-gradient(to right, var(--bg-absent) 50%, var(--bg-unknown) 51%, var(--bg-unknown) 100%);
}
.cell-status-2.status-absent-leave .status-span {
  background-image: linear-gradient(to right, var(--bg-absent) 50%, var(--bg-leave) 51%, var(--bg-leave) 100%);
}
.cell-status-2.status-absent-present .status-span {
  background-image: linear-gradient(to right, var(--bg-absent) 50%, var(--bg-present) 51%, var(--bg-present) 100%);
}
.cell-status-2.status-absent-weekoff .status-span {
  background-image: linear-gradient(to right, var(--bg-absent) 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
}
.cell-status-2.status-absent-holiday .status-span {
  background-image: linear-gradient(to right, var(--bg-absent) 50%, var(--bg-holiday) 51%, var(--bg-holiday) 100%);
}
.cell-status-2.status-weekoff-unknown .status-span {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, var(--bg-unknown) 51%, var(--bg-unknown) 100%);
}
.cell-status-2.status-weekoff-leave .status-span {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, var(--bg-leave) 51%, var(--bg-leave) 100%);
}
.cell-status-2.status-weekoff-present .status-span {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, var(--bg-present) 51%, var(--bg-present) 100%);
}
.cell-status-2.status-weekoff-absent .status-span {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, var(--bg-absent) 51%, var(--bg-absent) 100%);
}
.cell-status-2.status-weekoff-holiday .status-span {
  background-image: linear-gradient(to right, var(--bg-weekoff) 50%, var(--bg-holiday) 51%, var(--bg-holiday) 100%);
}
.cell-status-2.status-holiday-unknown .status-span {
  background-image: linear-gradient(to right, var(--bg-holiday) 50%, var(--bg-unknown) 51%, var(--bg-unknown) 100%);
}
.cell-status-2.status-holiday-leave .status-span {
  background-image: linear-gradient(to right, var(--bg-holiday) 50%, var(--bg-leave) 51%, var(--bg-leave) 100%);
}
.cell-status-2.status-holiday-present .status-span {
  background-image: linear-gradient(to right, var(--bg-holiday) 50%, var(--bg-present) 51%, var(--bg-present) 100%);
}
.cell-status-2.status-holiday-absent .status-span {
  background-image: linear-gradient(to right, var(--bg-holiday) 50%, var(--bg-absent) 51%, var(--bg-absent) 100%);
}
.cell-status-2.status-holiday-weekoff .status-span {
  background-image: linear-gradient(to right, var(--bg-holiday) 50%, var(--bg-weekoff) 51%, var(--bg-weekoff) 100%);
}

.table-bordered {
  --bs-border-color: #dee3e78f;
}

td[class^=bg] {
  box-shadow: none !important;
}

.current-day {
  background-color: var(--bg--currentday) !important;
}

th.current-day {
  position: relative;
}
th.current-day::before {
  outline: 1px solid #0078ce;
  content: "";
  height: 100%;
  width: 4.875rem;
  outline-offset: -1px;
  position: absolute;
  top: 0;
  left: 0;
}

.th-sort {
  cursor: pointer;
}
.th-sort .icon-th-sort::before {
  font-family: "icomoon" !important;
  content: "";
}
.th-sort .icon-th-sort:not(.th-sort-ascend, .th-sort-descend) {
  visibility: hidden;
}
.th-sort:hover .icon-th-sort {
  visibility: visible !important;
  opacity: 0.65;
  animation: bounce 0.6s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  display: inline-block;
}
.th-sort .th-sort-ascend.icon-th-sort::before {
  content: "";
}
.th-sort .th-sort-descend.icon-th-sort::before {
  content: "";
}

@keyframes bounce {
  from {
    transform: translate3d(0, -2px, 0);
  }
  to {
    transform: translate3d(0, 2px, 0);
  }
}
.sticky-header.sticky-first-col thead tr th:first-child {
  z-index: 12;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: initial;
  z-index: 7000;
}
.sidebar--menu-icon {
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: initial;
  flex: 1 1 0%;
  z-index: 1000;
  transition: background-color 400ms ease-in-out;
}
.sidebar--menu-icon .brand-logo {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  min-height: calc(3.75rem - 0.75rem);
}
.sidebar--menu-icon .menu-icon {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1000;
  height: calc(100vh - 3.75rem);
  display: flex;
  flex-direction: column;
  padding-top: 0.5rem;
}
.sidebar--menu-icon .menu-icon li {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  width: 3.75rem;
  position: relative;
}
.sidebar--menu-icon .menu-icon li a.icon-link {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
}
.sidebar--menu-icon .menu-icon li:hover .icon-popover {
  visibility: visible;
  opacity: 1;
  transform: translateY(-50%) translateX(0%);
}
.sidebar--menu-icon .user-profile {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  transition: background-color 400ms ease-in-out;
  margin-top: auto;
  z-index: 10;
}
.sidebar--menu-icon .user-profile > * {
  width: 1.938rem !important;
  height: 1.938rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
}
.sidebar--menu-icon .user-profile img {
  object-fit: cover;
}
.sidebar--menu-icon .user-profile .no-image {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
}
.sidebar--menu-icon .user-profile li a {
  display: flex;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 200ms ease-in-out;
}
.sidebar--menu-icon .user-profile li.hasSubmenu > a {
  padding-right: 1.875rem;
}
.sidebar--menu-icon .user-profile li.hasSubmenu li a {
  padding-left: 1.75rem;
  padding-right: 0.75rem;
  font-size: 0.75rem;
  font-weight: 500;
}
.sidebar--menu-icon .user-profile li.hasSubmenu > a::after {
  content: "❯";
  font-size: 0.813rem;
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 200ms ease-in-out;
}
.sidebar--menu-icon .user-profile li.hasSubmenu.showMenu > a::after {
  transform: translateY(-50%) rotate(90deg);
}
.sidebar--menu-icon .user-profile .hasSubmenu li {
  display: flex;
}
.sidebar--menu-icon .user-profile li.hasSubmenu > ul {
  overflow: hidden;
  height: 0;
  transform: translateY(-50%) scaleY(0);
  transition: transform 300ms ease-in-out;
}
.sidebar--menu-icon .user-profile li.hasSubmenu.showMenu > ul {
  transform: translateY(0%) scaleY(1);
  height: 100%;
}
.sidebar--menu-icon .menu-list:not(.user-detail) > li > a {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1.563rem;
}
.sidebar--menu-icon .sidebar--collapse-btn {
  position: absolute;
  width: 1rem !important;
  height: 1rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  left: 3.75rem;
  top: 3.313rem;
  transform: translateX(-50%);
  z-index: 9999;
  font-size: 0.5rem;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 400ms ease-in-out;
}
.sidebar--menu-icon .sidebar--collapse-btn i {
  position: absolute;
  top: 50%;
  left: 52%;
  transform: translate(-50%, -50%);
}
.sidebar--menu-list {
  display: flex;
  flex-direction: initial;
  justify-content: initial;
  align-items: initial;
  z-index: 999;
  position: relative;
  width: 0;
  transition: width 200ms ease-in-out, background-color 400ms ease-in-out;
  box-shadow: inset 0rem 0rem 0.25rem rgba(0, 0, 0, 0.15);
}
.sidebar--menu-list ul {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 0;
}
.sidebar .menu-list {
  padding-top: 1.25rem;
  padding-bottom: 4.063rem;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  position: absolute;
  opacity: 0;
  left: -100%;
}
.sidebar .menu-list.user-detail {
  opacity: 1;
}
.sidebar .menu-list.active {
  left: 0;
  opacity: 1;
  transition: left 200ms ease-in-out, opacity 200ms 200ms;
}
.sidebar .menu-list li {
  display: flex;
  flex-direction: column;
}
.sidebar .menu-list li a {
  display: flex;
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 200ms ease-in;
}
.sidebar .menu-list li.hasSubmenu > a {
  padding-right: 1.875rem;
}
.sidebar .menu-list li.hasSubmenu li a {
  padding-left: 1.5rem;
  padding-right: 0.625rem;
  font-size: 0.875rem;
  font-weight: 500;
}
.sidebar .menu-list li.hasSubmenu > a::after {
  content: "❯";
  font-size: 0.625rem;
  position: absolute;
  right: 0.938rem;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: transform 200ms ease-in-out;
}
.sidebar .menu-list li.hasSubmenu.showMenu > a::after {
  transform: translateY(-50%) rotate(90deg);
}
.sidebar .menu-list .hasSubmenu li {
  display: flex;
}
.sidebar .menu-list li.hasSubmenu > ul {
  overflow: hidden;
  height: 0;
  transform: translateY(-50%) scaleY(0);
  transition: transform 300ms ease-in-out;
}
.sidebar .menu-list li.hasSubmenu.showMenu > ul {
  transform: translateY(0%) scaleY(1);
  height: 100%;
}
.sidebar .menu-list .hasSubmenu li a.subChild.active::before, .sidebar .menu-list > li a.active:not(.subParent)::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 0.188rem;
  height: 0.938rem;
  border-radius: 0 1rem 1rem 0;
}
.sidebar .menu-list:not(.user-detail) > li > a {
  padding: 0rem 1rem;
}
.sidebar .menu-list:not(.user-detail) > li > a.subParent {
  padding-right: 1.688rem;
}
.sidebar .menu-list.user-detail > li > a {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.sidebar .menu-list.user-detail {
  position: relative;
  inset: 0;
  list-style-type: none;
  overflow: hidden;
  pointer-events: none;
}
.sidebar .menu-list.user-detail li {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4.063rem;
  display: flex;
  flex-direction: initial;
  justify-content: space-between;
  align-items: center;
  padding: 0.813rem 0.75rem;
  pointer-events: all;
}
.sidebar .menu-list.user-detail li a {
  line-height: unset;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
.sidebar .menu-list.user-detail li a:hover {
  background: unset !important;
}
.sidebar .menu-list.user-detail li i {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  width: 2.5rem !important;
  height: 2.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1.375rem;
}
.sidebar .menu-list.user-detail .user-name {
  font-size: 0.875;
}
.sidebar .menu-list.user-detail .user-desg {
  font-size: 0.75rem;
}
.sidebar .menu-list.user-detail .user-name,
.sidebar .menu-list.user-detail .user-desg {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  text-transform: capitalize;
}

.expand .sidebar--collapse-btn {
  left: 15rem !important;
  transform: translateX(-50%) rotate(180deg) !important;
}

.hasSubmenu.showMenu {
  position: relative;
}
.hasSubmenu.showMenu:after {
  content: "";
  position: absolute;
  bottom: 0.5rem;
  width: calc(100% - 2rem);
  height: 0.0625rem;
  left: 50%;
  transform: translateX(-50%);
}
.hasSubmenu.showMenu ul {
  padding-bottom: 1rem;
}

.sidebar--menu-icon {
  width: 3.75rem;
}
.sidebar--menu-icon .brand-logo img {
  width: 1.48rem;
}
.sidebar--menu-icon .menu-icon li {
  min-height: calc(3.75rem - 0.75rem);
}
.sidebar--menu-icon .menu-icon li a.icon-link {
  width: calc(3.75rem - 1.25rem) !important;
  height: calc(3.75rem - 1.25rem) !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  font-size: 1.25rem;
}
.sidebar--menu-icon .user-profile {
  min-height: 4.063rem;
}
.sidebar--menu-icon .user-profile > * {
  width: 2.375rem !important;
  height: 2.375rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
}
.sidebar--menu-list.active {
  width: 11.25rem;
}
.sidebar--menu-list .menu-list li a {
  line-height: 2.188rem;
  height: 2.188rem;
}

.sidebar .sidebar--menu-icon {
  background-color: var(--sidebar-icon-bg-main);
}
.sidebar .sidebar--menu-icon .menu-icon::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 5.5rem;
}
.sidebar .sidebar--menu-icon .menu-icon::-webkit-scrollbar-thumb {
  background: var(--sidebar-icon-bg-hover);
  border-radius: 5.5rem;
}
.sidebar .sidebar--menu-icon .menu-icon::-webkit-scrollbar-track {
  background: var(--sidebar-icon-bg-main);
  border-radius: 5.5rem;
}
.sidebar .sidebar--menu-icon .menu-icon {
  scrollbar-face-color: var(--sidebar-icon-bg-hover);
  scrollbar-track-color: var(--sidebar-icon-bg-main);
}
.sidebar .sidebar--menu-icon .menu-icon {
  scrollbar-color: var(--sidebar-icon-bg-hover) var(--sidebar-icon-bg-main) !important;
  scrollbar-width: thin;
}
.sidebar .sidebar--menu-icon .menu-icon li a.icon-link {
  color: var(--sidebar-icon-color);
}
.sidebar .sidebar--menu-icon .menu-icon li.active a.icon-link {
  color: var(--sidebar-icon-color-active);
  background-color: var(--sidebar-icon-bg-active);
}
.sidebar .sidebar--menu-icon .menu-icon li a.icon-link:hover {
  color: var(--sidebar-icon-color-hover);
  background-color: var(--sidebar-icon-bg-hover);
  font-size: 1.5rem;
}
.sidebar .sidebar--menu-icon .user-profile.active {
  background-color: #2e3840;
}
.sidebar .sidebar--menu-icon .user-profile.active > * {
  border: 0.125rem solid var(--sidebar-list-bg-active);
}
.sidebar .sidebar--menu-icon .user-profile.active > *:hover {
  border: 0.125rem solid var(--sidebar-icon-color);
}
.sidebar .sidebar--menu-icon .user-profile > *:hover {
  border: 0.125rem solid var(--sidebar-icon-color);
}
.sidebar .sidebar--menu-icon .user-profile .no-image {
  color: #324552;
  background-color: #daedfb;
}
.sidebar .sidebar--menu-icon .user-profile li a {
  color: #ffffff;
}
.sidebar .sidebar--menu-icon .user-profile li a:hover {
  background-color: #1c2731;
}
.sidebar .sidebar--menu-icon .user-profile li.active:not(.hasSubmenu) > a {
  color: #0078ce;
}
.sidebar .sidebar--menu-icon .user-profile li.hasSubmenu.active > a {
  color: #0078ce;
}
.sidebar .sidebar--menu-icon .user-profile .hasSubmenu li a.active,
.sidebar .sidebar--menu-icon .user-profile .hasSubmenu.active > li a, .sidebar .sidebar--menu-icon .user-profile > li a.active {
  color: #0078ce;
}
.sidebar .sidebar--menu-icon .sidebar--collapse-btn {
  color: #fff;
  background: #566976;
}
.sidebar .sidebar--menu-icon .sidebar--collapse-btn:hover {
  background-color: #31aab7;
}
.sidebar .sidebar--menu-list {
  background-color: var(--sidebar-list-bg-main);
}
.sidebar .sidebar--menu-list .menu-list::-webkit-scrollbar {
  width: 0.3125rem;
  height: 0.3125rem;
  border-radius: 5.5rem;
}
.sidebar .sidebar--menu-list .menu-list::-webkit-scrollbar-thumb {
  background: var(--sidebar-list-color);
  border-radius: 5.5rem;
}
.sidebar .sidebar--menu-list .menu-list::-webkit-scrollbar-track {
  background: var(--sidebar-list-bg-main);
  border-radius: 5.5rem;
}
.sidebar .sidebar--menu-list .menu-list {
  scrollbar-face-color: var(--sidebar-list-color);
  scrollbar-track-color: var(--sidebar-list-bg-main);
}
.sidebar .sidebar--menu-list .menu-list {
  scrollbar-color: var(--sidebar-list-color) var(--sidebar-list-bg-main) !important;
  scrollbar-width: thin;
}
.sidebar .sidebar--menu-list .menu-list li a {
  color: var(--sidebar-list-color);
}
.sidebar .sidebar--menu-list .menu-list li a:hover {
  color: var(--sidebar-list-color-hover);
  background: linear-gradient(90deg, var(--sidebar-list-bg-hover) 0%, var(--sidebar-list-bg-main) 100%);
}
.sidebar .sidebar--menu-list .menu-list li.active:not(.hasSubmenu) > a {
  color: #0078ce;
}
.sidebar .sidebar--menu-list .menu-list li.hasSubmenu.active > a {
  color: #0078ce;
}
.sidebar .sidebar--menu-list .menu-list .hasSubmenu li a.subChild.active, .sidebar .sidebar--menu-list .menu-list > li a.active:not(.subParent) {
  color: var(--sidebar-list-color-active);
}
.sidebar .sidebar--menu-list .menu-list .hasSubmenu li a.subChild.active::before, .sidebar .sidebar--menu-list .menu-list > li a.active:not(.subParent)::before {
  background-color: var(--sidebar-list-color-active);
}
.sidebar .menu-list.user-detail li {
  background-color: #2e3840;
}
.sidebar .menu-list.user-detail li i {
  color: #566976;
}
.sidebar .menu-list.user-detail li i:hover {
  background-color: #141f28;
}
.sidebar .menu-list.user-detail .user-name {
  color: #fff;
}
.sidebar .menu-list.user-detail .user-desg {
  color: #acbac3;
}
.sidebar .hasSubmenu.showMenu:after {
  background-color: #566976;
}

.sidebar.skizzle-default {
  --sidebar-icon-bg-main: #111b22;
  --sidebar-icon-color: #C3CBD1;
  --sidebar-icon-color-hover: #ffffff;
  --sidebar-icon-color-active: #ffffff;
  --sidebar-icon-bg-hover: #353D43;
  --sidebar-icon-bg-active: #0078CE;
  --sidebar-list-bg-main: #17222b;
  --sidebar-list-color: #ACBAC3;
  --sidebar-list-color-hover: #ffffff;
  --sidebar-list-color-active: #0078ce;
  --sidebar-list-bg-hover: #17222b;
  --sidebar-list-bg-active: #152b3c;
  --sidebar-popover-color: #ffffff;
  --sidebar-popover-bg: #324552;
}
.sidebar.skizzle-bright-white {
  --sidebar-icon-bg-main: #ffffff;
  --sidebar-icon-color: #324552;
  --sidebar-icon-color-hover: #7d92a1;
  --sidebar-icon-color-active: #0078ce;
  --sidebar-icon-bg-hover: #353d43;
  --sidebar-icon-bg-active: #f2f5f6;
  --sidebar-list-bg-main: #ffffff;
  --sidebar-list-color: #acbac3;
  --sidebar-list-color-hover: #bdcbd4;
  --sidebar-list-color-active: #0078ce;
  --sidebar-list-bg-hover: #f2f5f6;
  --sidebar-list-bg-active: #152b3c;
  --sidebar-popover-color: #ffffff;
  --sidebar-popover-bg: #324552;
}
.sidebar.skizzle-space-blue {
  --sidebar-icon-bg-main: #033b4f;
  --sidebar-icon-color: #acbac3;
  --sidebar-icon-color-active: #ffffff;
  --sidebar-icon-bg-active: #012e3e;
  --sidebar-list-bg-main: #00455f;
  --sidebar-list-color: #acbac3;
  --sidebar-list-bg-active: #0078ce;
  --sidebar-list-bg-hover: #1e6079;
}
.sidebar.skizzle-barn-red {
  --sidebar-icon-bg-main: #370e06;
  --sidebar-icon-color: #566976;
  --sidebar-icon-color-active: #ffffff;
  --sidebar-icon-bg-active: #4d291f;
  --sidebar-list-bg-main: #4e1c1b;
  --sidebar-list-color: #acbac3;
  --sidebar-list-bg-active: #0078ce;
  --sidebar-list-bg-hover: #622d2c;
}
.sidebar.skizzle-eminence {
  --sidebar-icon-bg-main: #1a102a;
  --sidebar-icon-color: #566976;
  --sidebar-icon-color-active: #ffffff;
  --sidebar-icon-bg-active: #5e389a;
  --sidebar-list-bg-main: #3a2855;
  --sidebar-list-color: #acbac3;
  --sidebar-list-bg-active: #0078ce;
  --sidebar-list-bg-hover: #49316c;
}

.theme-box {
  position: relative;
  width: 4rem;
  height: 4rem;
  outline: 0.125rem solid #e4e8ea;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  transition: all ease-in 400ms;
}
.theme-box .theme-primary,
.theme-box .theme-secondary {
  width: calc(4rem/ 2);
  height: 4rem;
}
.theme-box .theme-primary {
  border-radius: 4rem 0 0 4rem;
}
.theme-box .theme-secondary {
  border-radius: 0 4rem 4rem 0;
}
.theme-box.active {
  outline: 0.25rem solid rgba(54, 157, 232, 0.87);
}
.theme-box.active:after {
  content: "";
  font-family: bootstrap-icons !important;
  background-color: #0078ce;
  width: 1.625rem !important;
  height: 1.625rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border-radius: 50%;
  position: absolute;
  right: -0.3125rem;
  top: -0.3125rem;
  color: #fff;
  padding-top: 0.0625rem;
}

.theme-default .theme-primary {
  background-color: #566976;
}
.theme-default .theme-secondary {
  background-color: #111b22;
}

.theme-bright-white .theme-primary {
  background-color: #ffffff;
}
.theme-bright-white .theme-secondary {
  background-color: #f2f5f6;
}

.theme-space-blue .theme-primary {
  background-color: #00455f;
}
.theme-space-blue .theme-secondary {
  background-color: #012e3e;
}

.theme-barn-red .theme-primary {
  background-color: #4e1c1b;
}
.theme-barn-red .theme-secondary {
  background-color: #360e06;
}

.theme-eminence .theme-primary {
  background-color: #3a2855;
}
.theme-eminence .theme-secondary {
  background-color: #1a102a;
}

.ngu-touch-container {
  width: calc(100% - 4rem);
  margin: 0 auto;
  overflow: hidden;
}
.ngu-touch-container .item {
  padding-bottom: 0.75rem;
}
.ngu-touch-container .tile {
  box-shadow: none !important;
  height: 100%;
}

.leftRs,
.rightRs {
  background: #eaedef;
  border-radius: 50%;
  width: 2rem !important;
  height: 2rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  border: none;
  box-shadow: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.leftRs img,
.rightRs img {
  width: 0.875rem;
}

.rightRs {
  right: 0;
}

.custom-carousel .ngu-touch-container {
  width: 100%;
  padding: 1rem 0;
}
.custom-carousel .ngucarousel-items .item {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.custom-carousel .ngucarousel-items .item:last-child {
  padding-right: 0rem;
}
.custom-carousel .leftRs,
.custom-carousel .rightRs {
  transition: opacity 100ms ease-in;
  z-index: 1;
  border: 1px solid #e4e8ea;
  background-color: #ffffff;
  color: #324552;
  font-size: 0.8125rem;
  box-shadow: 0px 2px 4px rgba(105, 112, 117, 0.2);
}
@media (min-width: 1440px) {
  .custom-carousel .leftRs,
.custom-carousel .rightRs {
    border: 0.0625rem solid #e4e8ea;
  }
}

.carousel-c1 {
  position: relative;
}
.carousel-c1 .carousel-items {
  display: flex;
  gap: 1rem;
  overflow: auto;
}
.carousel-c1 .carousel-items {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}
.carousel-c1 .carousel-items::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}
.carousel-c1 button {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.75rem;
}
.carousel-c1 button:hover, .carousel-c1 button:active {
  background-color: #ffffff !important;
}
.carousel-c1 .btnLeft {
  left: 0;
}
.carousel-c1 .btnRight {
  right: 0;
}

.accordion-reset > .card {
  box-shadow: none;
}
.accordion-reset > .card .card-header {
  --bs-card-cap-padding-y: 0;
  --bs-card-cap-padding-x: 0;
  --bs-card-border-color: #dee3e7;
}
.accordion-reset > .card .card-header button {
  width: 100%;
}
.accordion-reset > .card .card-body {
  padding: 0;
}

.custom-accordion,
.custom-accordion1 {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.custom-accordion .card,
.custom-accordion1 .card {
  box-shadow: none;
  border: 0.0625rem solid #e4e8ea;
  border-radius: 0.25rem;
}
.custom-accordion .card-header,
.custom-accordion1 .card-header {
  padding: 0;
}
.custom-accordion .accordion-button:focus,
.custom-accordion1 .accordion-button:focus {
  box-shadow: none;
}

.custom-accordion .card-header {
  border-bottom: none;
  border-radius: 0.25rem 0.25rem 0 0;
}
.custom-accordion .accordion-button {
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 1rem;
}
.custom-accordion .accordion-button.collapsed {
  border-radius: 0.25rem;
}
.custom-accordion .accordion-button:not(.collapsed):focus {
  box-shadow: inset 0 -0.0625rem 0 rgba(0, 0, 0, 0.125);
}
.custom-accordion .accordion-button::after {
  font-family: "icomoon" !important;
  content: "";
  background-image: none;
}
.custom-accordion .accordion-button:not(.collapsed) {
  background-color: #fff;
  color: #324552;
}
.custom-accordion .card-body {
  background: #f8fafb;
  padding: 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
}

.custom-accordion1 > .card {
  padding: 0 1.5rem;
}
.custom-accordion1 .card-header {
  background-color: #fff;
  border: none;
}
.custom-accordion1 .accordion-button {
  padding: 1rem 0;
  background-color: #fff;
  color: #324552;
  border-bottom: 0.0625rem solid #dee3e7;
  box-shadow: none;
  line-height: 1.313rem;
}
.custom-accordion1 .accordion-button.collapsed {
  border: none;
}
.custom-accordion1 .accordion-button::before {
  flex-shrink: 0;
  width: 0.875rem;
  height: 0.875rem;
  content: "";
  background-repeat: no-repeat;
  background-size: 0.875rem;
  transition: transform 0.2s ease-in-out;
  background-image: url("data:image/svg+xml,%3Csvg width='13' height='13' viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.6852 12.9077L1.35189 12.9077C0.998266 12.9077 0.659127 12.7672 0.409079 12.5172C0.15903 12.2671 0.0185547 11.928 0.0185547 11.5744L0.0185547 2.24105C0.0185547 1.88743 0.15903 1.54829 0.409079 1.29824C0.659127 1.04819 0.998266 0.907715 1.35189 0.907715L10.6852 0.907715C11.0388 0.907715 11.378 1.04819 11.628 1.29824C11.8781 1.54829 12.0186 1.88743 12.0186 2.24105L12.0186 11.5744C12.0186 11.928 11.8781 12.2671 11.628 12.5172C11.378 12.7672 11.0388 12.9077 10.6852 12.9077ZM1.35189 2.24105L1.35189 11.5744L10.6852 11.5744L10.6852 2.24105L1.35189 2.24105ZM6.68522 10.241L5.35189 10.241L5.35189 7.57438L2.68522 7.57438L2.68522 6.24105L5.35189 6.24105L5.35189 3.57438L6.68522 3.57438L6.68522 6.24105L9.35189 6.24105L9.35189 7.57438L6.68522 7.57438L6.68522 10.241Z' fill='%2331AAB7'/%3E%3C/svg%3E%0A");
  margin-right: 0.5rem;
}
.custom-accordion1 .accordion-button:not(.collapsed)::before {
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.9005 1.52515L2.1288 1.52515C1.51563 1.52515 1.01855 2.02222 1.01855 2.63539L1.01855 10.4071C1.01855 11.0203 1.51563 11.5173 2.1288 11.5173L9.9005 11.5173C10.5137 11.5173 11.0107 11.0203 11.0107 10.4071L11.0107 2.63539C11.0107 2.02222 10.5137 1.52515 9.9005 1.52515Z' stroke='%2331AAB7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3.79395 6.52124L8.23492 6.52124' stroke='%2331AAB7' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
}
.custom-accordion1 .accordion-button::after {
  content: none;
}
.custom-accordion1 .card-body {
  padding: 1rem 0;
}

.custom-accordion1.border-0 > .card,
.custom-accordion.border-0 > .card {
  border: none !important;
}

.custom-accordion3 > .card {
  --bs-card-border-radius: 0.25rem;
  --bs-card-inner-border-radius: 0.25rem;
  box-shadow: none;
  border: none;
}
.custom-accordion3 > .card .card-header {
  --bs-card-cap-padding-y: 0;
  --bs-card-cap-padding-x: 0;
  --bs-card-border-width: 0;
}
.custom-accordion3 .accordion-button {
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-active-color: $high-contrast-light;
  --bs-accordion-active-bg: #fff;
  --bs-accordion-btn-focus-border-color: none;
  border-width: 0 !important;
  box-shadow: none !important;
  flex-direction: row;
}
.custom-accordion3 .accordion-button::after {
  background-image: none !important;
  font-family: "icomoon" !important;
  content: "";
  font-size: 1.5rem;
  color: #31aab7;
}
.custom-accordion3 .accordion-button:not(.collapsed)::after {
  transform: none;
  content: "";
}

.accordion-c1 > .card button {
  position: relative;
  padding-right: 3rem !important;
}
.accordion-c1 > .card button::after {
  position: absolute;
  content: "";
  font-family: bootstrap-icons !important;
  right: 2.0625rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all ease-in-out 200ms;
  color: #324552;
  font-size: 12px;
}
.accordion-c1 > .card button.collapsed::after {
  transform: translateY(-50%) rotateZ(180deg);
}

.panel-white > .card .card-body {
  background-color: #fff;
}

.panel-p-0 > .card .card-body {
  padding: 0;
}

.formula-editor {
  height: 5.625rem;
  display: flex;
  flex-direction: row;
  overflow-y: auto;
  flex-wrap: wrap;
  align-items: start;
}
.formula-editor .edit-curser {
  color: #31aab7;
  font-size: 1.25rem;
  padding-left: 0.25rem;
  line-height: 1.25rem;
  animation: cursor-blink 1.5s steps(2) infinite;
}
.formula-editor .condition, .formula-editor .operator, .formula-editor .number, .formula-editor .tags, .formula-editor .component {
  padding-right: 0.25rem;
  transition: all 400ms ease-in-out;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.formula-editor .condition .icon-close, .formula-editor .operator .icon-close, .formula-editor .number .icon-close, .formula-editor .tags .icon-close, .formula-editor .component .icon-close {
  opacity: 0;
  color: #566976;
  position: absolute;
  right: 0rem;
  top: -0.688rem;
  border-radius: 1.25rem 1.25rem 0 0;
}
.formula-editor .condition:hover, .formula-editor .operator:hover, .formula-editor .number:hover, .formula-editor .tags:hover, .formula-editor .component:hover {
  background-color: #f0f0f0eb;
  border-radius: 0.25rem;
  padding-left: 0.25rem;
  padding-right: 0.5rem;
  border-radius: 1.25rem 0 1.25rem 1.25rem;
  cursor: pointer;
  font-size: 0.75rem;
  margin-top: 0.063rem;
  word-break: break-word;
  overflow: unset;
  white-space: unset;
}
.formula-editor .condition:hover .icon-close, .formula-editor .operator:hover .icon-close, .formula-editor .number:hover .icon-close, .formula-editor .tags:hover .icon-close, .formula-editor .component:hover .icon-close {
  opacity: 0.8;
  padding: 0.125rem;
  background-color: #f0f0f0eb;
}
.formula-editor .condition {
  color: #28A745;
}
.formula-editor .operator {
  color: #324552;
}
.formula-editor .number {
  color: #0078CE;
}
.formula-editor .tags {
  color: #CE00C6;
}
.formula-editor .component {
  color: #CEA100;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}
.btn-editor {
  background-color: #F8FAFB;
  border: 0.0625rem solid #E4E8EA;
  cursor: pointer;
}
.btn-editor:hover {
  background-color: #F0F0F0;
}
.btn-editor:active {
  background-color: #ECECEC;
}

.btn-operator {
  max-width: 3.438rem;
  width: calc(33.33% - 0.375rem);
  aspect-ratio: 1;
  border-radius: 0.5rem;
}

.btn-condition {
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.btn-tags {
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.operator-box {
  height: calc( 100vh - (3.625rem + 4.5rem + 1.5rem + 1.5rem + 1.5rem + 4.5rem + 4rem + 5.625rem + 3.5rem + 2.625rem ));
  overflow-y: auto;
}

.formula-box {
  height: calc( 100vh - (3.625rem + 4.5rem + 1.5rem + 1.5rem + 1.5rem + 4.5rem + 4rem + 5.625rem + 3.5rem + 3rem + 2.188rem));
  overflow-y: auto;
}

.btn-validate {
  overflow: hidden;
  position: relative;
  border: none;
}
.btn-validate:after {
  content: "VALIDATED";
  background: #DFF0D8;
  inset: 0;
  color: #28A745;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.125rem;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='12' viewBox='0 0 15 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L14 1' stroke='%2328A745' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 1.125rem center;
  padding-left: 1.3125rem;
  background-size: 0.8125rem;
  transition: all 200ms ease-in;
  transition-delay: 400ms;
}

.btn-validate::before {
  position: absolute;
  content: "";
  background: #DFF0D8;
  border-radius: 3.125rem;
  transition: all 300ms ease-in;
  z-index: 2;
  width: 0;
  height: 0;
  scale: 0;
}
.btn-validate:after {
  left: 100%;
  opacity: 0;
  z-index: 4;
}

.btn-validated::before {
  scale: 1;
  border-radius: 0.125rem;
  width: 8.75rem;
  height: 8.75rem;
}
.btn-validated::after {
  left: 0;
  opacity: 1;
}

.main-sidebar.expand ~ .main-container .search-wrap {
  width: calc(100% - 15rem);
}

.main-sidebar:not(.expand) ~ .main-container .search-wrap {
  width: calc(100% - 3.75rem);
}

.card-ribbon {
  position: relative;
}
.card-ribbon .ribbon {
  position: absolute;
}
.card-ribbon .ribbon.ribbon-right {
  right: 0;
}

.ribbon {
  height: 1.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0.519rem 0 1.526rem;
  font-weight: 500;
  font-size: 0.75rem;
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 12% 50%, 0% 0%);
}
.ribbon.ribbon-sm {
  height: 1rem;
  font-size: 0.5rem;
  padding: 0 0.449rem 0 0.762rem;
}
.ribbon.ribbon-secondary {
  background-color: #566976;
  color: #fff;
}
.ribbon.ribbon-danger {
  background-color: #DC3545;
  color: #fff;
}
.ribbon.ribbon-primary {
  background-color: #0078CE;
  color: #fff;
}

.custom-orgchart-container {
  margin: 0 !important;
  border-width: 1px !important;
  border-radius: 0 !important;
  overflow: hidden !important;
}
@media (min-width: 1440px) {
  .custom-orgchart-container {
    border-width: 0.0625rem !important;
  }
}
.custom-orgchart-container .orgchart {
  background-image: none !important;
}
.custom-orgchart-container .oc-node {
  width: 14.4819rem !important;
  padding: 0 !important;
  border: none !important;
  font-family: var(--bs-font-sans-serif) !important;
}
.custom-orgchart-container .oc-group:before,
.custom-orgchart-container orgchart-node::before,
.custom-orgchart-container orgchart-node::after,
.custom-orgchart-container .oc-node:after {
  border-color: #acbac3 !important;
}
.custom-orgchart-container .oc-toggle-btn {
  inset: 0 !important;
  color: transparent !important;
  background-color: transparent !important;
  cursor: pointer;
}

orgchart-node {
  padding-bottom: 20px !important;
}

.org-card.uniquebg1 {
  border-bottom-color: #8ad7e0 !important;
}
.org-card.uniquebg2 {
  border-bottom-color: #e0bd75 !important;
}
.org-card.uniquebg3 {
  border-bottom-color: #eac2ac !important;
}
.org-card.uniquebg4 {
  border-bottom-color: #c0afdb !important;
}
.org-card.uniquebg5 {
  border-bottom-color: #96b2e0 !important;
}
.org-card.uniquebg6 {
  border-bottom-color: #8be0b0 !important;
}
.org-card.uniquebg7 {
  border-bottom-color: #d2a1e0 !important;
}
.org-card.uniquebg8 {
  border-bottom-color: #bce080 !important;
}

.org-card {
  display: flex;
  align-items: center;
  border: 1px solid #e4e8ea;
  border-radius: 0.25rem;
  width: 14.4819rem;
  background-color: #ffffff;
  padding: 0.75rem 1rem;
  border-bottom-width: 0.3125rem;
  border-bottom-style: solid;
  border-bottom-color: #96b2e0;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .org-card {
    border: 0.0625rem solid #e4e8ea;
  }
}
.org-card > .org-detail {
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;
}
.org-card.active .org-user-count {
  background-color: #0078ce;
  color: #fff;
  border-color: #0078ce;
}
.org-card.active .org-user-count::after {
  content: "";
}
.org-card .org-title {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.1875rem;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 14ch;
}
.org-card .org-desc {
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1.125rem;
  color: #7d92a1;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 21ch;
}
.org-card .org-avatar {
  border: 2px solid #ffffff;
  filter: drop-shadow(0 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
  border-radius: 50%;
  width: 2.875rem;
  aspect-ratio: 1;
  object-fit: contain;
  margin-right: 1rem;
}
@media (min-width: 1440px) {
  .org-card .org-avatar {
    border: 0.125rem solid #ffffff;
  }
}

.oc-groups {
  position: relative;
}

.custom-orgchart-container > .orgchart > orgchart-node > .oc-groups::before {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #acbac3;
  top: 10px;
  left: 0;
}

.custom-chart {
  --size: 50px;
  position: relative;
}
.custom-chart > orgchart-node {
  padding-top: var(--size);
}
.custom-chart > orgchart-node::after {
  all: unset !important;
}
.custom-chart .chart-upperlevel {
  position: absolute;
  top: var(--size);
  left: 50%;
  transform: translate(-50%, -70%);
  aspect-ratio: 1;
  height: var(--size);
  cursor: pointer;
}
.custom-chart .chart-upperlevel i {
  font-size: 1.5625rem;
  color: #acbac3;
}

.oc-group {
  padding-top: calc(20px * 2) !important;
  padding-left: 0px !important;
}
.oc-group::before {
  border-width: 0 !important;
}

.oc-groups {
  gap: 20px;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
}
.oc-groups::before {
  width: calc(100% - 14.4819rem) !important;
  top: 20px !important;
  left: 50% !important;
  transform: translateX(-50%);
  content: "" !important;
  height: 2px !important;
  background-color: #acbac3;
  all: unset !important;
}
.oc-groups::after {
  width: 2px !important;
  height: 20px !important;
  background-color: #acbac3;
  left: 50% !important;
  transform: translateX(-50%) !important;
  top: 0 !important;
  position: absolute;
  content: "";
}

orgchart-node {
  align-items: center;
}
orgchart-node:before {
  border-width: 0 !important;
  width: 2px !important;
  height: 20px !important;
  background-color: #acbac3;
  left: 50% !important;
  transform: translateX(-50%) !important;
  bottom: 100% !important;
  top: unset !important;
}

.oc-node::after {
  all: unset !important;
}

.oc-group {
  position: relative;
}
.oc-group:not(:first-child)::before {
  position: absolute;
  content: "";
  width: calc(50% + 20px) !important;
  height: 2px;
  left: -20px !important;
  top: 20px !important;
  background-color: #acbac3;
}
.oc-group:not(:last-child)::after {
  position: absolute;
  content: "";
  width: calc(50% + 20px) !important;
  height: 2px;
  left: 50% !important;
  top: 20px !important;
  background-color: #acbac3;
}

.oc-node {
  position: relative;
}
.oc-node .org-user-count {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  font-weight: 500;
  font-size: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.375rem;
  z-index: 2;
  border-radius: 0.9413rem;
  padding: 0.25rem 0.625rem;
  border: 1px solid #dee3e7;
  background-color: #ffffff;
  border-color: #dee3e7;
  cursor: pointer;
}
@media (min-width: 1440px) {
  .oc-node .org-user-count {
    border: 0.0625rem solid #dee3e7;
  }
}
.oc-node .org-user-count::after {
  content: "";
  font-family: bootstrap-icons !important;
}
.oc-node .oc-icon-minus ~ .org-user-count {
  background-color: #0078ce;
  color: #fff;
  border-color: #0078ce;
}
.oc-node .oc-icon-minus ~ .org-user-count::after {
  content: "";
}

.section-layout {
  display: flex;
  position: relative;
}

.section-main {
  width: calc(100% - var(--section-sideabar-width));
}
.section-main::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5.5rem;
}
.section-main::-webkit-scrollbar-thumb {
  background: #eff4f6;
  border-radius: 5.5rem;
}
.section-main::-webkit-scrollbar-track {
  background: #eff4f6;
  border-radius: 5.5rem;
}
.section-main {
  scrollbar-face-color: #eff4f6;
  scrollbar-track-color: #eff4f6;
}
.section-main {
  scrollbar-color: #eff4f6 #eff4f6 !important;
  scrollbar-width: thin;
}
.section-main:hover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5.5rem;
}
.section-main:hover::-webkit-scrollbar-thumb {
  background: rgba(50, 69, 82, 0.35);
  border-radius: 5.5rem;
}
.section-main:hover::-webkit-scrollbar-track {
  background: #f8fafb;
  border-radius: 5.5rem;
}
.section-main:hover {
  scrollbar-face-color: rgba(50, 69, 82, 0.35);
  scrollbar-track-color: #f8fafb;
}
.section-main:hover {
  scrollbar-color: rgba(50, 69, 82, 0.35) #f8fafb !important;
  scrollbar-width: thin;
}

.section-sidebar {
  background-color: #fff;
  width: var(--section-sideabar-width);
  overflow-y: scroll;
  flex-shrink: 0;
  height: calc(100vh - 3rem);
  position: sticky;
  top: 3rem;
}
.section-sidebar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5.5rem;
}
.section-sidebar::-webkit-scrollbar-thumb {
  background: #eff4f6;
  border-radius: 5.5rem;
}
.section-sidebar::-webkit-scrollbar-track {
  background: #eff4f6;
  border-radius: 5.5rem;
}
.section-sidebar {
  scrollbar-face-color: #eff4f6;
  scrollbar-track-color: #eff4f6;
}
.section-sidebar {
  scrollbar-color: #eff4f6 #eff4f6 !important;
  scrollbar-width: thin;
}
.section-sidebar:hover::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 5.5rem;
}
.section-sidebar:hover::-webkit-scrollbar-thumb {
  background: rgba(50, 69, 82, 0.35);
  border-radius: 5.5rem;
}
.section-sidebar:hover::-webkit-scrollbar-track {
  background: #f8fafb;
  border-radius: 5.5rem;
}
.section-sidebar:hover {
  scrollbar-face-color: rgba(50, 69, 82, 0.35);
  scrollbar-track-color: #f8fafb;
}
.section-sidebar:hover {
  scrollbar-color: rgba(50, 69, 82, 0.35) #f8fafb !important;
  scrollbar-width: thin;
}

.avatar-box {
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  background-color: rgba(109, 205, 216, 0.8);
  border-radius: 0.5rem;
  width: 3rem !important;
  height: 3rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
}

.overlap-avatar {
  height: 2rem;
  position: relative;
  width: 100%;
  z-index: 0;
}
.overlap-avatar .avatar-sm {
  position: absolute;
  z-index: 1000;
}

.overlap-left {
  left: calc(1.625rem * (-1));
}
.overlap-left .avatar-sm:nth-child(1) {
  left: calc(1.625rem * 1);
}
.overlap-left .avatar-sm:nth-child(2) {
  left: calc(1.625rem * 2);
}
.overlap-left .avatar-sm:nth-child(3) {
  left: calc(1.625rem * 3);
}
.overlap-left .avatar-sm:nth-child(4) {
  left: calc(1.625rem * 4);
}

.overlap-right {
  right: calc(1.625rem * (-1));
}
.overlap-right .avatar-sm:nth-child(1) {
  right: calc(1.625rem * 1);
}
.overlap-right .avatar-sm:nth-child(2) {
  right: calc(1.625rem * 2);
}
.overlap-right .avatar-sm:nth-child(3) {
  right: calc(1.625rem * 3);
}
.overlap-right .avatar-sm:nth-child(4) {
  right: calc(1.625rem * 4);
}

.overlap-avatar .avatar-sm:hover {
  outline: 2px solid #0078ce;
  z-index: 9999;
  transform: translateY(-4%);
  transition: transform ease-in-out 250ms;
}
@media (min-width: 1440px) {
  .overlap-avatar .avatar-sm:hover {
    outline: 0.125rem solid #0078ce;
  }
}

.avatar-overlap {
  --size: 2rem;
  --count: 5;
  --gap: 1.5rem;
  height: var(--size);
  width: calc((var(--gap) * var(--count)) + (var(--size) - var(--gap)));
  position: relative;
}
.avatar-overlap .avatar {
  width: var(--size) !important;
  height: var(--size) !important;
  min-height: unset !important;
  aspect-ratio: 1;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
  color: #324552;
  font-size: 0.75rem;
  border: 2px solid #ffffff;
  position: absolute;
}
.avatar-overlap .avatar:not(img) {
  background-color: #80c8fb;
}
.avatar-overlap .avatar:hover {
  outline: 2px solid #0078ce;
  z-index: 100;
  transform: translateY(-4%);
  transition: transform ease-in-out 250ms;
}
@media (min-width: 1440px) {
  .avatar-overlap .avatar:hover {
    outline: 0.125rem solid #0078ce;
  }
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(1) {
  left: calc(((1 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(2) {
  left: calc(((2 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(3) {
  left: calc(((3 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(4) {
  left: calc(((4 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(5) {
  left: calc(((5 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(6) {
  left: calc(((6 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(7) {
  left: calc(((7 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(8) {
  left: calc(((8 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(9) {
  left: calc(((9 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-left .avatar:nth-child(10) {
  left: calc(((10 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(1) {
  right: calc(((1 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(2) {
  right: calc(((2 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(3) {
  right: calc(((3 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(4) {
  right: calc(((4 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(5) {
  right: calc(((5 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(6) {
  right: calc(((6 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(7) {
  right: calc(((7 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(8) {
  right: calc(((8 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(9) {
  right: calc(((9 - 1)) * var(--gap));
}
.avatar-overlap.avatar-overlap-right .avatar:nth-child(10) {
  right: calc(((10 - 1)) * var(--gap));
}

.avatar-second {
  --color: #31aab7;
  width: 5rem;
}
.avatar-second::after {
  content: "2nd";
  bottom: 0.5rem;
}

.avatar-first {
  --color: #ffc107;
  width: 5.5rem;
}
.avatar-first::after {
  content: "1st";
  bottom: 0.1875rem;
}

.avatar-third {
  --color: #0078ce;
  width: 5rem;
}
.avatar-third::after {
  content: "3rd";
  bottom: 0.5rem;
}

.avatar-second,
.avatar-first,
.avatar-third {
  position: relative;
  border-radius: 50%;
  background-color: #f8fafb;
  aspect-ratio: 1;
}
.avatar-second > *,
.avatar-first > *,
.avatar-third > * {
  outline-width: 2px;
  outline-style: solid;
  border: 2px solid #fff;
  outline-color: var(--color);
}
.avatar-second::after,
.avatar-first::after,
.avatar-third::after {
  position: absolute;
  font-weight: 600;
  font-size: 0.625rem;
  color: #fff;
  background-color: var(--color);
  padding: 0.0625rem 0.375rem;
  border-radius: 16px;
}

.avatar-circle {
  object-fit: cover;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  text-transform: uppercase;
}

.avatar-border {
  border: 2px solid #ffffff;
  filter: drop-shadow(0rem 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
}
@media (min-width: 1440px) {
  .avatar-border {
    border: 0.125rem solid #ffffff;
  }
}

.avatar-border2 {
  border: 1px solid #e4e8ea;
}
@media (min-width: 1440px) {
  .avatar-border2 {
    border: 0.0625rem solid #e4e8ea;
  }
}

.avatar-border4 {
  border: 4px solid #ffffff;
  filter: drop-shadow(0rem 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
}
@media (min-width: 1440px) {
  .avatar-border4 {
    border: 0.25rem solid #ffffff;
  }
}

.avatar-sm {
  color: #fff;
  color: #324552;
  font-size: 0.75rem;
  object-fit: cover;
  border: 2px solid #ffffff;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
  text-transform: uppercase;
}
@media (min-width: 1440px) {
  .avatar-sm {
    border: 0.125rem solid #ffffff;
  }
}

ng-dropdown-panel .sq-24 {
  border-width: 0 !important;
}

.avatar-sm:not(img) {
  background-color: #0078ce;
}

.avatar-success {
  position: relative;
}

.avatar-success::before {
  content: "";
  font-family: "icomoon";
  position: absolute;
  inset: 0;
  color: #fff;
  display: flex;
  flex-direction: initial;
  justify-content: center;
  align-items: center;
  width: inherit !important;
  height: inherit !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  background-color: #28a745;
  border-radius: 50%;
}

.avatar-1st {
  --color: #ffc107;
}
.avatar-1st::after {
  content: "1st";
}

.avatar-2nd {
  --color: #31aab7;
}
.avatar-2nd::after {
  content: "2nd";
}

.avatar-3rd {
  --color: #0078ce;
}
.avatar-3rd::after {
  content: "3rd";
}

.avatar-1st,
.avatar-2nd,
.avatar-3rd {
  width: 5rem;
  padding: 0.375rem;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8fafb;
  position: relative;
  border-radius: 50%;
}
.avatar-1st::after,
.avatar-2nd::after,
.avatar-3rd::after {
  position: absolute;
  font-weight: 600;
  font-size: 0.625rem;
  color: #fff;
  background-color: var(--color);
  padding: 0.0625rem 0.375rem;
  border-radius: 16px;
  bottom: 0.5rem;
}
.avatar-1st .avatar,
.avatar-2nd .avatar,
.avatar-3rd .avatar {
  outline: 2px solid var(--color);
}
@media (min-width: 1440px) {
  .avatar-1st .avatar,
.avatar-2nd .avatar,
.avatar-3rd .avatar {
    outline: 0.125rem solid var(--color);
  }
}

.append-end {
  position: relative;
}
.append-end::after {
  content: "+3";
  color: #31aab7;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
}

.avatar-map-marker {
  display: inline-block;
  border-radius: 50%;
  border: 3px solid white;
  position: relative;
}
.avatar-map-marker::after {
  position: absolute;
  content: "";
  width: 0px;
  height: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #fff;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.map-marker-img {
  width: 1.625rem;
  /* Adjust the size as needed */
  height: 1.625rem;
  /* Adjust the size as needed */
  object-fit: cover;
  border-radius: 50%;
}

.avatar-marker {
  border-radius: 50%;
  border: 4px solid #fff;
  position: relative;
  outline: 1px solid #00000045;
}
.avatar-marker:after {
  width: 0;
  height: 0;
  content: "";
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 7px solid #fff;
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: -8px;
  transform: translateX(-50%);
}
.avatar-marker::before {
  width: 8px;
  height: 8px;
  content: "";
  display: inline-block;
  position: absolute;
  left: 50%;
  bottom: -10px;
  outline: 1px solid #00000045;
  background-color: #fff;
  z-index: -1;
  transform: rotate(45deg) translate(-5px, 1px);
  transform-origin: center;
}

.type-indicator {
  border-radius: 50%;
  display: inline-block;
}
.type-indicator.type-regularized {
  background-color: var(--bg-regularized);
}
.type-indicator.type-deduction {
  background-color: var(--bg-deduction);
}
.type-indicator.type-override {
  background-color: var(--bg-override);
}
.type-indicator.type-punch {
  background-color: var(--bg-punch);
}
.type-indicator.type-on-duty {
  background-color: var(--bg-onduty);
}
.type-indicator.type-late {
  background-color: var(--bg-late);
}
.type-indicator.type-weekoff {
  background-color: var(--bg-weekoff);
}
.type-indicator.type-leave {
  background-color: var(--bg-leave);
}
.type-indicator.type-present {
  background-color: var(--bg-present);
}
.type-indicator.type-absent {
  background-color: var(--bg-absent);
}
.type-indicator.type-holiday {
  background-color: var(--bg-holiday);
}
.type-indicator.type-national-holiday {
  background-color: var(--bg-national);
}
.type-indicator.type-floating-holiday {
  background-color: var(--bg-floating);
}
.type-indicator.type-regular-holiday {
  background-color: var(--bg-regular);
}
.type-indicator.type-WFH {
  background-color: var(--bg--WFH);
}
.type-indicator.type-early-checkout {
  background-color: var(--bg--early-checkout);
}
.type-indicator.type-overdue {
  background-color: var(--bg--overdue);
}
.type-indicator.type-on-behalf-request {
  background-color: var(--bg--on-behalf-request);
}

.ol-type-1 {
  padding: 0;
  margin: 0;
}
.ol-type-1 li {
  counter-increment: steps;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.ol-type-1 li::before {
  content: counter(steps);
  width: 1.5rem;
  aspect-ratio: 1;
  background-color: #80e0b4;
  color: #fff;
  font-size: 0.875rem;
  border-radius: 50%;
  font-weight: 500;
  margin-right: 0.5rem;
}

.timeline-c2 {
  counter-reset: item;
  list-style-type: none;
  padding-left: 3.25rem;
  transition: all ease-in 400ms;
}
.timeline-c2 li {
  position: relative;
  min-height: 2.5rem;
}
.timeline-c2 li:before {
  content: counter(item) "  ";
  counter-increment: item;
  font-size: 0.875rem;
  width: 2.5rem !important;
  height: 2.5rem !important;
  aspect-ratio: 1;
  flex-shrink: 0;
  position: absolute;
  top: calc((5.938rem / 2) + 1rem);
  transform: translateY(-50%);
  left: -3.25rem;
  border: 0.0625rem solid #acbac3;
  border-radius: 50%;
  background-color: #fff;
  z-index: 1;
  color: #acbac3;
  transition: all ease-in 400ms;
  background-position: center;
  background-size: 0.0625rem;
}
.timeline-c2 li:first-child::before {
  top: calc(5.938rem / 2);
}
.timeline-c2 li:not(:last-child)::after {
  position: absolute;
  content: "";
  left: -2rem;
  width: 0.0625rem;
  height: calc(100% - 2.5rem);
  background-color: #acbac3;
  top: 5.25rem;
}
.timeline-c2 li:first-child::after {
  top: 4.25rem;
  height: calc(100% - 1.5rem);
}
.timeline-c2 li.success:not(:hover, .disabled)::before {
  background-color: #28a745;
  border-color: transparent;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 0.875rem;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 5.66699L3.66667 8.33366L9.66667 1.66699' stroke='white' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
}
.timeline-c2 li.success:not(:hover, .disabled)::after {
  background-color: #28a745;
}
.timeline-c2 li:hover:not(.disabled)::before {
  border-color: #0078ce;
  color: #0078ce;
}
.timeline-c2 li:hover:not(.disabled) .card-hover {
  border-color: #0078ce;
  box-shadow: none;
  transition: all ease-in 400ms;
}
.timeline-c2 li.disabled::before, .timeline-c2 li.disabled::after {
  opacity: 0.5;
}
.timeline-c2 li.disabled .card-hover {
  border-color: rgba(228, 232, 234, 0.5);
  pointer-events: none;
}
.timeline-c2 li.disabled .card-hover > * {
  opacity: 0.5;
}
.timeline-c2 li.disabled .card {
  position: relative;
}
.timeline-c2 li.disabled .card::after {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  content: "COMING SOON";
  font-size: 0.5625rem;
  font-weight: 600;
  color: #fff;
  background: #ffc107;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.ul-leaderboard {
  --leader-boardcolor: transparent;
  counter-reset: item;
  list-style-type: none;
  padding: 0;
  margin: 0;
  transition: all ease-in 400ms;
}
.ul-leaderboard li + li {
  margin-top: 0.5rem;
}
.ul-leaderboard li > .card-c2 {
  position: relative;
}
.ul-leaderboard li > .card-c2::before {
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.125rem;
  transition: all ease-in 400ms;
}
.ul-leaderboard li > .card-c2 .card-body {
  padding-left: 2.0625rem !important;
  border-left: 0.375rem solid var(--leader-boardcolor);
  border-radius: 0.25rem 0 0 0.25rem;
}
.ul-leaderboard li > .card-c2 .avatar-circle {
  border: 2px solid #ffffff !important;
  outline: 2px solid var(--leader-boardcolor);
}
.ul-leaderboard li.first-place {
  --leader-boardcolor: #ffc107;
}
.ul-leaderboard li.first-place > .card-c2:before {
  color: var(--leader-boardcolor);
  content: "1";
}
.ul-leaderboard li.second-place {
  --leader-boardcolor: #31aab7;
}
.ul-leaderboard li.second-place > .card-c2:before {
  color: var(--leader-boardcolor);
  content: "2";
}
.ul-leaderboard li.third-place {
  --leader-boardcolor: #0078ce;
}
.ul-leaderboard li.third-place > .card-c2:before {
  color: var(--leader-boardcolor);
  content: "3";
}

.li-reset {
  margin-bottom: 0;
  padding-left: 1rem;
}

.li-hover li {
  cursor: pointer;
}
.li-hover li:hover {
  background-color: #0078CE1A;
}

.timeline-c3 {
  padding: 1rem 1rem 1rem 1.5rem;
  position: relative;
  list-style-type: none;
}
.timeline-c3::before {
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #C3CBD1;
  content: "";
  left: 1rem;
  top: 0;
}
.timeline-c3 > :first-child::before, .timeline-c3 > :last-child::before {
  position: absolute;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  content: "";
  background-color: #C3CBD1;
  left: 1rem;
  top: 0;
  transform: translateX(-50%);
}
.timeline-c3 > :last-child::before {
  top: calc(100% - 8px);
}
.timeline-c3 .timeline-li-1 {
  padding: 0.75rem;
  display: flex;
  gap: 0.5rem;
  font-size: 0.75rem;
}
.timeline-c3 .timeline-li-2 {
  padding: 0.75rem 0 0.75rem 0.5rem;
}
.timeline-c3 .timeline-li-2.active .card-c8 {
  background-color: #DCEAFB;
  border-color: #0066DD;
}
.timeline-c3 .li-card {
  position: relative;
}
.timeline-c3 .li-card::before, .timeline-c3 .li-card::after {
  content: "";
  position: absolute;
  width: 1rem;
  height: 1rem;
  background-color: #0078CE;
  border-radius: 50%;
  left: -1rem;
  top: 0.75rem;
}
.timeline-c3 .li-card::after {
  top: calc(100% - .75rem);
  transform: translateY(-100%);
}
.timeline-c3 .li-card.active .card-c8 {
  position: relative;
}
.timeline-c3 .li-card.active .card-c8::before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 1rem);
  background-color: #0078CE;
  left: calc(-1rem + -2px);
  top: 50%;
  transform: translateY(-50%);
}

.config-summernote .note-frame {
  border-radius: 0;
  border: none;
  height: calc(100vh - (3.625rem + 4.5rem));
  font-family: unset;
}
.config-summernote .note-editing-area {
  padding: 1.5rem;
  height: calc(100vh - (3.625rem + 8.5rem));
  overflow: auto !important;
}
.config-summernote .note-editable {
  padding: 3.125rem 2.5rem !important;
  min-height: calc(100vh - 16rem);
  background-color: #fff;
}
.config-summernote .note-editable p {
  margin-bottom: 0;
}
.config-summernote .note-statusbar {
  display: none;
}
.config-summernote .note-toolbar {
  padding: 0px !important;
  border: none;
  background-color: #fff;
}
.config-summernote .note-toolbar .note-btn-group {
  margin-top: 0px !important;
  margin-right: 0px !important;
}
.config-summernote .note-toolbar .note-btn-group :first-child {
  border-left-color: transparent;
}
.config-summernote .note-toolbar .note-btn {
  border-radius: 0px;
  border-top-color: transparent;
}

.table-summernote thead,
.table-summernote tbody,
.table-summernote tfoot,
.table-summernote tr,
.table-summernote td,
.table-summernote th {
  border-color: black !important;
  color: black;
}

.note-dropzone,
.note-placeholder {
  display: none !important;
}

.note-modal-content {
  border: none !important;
  box-shadow: none !important;
  border-radius: 1.5rem !important;
}
.note-modal-content .note-modal-title {
  color: #324552;
}
.note-modal-content .note-modal-header {
  border: none;
  padding: 1.5rem 1.5rem 1rem 2rem;
}
.note-modal-content .note-modal-body {
  padding: 0rem 1.5rem 1rem 2rem;
}
.note-modal-content .note-modal-footer {
  text-align: right;
  height: unset;
  padding: 1.5rem 1.5rem 1rem 2rem;
}
.note-modal-content .note-modal-footer .note-btn {
  float: unset;
  border-radius: 0.125rem;
  height: 2.5rem;
  font-size: 0.875rem;
  min-width: 8.75rem;
  font-weight: 600;
  font-family: "work sans";
}
.note-modal-content .note-form-group {
  padding-bottom: 1rem;
}
.note-modal-content .note-form-label {
  font-size: 0.875rem;
  line-height: 1.313rem;
  margin-bottom: 0.375rem !important;
  padding: 0 !important;
  font-weight: 400;
  font-family: "work sans";
  color: #324552;
}
.note-modal-content .note-form-control {
  border: 0.0625rem solid #dae2e8;
  border-radius: 0.25rem;
}

.note-modal-backdrop {
  display: none !important;
}

.note-editor .note-toolbar .note-color-all .note-dropdown-menu {
  min-width: auto !important;
  transform: translatex(calc(-50% + 28px));
}

.note-btn-group.open.note-color-all .note-dropdown-menu {
  display: flex !important;
}

.nav-vertical .nav-link,
.nav-vertical .dropdown-item {
  font-size: 0.75rem;
  padding: 0 2rem 0 1.5rem;
  display: flex;
}
.nav-vertical .nav-link.active,
.nav-vertical .dropdown-item.active {
  background: #0078ce1a;
  color: #0078ce !important;
  font-weight: 600;
}
.nav-vertical .nav-link.active::before,
.nav-vertical .dropdown-item.active::before {
  content: "";
  position: absolute;
  left: 0rem;
  top: 50%;
  transform: translateY(-50%);
  border-right: 0.25rem solid #0078ce;
  height: 50%;
  border-top-right-radius: 0.3125rem;
  border-bottom-right-radius: 0.3125rem;
  font-weight: 400;
}
.nav-vertical .nav-link.active::after,
.nav-vertical .dropdown-item.active::after {
  content: none !important;
}
.nav-vertical .nav-link:hover,
.nav-vertical .dropdown-item:hover {
  background-color: #0078ce1a;
  color: #324552;
}
.nav-vertical .nav-link :last-child:not(:first-child),
.nav-vertical .dropdown-item :last-child:not(:first-child) {
  width: 4ch;
  text-align: right;
  white-space: nowrap;
  margin-left: 0.5rem;
}
.nav-vertical .nav-link :first-child,
.nav-vertical .dropdown-item :first-child {
  width: calc(100% - 5ch);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.nav-vertical .nav-link :only-child,
.nav-vertical .dropdown-item :only-child {
  width: 100%;
}
.nav-vertical .nav-link {
  font-weight: 600;
  line-height: 2.5rem;
  height: 2.5rem;
  position: relative;
}
.nav-vertical .nav-link:focus {
  border: none;
}
.nav-vertical .nav-link::after {
  font-family: "icomoon";
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 0.5rem;
  font-weight: 400;
  border: none;
  transition: all ease-in-out 200ms;
}
.nav-vertical .dropdown-item {
  font-weight: 500;
  color: #566976;
  padding-left: 2rem !important;
  line-height: 2rem;
  height: 2rem;
  border-radius: 0 !important;
}
.nav-vertical .dropdown-menu {
  --bs-dropdown-item-padding-y: 0;
  position: static !important;
  transform: none !important;
  box-shadow: none;
  border-bottom: 1px solid #ACBAC3;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;
}
.nav-vertical .nav-item.dropdown .nav-link {
  padding-right: 2.5rem;
}
.nav-vertical .nav-item.dropdown .nav-link::after {
  content: "" !important;
}
.nav-vertical .nav-item.dropdown.show .nav-link::after {
  transform: translateY(-50%) rotate(90deg);
}
.nav-vertical .nav-item {
  display: block;
  width: 100%;
}

.nav-inverse .nav-link.active,
.nav-inverse .dropdown-item.active {
  background-color: #fff !important;
}
.nav-inverse .nav-link:hover,
.nav-inverse .dropdown-item:hover {
  background-color: #ffffffb0;
}