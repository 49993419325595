// .popup{
//     position: absolute;
//     color:#324552;
//     background-color: $component-background-01-light;
//     white-space: nowrap;

//     z-index: 1000;
//     border-radius: .25rem;
//     font-size: .875rem;
//     font-weight: 400;
//     padding: .5rem .75rem;
//     top: 50%;
//     transform: translateY(-50%) ;
//     box-shadow: 0rem .125rem .25rem 0rem #69707533;
// }
// .popup::before{
//     content: "";
//     border: .375rem solid transparent;
//     border-right-color: #fff;
//     position: absolute;
// }
// .popup.popup-right{
//     left: 100%;
// }
// .popup-right.popup::before{
//     right: 100%;
//     top: 50%;
//     transform: translateY(-50%);
// }
.popover {
  z-index: 9999;
}
.tips {
  position: relative;
  @extend .text-trim;
}
.tips-helper {
  // display: none;
  transform: scale(0);
  transform-origin: top left;
  position: absolute;
  background: #ffffff;
  border-radius: 0.25rem;
  max-width: 12.5rem;
  font-size: 0.75rem;
  padding: 0.5rem 0.75rem;
  filter: drop-shadow(0rem 0.125rem 0.25rem rgba(105, 112, 117, 0.2));
  transition: 300ms transform ease-in;
  transition-delay: 0.1s;
  z-index: 999;
}
.tips:hover + .tips-helper {
  // display: block;
  transform: scale(1);
}

.profile-popover {
  padding: 0;
  color: unset;
  width: 13.75rem;
  background-color: transparent;
  border: none;
  top: 1rem !important;
  // left:90% !important;
  // transform: unset !important;
  // bottom:1.5rem !important;
  left: -4.5rem !important;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  // bottom: 0;
  // transform: translate(5.5rem, 18.4375rem)!important;
  .popover-body {
    padding: 0;
  }
  .profile-link {
    cursor: pointer;
    border-radius: 0.5rem;
    &:hover {
      background: #eaedef80;
    }
  }
}
.error-popover {
  border: none;
  padding: 0.5rem 1.5rem 1rem;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
  left: 0.625rem !important;
  .popover-body {
    padding: 0;
  }
  .arrow {
    width: 0.625rem;
    height: 0.625rem;
    //   background-color: magenta;
    border-color: transparent #fff transparent transparent;
    border-style: solid;
    border-width: 0.625rem;
    left: -1.125rem;
    top: 1.5rem;
    position: absolute;
    top: 53% !important;
    transform: translateY(-50%);
  }
}
.popover-input {
  //reset
  .popover-body {
    padding: 0;
  }
  //
  padding: 0.5rem 0.75rem;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  border-radius: 0.125rem;
  font-size: 0.875rem;
  background-color: #fff;
  border-color: $medium-contrast-light;
  .popover-body {
    color: $medium-contrast-light;
  }
}

.popover-md {
  --bs-popover-max-width: 21.875rem;
}

.popover-default {
  // box-shadow: 0rem .5rem 1rem rgba(18, 27, 33, 0.1);
  box-shadow: 0rem 0.5rem 1rem 0rem #121b211a;
  border-radius: 0.25rem;
  border: none;
  .popover-body {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #324552;
  }
  &.popover-c2 {
    background-color: #566976;
    .arrow {
      background-color: #566976;
    }
    .popover-body {
      color: #fff;
    }
  }
  &.popover-alert {
    background-color: #dc3545;
    .arrow {
      background-color: #dc3545;
    }
    .popover-body {
      color: #fff;
    }
  }
}
// .popover {
//     font-family: "Work Sans";
// }
.popover-type1 {
  z-index: 2002;
  border: none;
  box-shadow: 0rem 1.5rem 8rem 0rem rgba(18, 27, 33, 0.4);

  .arrow {
    position: absolute;
    top: 50%;
    margin-top: unset !important;
    width: 0.5rem;
    height: 0.5rem;
    background-color: #566976;

    transform: translateY(-50%) rotateZ(45deg);
  }
  &.bs-popover-right {
    left: 0.438rem !important;
    .arrow {
      left: -0.25rem;
    }
  }
  &.bs-popover-left {
    // right: 0.438rem!important;
    .arrow {
      left: calc(100% - 0.25rem);
    }
  }
  .popover-body {
    padding: 0 1rem;
    color: #ffffff;
    background-color: #566976;
    height: 2.125rem;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.313rem;
    min-width: 6.5rem;
    justify-content: center;
    border-radius: 0.25rem;
  }
}
.popover {
  z-index: $zIndex-popover;
}
.user-list {
  border-radius: 0.25rem;
  &.single-user .popover-body {
    width: 11.563rem;
    // min-height:3.375rem;
  }
  &.multi-user .popover-body {
    width: 15.813rem;
    max-height: 14.688rem;
  }
  .popover-body {
    box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    overflow-x: hidden;
    overflow-y: auto;
    @include scrollbars(0.3125rem, #fff, #fff);
    &:hover {
      @include scrollbars(0.3125rem, #dfdfdf, #fff);
    }
    ul {
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;
      li {
        display: flex;
        align-items: center;
        padding: 0.5rem 0;
      }
      li + li {
        border-top: 0.0625rem solid #eaedef;
      }
    }
    .user-details {
      display: flex;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
    }
    .user-avatar {
      @include sq(2.125rem);
      border-radius: 50%;
      margin-right: 0.625rem;
      box-shadow: 0rem 0.125rem 0.25rem 0rem #69707533;
    }
    span.user-avatar {
      color: #fff;
      color: #324552;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 0.875rem;
      // background-color: #80e0b4;
    }
    // img.user-avatar{}
    .user-label {
      font-size: 0.875rem;
      font-weight: 500;
      word-break: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .user-code {
      font-size: 0.625rem;
      color: #7d92a1;
      text-transform: uppercase;
    }
  }
}
.draggable-popup {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 19.063rem;
  // mi-height: 6.313rem;
  border-radius: 0.5rem;
  // color: #ffffff;
  z-index: 9999;
  // height: 100%;
  cursor: move;
  // background-color: #0078CE;
  box-shadow: 0rem 0.5rem 1rem rgba(18, 27, 33, 0.1);
  &.popup-primary {
    background-color: #e3ecf7;
    height: 6.313rem;
  }
  &.popup-success {
    background-color: #e6f5e0;
    height: 7.75rem;
  }
  &.popup-danger {
    background-color: #fae9e9;
    height: 7.75rem;
    // color:$text-danger;
  }
}
.popover-c2 {
  --bs-popover-max-width: 21.75rem;
  .popover-body {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
}
.popover {
  .arrow {
    width: 0.5rem;
    height: 0.5rem;
    // background-color: #fff;
    position: absolute;
    margin: unset !important;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &.bs-popover-bottom {
    top: 0.313rem !important;
    .arrow {
      left: 50%;
      top: 0%;
    }
  }
  &.bs-popover-top {
    top: -0.313rem !important;
    .arrow {
      left: 50%;
      top: 100%;
    }
  }
  &.bs-popover-start,
  &.bs-popover-right {
    left: 0.438rem !important;
    .arrow {
      top: 50%;
      left: 0;
    }
  }
  &.bs-popover-end,
  &.bs-popover-left {
    left: -0.438rem !important;
    .arrow {
      top: 50%;
      left: 100%;
    }
  }
}
.popover-info {
  background-color: #566976;
  .arrow {
    background-color: #566976;
  }
  .popover-body {
    color: #fff;
  }
}
