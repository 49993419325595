.nav-vertical {
  .nav-link,
  .dropdown-item {
    font-size: .75rem;
    padding: 0 2rem 0 1.5rem;
    display: flex;
    &.active {
      background: #0078ce1a;
      color: #0078ce !important;
      font-weight: 600;
      &::before {
        content: "";
        position: absolute;
        left: 0rem;
        top: 50%;
        transform: translateY(-50%);
        border-right: 0.25rem solid #0078ce;
        height: 50%;
        border-top-right-radius: 0.3125rem;
        border-bottom-right-radius: 0.3125rem;
        font-weight: 400;
      }
      &::after {
        content: none !important;
      }
    }
    &:hover {
      background-color: #0078ce1a;
      color: #324552;
    }
    & :last-child:not(:first-child) {
      width: 4ch;
      text-align: right;
      white-space: nowrap;
      margin-left: 0.5rem;
    }

    & :first-child {
      width: calc(100% - 5ch);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }
    & :only-child {
      width: 100%;
    }
  }
  .nav-link {
    font-weight: 600;
    line-height: 2.5rem;
    height: 2.5rem;
    position: relative;


    &:focus {
      border: none;
    }
    &::after {
      font-family: "icomoon";
      position: absolute;
      right: 1.5rem;
      top: 50%;
      transform: translateY(-50%);
      font-size: .5rem;
      font-weight: 400;
      border: none;
      transition: all ease-in-out 200ms;
    }
  }
  .dropdown-item {
    font-weight: 500;
    color: #566976;
    padding-left: 2rem !important;
    line-height: 2rem;
    height: 2rem;
    border-radius: 0 !important;
  }
  .dropdown-menu {
    --bs-dropdown-item-padding-y: 0;
    position: static !important;
    transform: none !important;
    box-shadow: none;
    border-bottom: 1px solid #ACBAC3;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .nav-item.dropdown .nav-link {
    padding-right: 2.5rem;
    &::after {
      content: '\e973' !important; //chevron-right-lg
    }
  }
  .nav-item.dropdown.show .nav-link {
    &::after {
      transform: translateY(-50%) rotate(90deg);
    }
  }
  .nav-item {
    display: block;
    width: 100%;

  }
}
.nav-inverse {
  .nav-link.active,
  .dropdown-item.active {
    background-color: #fff !important;
  }
  .nav-link:hover,
  .dropdown-item:hover {
    background-color: #ffffffb0;
  }
}
